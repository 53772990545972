import { Select, SelectProps, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useActions } from '../../hooks';
import { useSelector } from 'react-redux';
import {
    getFiberSources as getFiberSourcesAction,
    getProviderCertificationsGetFiberSourcesState,
} from '../../store/actions/providerCertifications';
import { getSelectValue, selectValueOnChange } from '../../helpers/select';
import Translate from '../Translate';
import { Translation, ValueListItem, ValueListSlug } from '../../store/api/apiTypes';
import { translation } from '../../helpers';
import { getUser } from '../../store/actions/auth';

type SelectFiberSourceProps = SelectProps<any> & {
    onChange?: SelectProps<ValueListItem['id']>['onChange'];
    initialValue?: ValueListItem[];
    valueType?: 'id' | 'object';
};

const SelectFiberSource: FC<SelectFiberSourceProps> = ({ valueType = 'id', onChange, ...props }) => {
    const [value, setValue] = useState<any>();
    const [search] = useActions([getFiberSourcesAction.trigger]);
    const searchState = useSelector(getProviderCertificationsGetFiberSourcesState);
    const [initialValue, setInitialValue] = useState<ValueListItem[]>([]);
    const list = searchState?.data?.items ?? [];
    const user = useSelector(getUser);

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue);
            setInitialValue(props.initialValue);
        } else {
            setValue([]);
            setInitialValue([]);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setValue(getSelectValue(props.value));
    }, [props.value]);

    const onFocus: SelectProps<ValueListItem['id']>['onFocus'] = () => {
        search({
            valueListSlug: ValueListSlug.providerCertificationFiberSource,
            search: null,
        });
    };

    useEffect(() => {
        search({
            valueListSlug: ValueListSlug.providerCertificationFiberSource,
            search: null,
        });
    }, [search]);

    const onSearch: SelectProps<ValueListItem['id']>['onSearch'] = (newValue) => {
        if (newValue) {
            search({
                valueListSlug: ValueListSlug.providerCertificationFiberSource,
                search: newValue,
            });
        } else {
            search({
                valueListSlug: ValueListSlug.providerCertificationFiberSource,
                search: null,
            });
        }
    };

    const onChangeSelect: SelectProps<ValueListItem['id']>['onChange'] = (newValue, labelValueObj) => {
        setValue(newValue);
        if (onChange) {
            onChange(selectValueOnChange(newValue, props.mode, valueType, searchState?.data?.items), labelValueObj);
        }
    };

    return (
        <Select
            {...props}
            showSearch
            value={searchState.loading ? undefined : value}
            notFoundContent={searchState.loading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSearch}
            onChange={onChangeSelect}
            onFocus={onFocus}
            allowClear
        >
            {[
                ...initialValue, // Display initial value
                ...list.filter((a) => !initialValue.map((b) => b.id).includes(a.id)), // Search items, excluding initial value
            ].map((fiber) => (
                <Select.Option
                    value={fiber.id}
                    key={fiber.id}
                    text={translation(fiber?.fields?.name as Translation, user)}
                >
                    {<Translate text={fiber?.fields?.name as Translation} />}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SelectFiberSource;
