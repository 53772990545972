import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Audit, ListResponse } from '../api/apiTypes';
import {
    AuditIdPayload,
    AuditListPayload,
    AuditCreatePayload,
    AuditUpdatePayload,
    list as listApiCall,
    create as createApiCall,
    remove as removeApiCall,
    details as detailsApiCall,
    update as updateApiCall,
} from '../api/audits';

// States
export interface AuditsState {
    create: RequestState<Audit>;
    update: RequestState<Audit>;
    remove: RequestState<Audit>;
    details: RequestState<Audit>;
    list: RequestState<ListResponse<Audit>>;
}

const initialState: AuditsState = {
    create: {
        loading: false,
    },
    remove: {
        loading: false,
    },
    details: {
        loading: false,
    },
    list: {
        loading: false,
    },
    update: {
        loading: false,
    },
};

export const create = new EzeeAsyncAction<AuditsState['create'], AuditCreatePayload, Audit>(
    'Audits/create',
    initialState.create,
    requestReducer<AuditsState['create'], Audit>(initialState.create)
);

export const update = new EzeeAsyncAction<AuditsState['update'], AuditUpdatePayload, Audit>(
    'Audits/update',
    initialState.update,
    requestReducer<AuditsState['update'], Audit>(initialState.update)
);

export const remove = new EzeeAsyncAction<AuditsState['remove'], AuditIdPayload, Audit>(
    'Audits/remove',
    initialState.remove,
    requestReducer<AuditsState['remove'], Audit>(initialState.remove)
);

export const details = new EzeeAsyncAction<AuditsState['details'], AuditIdPayload, Audit>(
    'Audits/details',
    initialState.details,
    requestReducer<AuditsState['details'], Audit>(initialState.details)
);

export const list = new EzeeAsyncAction<AuditsState['list'], AuditListPayload, ListResponse<Audit>>(
    'Audits/list',
    initialState.list,
    requestReducer<AuditsState['list'], ListResponse<Audit>>(initialState.list)
);

// Reducer
export const auditsReducer = combineReducers<AuditsState>({
    list: list.reducer,
    create: create.reducer,
    remove: remove.reducer,
    details: details.reducer,
    update: update.reducer,
});

// Saga
export function* auditsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
}

// Store helpers
export const getAuditsListState = (state: MainReducerState) => state.audits.list;
export const getAuditsCreateState = (state: MainReducerState) => state.audits.create;
export const getAuditsRemoveState = (state: MainReducerState) => state.audits.remove;
export const getAuditsDetailsState = (state: MainReducerState) => state.audits.details;
export const getAuditsUpdateState = (state: MainReducerState) => state.audits.update;
