import { FC, Fragment, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider, Drawer, DrawerProps, Typography } from 'antd';

import UserInfos from './UserInfos';
import { list as userList, getUsersListState } from '../store/actions/users';
import { useActions } from '../hooks';
import { useSelector } from 'react-redux';
import { RoleName } from '../store/api/apiTypes';
import { hasRole } from '../helpers/security';

const ContactsDrawer: FC<DrawerProps> = ({ onClose, ...props }) => {
    const { formatMessage } = useIntl();
    const loadUserList = useActions(userList.trigger);
    const usersListState = useSelector(getUsersListState);
    useEffect(() => {
        loadUserList();
    }, [loadUserList]);

    return (
        <Drawer
            width={500}
            title={formatMessage({
                id: 'contacts_drawer.title',
                defaultMessage: 'Contact(s) administrateur(s)',
                description: 'Drawer title',
            })}
            onClose={onClose}
            {...props}
        >
            <Typography.Paragraph>
                <p>
                    <FormattedMessage
                        id="contacts_drawer.paragraph"
                        defaultMessage="Retrouvez ci-dessous les coordonnées du/des administrateur(s) du compte fournisseur."
                        description="contact list info text"
                    />
                </p>
            </Typography.Paragraph>
            <Divider />
            {usersListState.data?.items
                .filter((user) => hasRole(user, [RoleName.adminProvider]))
                .map((user) => (
                    <Fragment key={user.id}>
                        <UserInfos user={user} />
                        <Divider />
                    </Fragment>
                ))}
        </Drawer>
    );
};

export default ContactsDrawer;
