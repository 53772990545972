import api from './_client';
import { SearchPaginationQuery } from '.';

// Types
import { Fiber, ObjectId, ProviderCertification, RemoteFile } from './apiTypes';
import { RcFile } from 'antd/lib/upload';
import { serialize } from 'object-to-formdata';

// Controller Interface
export interface ProviderCertificationCreatePayload {
    body: {
        providerCollection: ProviderCertification['providerCollection'];
        brands: ProviderCertification['brands'];
        providerTypes: ProviderCertification['providerTypes'];
        reference: ProviderCertification['reference'];
        year: ProviderCertification['year'];
        fibers?: FiberElement[];
    };
}

export interface FiberElement {
    name: Fiber['name'];
    scientificName: Fiber['scientificName'];
    originCountry: Fiber['originCountry'];
    source: ObjectId;
    commonName?: Fiber['commonName'];
    cites?: Fiber['cites'];
    citesFile?: RcFile;
}

export interface ProviderCertificationIdPayload {
    id: ProviderCertification['id'];
}

export interface ProviderCertificationDownloadPayload {
    id: ProviderCertification['id'];
    fileId: RemoteFile['id'];
    filename: string;
}

export interface ProviderCertificationUpdatePayload extends ProviderCertification {}
export type ProviderCertificationListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: ProviderCertificationListPayload = {}) => {
    return await api.get(`/providerCertifications`, { params: payload });
};

export const create = async (payload: ProviderCertificationCreatePayload) => {
    const formData = serialize(payload, { indices: true });
    return await api.post(`/providerCertifications`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const details = async (payload: ProviderCertificationIdPayload) => {
    return await api.get(`/providerCertifications/${payload.id}`);
};

export const remove = async (payload: ProviderCertificationIdPayload) => {
    return await api.delete(`/providerCertifications/${payload.id}`);
};

export const update = async (payload: ProviderCertificationIdPayload) => {
    const formData = serialize(payload);
    return await api.put(`/providerCertifications/${payload.id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const downloadFromApi = async (payload: ProviderCertificationIdPayload) => {
    return await api.get(`/providerCertifications/download/${payload.id}`, {
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
    });
};

export const downloadAll = async (payload: ProviderCertificationIdPayload) => {
    return await api.get(`/providerCertifications/download/${payload.id ?? ''}`, { responseType: 'blob' });
};

export const download = async (payload: ProviderCertificationDownloadPayload) => {
    return await api.get(`/providerCertifications/download/${payload.id ?? ''}/${payload.fileId ?? ''}`, {
        responseType: 'blob',
    });
};
