import { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path
            d="M9.707 10.293a1 1 0 10-1.414 1.414L10.586 14l-2.293 2.293a1 1 0 101.414 1.414L12 15.414l2.293 2.293a1 1 0 001.414-1.414L13.414 14l2.293-2.293a1 1 0 00-1.414-1.414L12 12.586l-2.293-2.293z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 1a2 2 0 00-2 2v18a2 2 0 002 2h14a2 2 0 002-2V7a.996.996 0 00-.26-.672l-4.497-4.997A1 1 0 0015.5 1H5zm9 2H5v18h14V8h-4a1 1 0 01-1-1V3zm3.755 3H16V4.05L17.755 6z"
            fill="currentColor"
        />
    </svg>
);

const IconStatusContested: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon
        component={svg}
        {...props}
        className={`icon-status-contested${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconStatusContested;
