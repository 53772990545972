import { VFC, ReactNode } from 'react';
import { Col, Row, Typography } from 'antd';

interface Column {
    title?: ReactNode;
    content: ReactNode;
    /** Antd Col span */
    span?: number;
}

export interface CollapseHeaderProps {
    columns: Column[];
    prefix?: ReactNode;
}
const CollapseHeader: VFC<CollapseHeaderProps> = ({ columns, prefix }) => (
    <div className="flex items-center gap-16">
        {prefix}
        <Row gutter={12} align="middle" justify="space-between" className="flex-auto">
            {columns.map((column, index) => (
                <Col key={index} span={column.span}>
                    <div className="flex-col">
                        {column.title && <Typography.Text strong>{column.title}</Typography.Text>}
                        <div style={{ lineHeight: '17px' }}>{column.content}</div>
                    </div>
                </Col>
            ))}
        </Row>
    </div>
);

export default CollapseHeader;
