import { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 21">
        <path
            d="M7.08333 12.1666C6.6231 12.1666 6.25 12.5397 6.25 13C6.25 13.4602 6.6231 13.8333 7.08333 13.8333H12.9167C13.3769 13.8333 13.75 13.4602 13.75 13C13.75 12.5397 13.3769 12.1666 12.9167 12.1666H7.08333Z"
            fill="currentColor"
            fillOpacity="0.85"
        />
        <path
            d="M6.25 15.5C6.25 15.0397 6.6231 14.6666 7.08333 14.6666H10C10.4602 14.6666 10.8333 15.0397 10.8333 15.5C10.8333 15.9602 10.4602 16.3333 10 16.3333H7.08333C6.6231 16.3333 6.25 15.9602 6.25 15.5Z"
            fill="currentColor"
            fillOpacity="0.85"
        />
        <path
            d="M13.0893 6.5059C13.4147 6.18046 13.4147 5.65283 13.0893 5.32739C12.7638 5.00195 12.2362 5.00195 11.9107 5.32739L9.16667 8.07147L8.08926 6.99406C7.76382 6.66862 7.23618 6.66862 6.91074 6.99406C6.58531 7.31949 6.58531 7.84713 6.91074 8.17257L8.57741 9.83923C8.90285 10.1647 9.43049 10.1647 9.75592 9.83923L13.0893 6.5059Z"
            fill="currentColor"
            fillOpacity="0.85"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.16667 1.33331C3.24619 1.33331 2.5 2.0795 2.5 2.99998V18C2.5 18.9205 3.2462 19.6666 4.16667 19.6666H15.8333C16.7538 19.6666 17.5 18.9205 17.5 18V2.99998C17.5 2.07951 16.7538 1.33331 15.8333 1.33331H4.16667ZM4.16667 2.99998H15.8333V18H4.16667V2.99998Z"
            fill="currentColor"
            fillOpacity="0.85"
        />
    </svg>
);

const IconDocProcessing: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-doc-processing${props.className ? ` ${props.className}` : ''}`} />
);

export default IconDocProcessing;
