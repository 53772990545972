import { FC, lazy, Suspense } from 'react';
import { Spin } from 'antd';
import type { PDFViewerProps } from './PDFViewerLazy';

const PDFViewerLazy = lazy(async () => await import(/* webpackChunkName: "pdf-viewer" */ './PDFViewerLazy'));

const PDFViewer: FC<PDFViewerProps> = (props) => (
    <Suspense fallback={<Spin />}>
        <PDFViewerLazy {...props} />
    </Suspense>
);
export default PDFViewer;
