import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { MaterialCertification, ListResponse } from '../api/apiTypes';
import {
    MaterialCertificationIdPayload,
    MaterialCertificationListPayload,
    MaterialCertificationCreatePayload,
    list as listApiCall,
    create as createApiCall,
    remove as removeApiCall,
    details as detailsApiCall,
} from '../api/materialCertifications';

// States
export interface MaterialCertificationsState {
    create: RequestState<MaterialCertification>;
    remove: RequestState<MaterialCertification>;
    details: RequestState<MaterialCertification>;
    list: RequestState<ListResponse<MaterialCertification>>;
}

const initialState: MaterialCertificationsState = {
    create: {
        loading: false,
    },
    remove: {
        loading: false,
    },
    details: {
        loading: false,
    },
    list: {
        loading: false,
    },
};

export const create = new EzeeAsyncAction<
    MaterialCertificationsState['create'],
    MaterialCertificationCreatePayload,
    MaterialCertification
>(
    'MaterialCertifications/create',
    initialState.create,
    requestReducer<MaterialCertificationsState['create'], MaterialCertification>(initialState.create)
);

export const remove = new EzeeAsyncAction<
    MaterialCertificationsState['remove'],
    MaterialCertificationIdPayload,
    MaterialCertification
>(
    'MaterialCertifications/remove',
    initialState.remove,
    requestReducer<MaterialCertificationsState['remove'], MaterialCertification>(initialState.remove)
);

export const details = new EzeeAsyncAction<
    MaterialCertificationsState['details'],
    MaterialCertificationIdPayload,
    MaterialCertification
>(
    'MaterialCertifications/details',
    initialState.details,
    requestReducer<MaterialCertificationsState['details'], MaterialCertification>(initialState.details)
);

export const list = new EzeeAsyncAction<
    MaterialCertificationsState['list'],
    MaterialCertificationListPayload,
    ListResponse<MaterialCertification>
>(
    'MaterialCertifications/list',
    initialState.list,
    requestReducer<MaterialCertificationsState['list'], ListResponse<MaterialCertification>>(initialState.list)
);

// Reducer
export const materialCertificationsReducer = combineReducers<MaterialCertificationsState>({
    list: list.reducer,
    create: create.reducer,
    remove: remove.reducer,
    details: details.reducer,
});

// Saga
export function* materialCertificationsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getMaterialCertificationsListState = (state: MainReducerState) => state.materialCertifications.list;
export const getMaterialCertificationsCreateState = (state: MainReducerState) => state.materialCertifications.create;
export const getMaterialCertificationsRemoveState = (state: MainReducerState) => state.materialCertifications.remove;
export const getMaterialCertificationsDetailsState = (state: MainReducerState) => state.materialCertifications.details;
