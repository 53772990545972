import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Input, Button, Typography } from 'antd';

import { getAuthState, forgottenPassword } from '../../store/actions/auth';
import { LoginPayload } from '../../store/api/auth';

import Seo from '../../components/Seo';
import LoginLayout from '../../components/LoginLayout';
import { getRoute, RoutePathName } from '../../routes';
import { useActions } from '../../hooks';
import formMessages from '../../i18n/formMessages';
import genericMessages from '../../i18n/genericMessages';

const ForgottenPassword: FC = () => {
    const { formatMessage } = useIntl();
    const [sendForgottenPassword, resetForgottenPassword] = useActions([
        forgottenPassword.trigger,
        forgottenPassword.reset,
    ]);
    const authState = useSelector(getAuthState);
    const [email, setEmail] = useState<string | undefined>();
    const onFormValidSubmit: FormProps['onFinish'] = (values: LoginPayload) => {
        setEmail(values.username);
        sendForgottenPassword(values);
    };
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const pageTitle = formatMessage({
        id: 'forgotten_password.page_title.forgotten_password',
        defaultMessage: 'Mot de passe oublié ?',
        description: 'Forgotten password page title',
    });

    let error = authState.forgottenPasswordError ? formatMessage(genericMessages.defaultError) : null;

    if (authState.forgottenPasswordError?.status && authState.forgottenPasswordError.status === 404) {
        error = formatMessage({
            id: 'forgotten_password.error_message.email',
            defaultMessage: 'Adresse e-mail non trouvée.',
            description: 'forgotten password email not found',
        });
    } else if (authState.forgottenPasswordError?.status && authState.forgottenPasswordError.status === 401) {
        error = formatMessage({
            id: 'forgotten_password.error_message.user_disable',
            defaultMessage: 'Compte désactivé',
            description: 'forgotten password user disable',
        });
    }

    useEffect(
        () => () => {
            setEmail(undefined);
            resetForgottenPassword();
        },
        [setEmail, resetForgottenPassword]
    );

    return (
        <LoginLayout>
            <Seo title={pageTitle} />
            <Form
                className="login-form"
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
                scrollToFirstError
            >
                <Typography.Title level={1}>{pageTitle}</Typography.Title>

                {authState.forgottenPasswordSuccess ? (
                    <Typography.Paragraph>
                        <FormattedMessage
                            id="forgotten_password.paragraph.send_email"
                            defaultMessage="Nous venons de vous envoyer un lien pour réinitialiser votre mot de passe à l'adresse {email}. {br}Merci de surveiller vos e-mails."
                            description="Reset password mail sent explanation"
                            values={{
                                email,
                                br: (
                                    <>
                                        <br />
                                        <br />
                                    </>
                                ),
                            }}
                            tagName="p"
                        />
                    </Typography.Paragraph>
                ) : (
                    <>
                        <Typography.Paragraph>
                            <FormattedMessage
                                id="forgotten_password.paragraph.intro"
                                defaultMessage="Saisir l'adresse e-mail associé à votre compte, nous vous enverrons un mail avec un lien pour réinitialiser votre mot de passe"
                                description="Forgotten password page intro text"
                                tagName="p"
                            />
                        </Typography.Paragraph>
                        <Form.Item
                            name="username"
                            label={formatMessage({
                                id: 'forgotten_password.form.item.email',
                                defaultMessage: 'Adresse e-mail',
                                description: 'forgotten password email field label',
                            })}
                            rules={[
                                requiredRule,
                                {
                                    type: 'email',
                                    message: formatMessage(formMessages.invalidEmail),
                                },
                            ]}
                            validateTrigger="onBlur"
                        >
                            <Input
                                placeholder={formatMessage({
                                    id: 'forgotten_password.form.item.input.email',
                                    defaultMessage: 'Saisir votre e-mail',
                                    description: 'forgotten password email field placeholder',
                                })}
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item>
                            {error ? (
                                <Typography.Paragraph type="danger">
                                    <p>{error}</p>
                                </Typography.Paragraph>
                            ) : null}

                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                loading={authState.loading}
                                shape="round"
                                block
                            >
                                <FormattedMessage
                                    id="forgotten_password.button.submit"
                                    defaultMessage="Réinitialiser votre mot de passe"
                                    description="Forgotten password submit button"
                                />
                            </Button>
                        </Form.Item>
                    </>
                )}
                <Link to={getRoute(RoutePathName.login)}>
                    <FormattedMessage
                        id="forgotten_password.link.page_back"
                        defaultMessage="Revenir à l'écran de connexion"
                        description="Forgotten password page back to login link"
                    />
                </Link>
            </Form>
        </LoginLayout>
    );
};

export default ForgottenPassword;
