import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { MajInfoProduct } from '../api/apiTypes';
import { details as detailsApiCall } from '../api/majInfoProduct';

// States
export interface MajInfoProductsState {
    details: RequestState<MajInfoProduct>;
}

const initialState: MajInfoProductsState = {
    details: {
        loading: false,
        data: {
            id: '',
            majDueDate: '',
        },
    },
};

export const details = new EzeeAsyncAction<MajInfoProductsState['details'], MajInfoProduct>(
    'MajInfoProducts/details',
    initialState.details,
    requestReducer<MajInfoProductsState['details'], MajInfoProduct>(initialState.details)
);

// Reducer
export const majInfoProductReducer = combineReducers<MajInfoProductsState>({
    details: details.reducer,
});

// Saga
export function* majInfoProductSaga() {
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getMajInfoProductsDetailsState = (state: MainReducerState) => state.majInfoProducts.details;
