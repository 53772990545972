import { FC } from 'react';
import Popconfirm, { PopconfirmProps } from 'antd/lib/popconfirm';
import { FormattedMessage } from 'react-intl';

interface DeletePopConfirmProps extends Omit<PopconfirmProps, 'title'> {
    title?: PopconfirmProps['title'];
}

const DeletePopConfirm: FC<DeletePopConfirmProps> = ({ children, ...props }) => (
    <Popconfirm
        title={
            <FormattedMessage
                id="delete_pop_confirm.title"
                defaultMessage="Confirmez-vous la suppression ?"
                description="delete pop confirm title"
            />
        }
        okText={
            <FormattedMessage
                id="delete_pop_confirm.button_ok"
                defaultMessage="Confirmer"
                description="delete pop confirm button"
            />
        }
        cancelText={
            <FormattedMessage
                id="delete_pop_confirm.button_cancel"
                defaultMessage="Annuler"
                description="delete pop confirm button"
            />
        }
        okButtonProps={{
            size: 'small',
        }}
        cancelButtonProps={{
            size: 'small',
        }}
        {...props}
    >
        {children ?? (
            <FormattedMessage
                id="delete_pop_confirm.text"
                defaultMessage="Supprimer"
                description="delete pop confirm text"
            />
        )}
    </Popconfirm>
);

export default DeletePopConfirm;
