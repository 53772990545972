import { defineMessages } from 'react-intl';

import { RoleName } from '../store/api/apiTypes';

const messages = defineMessages({
    userProvider: {
        id: 'role_message.user_provider',
        defaultMessage: 'Utilisateur',
        description: 'role',
    },
    adminProvider: {
        id: 'role_message.admin_provider',
        defaultMessage: 'Administrateur',
        description: 'role',
    },
    reader: {
        id: 'role_message.reader_provider',
        defaultMessage: 'Lecteur',
        description: 'role',
    },
    userProviderHelp: {
        id: 'role_message.user_provider_help',
        defaultMessage:
            "Il faut affecter entre 1 et 3 droits à chaque utilisateur, puis indiquer pour quel(s) univers de Christian Dior cet utilisateur travaille, ainsi que renseigner le type de produit qu'il gère (champ type fournisseur).{br}Note: l'utilisateur avec des droits limités a tout de même un accès en lecture aux autres fonctionnalités de la plateforme.",
        description: 'role help',
    },
    adminProviderHelp: {
        id: 'role_message.admin_provider_help',
        defaultMessage:
            "Un administrateur a accès à l'intégralité de la plateforme. Il reçoit l'ensemble des notifications liées à son utilisation et peut gérer les utilisateurs liés à la société.",
        description: 'role help',
    },
    readerHelp: {
        id: 'role_message.reader_provider_help',
        defaultMessage:
            "Un lecteur a un accès à la totalité de la plateforme en lecture : il peut consulter l'intégralité des documents / informations présentes mais ne peut pas signer ni charger de documents sur la plateforme. Il ne reçoit également aucune notification",
        description: 'role help',
    },
});

export default messages;

export const rolesMessages = new Map([
    [RoleName.userProvider, messages.userProvider],
    [RoleName.adminProvider, messages.adminProvider],
    [RoleName.readerProvider, messages.reader],
]);

export const rolesHelpMessages = new Map([
    [RoleName.userProvider, messages.userProviderHelp],
    [RoleName.adminProvider, messages.adminProviderHelp],
    [RoleName.readerProvider, messages.readerHelp],
]);
