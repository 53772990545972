import { FC, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import '../assets/styles/ReactPlayer.less';
import { IconCross } from './icons';
import ErrorBoundary from './ErrorBoundary';

export interface PlayerProps {
    isVisible: boolean;
    onClose: () => void;
    url: string;
}

const ResponsivePlayer: FC<PlayerProps> = ({ isVisible, onClose, url }) => {
    const [isPlaying, setIsPlaying] = useState(true);
    const player = useRef<ReactPlayer | null>(null);
    const onModalClose = () => {
        setIsPlaying(false);
        onClose();
    };

    return (
        <ErrorBoundary>
            <Modal
                visible={isVisible}
                footer={null}
                onCancel={onModalClose}
                width="70%"
                wrapClassName="video-modal"
                closable={false}
                destroyOnClose
                keyboard
            >
                <Button type="primary" shape="circle" className="video-modal-close" size="large" onClick={onModalClose}>
                    <IconCross />
                </Button>
                <div className="react-player-wrapper">
                    <ReactPlayer ref={player} url={url} playing={isPlaying} width="100%" height="100%" controls />
                </div>
            </Modal>
        </ErrorBoundary>
    );
};

export default ResponsivePlayer;
