import { SearchPaginationQuery } from './';
import api from './_client';
import { Order, RemoteFile } from './apiTypes';
import { serialize } from 'object-to-formdata';

export interface OrderDetailsPayload {
    id: Order['id'];
}

export interface docConsultationPayload {
    orderId: Order['id'];
    documentId: RemoteFile['id'];
}
export interface docApprovalPayload {
    orderId: Order['id'];
    documentId: RemoteFile['id'];
    body: unknown;
}

export interface docContestPayload {
    orderId: Order['id'];
    documentId: RemoteFile['id'];
    body: {
        comment: string;
    };
}

export interface OrderdownloadAllPayload {
    id: Order['id'];
}

export interface OrderdownloadPayload {
    id: Order['id'];
    fileId: RemoteFile['id'];
    filename: string;
}

export interface OrderDownloadApprovalConfirmationDocPayload {
    docId: RemoteFile['id'];
    comfimationDocId: RemoteFile['id'];
}

export type OrderListPayload = SearchPaginationQuery;

export const details = async (payload: OrderDetailsPayload) => await api.get(`/orders/${payload.id}`);

export const list = async (payload: OrderListPayload = {}) => {
    return await api.get(`/orders`, { params: payload });
};

export const consultation = async (payload: docConsultationPayload) => {
    return await api.post(`/orders/${payload.orderId}/documents/${payload.documentId}/consult`);
};

export const approve = async (payload: docApprovalPayload) => {
    const formData = serialize(payload.body, { indices: true });

    return await api.post(`/orders/${payload.orderId}/documents/${payload.documentId}/approve`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

// api contestation
export const contest = async (payload: docContestPayload) => {
    return await api.post(`/orders/${payload.orderId}/documents/${payload.documentId}/contest`, payload.body);
};

export const downloadFromApi = async (payload: OrderDetailsPayload) => {
    return await api.get(`/orders/download/${payload.id}`, {
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
    });
};

export const downloadAll = async (payload: OrderdownloadAllPayload) => {
    return await api.get(`/orders/download/${payload.id ?? ''}`, { responseType: 'blob' });
};

export const download = async (payload: OrderdownloadPayload) => {
    return await api.get(`/orders/download/${payload.id ?? ''}/${payload.fileId ?? ''}`, { responseType: 'blob' });
};
