import { FC, useCallback, useEffect, useState } from 'react';
import { Badge, Button, Form, FormProps, Input, Table, Typography } from 'antd';
import { FormattedDate, useIntl } from 'react-intl';
import { NavLink, Route, useRouteMatch } from 'react-router-dom';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps, TableProps } from 'antd/lib/table';

import '../../assets/styles/Signature.less';

import genericMessages from '../../i18n/genericMessages';
import { getRawRoute, getRoute, RoutePathName } from '../../routes';
import Seo from '../../components/Seo';
import BasicList from '../../components/BasicList';
import useQueryParams from '../../hooks/queryParams';
import TableHeader from '../../components/TableHeader';
import SignatureListTitle from './SignatureListTitle';
import SignatureDrawer from './SignatureDrawer';
import {
    ConformityDocument,
    DocumentStatus,
    Permission,
    PermissionRight,
    RoleName,
    Translation,
} from '../../store/api/apiTypes';
import { useActions, useDebounce } from '../../hooks';
import { useSelector } from 'react-redux';
import {
    list as documentsList,
    getConformityDocumentsListState,
    polling,
} from '../../store/actions/conformityDocuments';
import { getUser } from '../../store/actions/auth';
import { hasPermission, hasRole } from '../../helpers/security';
import { sortOrderConverter } from '../../helpers';
import SelectCategory from '../../components/form/SelectCategory';
import Translate from '../../components/Translate';

const Signature: FC = () => {
    const { formatMessage } = useIntl();
    const itemsPerPage = 20;
    const [selectedDocumentId, setSelectedDocumentId] = useState<ConformityDocument['id'] | undefined>();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [loadDocuments, stopPolling] = useActions([documentsList.trigger, polling.actions.stopPolling]);
    const user = useSelector(getUser);
    const documentListState = useSelector(getConformityDocumentsListState);
    const [queryParams, setQueryParams] = useQueryParams('signature');
    const category = queryParams.get('category') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const page = queryParams.get('page') ?? undefined;
    const searchParam = queryParams.get('search') ?? undefined;
    const search = useDebounce(searchParam, 350);
    const [filtersForm] = Form.useForm();
    const isSignedPage = !!useRouteMatch(getRawRoute(RoutePathName.signatureSigned));
    const unsignedPageTitle = formatMessage({
        id: 'signature.title.tosign',
        defaultMessage: 'Documents à signer',
        description: 'menu item',
    });
    const signedPageTitle = formatMessage({
        id: 'signature.title.history',
        defaultMessage: 'Historique des documents signés',
        description: 'menu item',
    });

    const updateList = useCallback(() => {
        const params: any = {
            poll: true,
            pageSize: itemsPerPage,
            status: isSignedPage ? DocumentStatus.signed : undefined,
            sort: sort || (isSignedPage ? 'signatureDate' : 'createdAt'),
            sortOrder: sortOrder || (isSignedPage ? 'desc' : 'asc'),
            category,
            search,
            page,
        };

        loadDocuments(params);
        filtersForm.setFieldsValue(params);
    }, [isSignedPage, category, search, loadDocuments, filtersForm, sort, sortOrder, page]);

    const onFiltersChange: FormProps['onValuesChange'] = (change, values) => {
        setQueryParams({
            ...values,
        });
    };

    const canSign =
        hasRole(user, [RoleName.adminProvider]) ||
        hasPermission(user, Permission.conformityDocument, PermissionRight.write);
    const onDocumentClick = (id: ConformityDocument['id']) => {
        setSelectedDocumentId(id);
        setIsDrawerVisible(true);
    };
    const onCloseDrawer = () => {
        setIsDrawerVisible(false);
        updateList();
        setSelectedDocumentId(undefined);
    };
    const onTableChange: TableProps<ConformityDocument>['onChange'] = (pagination, filters, sorter: any) => {
        setQueryParams({
            sort: sorter.column?.dataIndex ? sorter.column?.dataIndex : undefined,
            sortOrder: sorter.order ? sortOrderConverter(sorter.order) : undefined,
            page: pagination.current ? pagination.current - 1 : 0,
        });
    };
    const columns: Array<ColumnProps<ConformityDocument>> = [
        {
            dataIndex: 'name',
            title: formatMessage(genericMessages.documentName),
            width: 165,
            ellipsis: true,
            render: (_, record) => {
                const isLate = record.delaySignatureStatus === 'late';
                const icon = isLate ? (
                    <ExclamationCircleOutlined style={{ fontSize: '1.5rem', verticalAlign: 'top' }} />
                ) : record.status === 'new' ? (
                    <Badge color="green" dot />
                ) : null;
                const iconType = isLate ? 'danger' : undefined;

                return (
                    <>
                        <div className="flex">
                            {icon && (
                                <Typography.Text type={iconType} style={{ marginRight: '0.5rem' }}>
                                    {icon}
                                </Typography.Text>
                            )}
                            <Typography.Text
                                ellipsis={{
                                    tooltip: record.name,
                                }}
                                className="overflow-hidden"
                            >
                                {record.name}
                            </Typography.Text>
                        </div>
                    </>
                );
            },
            sorter: true,
        },
        {
            dataIndex: 'category',
            title: formatMessage({
                id: 'signature.column.table_header.category',
                defaultMessage: 'Catégorie',
                description: 'table header',
            }),
            width: 180,
            render: (category) => (
                <span style={{ textTransform: 'uppercase' }}>
                    {category?.fields?.name ? <Translate text={category?.fields?.name as Translation} /> : '-'}
                </span>
            ),
            sorter: true,
        },
        {
            dataIndex: 'documentType',
            title: formatMessage({
                id: 'signature.column.table_header.documentType',
                defaultMessage: 'Type de document',
                description: 'table header',
            }),
            width: 230,
            render: (category) => (
                <span style={{ textTransform: 'uppercase' }}>
                    {category?.fields?.name ? <Translate text={category?.fields?.name as Translation} /> : '-'}
                </span>
            ),
        },
        {
            dataIndex: 'version',
            title: formatMessage({
                id: 'signature.column.table_header.version',
                defaultMessage: 'Version',
                description: 'table header',
            }),
            render: (version) => version || '-',
            width: 120,
        },
        {
            dataIndex: 'createdAt',
            title: formatMessage(genericMessages.postedAtTableHeader),
            width: 140,
            render: (_, record) => (record.createdAt ? <FormattedDate value={record.createdAt} /> : '-'),
            sorter: true,
        },
        {
            dataIndex: 'deadlineSigningDate',
            title: isSignedPage
                ? formatMessage({
                      id: 'signature.column.table_header.deadline_date_to_sign',
                      defaultMessage: 'Date de signature',
                      description: 'table header',
                  })
                : formatMessage({
                      id: 'signature.column.table_header.deadline_date',
                      defaultMessage: 'Date limite',
                      description: 'table header',
                  }),
            width: 140,
            render: (_, record) => {
                if (isSignedPage) {
                    return record.signatureDate ? <FormattedDate value={record.signatureDate} /> : '-';
                } else {
                    const isLate = record.delaySignatureStatus === 'late';
                    const iconType = isLate ? 'danger' : undefined;
                    return record.deadlineSigningDate ? (
                        <Typography.Text type={iconType} strong={isLate}>
                            <FormattedDate value={record.deadlineSigningDate} />
                        </Typography.Text>
                    ) : (
                        '-'
                    );
                }
            },
            sorter: true,
        },
        {
            key: 'actions',
            title: formatMessage(genericMessages.actions),
            width: 186,
            render: (record) => (
                <Button type="primary" shape="round" onClick={onDocumentClick.bind(null, record.id)}>
                    {record.status !== 'signed' && canSign
                        ? formatMessage(genericMessages.readAndSign)
                        : formatMessage({
                              id: 'signature.column.button.see',
                              defaultMessage: 'Voir',
                              description: 'table row action',
                          })}
                </Button>
            ),
        },
    ];

    useEffect(() => {
        updateList();
    }, [isSignedPage, updateList]);

    useEffect(
        () => () => {
            stopPolling();
        },
        [stopPolling]
    );

    return (
        <>
            <nav style={{ marginBottom: '2.5rem' }}>
                <BasicList inline>
                    <li>
                        <NavLink to={getRoute(RoutePathName.signatureUnsigned)} exact>
                            {unsignedPageTitle}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={getRoute(RoutePathName.signatureSigned)} exact>
                            {signedPageTitle}
                        </NavLink>
                    </li>
                </BasicList>
            </nav>
            <Route path={getRawRoute(RoutePathName.signatureUnsigned)} exact>
                <Seo title={unsignedPageTitle} />
                <SignatureListTitle
                    countLate={documentListState.data?.countLate ?? 0}
                    countToSignNotLate={documentListState.data?.countToSignNotLate ?? 0}
                    countNewNotLateNotOpened={documentListState.data?.countNewNotLateNotOpened ?? 0}
                />
            </Route>
            <Route path={getRawRoute(RoutePathName.signatureSigned)} exact>
                <Seo title={signedPageTitle} />
            </Route>
            <Form form={filtersForm} onValuesChange={onFiltersChange}>
                <TableHeader>
                    <Form.Item name="search" className="w-full">
                        <Input
                            placeholder={formatMessage(genericMessages.documentSearchPlaceholder)}
                            defaultValue={search}
                            suffix={<SearchOutlined className="font-16" />}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item name="category">
                        <SelectCategory
                            placeholder={formatMessage({
                                id: 'signature.column.table_header.select.category',
                                defaultMessage: 'Toutes les catégories',
                                description: 'select placeholder',
                            })}
                            style={{ width: '20.875rem' }}
                            allowClear
                        />
                    </Form.Item>
                </TableHeader>
            </Form>
            <Table<ConformityDocument>
                columns={columns}
                loading={documentListState.loading}
                dataSource={documentListState.data?.items}
                pagination={{
                    total: documentListState.data?.totalCount,
                    pageSize: itemsPerPage,
                    hideOnSinglePage: true,
                }}
                rowKey={(record) => record.id}
                rowClassName={(record) => {
                    if (record.delaySignatureStatus === 'late') {
                        return 'signature-row-late';
                    } else if (record.status === 'new' && !['late'].includes(record.delaySignatureStatus ?? '')) {
                        return 'signature-row-unopened';
                    }

                    return '';
                }}
                onChange={onTableChange}
            />
            <SignatureDrawer
                documentId={selectedDocumentId}
                visible={isDrawerVisible}
                canSign={canSign}
                onClose={onCloseDrawer}
            />
        </>
    );
};

export default Signature;
