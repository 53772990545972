import { FC, useState, useEffect, KeyboardEvent, MouseEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, Drawer, DrawerProps, Form, FormProps, Input, Modal, SelectProps, Typography } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Currency, Revenue, RevenueType } from '../../store/api/apiTypes';
import {
    create as createRevenue,
    getRevenuesCreateState,
    update as updateRevenue,
    getRevenuesUpdateState,
} from '../../store/actions/revenue';
import formMessages from '../../i18n/formMessages';
import RequiredFields from '../../components/RequiredFields';
import Select from '../../components/Select';
import { useActions, usePrevious } from '../../hooks';
import objectSupport from 'dayjs/plugin/objectSupport';
import genericMessages from '../../i18n/genericMessages';
import { errorMessage, successMessage } from '../../helpers/message';
import { ExclamationCircleOutlined } from '@ant-design/icons';
dayjs.extend(objectSupport);
interface RevenueFormDrawerProps extends DrawerProps {
    revenues?: Revenue[];
}

const revenueYearsTable = [dayjs().year() - 1, dayjs().year() - 2, dayjs().year() - 3];

const RevenueFormDrawer: FC<RevenueFormDrawerProps> = ({ revenues, onClose, ...props }) => {
    const { formatMessage, formatDate } = useIntl();
    const [form] = Form.useForm();
    const [create, update] = useActions([createRevenue.trigger, updateRevenue.trigger]);
    const revenuesCreateState = useSelector(getRevenuesCreateState);
    const revenuesUpdateState = useSelector(getRevenuesUpdateState);
    const previous = usePrevious({ revenuesUpdateState, revenuesCreateState });
    const [currency, setCurrency] = useState<Currency | undefined>();
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    useEffect(() => {
        if (revenues && revenues.length > 0 && props.visible) {
            setCurrency(revenues[0].currency);
            const fields: any = { currency: revenues[0].currency };
            for (const keyType of [RevenueType.dior, RevenueType.global]) {
                fields[keyType] = {};
                for (const revenue of revenues) {
                    if (revenue.type === keyType) {
                        fields[keyType][dayjs(revenue.date).year()] = revenue.revenue;
                    }
                }
            }
            form.setFieldsValue(fields);
        }
    }, [revenues, form, props.visible]);

    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        for (const keyType of [RevenueType.dior, RevenueType.global]) {
            Object.keys(values[keyType]).map((year: string) => {
                if (
                    !revenues?.find(
                        (revenue) => revenue.type === keyType && dayjs(revenue.date).year() === Number(year)
                    )
                ) {
                    const payload = {
                        revenue: values[keyType][year],
                        now: year === dayjs().year().toString(),
                        currency: currency,
                        type: keyType,
                        // @ts-expect-error
                        date: dayjs({ year: Number(year), month: 1, day: 1 }).toISOString(),
                    };
                    create(payload);
                } else {
                    const updatedPayload = {
                        revenue: values[keyType][year],
                        currency: currency,
                        id: revenues?.find(
                            (revenue) => revenue.type === keyType && dayjs(revenue.date).year() === Number(year)
                        )?.id,
                    };
                    update(updatedPayload);
                }
                return true;
            });
        }
    };
    const onBeforeClose = () => {
        if (form.isFieldsTouched()) {
            Modal.confirm({
                title: formatMessage({
                    id: 'revenue_form_drawer.before_close.modal.title',
                    defaultMessage: "Êtes-vous sûr de vouloir quitter la création / l'édition des revenues  ?",
                    description: 'close drawer revenues',
                }),
                icon: <ExclamationCircleOutlined />,
                content: formatMessage({
                    id: 'revenue_form_drawer.before_close.modal.content',
                    defaultMessage: 'Les informations saisies seront perdues',
                    description: 'close drawer revenues contente',
                }),
                onOk() {
                    form.resetFields();
                    onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
                },
                okText: formatMessage({
                    id: 'revenue_form_drawer.before_close.modal.ok',
                    defaultMessage: 'oui',
                    description: 'ok',
                }),
                cancelText: formatMessage({
                    id: 'revenue_form_drawer.before_close.modal.cancel',
                    defaultMessage: 'annuler',
                    description: 'cancel',
                }),
            });
        } else {
            form.resetFields();
            onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
        }
    };
    useEffect(() => {
        // si loading ÉTAIT true, et que MAINTENANT il est false, cela veut dire que la requête est finie
        if (previous?.revenuesUpdateState.loading && !revenuesUpdateState.loading) {
            if (revenuesUpdateState.error || !revenuesUpdateState.data) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'revenue_form_drawer.success_message.update',
                        defaultMessage: "Chiffres d'affaires éditées avec succès",
                        description: 'revenues Success Message',
                    }),
                });
                form.resetFields();
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
        }
        if (previous?.revenuesCreateState.loading && !revenuesCreateState.loading) {
            if (revenuesCreateState.error || !revenuesCreateState.data) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'revenue_form_drawer.success_message.create',
                        defaultMessage: "Chiffres d'affaires crées avec succès",
                        description: 'revenues Success Message',
                    }),
                });
                form.resetFields();
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
        }
    }, [previous, revenuesCreateState, revenuesUpdateState, formatMessage, onClose, form]);

    const onAfterVisibleChange: DrawerProps['afterVisibleChange'] = (visible) => {
        if (!visible) {
            form.resetFields();
        }
    };
    const onChangeCurrency: SelectProps<Currency>['onChange'] = (value) => {
        setCurrency(value);
    };

    const renderFormItems = (year: number, revenueType: RevenueType) => (
        <Form.Item
            label={
                year === dayjs().year()
                    ? formatMessage(
                          {
                              id: 'revenue_form_drawer.form.item.input.revenue_today',
                              defaultMessage: "Chiffre d'affaires à date ({date})",
                              description: 'input label',
                          },
                          { date: formatDate(dayjs().toDate(), { year: 'numeric', month: 'short' }) }
                      )
                    : formatMessage(
                          {
                              id: 'revenue_form_drawer.form.item.input.revenue_day',
                              defaultMessage: "Chiffre d'affaires {date}",
                              description: 'input label',
                          },
                          { date: year }
                      )
            }
            rules={[
                requiredRule,
                {
                    pattern: /^[0-9]*$/,
                    message: formatMessage({
                        id: 'revenue_form_drawer.form.item.input.rules_number',
                        defaultMessage: 'Vous devez entrer un chiffre',
                    }),
                },
                ({ getFieldValue }) => ({
                    async validator(_, value) {
                        if (revenueType === RevenueType.global) {
                            if (
                                !value ||
                                !getFieldValue([RevenueType.dior, `${year}`]) ||
                                getFieldValue([RevenueType.dior, `${year}`]) <= value
                            ) {
                                return await Promise.resolve();
                            } else {
                                return await Promise.reject(formatMessage(formMessages.errorRevenuGlobal));
                            }
                        } else {
                            if (
                                !value ||
                                !getFieldValue([RevenueType.global, `${year}`]) ||
                                getFieldValue([RevenueType.global, `${year}`]) >= value
                            ) {
                                return await Promise.resolve();
                            } else {
                                return await Promise.reject(formatMessage(formMessages.errorRevenuDior));
                            }
                        }
                    },
                }),
            ]}
            normalize={(value) => (isNaN(parseInt(value)) ? undefined : parseInt(value))}
            name={[revenueType, `${year}`]}
            key={year}
        >
            <Input
                maxLength={15}
                placeholder={formatMessage({
                    id: 'revenue_form_drawer.form.item.input.placeholder',
                    defaultMessage: "Saisir le chiffre d'affaires",
                    description: 'field placeholder',
                })}
                suffix={currency}
                size="large"
            />
        </Form.Item>
    );

    return (
        <Drawer
            width={500}
            title={formatMessage({
                id: 'revenue_form_drawer.drawer_title',
                defaultMessage: "Chiffres d'affaires",
                description: 'Drawer title',
            })}
            onClose={onBeforeClose}
            afterVisibleChange={onAfterVisibleChange}
            {...props}
        >
            <Typography.Paragraph>
                <FormattedMessage
                    id="revenue_form_drawer.paragraph.intro"
                    defaultMessage="Indiquez ci-dessous les différents chiffres d'affaires annuels au global et réalisés avec Christian Dior Couture arrondis à l'entier le plus proche."
                    description="form intro"
                    tagName="p"
                />
            </Typography.Paragraph>
            <Form form={form} onFinish={onFormValidSubmit} layout="vertical" requiredMark={false} scrollToFirstError>
                <Form.Item
                    label={formatMessage({
                        id: 'revenue_form_drawer.form.item.label.currency',
                        defaultMessage: 'Commencez par sélectionner votre devise :',
                        description: 'field label',
                    })}
                    rules={[requiredRule]}
                    name="currency"
                >
                    <Select<Currency>
                        placeholder={formatMessage({
                            id: 'revenue_form_drawer.form.item.select.currency',
                            defaultMessage: 'Choisissez une devise',
                            description: 'input placeholder',
                        })}
                        size="large"
                        onChange={onChangeCurrency}
                    >
                        {Object.values(Currency).map((currency, index) => (
                            <Select.Option value={currency} key={index}>
                                {currency}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Divider />
                <Typography.Text
                    style={{
                        textTransform: 'uppercase',
                    }}
                    strong
                >
                    <FormattedMessage
                        id="revenue_form_drawer.form.item.title.global"
                        defaultMessage="Au global"
                        description="section title"
                    />
                </Typography.Text>
                {revenueYearsTable.map((year) => renderFormItems(year, RevenueType.global))}
                <Divider />
                <Typography.Text
                    style={{
                        textTransform: 'uppercase',
                    }}
                    strong
                >
                    <FormattedMessage
                        id="revenue_form_drawer.form.item.title.dior"
                        defaultMessage="Avec Christian Dior Couture"
                        description="section title"
                    />
                </Typography.Text>
                {revenueYearsTable.map((year) => renderFormItems(year, RevenueType.dior))}
                <Divider />
                <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    loading={revenuesUpdateState.loading ?? revenuesCreateState.loading}
                    size="large"
                    block
                >
                    {formatMessage({
                        id: 'revenue_form_drawer.form.item.button.submit',
                        defaultMessage: "Valider des chiffres d'affaires",
                        description: 'form submit button',
                    })}
                </Button>
                <p style={{ margin: '1.5rem 0 0', textAlign: 'center' }}>
                    <RequiredFields />
                </p>
            </Form>
        </Drawer>
    );
};

export default RevenueFormDrawer;
