import { defineMessages } from 'react-intl';
import { CertificateType } from '../store/api/apiTypes';

const messages = defineMessages({
    scopeCertificate: {
        id: 'material_certificate_type_message.scope_certificate',
        defaultMessage: 'Scope certificate',
        description: 'material certificate type',
    },
    transactionCertificate: {
        id: 'material_certificate_type_message.transaction_certificate',
        defaultMessage: 'Transaction certificate',
        description: 'material certificate type',
    },
});

export default messages;

export const materialCertificateTypeMessages = new Map([
    [CertificateType.scopeCertificate, messages.scopeCertificate],
    [CertificateType.transactionCertificate, messages.transactionCertificate],
]);
