import { VFC, useEffect, useState } from 'react';
import { Spin, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import constants from '../config/constants';
import Seo from './Seo';
import TitleAlt from './TitleAlt';
import { IconLogo } from './icons';
import { useActions } from '../hooks';
import { infos as getApiInfos, getApiInfosInfosState, polling } from '../store/actions/apiInfos';
import { useSelector } from 'react-redux';

/*
    Maintenance message to avoid displaying the app if the API returns an error.
    Use cases :
    - Unscheduled API downtime (eg 504)
    - Scheduled API downtime (eg 502 during long API deployments, data migration...)
*/
const Maintenance: VFC = () => {
    const { formatMessage } = useIntl();
    const [isError, setIsError] = useState(false);
    const [isLoadingInitial, setIsLoadingInitial] = useState(true);

    const [apiInfos, stopPolling] = useActions([getApiInfos.trigger, polling.actions.stopPolling]);
    const apiInfosInfosState = useSelector(getApiInfosInfosState);

    useEffect(() => {
        if (constants.ENABLE_MAINTENANCE_CHECK) {
            apiInfos();
        }
    }, [apiInfos]);

    useEffect(
        () => () => {
            stopPolling();
        },
        [stopPolling]
    );

    useEffect(() => {
        if (apiInfosInfosState.success) {
            setIsError(false);
            document.body.style.overflow = 'auto';
        } else if (apiInfosInfosState.error) {
            setIsError(true);
            document.body.style.overflow = 'hidden';
        }

        if (!apiInfosInfosState.loading) {
            setIsLoadingInitial(false);
        }
    }, [apiInfosInfosState]);

    // if setting is not enabled, bail and don't display maintenance message
    if (!constants.ENABLE_MAINTENANCE_CHECK) {
        return null;
    }

    // show spinner while initial API request is in progress
    if (isLoadingInitial) {
        return (
            <div id="initial-loader" className="fixed inset-0 bg-white z-50">
                <Spin size="large" />
            </div>
        );
    }

    // show maintenance message when API returns an error status (4xx or 5xx)
    if (isError) {
        return (
            <div className="fixed inset-0 z-50 bg-white flex flex-col items-center pt-14 text-center">
                <Seo title={formatMessage({ id: 'maintenance.page_title', defaultMessage: 'Maintenance en cours' })} />
                <IconLogo className="w-140 font-140 mb-3" />
                <TitleAlt>
                    <FormattedMessage
                        id="maintenance.title"
                        defaultMessage="Le site est actuellement en maintenance."
                    />
                </TitleAlt>
                <Typography.Paragraph>
                    <FormattedMessage
                        id="maintenance.text"
                        defaultMessage="Veuillez réessayer plus tard. {br}Nous vous remercions de votre compréhension."
                        values={{
                            br: <br />,
                        }}
                        tagName="p"
                    />
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <FormattedMessage id="maintenance.footer" defaultMessage="À bientôt" />
                </Typography.Paragraph>
            </div>
        );
    }

    // all is good, don't show maintenance message
    return null;
};

export default Maintenance;
