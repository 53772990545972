import api from './_client';
import { SearchPaginationQuery } from '.';

// Types
import { ConformityDocument } from './apiTypes';
import { serialize } from 'object-to-formdata';

export interface ConformityDocumentIdPayload {
    id: ConformityDocument['id'];
}

export interface ConformityDocumentSignPayload extends ConformityDocument {}
export type ConformityDocumentListPayload = SearchPaginationQuery & { delaySignatureStatus?: string; poll?: boolean };
// Routes
export const list = async (payload: ConformityDocumentListPayload = {}) => {
    return await api.get(`/conformityDocuments`, { params: payload });
};

export const details = async (payload: ConformityDocumentIdPayload) => {
    return await api.get(`/conformityDocuments/${payload.id}`);
};

export const sign = async (payload: ConformityDocumentSignPayload) => {
    const formData = serialize(payload);
    return await api.put(`/conformityDocuments/sign/${payload.id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const downloadFromApi = async (payload: ConformityDocumentIdPayload) => {
    return await api.get(`/conformityDocuments/download/${payload.id}`, {
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
    });
};
