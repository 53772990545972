import { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Layout } from 'antd';

import '../assets/styles/Header.less';

import { getRawRoute, getRoute, RoutePathName } from '../routes';
import { IconLogo } from './icons';
import ProductionInfosBanner from './ProductionInfosBanner';
import HeaderMenu from './HeaderMenu';
import UserMenu from './UserMenu';
import { logout as logoutAction } from '../store/actions/auth';
import { useActions } from '../hooks';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getApiInfosInfosState } from '../store/actions/apiInfos';

const Header: FC = () => {
    const isValidateAccountPage = useRouteMatch(getRawRoute(RoutePathName.validateAccount));
    const logout = useActions(logoutAction.trigger);
    const apiInfosInfosState = useSelector(getApiInfosInfosState);
    return (
        <>
            {!isValidateAccountPage && <ProductionInfosBanner inHeader />}
            <Layout.Header
                id="main-header"
                className={apiInfosInfosState?.data?.env === 'preprod' ? 'uat-environment' : undefined}
            >
                <div className="container">
                    <nav>
                        <Link id="logo" to={getRoute(RoutePathName.home)}>
                            <IconLogo />
                        </Link>
                        {apiInfosInfosState?.data?.env === 'preprod' && (
                            <div id="test-env-badge" className="font-24 font-bold uppercase">
                                UAT
                            </div>
                        )}
                        {apiInfosInfosState?.data?.env === 'development' && (
                            <div id="test-env-badge" className="font-24 font-bold uppercase">
                                DEV
                            </div>
                        )}
                        {!isValidateAccountPage && <HeaderMenu />}
                    </nav>
                    {!isValidateAccountPage ? (
                        <UserMenu />
                    ) : (
                        <Link
                            style={{ textTransform: 'uppercase', fontSize: 16 }}
                            to={getRoute(RoutePathName.login)}
                            onClick={logout}
                        >
                            <FormattedMessage
                                id="header.link.disconnect"
                                defaultMessage="Se déconnecter"
                                description="user dropdown menu item"
                            />
                        </Link>
                    )}
                </div>
            </Layout.Header>
        </>
    );
};

export default Header;
