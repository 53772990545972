import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Collection, ListResponse } from '../api/apiTypes';
import {
    CollectionIdPayload,
    CollectionListPayload,
    list as listApiCall,
    details as detailsApiCall,
} from '../api/collections';

// States
export interface CollectionsState {
    details: RequestState<Collection>;
    list: RequestState<ListResponse<Collection>>;
}

const initialState: CollectionsState = {
    details: {
        loading: false,
    },
    list: {
        loading: false,
    },
};

export const details = new EzeeAsyncAction<CollectionsState['details'], CollectionIdPayload, Collection>(
    'Collections/details',
    initialState.details,
    requestReducer<CollectionsState['details'], Collection>(initialState.details)
);

export const list = new EzeeAsyncAction<CollectionsState['list'], CollectionListPayload, ListResponse<Collection>>(
    'Collections/list',
    initialState.list,
    requestReducer<CollectionsState['list'], ListResponse<Collection>>(initialState.list)
);

// Reducer
export const collectionsReducer = combineReducers<CollectionsState>({
    list: list.reducer,
    details: details.reducer,
});

// Saga
export function* collectionsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getCollectionsListState = (state: MainReducerState) => state.collections.list;
export const getCollectionsDetailsState = (state: MainReducerState) => state.collections.details;
