import { parseStringToBoolean } from '../helpers';

export default Object.freeze({
    PAGE_SIZE: 50,
    API: Object.freeze({
        BASE_URL: process.env.REACT_APP_API_BASE_URL ?? '/api',
        KEY: process.env.REACT_APP_API_KEY ?? 'ebeb15e0b7b511eb85290242ac130003',
    }),
    SEO: Object.freeze({
        BASE_URL: '',
        SITE_NAME: 'Dior conformité fournisseurs',
        DEFAULT_TITLE: 'Dior conformité fournisseurs',
        DEFAULT_DESCRIPTION: 'Dior conformité fournisseurs',
    }),
    GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS ?? '',
    ENABLE_DEBUG_LOGS: false, // process.env.NODE_ENV === 'production',
    DEBUG_LOGS_APP: '', // <app>_<service>
    VERSION: process.env.REACT_APP_VERSION ?? '',
    ENABLE_MAINTENANCE_CHECK: parseStringToBoolean(process.env.REACT_APP_ENABLE_MAINTENANCE_CHECK) ?? true, // checks if the API is responsive every minutes, if it is not, displays a maintenance page instead of the app
});
