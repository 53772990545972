import { ReactNode, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Drawer, Row, Col, Button, Divider, Typography, Tag, Badge, Descriptions, Collapse } from 'antd';
import { useSelector } from 'react-redux';

import '../../../assets/styles/DetailOrderDrawer.less';

import { Order, OrderStatus, DocTypes } from '../../../store/api/apiTypes';
import {
    details as detailOrderAction,
    getOrdersDetailsState,
    downloadAll as downloadAllAction,
    getOrdersDownloadAllState,
    getConsultationState,
} from '../../../store/actions/orders';

import { useActions, usePrevious } from '../../../hooks';
import genericMessages from '../../../i18n/genericMessages';
import { downloadBlobFile } from '../../../helpers/index';
import BrandsAndTypesTagsList from '../../../components/BrandsAndTypesTagsList';
import { errorMessage } from '../../../helpers/message';
import { truthy } from '../../../globalTypes';
import OrderDocumentCard from './OrderDocumentCard';
import NiceModal, { antdDrawer, useModal } from '@ebay/nice-modal-react';
import dayjs from 'dayjs';

interface OrderDetailsDrawerProps {
    orderId?: Order['id'];
}

const OrderDetailsDrawer = NiceModal.create<OrderDetailsDrawerProps>(({ orderId }) => {
    const drawer = useModal();
    const { formatMessage, formatNumber } = useIntl();
    const consultationState = useSelector(getConsultationState);
    const [detail, downloadAll] = useActions([detailOrderAction.trigger, downloadAllAction.trigger]);
    const orderDetailsState = useSelector(getOrdersDetailsState);
    const downloadAllState = useSelector(getOrdersDownloadAllState);
    const previous = usePrevious({
        orderDetailsState,
        downloadAllState,
        consultationState,
    });

    const documentsFormerVersions = orderDetailsState?.data?.documentActions
        ?.filter((documentAction) =>
            [OrderStatus.approved, OrderStatus.contested, OrderStatus.consulted, OrderStatus.sent].includes(
                documentAction?.status
            )
        )
        .sort((a, b) => dayjs(b.send?.sendDate).diff(dayjs(a.send?.sendDate)));

    const indexLastOAReceived = documentsFormerVersions?.findIndex(
        (documentAction) => documentAction.docType === DocTypes.OA
    );

    const lastDocumentsReceived = [];
    if (indexLastOAReceived !== undefined && documentsFormerVersions && indexLastOAReceived >= 0) {
        lastDocumentsReceived.push(documentsFormerVersions[indexLastOAReceived]);
        documentsFormerVersions?.splice(indexLastOAReceived, 1);
    }

    const indexLastCommessaReceived = documentsFormerVersions?.findIndex(
        (documentAction) => documentAction.docType === DocTypes.COMMESSA
    );

    if (indexLastCommessaReceived !== undefined && documentsFormerVersions && indexLastCommessaReceived >= 0) {
        lastDocumentsReceived.push(documentsFormerVersions[indexLastCommessaReceived]);
        documentsFormerVersions?.splice(indexLastCommessaReceived, 1);
    }

    const onDownloadAll = () => {
        downloadAll({ id: orderDetailsState?.data?.id });
    };

    useEffect(() => {
        if (previous?.downloadAllState.loading && !downloadAllState.loading) {
            if (downloadAllState.success && downloadAllState.data) {
                const fileName = `allDoc-${orderDetailsState.data?.docNumber ?? ''}.zip`;
                downloadBlobFile(fileName, downloadAllState.data, 'application/zip');
            } else {
                errorMessage({ content: formatMessage(genericMessages.defaultError) });
            }
        }
    }, [previous?.downloadAllState, downloadAllState, formatMessage, orderDetailsState.data?.docNumber]);

    useEffect(() => {
        if (orderId) {
            detail({ id: orderId });
        }
    }, [orderId, detail]);

    useEffect(() => {
        if (previous?.consultationState.loading && !consultationState.loading) {
            if (consultationState.success && orderId) {
                detail({ id: orderId });
            }
        }
    }, [consultationState, orderId, detail, previous]);

    return (
        <Drawer
            title={
                formatMessage({
                    id: 'OrdersProcess.OANumber',
                    defaultMessage: `Ordre d'achat N° `,
                }) + `${orderDetailsState?.data?.docNumber ?? ''}`
            }
            width={1100}
            placement="right"
            className="detail-order-drawer"
            {...antdDrawer(drawer)}
        >
            <Descriptions column={6} layout="vertical" colon={false} size="small">
                <Descriptions.Item
                    label={formatMessage({
                        id: 'OrdersProcess.Saison',
                        defaultMessage: `Saison`,
                    })}
                    span={1}
                >
                    <Typography.Text>
                        {orderDetailsState?.data?.saison
                            ? orderDetailsState?.data?.saison
                            : formatMessage(genericMessages.OrdersProcess_toInform)}
                    </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({
                        id: 'OrdersProcess.styleNumber',
                        defaultMessage: `Numéro de style`,
                    })}
                    span={2}
                >
                    <Typography.Text>{orderDetailsState?.data?.styleNumber || '-'}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({
                        id: 'OrdersProcess.ofType',
                        defaultMessage: `Type d'OF`,
                    })}
                    span={3}
                >
                    <Typography.Text>{orderDetailsState?.data?.reassort || '-'}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({
                        id: 'OrdersProcess.brandsAndTypes',
                        defaultMessage: `Univers & type(s)`,
                    })}
                >
                    {orderDetailsState.data?.brands?.length ? (
                        <BrandsAndTypesTagsList
                            brands={orderDetailsState?.data?.brands}
                            providerTypes={orderDetailsState?.data?.providerTypes}
                        />
                    ) : (
                        <Typography.Text>{formatMessage(genericMessages.OrdersProcess_toInform)}</Typography.Text>
                    )}
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({
                        id: 'OrdersProcess.informationComplimentaire',
                        defaultMessage: `Informations complémentaires`,
                    })}
                >
                    {!orderDetailsState?.data?.isVIP &&
                    !orderDetailsState?.data?.isPATOrPAI &&
                    !orderDetailsState?.data?.presse &&
                    !orderDetailsState?.data?.exportChina &&
                    !orderDetailsState?.data?.oneToOne &&
                    !orderDetailsState?.data?.cancelled &&
                    !orderDetailsState?.data?.mto &&
                    !orderDetailsState?.data?.permanent &&
                    !orderDetailsState?.data?.uniform
                        ? '-'
                        : [
                              orderDetailsState?.data?.isVIP && formatMessage(genericMessages.vipMessage),
                              orderDetailsState?.data?.isPATOrPAI && formatMessage(genericMessages.patPaiMessage),
                              orderDetailsState?.data?.presse && formatMessage(genericMessages.presseMessage),
                              orderDetailsState?.data?.exportChina && formatMessage(genericMessages.exportChineMessage),
                              orderDetailsState?.data?.oneToOne && formatMessage(genericMessages.oneToOneMessage),
                              orderDetailsState?.data?.cancelled && formatMessage(genericMessages.cancelledMessage),
                              orderDetailsState?.data?.mto && formatMessage(genericMessages.MTOMessage),
                              orderDetailsState?.data?.permanent && formatMessage(genericMessages.permanent),
                              orderDetailsState?.data?.uniform && formatMessage(genericMessages.uniform),
                          ]
                              .filter(truthy)
                              .map((item) => <Tag key={item}>{item}</Tag>)}
                </Descriptions.Item>
            </Descriptions>
            <Divider className="mb-8" />
            <Row gutter={16} align="middle" className="mb-6">
                <Col flex="auto">
                    <Typography.Title className="mb-0">
                        <FormattedMessage
                            id="OrdersProcess.docsLinkedToThisOA"
                            defaultMessage={`{count, plural, one {<bold>{count, number} Document reçu associé à ce numéro d'OA </bold>} other {<bold>{count, number} Documents reçus associés à ce numéro d'OA</bold>}}`}
                            description="Documents reçus associés à ce numéro d'OA"
                            values={{
                                count: orderDetailsState?.data?.documentActions?.filter((documentAction) =>
                                    [
                                        OrderStatus.approved,
                                        OrderStatus.consulted,
                                        OrderStatus.contested,
                                        OrderStatus.pending,
                                        OrderStatus.sent,
                                    ].includes(documentAction.status)
                                ).length,
                                bold: (chunk: ReactNode) => <Typography.Text strong>{chunk}</Typography.Text>,
                            }}
                        />
                    </Typography.Title>
                </Col>
                <Col>
                    <Button onClick={onDownloadAll} size="small" shape="round">
                        {formatMessage({
                            id: 'OrdersProcess.download',
                            defaultMessage: `télécharger`,
                        })}
                    </Button>
                </Col>
            </Row>
            <div className="OA-drawer-grey-content">
                <Typography.Text className="mb-4 block uppercase font-bold font-16 OA-versions-subtitles">
                    <FormattedMessage
                        id="OrdersProcess.lastDocVersionsReceived"
                        defaultMessage="Dernières versions reçues"
                        description="Dernières versions reçues pour un OA"
                    />
                    <Badge count={formatNumber(lastDocumentsReceived?.length ?? 0)} overflowCount={100} showZero />
                </Typography.Text>
                {lastDocumentsReceived?.map((documentAction, index) => (
                    <div key={index}>
                        <OrderDocumentCard documentAction={documentAction} />
                    </div>
                ))}

                <Collapse expandIconPosition="right" className="collapse-former-versions" defaultActiveKey={1}>
                    <Collapse.Panel
                        className="collapse-former-versions-panel"
                        key="1"
                        header={
                            <Typography.Text className="mt-5 block uppercase font-16 OA-versions-subtitles">
                                <FormattedMessage
                                    id="OrdersProcess.formerVersions"
                                    defaultMessage="Anciennes versions"
                                    description="Anciennes versions pour un OA"
                                />
                                <Badge
                                    count={formatNumber(documentsFormerVersions?.length ?? 0)}
                                    overflowCount={100}
                                    showZero
                                />
                            </Typography.Text>
                        }
                    >
                        {documentsFormerVersions?.map((documentAction, index) => (
                            <div key={index}>
                                <OrderDocumentCard documentAction={documentAction} formerVersion />
                            </div>
                        ))}
                        {documentsFormerVersions?.length === 0 && (
                            <Typography.Text>
                                <FormattedMessage
                                    id="OrdersProcess.thereIsNoFormerVersions"
                                    defaultMessage="Il n'y a aucune ancienne version de document associé à ce numéro d'OA."
                                    description="Il n'y a pas d'anciennes versions pour un OA"
                                />
                            </Typography.Text>
                        )}
                    </Collapse.Panel>
                </Collapse>
            </div>
        </Drawer>
    );
});

export default OrderDetailsDrawer;
