import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, Drawer, Form, FormProps, Input, Typography } from 'antd';
import NiceModal, { antdDrawer, useModal } from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';

import {
    create as createSupplierCertificate,
    getFiberSources as getFiberSourcesAction,
    getProviderCertificationsCreateState,
    getProviderCertificationsGetFiberSourcesState,
} from '../../store/actions/providerCertifications';
import { getUser } from '../../store/actions/auth';

import formMessages from '../../i18n/formMessages';
import LabelWithTooltipIcon from '../../components/LabelWithTooltipIcon';
import { useActions, usePrevious } from '../../hooks';
import { errorMessage, successMessage } from '../../helpers/message';
import genericMessages from '../../i18n/genericMessages';
import TreeSelectBrandAndType from '../../components/form/TreeSelectBrandAndType';
import SelectDocumentYear from '../../components/form/SelectDocumentYear';
import SelectProviderCollection from '../../components/form/SelectProviderCollection';
import RequiredFields from '../../components/RequiredFields';
import ProviderCertificationFiberDrawer from './orders/ProviderCertificationFiberDrawer';
import FiberCards from './FiberCards';
import { Fiber, ValueListSlug } from '../../store/api/apiTypes';
import { FiberElement } from '../../store/api/providerCertifications';

const ProviderCertificationDrawer = NiceModal.create((props) => {
    const drawer = useModal();
    const [create, listFiberSources] = useActions([createSupplierCertificate.trigger, getFiberSourcesAction.trigger]);
    const providerCertificateCreateState = useSelector(getProviderCertificationsCreateState);
    const fiberSourcesState = useSelector(getProviderCertificationsGetFiberSourcesState);
    const previous = usePrevious({ providerCertificateCreateState });
    const user = useSelector(getUser);
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [fibersToCreate, setFibersToCreate] = useState<Fiber[]>([]);
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        const brands = [];
        const providerTypes = [];
        for (const brand of values.brandsAndTypes) {
            brands.push(brand.brand);
            for (const providerType of brand.providerTypes) {
                providerTypes.push(providerType);
            }
        }

        create({ ...values, brands, providerTypes, fibers: fibersToCreate });
    };

    const onModifyFiber = (index: number, fiberToModify: Fiber) => {
        NiceModal.show('fiberDrawer', { fiberToModify }).then((fiber) => {
            setFibersToCreate((fibers) => {
                const fibersUpdatedValues = [...fibers];
                fibersUpdatedValues[index] = fiber as Fiber;
                form.setFieldsValue({ fibers: fibersUpdatedValues });
                return fibersUpdatedValues;
            });
        });
    };

    const onDeleteFiber = (index: number) => {
        setFibersToCreate(fibersToCreate.filter((value, currentIndex) => currentIndex !== index));
    };

    useEffect(() => {
        if (previous?.providerCertificateCreateState.loading && !providerCertificateCreateState.loading) {
            if (providerCertificateCreateState.error || !providerCertificateCreateState.data) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'supplier_certificate_drawer.succes_create_message',
                        defaultMessage: 'Attestation fournisseur créée avec succès',
                        description: 'Attestation fournisseur Success Message',
                    }),
                });
                drawer.resolve();
                drawer.hide();
            }
        }
    }, [previous?.providerCertificateCreateState.loading, providerCertificateCreateState, formatMessage, drawer]);

    useEffect(() => {
        listFiberSources({
            valueListSlug: ValueListSlug.providerCertificationFiberSource,
        });
    }, [listFiberSources]);

    return (
        <Drawer
            width={500}
            title={formatMessage({
                id: 'supplier_certificate_drawer.title',
                defaultMessage: 'Envoyer une nouvelle attestation',
                description: 'Drawer title',
            })}
            {...props}
            {...antdDrawer(drawer)}
            className="providerCertificationDrawer"
        >
            <Form form={form} onFinish={onFormValidSubmit} layout="vertical" requiredMark={false} scrollToFirstError>
                <Form.Item
                    name="brandsAndTypes"
                    label={
                        <LabelWithTooltipIcon
                            label={formatMessage({
                                id: 'supplier_certificate_drawer.form.item.label.univers&types',
                                defaultMessage: 'Univers & type(s)',
                                description: 'input label',
                            })}
                            tooltip={formatMessage({
                                id: 'supplier_certificate_drawer.form.item.tooltip.univers&types',
                                defaultMessage:
                                    'Univers est le champ qui indique pour quels univers de Christian Dior Couture vous travaillez Type permet de connaître votre activité, et ainsi de vous mettre en relation avec les bons interlocuteurs via la plateforme.',
                                description: 'input help tooltip',
                            })}
                        />
                    }
                    rules={[requiredRule]}
                >
                    <TreeSelectBrandAndType
                        brandsAndTypes={
                            user?.brandsAndTypes && user?.brandsAndTypes?.length > 0
                                ? user?.brandsAndTypes
                                : user?.organization?.brandsAndTypes
                        }
                        placeholder={formatMessage({
                            id: 'supplier_certificate_drawer.form.item.select.univers&types',
                            defaultMessage: 'Sélectionner',
                            description: 'Select label',
                        })}
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    name="year"
                    label={formatMessage(formMessages.supplier_certificate_drawer_year)}
                    rules={[requiredRule]}
                >
                    <SelectDocumentYear
                        placeholder={formatMessage({
                            id: 'supplier_certificate_drawer.form.item.select.year',
                            defaultMessage: "Sélectionner l'année",
                            description: 'input placeholder',
                        })}
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    name="providerCollection"
                    label={formatMessage(formMessages.supplier_certificate_drawer_collection)}
                    rules={[requiredRule]}
                >
                    <SelectProviderCollection
                        placeholder={formatMessage({
                            id: 'supplier_certificate_drawer.form.item.select.collection',
                            defaultMessage: 'Sélectionner la collection',
                            description: 'input placeholder',
                        })}
                        size="large"
                    />
                </Form.Item>
                <Divider />
                <Typography.Text className="mb-6 block uppercase font-16 font-bold">
                    <FormattedMessage
                        id="supplier_certificate_drawer.subtitle.material.and.fibers"
                        defaultMessage="Matière & fibres"
                        description="Matière et fibres sous titre"
                    />
                </Typography.Text>
                <Form.Item
                    name="reference"
                    label={
                        <LabelWithTooltipIcon
                            label={formatMessage(formMessages.supplier_certificate_drawer_ref)}
                            tooltip={
                                <FormattedMessage
                                    id="supplier_certificate_drawer.form.item.tooltip.ref"
                                    defaultMessage="Pour un tissu, mettre la référence DIOR du tissu {br} Pour un produit fini, mettre la référence DIOR du style"
                                    description="input help tooltip"
                                    values={{
                                        br: <br />,
                                    }}
                                />
                            }
                        />
                    }
                    rules={[requiredRule]}
                >
                    <Input
                        placeholder={formatMessage({
                            id: 'supplier_certificate_drawer.form.item.input.ref',
                            defaultMessage: 'Saisir une référence matière ou PF',
                            description: 'input placeholder',
                        })}
                        size="large"
                    />
                </Form.Item>

                <Form.Item
                    name="fibers"
                    className="formItemFibers"
                    label={
                        <LabelWithTooltipIcon
                            label={
                                <FormattedMessage
                                    id="supplier_certificate_drawer.form.item.input.label.fibers"
                                    defaultMessage="Informations sur les fibres"
                                    description="list infos fiber label"
                                />
                            }
                            tooltip={
                                <FormattedMessage
                                    id="supplier_certificate_drawer.form.item.tooltip.fibers"
                                    defaultMessage="tooltip d'informations sur les fibres"
                                    description="input help tooltip"
                                />
                            }
                        />
                    }
                    rules={[requiredRule]}
                >
                    <div className="mt-4 mb-4">
                        <FiberCards
                            fibers={fibersToCreate}
                            onDeleteFiber={onDeleteFiber}
                            onModifyFiber={onModifyFiber}
                        />
                    </div>
                    <Button
                        onClick={() => {
                            NiceModal.show('fiberDrawer').then((fiber) => {
                                const newFiber = fiber as Fiber;
                                const newFiberSource = fiberSourcesState.data?.items.find(
                                    (source) => source.id === (fiber as FiberElement).source
                                );
                                if (newFiberSource) {
                                    newFiber.source = newFiberSource;
                                }

                                setFibersToCreate((fibers) => {
                                    form.setFieldsValue({ fibers: [...fibers, fiber as Fiber] });
                                    return [...fibers, fiber as Fiber];
                                });
                            });
                        }}
                        shape="round"
                        size="middle"
                        className="!font-14"
                        ghost
                        block
                    >
                        <FormattedMessage
                            id="supplier_certificate_drawer.form.add_fiber_button"
                            defaultMessage="Ajouter des infos sur une fibre"
                            description="form add fiber button text"
                        />
                    </Button>
                </Form.Item>

                <Divider />

                <Button
                    loading={providerCertificateCreateState.loading}
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    size="large"
                    className="mb-4"
                    block
                >
                    <FormattedMessage
                        id="supplier_certificate_drawer.form.submit_button"
                        defaultMessage="Envoyer l'attestation"
                        description="form submit button text"
                    />
                </Button>
                <Button
                    onClick={() => {
                        drawer.hide();
                    }}
                    shape="round"
                    size="large"
                    className="mb-8"
                    ghost
                    block
                >
                    {formatMessage(formMessages.cancel)}
                </Button>
                <Typography.Paragraph className="!mb-8">
                    <p className="text-center !font-14">
                        <RequiredFields />
                    </p>
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <p className="mb-0 !font-14">
                        <Typography.Text type="secondary">
                            <FormattedMessage
                                id="supplier_certificate_drawer.form.item.paragrah.help"
                                defaultMessage="Attention, une fois l'envoi validé, vous ne pourrez plus supprimer cette attestation. Vous devrez contacter un administrateur."
                                description="form help text"
                            />
                        </Typography.Text>
                    </p>
                </Typography.Paragraph>
            </Form>
            <ProviderCertificationFiberDrawer id="fiberDrawer" />
        </Drawer>
    );
});

export default ProviderCertificationDrawer;
