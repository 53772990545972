import { FC, ReactNode } from 'react';
import { Button, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import '../assets/styles/ReactPlayer.less';
import { FormattedMessage } from 'react-intl';

export interface PlayerProps {
    isVisible: boolean;
    onClose?: () => void;
}

const ManagementModal: FC<PlayerProps> = ({ isVisible, onClose }) => {
    return (
        <Modal maskClosable={false} footer={null} visible={isVisible} closable={false} width={500}>
            <Typography.Title>
                <FormattedMessage
                    id="management_modal.title"
                    defaultMessage="Gestion des utilisateurs"
                    description="modal title"
                />
            </Typography.Title>
            <Typography.Paragraph style={{ marginBottom: '2rem' }}>
                <p>
                    <FormattedMessage
                        id="management_modal.content"
                        defaultMessage={`Dans le cadre de la politique de sécurité informatique de Christian Dior Couture, prière de vous rendre dans l'onglet << Utilisateurs >> de votre compte COFOU, afin de <bold>vérifier les comptes</bold> de vos collaborateurs:{br}{br}Veuillez supprimer les comptes des personnes qui n'ont plus besoin d'avoir accès à la plateforme.{br}Veuillez mettre à jour les informations : nom, numéro de téléphone, intitulé de poste et type de profil utilisateur.`}
                        description="modal content"
                        values={{
                            br: <br />,
                            bold: (chunk: ReactNode) => <Typography.Text strong>{chunk}</Typography.Text>,
                        }}
                    />
                </p>
            </Typography.Paragraph>
            <div style={{ textAlign: 'right' }}>
                <Button type="primary" shape="round" onClick={onClose} size="small">
                    <FormattedMessage
                        id="management_modal.close"
                        defaultMessage="J'ai compris"
                        description="modal call to action"
                    />
                </Button>
            </div>
        </Modal>
    );
};

export default ManagementModal;
