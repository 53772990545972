import jwt_decode from 'jwt-decode';
import { User, Contact, Translation, SupportedLanguage } from '../store/api/apiTypes';

export { default as constants } from '../config/constants';

interface LoggerType {
    isAllowed: boolean;
    log: (messages?: any, ...optionalParams: any[]) => void;
    warn: (messages?: any, ...optionalParams: any[]) => void;
}

class Logger implements LoggerType {
    public isAllowed: boolean;

    constructor() {
        this.isAllowed = process.env.NODE_ENV !== 'production';
    }

    public log(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: dodgerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public info(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: cornflowerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public warn(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: darkorange; font-weight: bold', messages, ...optionalParams);
        }
    }

    public error(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: tomato; font-weight: bold', messages, ...optionalParams);
        }
    }
}

export const debug = new Logger();

export const getFullName = (user?: User | Contact) => {
    if (!user || (!user.firstName && !user.lastName)) {
        return '';
    }

    return `${capitalize(user.firstName) ?? ''}${user.lastName ? ` ${capitalize(user.lastName)}` : ''}`.trim();
};

export const getNameWithFirstLetterOfLastName = (user?: User | Contact) => {
    if (!user || (!user.firstName && !user.lastName)) {
        return '';
    }

    return `${capitalize(user.firstName) ?? ''}${
        user.lastName ? ` ${capitalize(user.lastName.substring(0, 1))}.` : ''
    }`.trim();
};

export const capitalize = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.split(' ').map(capitalize).join(' ');
};

export const addYear = (date: Date) => {
    date.setFullYear(date.getFullYear() + 1);

    return date;
};

export function classNames(...args: Array<string | undefined | boolean>) {
    return [...args].filter(Boolean).join(' ');
}

export const stripUndefinedKeysFromObject = (object: { [key: string]: any }) =>
    Object.keys(object).forEach((key) => {
        if (object[key] === undefined) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete object[key];
        }
    });

export const checkIfTokenExpired = (token: string) => {
    try {
        const decoded: { exp: number } = jwt_decode(token);
        return decoded.exp * 1000 - Date.now() < 0;
    } catch (error) {
        return true;
    }
};

export const scrollToElement = (element: HTMLElement | null, offset?: number) => {
    if (element) {
        const elementTop =
            element.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop);

        window.scrollTo({
            top: elementTop - (offset ?? 0),
            behavior: 'smooth',
        });
    }
};

export const downloadFile = (url?: string, filename?: string) => {
    if (!url) {
        return;
    }

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename ?? 'download');
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
};

export const downloadBlobFile = (filename: string, data: any, type = 'text/csv') => {
    const file = new Blob([data], { type });
    const fileUrl = URL.createObjectURL(file);

    const element = document.createElement('a');
    element.href = fileUrl;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
};

export const printPage = (url: string) => {
    let iframe = document.getElementById('print-iframe') as HTMLIFrameElement;
    iframe = document.createElement('iframe');
    iframe.onload = () => {
        iframe.contentWindow!.onbeforeunload = () => {
            document.body.removeChild(document.getElementById('print-iframe')!);
            URL.revokeObjectURL(url);
        };
        iframe.contentWindow!.onafterprint = () => {
            document.body.removeChild(document.getElementById('print-iframe')!);
            URL.revokeObjectURL(url);
        };
        iframe.contentWindow!.focus(); // Required for IE
        iframe.contentWindow!.print();
    };
    iframe.style.position = 'fixed';
    iframe.style.right = '0';
    iframe.style.bottom = '0';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';
    iframe.id = 'print-iframe';
    iframe.src = url;

    document.body.appendChild(iframe);
};

export const translation = (field: Translation | string | undefined, user?: User) => {
    if (!field) {
        return '-';
    }

    const userLanguage = user?.language ?? SupportedLanguage.en;

    if (typeof field === 'string' || field instanceof String) {
        return field.toString();
    } else {
        return field[userLanguage] ?? field.fr ?? field.en ?? '-';
    }
};

export const sortOrderConverter = (value: string) => {
    switch (value) {
        case 'ascend':
            return 'asc';
        case 'descend':
            return 'desc';
        default:
            return value;
    }
};

export const parseStringToBoolean = (value?: string) => {
    if (value === undefined) {
        return undefined;
    }

    return value === 'true';
};
