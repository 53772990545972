import api from './_client';
import { SearchPaginationQuery } from './';

// Types
import { Certification } from './apiTypes';

export interface CertificationIdPayload {
    id: Certification['id'];
}

export type CertificationListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: CertificationListPayload = {}) => {
    return await api.get(`/certifications`, { params: payload });
};

export const details = async (payload: CertificationIdPayload) => {
    return await api.get(`/certifications/${payload.id}`);
};
