import { FC } from 'react';

import '../assets/styles/FixedFooter.less';

interface FixedFooterComposition {
    Wrapper: FC;
}

const FixedFooter: FC & FixedFooterComposition = ({ children }) => <footer className="fixed-footer">{children}</footer>;

const Wrapper: FixedFooterComposition['Wrapper'] = ({ children }) => (
    <div className="fixed-footer-wrapper">{children}</div>
);

FixedFooter.Wrapper = Wrapper;

export default FixedFooter;
