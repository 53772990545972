import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, Descriptions, Divider, Spin, Typography } from 'antd';
import { FC, Fragment, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import '../../assets/styles/FactoryAudit.less';

import { Answer, Audit, AuditCategory, Factory, RemoteFile } from '../../store/api/apiTypes';

import ButtonIcon from '../../components/ButtonIcon';
import LabelWithTooltipIcon from '../../components/LabelWithTooltipIcon';
import genericMessages from '../../i18n/genericMessages';
import { auditQuestions } from '../../i18n/auditQuestionsMessages';
import FactoryAuditDrawer from './FactoryAuditDrawer';
import { useSelector } from 'react-redux';
import { useActions, usePrevious } from '../../hooks';
import { downloadFile } from '../../helpers';
import {
    list as auditsList,
    getAuditsListState,
    getAuditsCreateState,
    getAuditsUpdateState,
} from '../../store/actions/audits';

import { list as typeOfAuditsList, getTypeOfAuditsListState } from '../../store/actions/typeOfAudits';
import { downloadFromApi } from '../../store/api/audits';
import EditOutlinedWithToolTip from '../../components/EditOutlinedWithToolTip';

interface FactoryAuditProps {
    factoryId: Factory['id'];
}

const FactoryAudit: FC<FactoryAuditProps> = ({ factoryId }) => {
    const { formatMessage, formatDate } = useIntl();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isCategory, setCategory] = useState<AuditCategory>(AuditCategory.Environnement);
    const [loadAudits, loadTypeOfAudits] = useActions([auditsList.trigger, typeOfAuditsList.trigger]);
    const auditListState = useSelector(getAuditsListState);
    const auditCreateState = useSelector(getAuditsCreateState);
    const auditUpdateState = useSelector(getAuditsUpdateState);
    const [auditPerFactory, setAuditPerFactory] = useState<Audit[]>();
    const previous = usePrevious({ auditListState, auditCreateState, auditUpdateState });
    const typeOfAuditListState = useSelector(getTypeOfAuditsListState);
    const [auditsData, setAuditsData] = useState<Audit[]>();
    const onClickEdit = (category: AuditCategory) => {
        setCategory(category);
        setIsDrawerVisible(true);
    };
    const onCloseFormDrawer = () => {
        setIsDrawerVisible(false);
    };

    const onSuccess = () => {
        loadAudits();
    };

    const onReportDownloadClick = async (record: RemoteFile) => {
        const downloadedFile = await downloadFromApi({ id: record.id });
        if (downloadedFile) {
            const blob = new Blob([downloadedFile as any], { type: 'application/pdf' });
            const src = URL.createObjectURL(blob);

            downloadFile(src, `${record.originalName}`);
        }
    };
    const auditExist = (category: AuditCategory) => {
        return auditPerFactory?.find((item) => item.name === category);
    };

    useEffect(() => {
        loadAudits();
    }, [loadAudits]);
    useEffect(() => {
        loadTypeOfAudits();
    }, [loadTypeOfAudits]);
    useEffect(() => {
        if (previous?.auditListState.loading && !auditListState.loading && auditListState.data) {
            setAuditPerFactory(auditListState.data.items.filter((audit) => audit.factory.id === factoryId));
        }
    }, [previous, auditListState, setAuditPerFactory, factoryId]);

    return (
        <>
            {
                <Spin spinning={auditListState.loading}>
                    <Card className="card-grey" bordered={false} style={{ marginBottom: '1.5rem' }}>
                        <div className="flex flex-between" style={{ marginBottom: '0.25rem' }}>
                            <Typography.Text
                                style={{
                                    textTransform: 'uppercase',
                                    fontSize: '1rem',
                                    lineHeight: '0.875rem',
                                }}
                                strong
                            >
                                <FormattedMessage
                                    id="factory_audit.card.text.title"
                                    defaultMessage="Informations audits de l'usine"
                                    description="section title"
                                />
                            </Typography.Text>
                        </div>
                        <Divider style={{ margin: '1rem 0' }} />
                        <Collapse expandIconPosition="right" className="audit-collapse" ghost>
                            {Object.values(AuditCategory).map((category) => {
                                const answer =
                                    auditPerFactory?.find((item) => item.name === category)?.answer === true
                                        ? true
                                        : auditPerFactory?.find((item) => item.name === category)?.answer === false
                                        ? false
                                        : undefined;
                                const audit = auditExist(category);

                                return (
                                    <Collapse.Panel
                                        showArrow={
                                            (category === AuditCategory.Environnement && !!auditPerFactory?.length) ||
                                            (!!auditPerFactory?.length && answer)
                                        }
                                        header={
                                            <Typography.Text
                                                style={{
                                                    fontSize: '0.875rem',
                                                    lineHeight: '1rem',
                                                    letterSpacing: '0.5px',
                                                    flex: 'auto',
                                                }}
                                            >
                                                <FormattedMessage
                                                    {...genericMessages[category as keyof typeof genericMessages]}
                                                />
                                                {!!auditExist(category) && (
                                                    <>
                                                        {` - `}
                                                        <FormattedMessage
                                                            {...(answer ? genericMessages.yes : genericMessages.no)}
                                                        />
                                                    </>
                                                )}
                                            </Typography.Text>
                                        }
                                        extra={
                                            auditExist(category) ? (
                                                <ButtonIcon
                                                    icon={<EditOutlinedWithToolTip />}
                                                    onClick={(event) => {
                                                        onClickEdit(category);
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            ) : (
                                                <Button
                                                    type="primary"
                                                    size="small"
                                                    shape="round"
                                                    onClick={(event) => {
                                                        onClickEdit(category);
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    <FormattedMessage
                                                        id="factory_audit.collapse.panel.button"
                                                        defaultMessage="Saisir"
                                                        description="call to action"
                                                    />
                                                </Button>
                                            )
                                        }
                                        collapsible={
                                            (category === AuditCategory.Environnement && !!auditPerFactory?.length) ||
                                            (!!auditPerFactory?.length && answer)
                                                ? undefined
                                                : 'disabled'
                                        }
                                        key={category}
                                    >
                                        {category === AuditCategory.Environnement && !answer && (
                                            <>
                                                {auditQuestions.map((item, index) => (
                                                    <Fragment key={index}>
                                                        <Typography.Paragraph strong>
                                                            {item.help ? (
                                                                <LabelWithTooltipIcon
                                                                    label={formatMessage(item.question)}
                                                                    tooltip={formatMessage(item.help)}
                                                                />
                                                            ) : (
                                                                formatMessage(item.question)
                                                            )}
                                                        </Typography.Paragraph>
                                                        <Typography.Paragraph>
                                                            <p style={{ fontSize: '0.7rem' }}>
                                                                {audit?.questionnaire?.[index]?.answer ===
                                                                    Answer.yes && (
                                                                    <FormattedMessage {...genericMessages.yes} />
                                                                )}
                                                                {audit?.questionnaire?.[index]?.answer ===
                                                                    Answer.no && (
                                                                    <FormattedMessage {...genericMessages.no} />
                                                                )}
                                                                {audit?.questionnaire?.[index]?.answer ===
                                                                    Answer.notConcerned && (
                                                                    <FormattedMessage
                                                                        {...genericMessages.notConcerned}
                                                                    />
                                                                )}
                                                            </p>
                                                            {audit?.questionnaire?.[index]?.comment && (
                                                                <blockquote>
                                                                    {audit?.questionnaire?.[index]?.comment}
                                                                </blockquote>
                                                            )}
                                                        </Typography.Paragraph>
                                                    </Fragment>
                                                ))}
                                            </>
                                        )}
                                        {answer && (
                                            <>
                                                <Descriptions>
                                                    <Descriptions.Item
                                                        label={formatMessage({
                                                            id: 'factory_audit.description.item.input.type',
                                                            defaultMessage: "Type d'audit",
                                                            description: 'input label',
                                                        })}
                                                    >
                                                        {audit?.typeOfAudit?.name}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item
                                                        label={formatMessage({
                                                            id: 'factory_audit.description.item.input.period',
                                                            defaultMessage: 'Période de validité',
                                                            description: 'input label',
                                                        })}
                                                    >
                                                        {`${formatDate(audit?.beginValidityDate)} - ${formatDate(
                                                            audit?.endValidityDate
                                                        )}`}
                                                    </Descriptions.Item>
                                                    {audit?.score && (
                                                        <Descriptions.Item
                                                            label={formatMessage({
                                                                id: 'factory_audit.description.item.input.score',
                                                                defaultMessage: "Score de l'audit",
                                                                description: 'input label',
                                                            })}
                                                        >
                                                            {audit?.score}
                                                        </Descriptions.Item>
                                                    )}
                                                </Descriptions>
                                                <Descriptions className="descriptionReports" layout="vertical">
                                                    <Descriptions.Item
                                                        label={formatMessage({
                                                            id: 'factory_audit.description.item.input.reports',
                                                            defaultMessage: 'Rapport(s)',
                                                            description: 'input label',
                                                        })}
                                                    >
                                                        <div>
                                                            {audit?.reports?.map((report) => (
                                                                <div key={report.id} className="report-label-download">
                                                                    {report.originalName}
                                                                    <ButtonIcon
                                                                        onClick={onReportDownloadClick.bind(
                                                                            null,
                                                                            report
                                                                        )}
                                                                        icon={<DownloadOutlined />}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </>
                                        )}
                                    </Collapse.Panel>
                                );
                            })}
                        </Collapse>
                    </Card>
                </Spin>
            }
            <FactoryAuditDrawer
                visible={isDrawerVisible}
                onClose={onCloseFormDrawer}
                onSuccess={onSuccess}
                afterVisibleChange={(visible) => setAuditsData(visible ? auditPerFactory : [])}
                audits={auditsData}
                typeOfAudits={typeOfAuditListState.data?.items}
                factoryId={factoryId}
                category={isCategory}
            />
        </>
    );
};

export default FactoryAudit;
