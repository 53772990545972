import { FC, ReactNode, useCallback, useEffect } from 'react';
import { Alert, Button, Form, FormProps, Input, Table, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { DownloadOutlined, ExclamationCircleOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { useModal } from '@ebay/nice-modal-react';

import {
    list as materialCertificationsList,
    getMaterialCertificationsListState,
} from '../../store/actions/materialCertifications';
import {
    CertificateType,
    MaterialCertification,
    Permission,
    PermissionRight,
    RoleName,
    SupportedLanguage,
} from '../../store/api/apiTypes';
import { getUser } from '../../store/actions/auth';
import { downloadFromApi } from '../../store/api/materialCertifications';

import Seo from '../../components/Seo';
import useQueryParams from '../../hooks/queryParams';
import TableHeader from '../../components/TableHeader';
import TitleAlt from '../../components/TitleAlt';
import { downloadFile, sortOrderConverter } from '../../helpers';
import genericMessages from '../../i18n/genericMessages';
import { hasPermission, hasRole } from '../../helpers/security';
import { useActions, useDebounce } from '../../hooks';
import MaterialCertificationDrawer from './MaterialCertificationDrawer';
import DocumentDrawer from './DocumentDrawer';
import { materialCertificateTypeMessages } from '../../i18n/materialCertificateTypeMessages';
import SelectCertificateType from '../../components/form/SelectCertificateType';
import SelectCertification from '../../components/form/SelectCertification';
import SelectProviderCollection from '../../components/form/SelectProviderCollection';
import LabelWithTooltipIcon from '../../components/LabelWithTooltipIcon';
import SelectDocumentYear from '../../components/form/SelectDocumentYear';

const MaterialCertificationsList: FC = () => {
    const { formatMessage } = useIntl();
    const loadMaterialCertifications = useActions(materialCertificationsList.trigger);
    const materialCertificationsListState = useSelector(getMaterialCertificationsListState);
    const [queryParams, setQueryParams] = useQueryParams('materialCertification-list');
    const searchParam = queryParams.get('search') ?? undefined;
    const certificateType = queryParams.get('certificateType') ?? undefined;
    const certification = queryParams.get('certification') ?? undefined;
    const season = queryParams.get('season') ?? undefined;
    const year = queryParams.get('year') ?? undefined;
    const page = queryParams.get('page') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const search = useDebounce(searchParam, 350);
    const [filtersForm] = Form.useForm();
    const user = useSelector(getUser);
    const itemsPerPage = 20;
    const materialCertificationDrawer = useModal(MaterialCertificationDrawer);
    const documentDrawer = useModal(DocumentDrawer);
    const pageTitle = formatMessage({
        id: 'material_certificate_list.title',
        defaultMessage: 'Certifications matières',
        description: 'documents page menu item',
    });
    const canEdit =
        hasRole(user, [RoleName.adminProvider]) ||
        (hasRole(user, [RoleName.userProvider]) &&
            hasPermission(user, Permission.documentExchange, PermissionRight.write));
    const onMaterialCertificationDetailsClick = (document: MaterialCertification) => {
        documentDrawer.show({
            documentUrl: document?.url,
            documentFilename: document?.name,
            title: document?.name,
            children:
                document.certificateType === CertificateType.scopeCertificate ? (
                    <Typography.Paragraph style={{ marginBottom: '2rem' }}>
                        <p style={{ textTransform: 'uppercase' }}>
                            <FormattedMessage
                                id="material_certificate_list.paragrah.document_validity"
                                defaultMessage="Ce document est valide jusqu'au {expirationDate, date}."
                                description="Document validity"
                                values={{
                                    expirationDate: new Date(document?.expirationDate ?? 0),
                                }}
                            />
                        </p>
                    </Typography.Paragraph>
                ) : null,
        });
    };
    const onMaterialCertificationDownloadClick = async (record: MaterialCertification) => {
        const downloadedFile = await downloadFromApi({ id: record.id });
        if (downloadedFile) {
            const blob = new Blob([downloadedFile as any], { type: 'application/pdf' });
            const src = URL.createObjectURL(blob);

            downloadFile(src, `${record.name}.pdf`);
        }
    };

    const onTableChange: TableProps<MaterialCertification>['onChange'] = (pagination, filters, sorter: any) => {
        setQueryParams({
            sort: sorter.column?.dataIndex ? sorter.column?.dataIndex : undefined,
            sortOrder: sorter.order ? sortOrderConverter(sorter.order) : undefined,
            page: pagination.current ? pagination.current - 1 : 0,
        });
    };
    const columns: Array<ColumnProps<MaterialCertification>> = [
        {
            key: 'name',
            title: formatMessage(genericMessages.documentName),
            sorter: true,
            ellipsis: true,
            width: 160,
            render: (_, record) => (
                <Button
                    className="uppercase no-underline w-full"
                    type="link"
                    onClick={() => onMaterialCertificationDetailsClick(record)}
                >
                    <Typography.Text
                        ellipsis={{
                            tooltip: record.name,
                        }}
                    >
                        {record.name}
                    </Typography.Text>
                </Button>
            ),
        },
        {
            dataIndex: 'certificateType',
            title: formatMessage(genericMessages.certificateType),
            width: 170,
            render: (type) => (type ? <FormattedMessage {...materialCertificateTypeMessages.get(type)} /> : '-'),
        },
        {
            dataIndex: 'certifications',
            title: formatMessage(genericMessages.certification),
            width: 190,
            render: (_, record) => record.certifications?.map((certification) => certification.name).join(', '),
        },
        {
            dataIndex: 'references',
            title: formatMessage({
                id: 'generic_message.material_ref_or_PF',
                defaultMessage: 'Ref. Matière ou PF',
                description: 'table header',
            }),
            width: 140,
            render: (references) => (references?.length ? references?.join(', ') : '-'),
        },
        {
            dataIndex: 'season',
            title: formatMessage(genericMessages.season),
            width: 120,
            render: (season) =>
                season?.name[user?.language ?? SupportedLanguage.en]
                    ? season?.name[user?.language ?? SupportedLanguage.en]
                    : '-',
        },
        {
            dataIndex: 'year',
            title: formatMessage({
                id: 'material_certificate_listcolumn.title.year',
                defaultMessage: 'Année',
                description: 'table header',
            }),
            width: 90,
            render: (year) => year || '-',
        },
        {
            key: 'brands',
            title: formatMessage({
                id: 'material_certificate_list.column.title.univers',
                defaultMessage: 'Univers',
                description: 'table header',
            }),
            width: 140,
            render: (_, record) => record.brands?.map((brand) => brand.name).join(', '),
        },
        {
            dataIndex: 'validityStartDate',
            title: formatMessage({
                id: 'material_certificate_list.column.title.validity_start_date',
                defaultMessage: 'Début de validité',
                description: 'table header',
            }),
            render: (date) => (date ? <FormattedDate value={date} /> : '-'),
            sorter: true,
            defaultSortOrder: 'descend',
        },
        {
            dataIndex: 'expirationDate',
            title: formatMessage({
                id: 'material_certificate_list.column.title.expiration_date',
                defaultMessage: 'Fin de validité',
                description: 'table header',
            }),
            render: (date) => (date ? <FormattedDate value={date} /> : '-'),
            sorter: true,
        },
        {
            dataIndex: 'createdAt',
            title: formatMessage(genericMessages.postedAtTableHeader),
            render: (date) => <FormattedDate value={date} />,
            sorter: true,
        },
        {
            key: 'actions',
            title: formatMessage(genericMessages.actions),
            width: 140,
            fixed: 'right',
            render: (_, record) => (
                <div className="space-x-3">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EyeOutlined />}
                        onClick={() => onMaterialCertificationDetailsClick(record)}
                    />
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<DownloadOutlined />}
                        onClick={onMaterialCertificationDownloadClick.bind(null, record)}
                    />
                </div>
            ),
        },
    ];

    const updateList = useCallback(() => {
        const params: any = {
            provider: user?.organization?.id,
            sort: sort ?? 'validityStartDate',
            sortOrder: sortOrder ?? 'desc',
            pageSize: itemsPerPage,
            page,
            certificateType,
            certification,
            season,
            year,
            search,
        };

        loadMaterialCertifications(params);
        filtersForm.setFieldsValue(params);
    }, [
        certificateType,
        certification,
        filtersForm,
        loadMaterialCertifications,
        page,
        search,
        season,
        year,
        sort,
        sortOrder,
        user?.organization?.id,
    ]);

    const onFiltersChange: FormProps['onValuesChange'] = (change, values) => {
        setQueryParams({
            ...values,
            page: 0,
        });
    };

    useEffect(() => {
        updateList();
    }, [updateList]);

    return (
        <>
            <Seo title={pageTitle} />
            <div className="dflex justify-between items-center mb-6">
                <TitleAlt style={{ fontWeight: 'normal' }} className="mb-0">
                    <LabelWithTooltipIcon
                        label={
                            <FormattedMessage
                                id="material_certificate_list.title.numbers_informations"
                                defaultMessage={`{count, plural, =0 {<bold>Aucune certification matière</bold> envoyée.} one {<bold>{count, number} certification matière</bold> envoyée.} other {<bold>{count, number} certifications matières</bold> envoyées.}}`}
                                description="supplier certificates list title"
                                values={{
                                    count: materialCertificationsListState.data?.totalCount ?? 0,
                                    bold: (chunk: ReactNode) => <Typography.Text strong>{chunk}</Typography.Text>,
                                }}
                            />
                        }
                        tooltip={
                            <FormattedMessage
                                id="material_certificate_list.text.informations"
                                defaultMessage="Les certifications matières sont émises par un organisme tiers. Elles sont constituées de deux documents confirmant le label de la matière ; la certification de l'usine par l'organisme tiers indiquant son périmètre et ses dates d'émission et d'expiration, ainsi que le certificat de transaction matière indiquant notamment le poids de la marchandise vendue."
                                description="material certificates intro text"
                            />
                        }
                    />
                </TitleAlt>
                {canEdit && (
                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        onClick={() => {
                            materialCertificationDrawer.show().then(() => {
                                updateList();
                            });
                        }}
                    >
                        <FormattedMessage
                            id="material_certificate_list.cta"
                            defaultMessage="Envoyer nouvelle certification"
                            description="material certificates CTA"
                        />
                    </Button>
                )}
            </div>
            {canEdit && (
                <Alert
                    type="warning"
                    className="!mb-6"
                    message={formatMessage({
                        id: 'material_certificate_list.warning',
                        defaultMessage: 'Veuillez penser à mettre à jour vos Transaction Certificates à chaque saison',
                        description: 'material certification duration',
                    })}
                    icon={<ExclamationCircleOutlined />}
                    showIcon
                />
            )}
            <Form form={filtersForm} onValuesChange={onFiltersChange}>
                <TableHeader>
                    <Form.Item name="search" style={{ width: '100%' }}>
                        <Input
                            placeholder={formatMessage({
                                id: 'material_certificate_list.table_header.input.search',
                                defaultMessage: 'Rechercher une certification',
                                description: 'Search input placeholder',
                            })}
                            suffix={<SearchOutlined className="font-16" />}
                            allowClear
                        />
                    </Form.Item>

                    <Form.Item name="certificateType">
                        <SelectCertificateType
                            placeholder={formatMessage({
                                id: 'material_certificate_list.table_header.input.certificateType',
                                defaultMessage: 'Type de certificat',
                                description: 'certificate type input placeholder',
                            })}
                            allowClear
                            style={{ width: '240px' }}
                        />
                    </Form.Item>

                    <Form.Item name="certification">
                        <SelectCertification
                            placeholder={formatMessage({
                                id: 'material_certificate_list.table_header.input.certification',
                                defaultMessage: 'Certification',
                                description: 'certification input placeholder',
                            })}
                            allowClear
                            style={{ width: '240px' }}
                        />
                    </Form.Item>
                    <Form.Item name="season">
                        <SelectProviderCollection
                            placeholder={formatMessage({
                                id: 'material_certificate_list.table_header.input.season',
                                defaultMessage: 'Saison',
                                description: 'season input placeholder',
                            })}
                            allowClear
                            style={{ width: '160px' }}
                        />
                    </Form.Item>
                    <Form.Item name="year">
                        <SelectDocumentYear
                            placeholder={formatMessage({
                                id: 'material_certificate_list.table_header.input.year',
                                defaultMessage: 'Année',
                                description: 'Year input placeholder',
                            })}
                            allowClear
                            yearsNumber={5}
                            style={{ width: '160px' }}
                        />
                    </Form.Item>
                </TableHeader>
            </Form>
            <Table<MaterialCertification>
                columns={columns}
                loading={materialCertificationsListState.loading}
                dataSource={materialCertificationsListState.data?.items}
                pagination={{
                    total: materialCertificationsListState.data?.totalCount,
                    pageSize: itemsPerPage,
                    hideOnSinglePage: true,
                }}
                rowKey={(record) => record.id}
                onChange={onTableChange}
                scroll={{ x: 1550 }}
            />
        </>
    );
};

export default MaterialCertificationsList;
