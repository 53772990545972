import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, Typography, Spin, Layout } from 'antd';
import { FormProps } from 'antd/lib/form/Form';

import {
    getAuthState,
    resetPassword,
    resendInvitation as resendInvitationAction,
    preHook,
    getPreHookState,
} from '../../store/actions/auth';

import { LoginPayload } from '../../store/api/auth';

import Seo from '../../components/Seo';
import PasswordInput from '../../components/PasswordInput';
import LoginLayout from '../../components/LoginLayout';
import ButtonLink from '../../components/ButtonLink';
import { checkIfTokenExpired } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';
import useQueryParams from '../../hooks/queryParams';
import validatePasswordRules from '../../helpers/passwords';
import { useActions, usePrevious } from '../../hooks';
import genericMessages from '../../i18n/genericMessages';
import formMessages from '../../i18n/formMessages';
import { errorMessage } from '../../helpers/message';
import { useHistory } from 'react-router-dom';

const ResetPassword: FC = () => {
    const history = useHistory();
    const { formatMessage } = useIntl();
    const [sendResetPassword, sendPreHook, resetResetPassword, resendInvitation] = useActions([
        resetPassword.trigger,
        preHook.trigger,
        resetPassword.reset,
        resendInvitationAction.trigger,
    ]);
    const authState = useSelector(getAuthState);
    const [isTokenExpired, setIsTokenExpired] = useState<boolean | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [queryParams] = useQueryParams('reset-password');
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const [validate, setValidator] = useState<string | undefined>('onSubmit');
    const isFirstSetup = queryParams.get('firstSetup');
    const preHookState = useSelector(getPreHookState);
    const token = queryParams.get('token');
    const previous = usePrevious({ preHookState });
    const onFormValidSubmit: FormProps['onFinish'] = (values: LoginPayload) => {
        sendResetPassword({
            ...values,
            token,
        });
    };
    const onClickSendNewInvitation = () => {
        resendInvitation({ token });
    };
    const onSubmit = () => {
        setValidator(undefined);
    };
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    let error =
        authState.resetPasswordError || authState.resendInvitationError ? (
            <FormattedMessage {...genericMessages.defaultError} />
        ) : null;

    if (authState.resetPasswordError) {
        if (isFirstSetup) {
            error = <FormattedMessage {...genericMessages.defaultError} />;
        } else if (authState.resetPasswordError?.status === 404) {
            error = (
                <FormattedMessage
                    id="reset_password.error.404.email"
                    defaultMessage="Adresse e-mail introuvable"
                    description="reset password email not found"
                />
            );
        } else if (authState.resetPasswordError?.status === 400) {
            error = <FormattedMessage {...formMessages.invalidEmail} />;
        } else if (authState.resetPasswordError?.status === 409) {
            error = <FormattedMessage {...genericMessages.newPasswordError} />;
        } else {
            error = <FormattedMessage {...genericMessages.defaultError} />;
        }
    }
    const pageTitle = isFirstSetup
        ? formatMessage({
              id: 'reset_password.title.create_password',
              defaultMessage: 'Créez votre mot de passe',
              description: 'Create password page title',
          })
        : formatMessage({
              id: 'reset_password.title.reset_password',
              defaultMessage: 'Reinitialisez votre mot de passe',
              description: 'Reset password page title',
          });
    const formContent = authState.resetPasswordSuccess ? (
        <>
            <Typography.Paragraph>
                {isFirstSetup ? (
                    <FormattedMessage
                        id="reset_password.paragraph.success_save"
                        defaultMessage="Votre mot de passe a été enregistré avec succès"
                        description="first setup create password success message"
                        tagName="p"
                    />
                ) : (
                    <FormattedMessage
                        id="reset_password.paragraph.success_edit"
                        defaultMessage="Votre mot de passe a été modifié avec succès"
                        description="change password success message"
                        tagName="p"
                    />
                )}
            </Typography.Paragraph>
            <ButtonLink
                to={isFirstSetup ? getRoute(RoutePathName.login) : getRoute(RoutePathName.home)}
                type="primary"
                shape="round"
            >
                <FormattedMessage
                    id="reset_password.button_link.access_site"
                    defaultMessage="Acceder au site"
                    description="go-to-site button after create/change password success"
                />
            </ButtonLink>
        </>
    ) : (
        <>
            <Typography.Paragraph>
                <FormattedMessage
                    id="reset_password.paragraph.change_password"
                    defaultMessage="Merci de saisir un nouveau mot de passe ci-dessous"
                    description="Change password information text"
                    tagName="p"
                />
            </Typography.Paragraph>
            <Form.Item
                label={formatMessage({
                    id: 'reset_password.form.item.label.new_password',
                    defaultMessage: 'Nouveau mot de passe',
                    description: 'Change-password password field label',
                })}
                rules={[
                    requiredRule,
                    {
                        validator: async (_, value) => {
                            if (!validatePasswordRules(value)) {
                                return await Promise.resolve();
                            }
                            return await Promise.reject(formatMessage(formMessages.invalidPassword));
                        },
                    },
                ]}
                name="password"
                validateTrigger={validate}
            >
                <PasswordInput
                    onChange={resetResetPassword}
                    placeholder={formatMessage({
                        id: 'reset_password.form.item.input.password',
                        defaultMessage: '••••••••••••',
                        description: 'reset password email placeholder',
                    })}
                />
            </Form.Item>
            {error ? (
                <Typography.Paragraph type="danger">
                    <p>{error}</p>
                </Typography.Paragraph>
            ) : null}
            <Form.Item shouldUpdate>
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={authState.loading}
                        size="large"
                        shape="round"
                        onClick={onSubmit}
                        disabled={
                            !form.isFieldsTouched(true) ||
                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                        }
                        block
                    >
                        {isFirstSetup ? (
                            <FormattedMessage
                                id="reset_password.form.item.button.create"
                                defaultMessage="Créer votre mot de passe"
                                description="Create password submit button"
                            />
                        ) : (
                            <FormattedMessage
                                id="reset_password.form.item.button.reset"
                                defaultMessage="Réinitialiser votre mot de passe"
                                description="Reset password submit button"
                            />
                        )}
                    </Button>
                )}
            </Form.Item>
        </>
    );

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    useEffect(() => {
        if (token) {
            setIsTokenExpired(token === null ? true : checkIfTokenExpired(token));
        }
    }, [setIsTokenExpired, token]);

    useEffect(
        () => () => {
            resetResetPassword();
        },
        [resetResetPassword]
    );

    useEffect(() => {
        isFirstSetup
            ? sendPreHook({
                  token,
                  firstSetup: true,
              })
            : setIsLoading(false);
    }, [isFirstSetup, sendPreHook, token]);
    useEffect(() => {
        if (previous?.preHookState.loading && !preHookState.loading) {
            if (preHookState.error) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
                setIsLoading(false);
            } else if (preHookState.data?.result?.isAlreadySet) {
                history.push(getRoute(RoutePathName.home));
                setIsLoading(false);
            } else if (preHookState.data) {
                setIsLoading(false);
            }
        }
    }, [previous?.preHookState, preHookState, formatMessage, history]);

    return isLoading ? (
        <Layout id="login-layout">
            <div id="login-section">
                <div id="login-section-inner">
                    <Spin size="large" />
                </div>
            </div>
        </Layout>
    ) : (
        <LoginLayout>
            <Seo title={pageTitle} />
            <Form
                className="login-form"
                onFinish={onFormValidSubmit}
                layout="vertical"
                form={form}
                requiredMark={false}
            >
                {isTokenExpired === null ? (
                    <Spin />
                ) : isTokenExpired ? (
                    <>
                        {authState.resendInvitationSuccess ? (
                            <Typography.Paragraph>
                                <FormattedMessage
                                    id="reset_password.form.item.paragraph.resend_invitation_success"
                                    defaultMessage="Une nouvelle invitation vous a été envoyée par e-mail"
                                    description="New invitation success message"
                                    tagName="p"
                                />
                            </Typography.Paragraph>
                        ) : (
                            <>
                                <Typography.Paragraph type="danger">
                                    <p>
                                        {error ?? (
                                            <FormattedMessage
                                                id="reset_password.form.item.paragraph.error.invitation"
                                                defaultMessage="L'invitation a expiré."
                                                description="Error message"
                                            />
                                        )}
                                    </p>
                                </Typography.Paragraph>
                                <Button
                                    onClick={onClickSendNewInvitation}
                                    type="primary"
                                    size="large"
                                    shape="round"
                                    loading={authState.loading}
                                >
                                    <FormattedMessage
                                        id="reset_password.form.item.button.get_new_invitation_email"
                                        defaultMessage="Recevoir une nouvelle invitation par email"
                                        description="Button to get a new invitation by email"
                                    />
                                </Button>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Typography.Title level={1}>{pageTitle}</Typography.Title>
                        {formContent}
                    </>
                )}
            </Form>
        </LoginLayout>
    );
};

export default ResetPassword;
