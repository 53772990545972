import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tag } from 'antd';

import { hasRole } from '../helpers/security';
import { rolesMessages } from '../i18n/roleMessages';
import { RoleName, User } from '../store/api/apiTypes';
interface TagUserProps {
    user: User;
}
const TagRoleProviderUser: FC<TagUserProps> = ({ user }) => {
    const { formatMessage } = useIntl();
    const permissions = (permissions: any) => {
        const permissionsDisplay = [];
        if (permissions.conformityDocument) {
            permissionsDisplay.push(
                <Tag style={{ margin: '4px' }} key={'conformity'}>
                    {formatMessage({
                        id: 'tagRoleProviderUser.conformity',
                        defaultMessage: 'Signature',
                        description: 'Tag provider user',
                    })}
                </Tag>
            );
        }
        if (permissions.documentExchange) {
            permissionsDisplay.push(
                <Tag style={{ margin: '4px' }} key={'documentExchange'}>
                    {formatMessage({
                        id: 'tagRoleProviderUser.documentExchange',
                        defaultMessage: 'Echange',
                        description: 'Tag provider user',
                    })}
                </Tag>
            );
        }
        if (permissions.majInfoProducts) {
            permissionsDisplay.push(
                <Tag style={{ margin: '4px' }} key={'majInfoProducts'}>
                    {formatMessage({
                        id: 'tagRoleProviderUser.majInfoProducts',
                        defaultMessage: 'Info de production',
                        description: 'Tag provider user',
                    })}
                </Tag>
            );
        }
        return permissionsDisplay;
    };
    return (
        <div style={{ marginBottom: '32px' }}>
            <div style={{ marginBottom: '8px' }}>
                <p
                    style={{
                        fontWeight: 700,
                        marginLeft: '4px',
                        marginRight: '8px',
                        fontSize: '16px',
                        textTransform: 'uppercase',
                    }}
                >
                    {hasRole(user, [RoleName.userProvider]) ? (
                        <FormattedMessage
                            id="tagRoleProviderUser.roleAndRights"
                            defaultMessage="Rôle et droits"
                            description="form section title"
                        />
                    ) : (
                        <FormattedMessage
                            id="tagRoleProviderUser.role"
                            defaultMessage="Rôle"
                            description="form section title"
                        />
                    )}
                </p>
            </div>
            <div style={{ marginLeft: '-4px' }}>
                <Tag style={{ backgroundColor: 'black', color: 'white', margin: '4px' }}>
                    <FormattedMessage {...rolesMessages.get(user.role?.name ?? RoleName.readerProvider)} />
                </Tag>
                {hasRole(user, [RoleName.userProvider]) && permissions(user.permissions)}
            </div>
        </div>
    );
};

export default TagRoleProviderUser;
