import NiceModal, { antdDrawer, useModal } from '@ebay/nice-modal-react';
import { Descriptions, Drawer, DrawerProps, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProviderCertification } from '../../store/api/apiTypes';
import formMessages from '../../i18n/formMessages';
import FiberCards from './FiberCards';
import {
    getProviderCertificationsDetailsState,
    details as getProviderCertificationDetailsAction,
} from '../../store/actions/providerCertifications';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks';
import { useEffect } from 'react';

interface ProviderCertificationFiberInfoDrawerProps extends DrawerProps {
    providerCertificationId: ProviderCertification['id'];
}

const ProviderCertificationFiberInfoDrawer = NiceModal.create<ProviderCertificationFiberInfoDrawerProps>(
    ({ providerCertificationId, ...props }) => {
        const drawer = useModal();
        const { formatMessage } = useIntl();
        const [detailProviderCertification] = useActions([getProviderCertificationDetailsAction.trigger]);
        const detailProviderCertificationState = useSelector(getProviderCertificationsDetailsState);

        useEffect(() => {
            detailProviderCertification({
                id: providerCertificationId,
            });
        }, [detailProviderCertification, providerCertificationId]);

        return (
            <Drawer
                width={500}
                title={formatMessage({
                    id: 'supplier_certificate_fiber_info_drawer.title',
                    defaultMessage: 'Informations sur les fibres',
                    description: 'Drawer title',
                })}
                {...props}
                {...antdDrawer(drawer)}
            >
                <Descriptions column={1} layout="vertical" colon={false} size="small">
                    <Descriptions.Item
                        label={formatMessage({
                            id: 'supplier_certificate_fiber_info_drawer.certificationName',
                            defaultMessage: `Nom de l'attestation`,
                        })}
                    >
                        <Typography.Text>{detailProviderCertificationState.data?.name}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={formatMessage(formMessages.supplier_certificate_drawer_ref)}>
                        <Typography.Text>{detailProviderCertificationState.data?.reference}</Typography.Text>
                    </Descriptions.Item>
                </Descriptions>
                <Typography.Text className="font-bold mt-4">
                    <FormattedMessage
                        id="supplier_certificate_fiber_info_drawer.fibersInfo"
                        defaultMessage="Informations sur les fibres"
                    />
                </Typography.Text>
                <div className="mt-1">
                    {detailProviderCertificationState.data?.fibers && (
                        <FiberCards
                            fibers={detailProviderCertificationState.data?.fibers}
                            providerCertification={detailProviderCertificationState.data}
                        />
                    )}
                </div>
            </Drawer>
        );
    }
);

export default ProviderCertificationFiberInfoDrawer;
