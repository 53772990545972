import { FC, useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { list as listAction, getMaterialsListState } from '../store/actions/materials';
import { useActions } from '../hooks';
import { useSelector } from 'react-redux';
import { Material } from '../store/api/apiTypes';
import { getSelectValue, selectValueOnChange } from '../helpers/select';
import { getUser } from '../store/actions/auth';
import { defaultLanguage } from '../context/LocaleContext';

type SelectMaterialProps = SelectProps<any> & {
    onChange?: (value?: Material['id']) => void;
    value?: Material['id'];
    valueType?: 'id' | 'object';
};

const MaterialsSelect: FC<SelectMaterialProps> = ({ valueType, ...props }) => {
    valueType = valueType ?? 'id';
    const [value, setValue] = useState<Material['id']>();
    const [loadMaterials] = useActions([listAction.trigger]);
    const materialsListState = useSelector(getMaterialsListState);
    const user = useSelector(getUser);
    const options = materialsListState?.data?.items.map((material: Material) => {
        return { label: material.name[user?.language ?? defaultLanguage], value: material.id, key: material.id };
    });

    const onChange: SelectProps<any>['onChange'] = (value) => {
        setValue(value);

        if (props.onChange) {
            props.onChange(selectValueOnChange(value, props.mode, valueType, materialsListState.data?.items));
        }
    };

    useEffect(() => {
        setValue(getSelectValue(props.value));
    }, [props.value]);

    useEffect(() => {
        loadMaterials();
    }, [loadMaterials]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Select
            {...props}
            mode={'multiple'}
            onChange={onChange}
            options={options}
            value={value}
            defaultActiveFirstOption={false}
            allowClear
        />
    );
};

export default MaterialsSelect;
