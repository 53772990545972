import { Select, SelectProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Certification } from '../../store/api/apiTypes';
import { useActions } from '../../hooks';
import { getSelectValue, selectValueOnChange } from '../../helpers/select';
import { list as certificationsList, getCertificationsListState } from '../../store/actions/certifications';

type SelectCertificationProps = SelectProps<any> & {
    onChange?: (value?: Certification['id']) => void;
    valueType?: 'id' | 'object';
};

const SelectCertification: FC<SelectCertificationProps> = ({ onChange, valueType = 'id', ...props }) => {
    const loadCertifications = useActions(certificationsList.trigger);
    const certificationListState = useSelector(getCertificationsListState);
    const [value, setValue] = useState<Certification['id']>();

    const onChangeSelect: SelectProps<Certification['id']>['onChange'] = (newValue) => {
        setValue(newValue);
        if (onChange) {
            onChange(selectValueOnChange(newValue, props.mode, valueType, certificationListState?.data?.items));
        }
    };

    useEffect(() => {
        loadCertifications();
    }, [loadCertifications]);

    useEffect(() => {
        setValue(getSelectValue(props.value));
    }, [props.value]);

    return (
        <Select
            {...props}
            value={certificationListState.loading ? undefined : value}
            onChange={onChangeSelect}
            allowClear
        >
            {certificationListState.data?.items.map((certification) => (
                <Select.Option value={certification.id} key={certification.id}>
                    {certification.name}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SelectCertification;
