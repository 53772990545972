import api from './_client';
import { SearchPaginationQuery } from './';

// Types
import { Material } from './apiTypes';

// Controller Interface
export interface MaterialCreatePayload {
    body: {
        name: Material['name'];
    };
}

export interface MaterialIdPayload {
    id: Material['id'];
}

export type MaterialListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: MaterialListPayload = {}) => {
    return await api.get(`/materials`, { params: payload });
};

export const create = async (payload: MaterialCreatePayload) => {
    return await api.post(`/materials`, payload.body);
};

export const details = async (payload: MaterialIdPayload) => {
    return await api.get(`/materials/${payload.id}`);
};

export const remove = async (payload: MaterialIdPayload) => {
    return await api.delete(`/materials/${payload.id}`);
};
