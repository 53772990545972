import api from './_client';
import { SearchPaginationQuery } from './';

// Types
import { Organization } from './apiTypes';

// Controller Interface
export interface OrganizationCreatePayload {
    body: {
        name: Organization['name'];
    };
}

export interface OrganizationUpdatePayload {
    id: Organization['id'];
}

export interface OrganizationIdPayload extends Organization {}

export type OrganizationListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: OrganizationListPayload = {}) => {
    return await api.get(`/organizations`, { params: payload });
};

export const create = async (payload: OrganizationCreatePayload) => {
    return await api.post(`/organizations`, payload.body);
};

export const update = async (payload: OrganizationUpdatePayload) => {
    return await api.put(`/organizations/${payload.id}`, payload);
};

export const details = async (payload: OrganizationIdPayload) => {
    return await api.get(`/organizations/${payload.id}`);
};

export const remove = async (payload: OrganizationIdPayload) => {
    return await api.delete(`/organizations/${payload.id}`);
};
