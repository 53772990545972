import { FC } from 'react';

import { Tag, Typography } from 'antd';
import { Brand, ProviderType } from '../store/api/apiTypes';
import Translate from './Translate';
import BasicList from './BasicList';

interface BrandsAndTypesTagsListProps {
    brands?: Brand[];
    providerTypes?: ProviderType[];
}
const BrandsAndTypesTagsList: FC<BrandsAndTypesTagsListProps> = ({ brands, providerTypes }) => {
    if (!brands?.length) {
        return <>—</>;
    }

    return (
        <BasicList>
            {brands.map((brand) => {
                const brandProviderTypes = providerTypes?.filter(
                    (providerType) => providerType?.brand?.id === brand.id
                );
                return (
                    <li key={brand.id}>
                        <Typography.Title level={4} className="mb-1">
                            <Translate text={brand.name} />
                        </Typography.Title>
                        <div style={{ margin: -4 }}>
                            {brandProviderTypes?.map((providerType) => (
                                <Tag key={providerType.id} className="inline-flex" style={{ margin: 4 }}>
                                    <Translate text={providerType?.name} />
                                </Tag>
                            ))}
                        </div>
                    </li>
                );
            })}
        </BasicList>
    );
};

export default BrandsAndTypesTagsList;
