import { FC } from 'react';
import { Redirect } from 'react-router-dom';

import { getRoute, RoutePathName } from '../../routes';

const Home: FC = () => {
    return <Redirect to={getRoute(RoutePathName.purchaseOrderList)} />;
};

export default Home;
