import { Button, Modal, Typography } from 'antd';
import { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { LayoutContext } from '../context/LayoutContext';
interface welcomeModalProps {
    isVisible?: boolean;
}
const WelcomeModal: FC<welcomeModalProps> = ({ isVisible }) => {
    const { setIsWelcomeModalOpen } = useContext(LayoutContext);

    return (
        <Modal
            onCancel={setIsWelcomeModalOpen.bind(null, false)}
            footer={null}
            visible={isVisible}
            width={500}
            closable={false}
            maskClosable={false}
            centered
        >
            <Typography.Paragraph strong>
                <p style={{ fontSize: '1.125rem' }}>
                    <FormattedMessage
                        id="welcome_modal.title"
                        defaultMessage="Ajout de collaborateurs"
                        description="modal title"
                    />
                </p>
            </Typography.Paragraph>
            <Typography.Paragraph style={{ marginBottom: '2rem' }}>
                <p>
                    <FormattedMessage
                        id="welcome_modal.content"
                        defaultMessage="Nous vous suggérons d'ajouter dès maintenant vos collaborateurs sur la plateforme.{br} Pour cela, rendez vous dans le menu présent en haut à droite, dans l'onglet « Utilisateurs »"
                        description="modal content"
                        values={{
                            br: <br />,
                        }}
                    />
                </p>
            </Typography.Paragraph>
            <div style={{ textAlign: 'right' }}>
                <Button onClick={setIsWelcomeModalOpen.bind(null, false)} size="small" ghost>
                    <FormattedMessage
                        id="welcome_modal.close"
                        defaultMessage="Fermer"
                        description="modal call to action"
                    />
                </Button>
            </div>
        </Modal>
    );
};

export default WelcomeModal;
