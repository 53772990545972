import { Badge } from 'antd';
import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getAuthState, getUser, logout } from '../store/actions/auth';
import { list as ordersList, getOrdersListState } from '../store/actions/orders';
import { list as certificationList, getProviderCertificationsListState } from '../store/actions/providerCertifications';
import { Permission, PermissionRight, RoleName } from '../store/api/apiTypes';

import { hasPermission, hasRole } from '../helpers/security';
import { getRoute, RoutePathName } from '../routes';
import { useActions } from '../hooks';
import BasicList from './BasicList';
import { getApiInfosInfosState } from '../store/actions/apiInfos';

const HeaderMenu: FC = () => {
    const [logoutUser, loadOrders, loadCertifications] = useActions([
        logout.trigger,
        ordersList.trigger,
        certificationList.trigger,
    ]);
    const purchaseOrderListCount = useSelector(getOrdersListState);
    const purchaseCertificationListCount = useSelector(getProviderCertificationsListState);
    const authState = useSelector(getAuthState);
    const user = useSelector(getUser);
    const showCount =
        (purchaseOrderListCount?.data?.countNewNotLateNotOpened &&
            purchaseOrderListCount?.data?.countNewNotLateNotOpened > 0) ||
        (purchaseCertificationListCount?.data?.countRefused && purchaseCertificationListCount?.data?.countRefused > 0);
    const apiInfosInfosState = useSelector(getApiInfosInfosState);

    const signature = (
        <li>
            <NavLink to={getRoute(RoutePathName.signature)}>
                <FormattedMessage
                    id="header_menu.signature.nav_link"
                    defaultMessage="Signature"
                    description="Main header menu item"
                />
            </NavLink>
        </li>
    );
    const documentLink = (
        <NavLink to={getRoute(RoutePathName.documents)}>
            <FormattedMessage
                id="header_menu.documentExchange.nav_link"
                defaultMessage="Échanges de documents"
                description="Main header menu item"
            />
        </NavLink>
    );
    const documentExchange = (
        <li>
            {showCount ? (
                <Badge
                    style={{
                        backgroundColor: apiInfosInfosState?.data?.env === 'preprod' ? 'white' : '#52c41a',
                        boxShadow: 'none',
                        width: 8,
                        height: 8,
                    }}
                    offset={[8, 7]}
                    dot
                >
                    {documentLink}
                </Badge>
            ) : (
                documentLink
            )}
        </li>
    );
    const productInformations = (
        <li>
            <NavLink to={getRoute(RoutePathName.productionInfos)}>
                <FormattedMessage
                    id="header_menu.infoProduct.nav_link"
                    defaultMessage="Informations de production"
                    description="Main header menu item"
                />
            </NavLink>
        </li>
    );

    useEffect(() => {
        let time: NodeJS.Timeout;
        const listener = () => {
            if (authState.isConnected) {
                clearTimeout(time);
                time = setTimeout(logoutUser, 25 * 60000);
            } else {
                clearTimeout(time);
            }
        };
        document.addEventListener('mousemove', listener);
        document.addEventListener('keyup', listener);

        return () => {
            clearTimeout(time);
            document.removeEventListener('mousemove', listener);
            document.removeEventListener('keyup', listener);
        };
    }, [authState, logoutUser]);

    useEffect(() => {
        loadOrders({ provider: user?.organization?.id });
    }, [loadOrders, user?.organization?.id]);

    useEffect(() => {
        loadCertifications({ provider: user?.organization?.id });
    }, [loadCertifications, user?.organization?.id]);

    if (hasRole(user, [RoleName.userProvider])) {
        if (hasPermission(user, Permission.documentExchange, PermissionRight.write)) {
            return (
                <BasicList inline>
                    {documentExchange}
                    {signature}
                    {hasPermission(user, Permission.majInfoProducts, PermissionRight.write) && productInformations}
                </BasicList>
            );
        } else if (hasPermission(user, Permission.conformityDocument, PermissionRight.write)) {
            return (
                <BasicList inline>
                    {signature}
                    {documentExchange}
                    {hasPermission(user, Permission.majInfoProducts, PermissionRight.write) && productInformations}
                </BasicList>
            );
        } else {
            return (
                <BasicList inline>
                    {hasPermission(user, Permission.majInfoProducts, PermissionRight.write) && productInformations}
                    {signature}
                    {documentExchange}
                </BasicList>
            );
        }
    } else {
        return (
            <BasicList inline>
                {documentExchange}
                {signature}
                {productInformations}
            </BasicList>
        );
    }
};

export default HeaderMenu;
