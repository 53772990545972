import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import './assets/styles/fonts.less';
import './assets/styles/utils.less';
import './assets/styles/App.less';

import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import ErrorBoundary from './components/ErrorBoundary';
import { getRawRoute, getRoute, RoutePathName } from './routes';
import ErrorPage from './pages/error';
import Login from './pages/login';
import Home from './pages/home';
import ForgottenPassword from './pages/forgottenPassword';
import ResetPassword from './pages/resetPassword';
import { ValidateAccount } from './pages/validateAccount';
import Signature from './pages/signature/Signature';
import Documents from './pages/documents/Documents';
import { ProductionInfos } from './pages/productionInfos';
import Maintenance from './components/Maintenance';

const App: FC = () => (
    <>
        <Maintenance />
        <Switch>
            <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
            <Route exact path={getRawRoute(RoutePathName.forgottenPassword)} component={ForgottenPassword} />
            <Route exact path={getRawRoute(RoutePathName.resetPassword)} component={ResetPassword} />
            <ProtectedRoute>
                <MainLayout>
                    <ErrorBoundary>
                        <Switch>
                            <Route exact path={getRawRoute(RoutePathName.home)}>
                                <Home />
                            </Route>

                            <Route exact path={getRawRoute(RoutePathName.validateAccount)}>
                                <ValidateAccount />
                            </Route>

                            <Route path={getRawRoute(RoutePathName.signature)} exact>
                                <Redirect to={getRoute(RoutePathName.signatureUnsigned)} />
                            </Route>

                            <Route path={getRawRoute(RoutePathName.signature)}>
                                <Signature />
                            </Route>

                            <Route path={getRawRoute(RoutePathName.documents)} exact>
                                <Redirect to={getRoute(RoutePathName.purchaseOrderList)} />
                            </Route>

                            <Route path={getRawRoute(RoutePathName.documents)}>
                                <Documents />
                            </Route>

                            <Route path={getRawRoute(RoutePathName.productionInfos)}>
                                <ProductionInfos />
                            </Route>

                            <Route path="*">
                                <ErrorPage />
                            </Route>
                        </Switch>
                    </ErrorBoundary>
                </MainLayout>
            </ProtectedRoute>
        </Switch>
    </>
);

export default App;
