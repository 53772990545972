import api from './_client';
import { SearchPaginationQuery } from './';

// Types
import { TypeOfAudit } from './apiTypes';

export interface TypeOfAuditIdPayload {
    id: TypeOfAudit['id'];
}

export type TypeOfAuditListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: TypeOfAuditListPayload = {}) => {
    return await api.get(`/typeOfAudits`, { params: payload });
};

export const details = async (payload: TypeOfAuditIdPayload) => {
    return await api.get(`/typeOfAudits/${payload.id}`);
};
