import { Button, Drawer, DrawerProps, message, Spin, Typography } from 'antd';
import { FC, ReactNode, useEffect, useRef, useState, KeyboardEvent, MouseEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import useResizeObserver from 'use-resize-observer';

import { getUser } from '../../store/actions/auth';
import {
    details as documentDetail,
    sign as signDocument,
    getConformityDocumentsDetailsState,
    getConformityDocumentsSignState,
} from '../../store/actions/conformityDocuments';
import { Document } from '../../store/api/apiTypes';

import Img from '../../components/Img';
import PDFViewer from '../../components/pdf/PDFViewer';
import { getFullName } from '../../helpers';
import { useActions, usePrevious } from '../../hooks';
import genericMessages from '../../i18n/genericMessages';
import { errorMessage, successMessage } from '../../helpers/message';
import SignatureDrawerTitle from './SignatureDrawerTitle';

interface SignatureDrawerProps extends DrawerProps {
    documentId?: string;
    canSign: boolean;
}

const SignatureDrawer: FC<SignatureDrawerProps> = ({ documentId, visible, onClose, canSign }) => {
    const { formatMessage } = useIntl();
    const user = useSelector(getUser);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [loadDocument, sign] = useActions([documentDetail.trigger, signDocument.trigger]);
    const documentDetailsState = useSelector(getConformityDocumentsDetailsState);
    const documentSignState = useSelector(getConformityDocumentsSignState);
    const [file, setFile] = useState<Document | undefined>();
    const previous = usePrevious({ documentDetailsState, documentSignState });
    const signaturePadRef = useRef<SignatureCanvas | null>(null);
    const canvasWrapperRef = useRef<HTMLDivElement>(null);
    const { height, width } = useResizeObserver<HTMLDivElement>({ ref: isDrawerOpen ? canvasWrapperRef : undefined });
    const onAfterVisibleChange: DrawerProps['afterVisibleChange'] = (value) => {
        setIsDrawerOpen(value);
    };
    const onSubmit = async () => {
        if (signaturePadRef.current?.isEmpty()) {
            message.error(
                formatMessage({
                    id: 'signature_drawer.onsubmit.error_message.missing_signature',
                    defaultMessage: 'Veuillez apposer votre signature dans la zone blanche pour pouvoir valider.',
                    description: 'signature error message',
                })
            );
        } else {
            const data = signaturePadRef.current?.getTrimmedCanvas().toDataURL('image/png');
            if (data) {
                const blob = await (await fetch(data)).blob();
                const signature = new File([blob], 'fileName.png', { type: 'image/png' });
                sign({ id: documentId, file: signature, fileId: file?.file });
            }
        }
    };
    const onClear = () => {
        signaturePadRef.current?.clear();
    };

    useEffect(() => {
        if (documentId) {
            loadDocument({ id: documentId });
        }
    }, [loadDocument, documentId]);

    useEffect(() => {
        if (previous?.documentSignState.loading && !documentSignState.loading) {
            if (documentSignState.error || !documentSignState.data) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'signature_drawer.success_message.signature',
                        defaultMessage: 'Document signé avec succès',
                        description: 'signed Success Message',
                    }),
                });
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
        }
    }, [previous, documentSignState, formatMessage, onClose]);

    useEffect(() => {
        if (previous?.documentDetailsState.loading && !documentDetailsState.loading) {
            if (documentDetailsState.error || !documentDetailsState.data) {
                errorMessage({
                    content: formatMessage({
                        id: 'signature_drawer.error_message.details',
                        defaultMessage: 'Une erreur est survenue pendant la récupération du fichier',
                        description: 'signed Error Message',
                    }),
                });
            } else {
                setFile(
                    documentDetailsState?.data?.attachements.find(
                        (attachement: Document) => attachement.language === user?.language
                    ) ??
                        documentDetailsState?.data?.attachements.find(
                            (attachement: Document) => attachement.defaultDocument === true
                        )
                );
            }
        }
    }, [previous?.documentDetailsState.loading, documentDetailsState, user, formatMessage]);

    return (
        <Drawer
            visible={visible}
            onClose={onClose}
            title={documentDetailsState.data?.name}
            width={1140}
            afterVisibleChange={onAfterVisibleChange}
        >
            <div className="signature-drawer-wrapper">
                {documentDetailsState.data?.status !== 'signed' && canSign && (
                    <SignatureDrawerTitle documentDetailsState={documentDetailsState} />
                )}
                <div className="signature-drawer-pdf-wrapper">
                    <PDFViewer file={file?.url} filename={documentDetailsState.data?.name} />
                </div>
                {documentDetailsState.data?.status !== 'signed' && canSign && (
                    <Spin spinning={documentSignState.loading}>
                        <div className="signature-drawer-pad-title">
                            <Typography.Title
                                level={2}
                                style={{ fontSize: '1.625rem', lineHeight: '1.625rem', marginBottom: 0 }}
                            >
                                <FormattedMessage
                                    id="signature_drawer.title.document_to_sign"
                                    defaultMessage="Signer le document"
                                    description="Document to sign title"
                                />
                            </Typography.Title>
                            <Typography.Paragraph style={{ marginBottom: 0 }}>
                                <p style={{ fontSize: '1.125rem' }}>
                                    <FormattedMessage
                                        id="signature_drawer.paragraph.document_to_sign"
                                        defaultMessage="À signer avant le <bold>{expireAt, date}</bold>."
                                        description="Document to sign date"
                                        values={{
                                            bold: (chunk: ReactNode) => (
                                                <Typography.Text strong>{chunk}</Typography.Text>
                                            ),
                                            expireAt: documentDetailsState.data?.deadlineSigningDate
                                                ? new Date(documentDetailsState.data?.deadlineSigningDate)
                                                : new Date(),
                                        }}
                                    />
                                </p>
                            </Typography.Paragraph>
                        </div>
                        <div className="signature-drawer-pad-wrapper">
                            <div className="signature-drawer-canvas-wrapper" ref={canvasWrapperRef}>
                                <SignatureCanvas
                                    ref={signaturePadRef}
                                    minDistance={2}
                                    throttle={8}
                                    velocityFilterWeight={0.1}
                                    canvasProps={{ width, height }}
                                />
                            </div>
                            <div className="flex flex-between">
                                <Button onClick={onClear} size="large" shape="round" style={{ minWidth: 235 }} ghost>
                                    <FormattedMessage
                                        id="signature_drawer.button.erase"
                                        defaultMessage="Effacer"
                                        description="signature button"
                                    />
                                </Button>
                                <Button
                                    loading={documentSignState.loading}
                                    onClick={onSubmit}
                                    type="primary"
                                    size="large"
                                    shape="round"
                                >
                                    <FormattedMessage
                                        id="signature_drawer.button.submit"
                                        defaultMessage="Valider la signature"
                                        description="signature button"
                                    />
                                </Button>
                            </div>
                        </div>
                    </Spin>
                )}
                {documentDetailsState.data?.status === 'signed' && (
                    <div className="signature-drawer-result">
                        <div className="signature-drawer-result-img-wrapper">
                            <Img
                                src={documentDetailsState.data?.signatureImage?.url}
                                alt={formatMessage({
                                    id: 'signature_drawer.img.signature',
                                    defaultMessage: 'Signature',
                                    description: 'signature image alt text',
                                })}
                                fit="contain"
                            />
                        </div>
                        <Typography.Paragraph>
                            <p style={{ fontSize: '1.125rem' }}>
                                <FormattedMessage
                                    id="signature_drawer.paragraph.signature_author"
                                    defaultMessage="{isMe, select, true {Vous avez signé} false {Signé par {user}}} le <bold>{signedAt, date}</bold>"
                                    description="signature author"
                                    values={{
                                        bold: (chunk: ReactNode) => <Typography.Text strong>{chunk}</Typography.Text>,
                                        signedAt: new Date(documentDetailsState.data?.signatureDate ?? ''),
                                        user: getFullName(documentDetailsState.data?.signedBy),
                                        isMe: user?.id === documentDetailsState.data?.signedBy?.id,
                                    }}
                                />
                            </p>
                        </Typography.Paragraph>
                    </div>
                )}
            </div>
        </Drawer>
    );
};

export default SignatureDrawer;
