import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Divider, Drawer, Form, FormProps, Input, Typography } from 'antd';
import { CheckCircleOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import Upload, { RcFile, UploadProps } from 'antd/lib/upload';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import NiceModal, { antdDrawer, useModal } from '@ebay/nice-modal-react';

import {
    create as createMaterialCertification,
    getMaterialCertificationsCreateState,
} from '../../store/actions/materialCertifications';
import { getUser } from '../../store/actions/auth';

import formMessages from '../../i18n/formMessages';
import Select from '../../components/Select';
import LabelWithTooltipIcon from '../../components/LabelWithTooltipIcon';
import DatePicker from '../../components/DatePicker';
import { classNames } from '../../helpers';
import { useActions, usePrevious } from '../../hooks';
import { errorMessage, successMessage } from '../../helpers/message';
import genericMessages from '../../i18n/genericMessages';
import SelectCertification from '../../components/form/SelectCertification';
import SelectCertificateType from '../../components/form/SelectCertificateType';
import SelectProviderCollection from '../../components/form/SelectProviderCollection';
import { CertificateType } from '../../store/api/apiTypes';
import RequiredFields from '../../components/RequiredFields';
import SelectDocumentYear from '../../components/form/SelectDocumentYear';

const MaterialCertificationDrawer = NiceModal.create((props) => {
    const drawer = useModal();
    const user = useSelector(getUser);
    const create = useActions(createMaterialCertification.trigger);
    const createState = useSelector(getMaterialCertificationsCreateState);
    const [fileToUpload, setFileToUpload] = useState<RcFile | undefined>();
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const previous = usePrevious({ createState });
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        create({
            file: fileToUpload,
            ...values,
            certifications: Array.isArray(values?.certifications) ? values?.certifications : [values?.certifications],
            expirationDate: values.expirationDate ? dayjs(values.expirationDate).toISOString() : undefined,
            validityStartDate: values.validityStartDate ? dayjs(values.validityStartDate).toISOString() : undefined,
        });
    };
    const beforeUpload: UploadProps['beforeUpload'] = (file) => {
        if (file.type !== 'application/pdf') {
            errorMessage({
                content: formatMessage({
                    id: 'material_certificate_list.upload.error_message_type',
                    defaultMessage: 'Seuls les fichier PDF sont autorisés',
                    description: 'upload error',
                }),
            });
            return false;
        }

        if (file.size / 1024 > 5000) {
            errorMessage({
                content: formatMessage({
                    id: 'material_certificate_list.upload.error_message_5Mo',
                    defaultMessage: 'Votre fichier dépasse la limite de taille maximum de 5Mo',
                    description: 'upload error',
                }),
            });
            return false;
        }

        setFileToUpload(file);

        return false;
    };

    useEffect(() => {
        if (previous?.createState.loading && !createState.loading) {
            if (createState.error || !createState.data) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'material_certificate_drawer.success_create_message',
                        defaultMessage: 'Certification matière créée avec succès',
                        description: 'Certification material Success Message',
                    }),
                });
                drawer.resolve();
                drawer.hide();
            }
        }
    }, [previous, createState, formatMessage, drawer, form]);

    return (
        <Drawer
            width={500}
            title={formatMessage({
                id: 'material_certificate_drawer.title',
                defaultMessage: 'Envoi de la certification',
                description: 'Drawer title (material certification)',
            })}
            {...props}
            {...antdDrawer(drawer)}
        >
            <Form
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    references: [''],
                }}
                form={form}
                scrollToFirstError
            >
                <Form.Item
                    label={formatMessage({
                        id: 'material_certificate_drawer.form.item.label.certification_file',
                        defaultMessage: 'Certification matière',
                    })}
                    help={
                        <FormattedMessage
                            id="material_certificate_list.text.upload_instructions"
                            defaultMessage="Les certifications matières ne sont conservées sur la plateforme que pour une durée correspondante à leur période de validité."
                            description="upload instructions"
                        />
                    }
                    rules={[requiredRule]}
                    required
                >
                    <Upload.Dragger showUploadList={false} beforeUpload={beforeUpload}>
                        <p className="ant-upload-drag-icon">
                            {fileToUpload ? <CheckCircleOutlined /> : <InboxOutlined />}
                        </p>
                        <p className="ant-upload-text">
                            {fileToUpload ? (
                                fileToUpload.name
                            ) : (
                                <FormattedMessage {...formMessages.uploadInstructions} />
                            )}
                        </p>
                        <p className="ant-upload-hint">
                            <FormattedMessage {...formMessages.uploadFileFormatAndSize} />
                        </p>
                    </Upload.Dragger>
                </Form.Item>

                <Divider />

                <Form.Item
                    name="certificateType"
                    label={formatMessage({
                        id: 'material_certificate_drawer.form.item.input_label.certificateType',
                        defaultMessage: 'Type de certification',
                        description: 'input label',
                    })}
                    rules={[requiredRule]}
                >
                    <SelectCertificateType
                        placeholder={formatMessage({
                            id: 'material_certificate_drawer.form.item.select.certificateType',
                            defaultMessage: 'Sélectionner le type de certification',
                            description: 'input placeholder',
                        })}
                        size="large"
                    />
                </Form.Item>
                <Form.Item noStyle dependencies={['certificateType']}>
                    {({ getFieldValue }) => (
                        <Form.Item
                            name="certifications"
                            label={formatMessage({
                                id: 'material_certificate_drawer.form.item.input_label.certification',
                                defaultMessage: 'Certification',
                                description: 'input label',
                            })}
                            rules={[requiredRule]}
                        >
                            <SelectCertification
                                placeholder={formatMessage({
                                    id: 'material_certificate_drawer.form.item.select.certification',
                                    defaultMessage: 'Sélectionner la certification',
                                    description: 'input placeholder',
                                })}
                                mode={
                                    getFieldValue('certificateType') === CertificateType.transactionCertificate
                                        ? 'multiple'
                                        : undefined
                                }
                                size="large"
                            />
                        </Form.Item>
                    )}
                </Form.Item>

                <Form.Item noStyle dependencies={['certificateType']}>
                    {({ getFieldValue }) =>
                        getFieldValue('certificateType') === CertificateType.transactionCertificate ? (
                            <>
                                <Form.Item
                                    name="season"
                                    label={formatMessage({
                                        id: 'material_certificate_drawer.form.item.input_label.season',
                                        defaultMessage: 'Saison',
                                        description: 'input label',
                                    })}
                                    rules={[requiredRule]}
                                >
                                    <SelectProviderCollection
                                        placeholder={formatMessage({
                                            id: 'material_certificate_drawer.form.item.select.season',
                                            defaultMessage: 'Sélectionner une saison',
                                            description: 'input placeholder',
                                        })}
                                        size="large"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="year"
                                    label={formatMessage(formMessages.material_certificate_drawer_year)}
                                    rules={[requiredRule]}
                                >
                                    <SelectDocumentYear
                                        placeholder={formatMessage({
                                            id: 'material_certificate_drawer.form.item.select.year',
                                            defaultMessage: 'Sélectionner une année de validité',
                                            description: 'input placeholder',
                                        })}
                                        size="large"
                                        yearsNumber={5}
                                    />
                                </Form.Item>

                                <Form.List name="references">
                                    {(fields, { add, remove }, { errors }) => (
                                        <Card
                                            className={classNames(
                                                'form-list-wrapper',
                                                fields.length > 1 && 'has-more-than-one-field'
                                            )}
                                            bordered={false}
                                            size="small"
                                        >
                                            {fields.map((field, index) => (
                                                <Form.Item
                                                    label={
                                                        index === 0 ? (
                                                            <LabelWithTooltipIcon
                                                                label={formatMessage({
                                                                    id:
                                                                        'material_certificate_drawer.form.item.input_label.ref',
                                                                    defaultMessage: 'Ref. matière ou PF Dior',
                                                                    description: 'input label',
                                                                })}
                                                                tooltip={
                                                                    <FormattedMessage
                                                                        id="material_certificate_drawer.form.item.tooltip.ref"
                                                                        defaultMessage="Cette référence correspond à la référence qui figure sur l'ordre d'achat / la commessa correspondante"
                                                                        description="input help tooltip"
                                                                    />
                                                                }
                                                            />
                                                        ) : (
                                                            formatMessage(
                                                                {
                                                                    id:
                                                                        'material_certificate_drawer.form.item.input_label_number.ref',
                                                                    defaultMessage:
                                                                        'Référence n°{index} de matière Dior',
                                                                    description: 'input label',
                                                                },
                                                                { index: index + 1 }
                                                            )
                                                        )
                                                    }
                                                    key={field.key}
                                                    required={true}
                                                    className="form-list-item"
                                                >
                                                    <Form.Item {...field} rules={[requiredRule]} noStyle>
                                                        <Input
                                                            placeholder={formatMessage({
                                                                id:
                                                                    'material_certificate_drawer.form.item.input_placeholder',
                                                                defaultMessage: 'Saisir une référence matière',
                                                                description: 'input placeholder',
                                                            })}
                                                            size="large"
                                                        />
                                                    </Form.Item>
                                                    {fields.length > 1 ? (
                                                        <Button
                                                            type="text"
                                                            onClick={() => remove(field.name)}
                                                            icon={<DeleteOutlined />}
                                                        />
                                                    ) : null}
                                                </Form.Item>
                                            ))}
                                            <Form.Item className="form-list-add-more">
                                                <Button type="link" onClick={() => add()}>
                                                    <FormattedMessage
                                                        id="material_certificate_drawer.form.button_add"
                                                        defaultMessage="Ajouter une référence supplémentaire"
                                                        description="dynamic form item add-more button"
                                                    />
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                        </Card>
                                    )}
                                </Form.List>
                            </>
                        ) : null
                    }
                </Form.Item>

                <Form.Item noStyle dependencies={['certificateType', 'validityStartDate', 'expirationDate']}>
                    {({ getFieldValue }) =>
                        getFieldValue('certificateType') === CertificateType.scopeCertificate ? (
                            <>
                                <Form.Item
                                    name="validityStartDate"
                                    label={formatMessage({
                                        id: 'material_certificate_drawer.form.item.input_label.validity_start_date',
                                        defaultMessage: 'Date de début de validité',
                                        description: 'input label',
                                    })}
                                    rules={[requiredRule]}
                                >
                                    <DatePicker
                                        placeholder={formatMessage({
                                            id: 'material_certificate_drawer.form.item.input_placeholder.date',
                                            defaultMessage: 'Sélectionner une date',
                                            description: 'input placeholder',
                                        })}
                                        disabledDate={(day) => day && day > getFieldValue('expirationDate')}
                                        style={{ width: '100%' }}
                                        format="DD/MM/YYYY"
                                        showToday={false}
                                        size="large"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="expirationDate"
                                    label={formatMessage({
                                        id: 'material_certificate_drawer.form.item.input_label.expiration_date',
                                        defaultMessage: 'Date de fin de validité',
                                        description: 'input label',
                                    })}
                                    rules={[requiredRule]}
                                >
                                    <DatePicker
                                        placeholder={formatMessage({
                                            id: 'material_certificate_drawer.form.item.input_placeholder.date',
                                            defaultMessage: 'Sélectionner une date',
                                            description: 'input placeholder',
                                        })}
                                        disabledDate={(day) => day && day < getFieldValue('validityStartDate')}
                                        style={{ width: '100%' }}
                                        format="DD/MM/YYYY"
                                        showToday={false}
                                        size="large"
                                    />
                                </Form.Item>
                            </>
                        ) : null
                    }
                </Form.Item>
                <Form.Item
                    name="brands"
                    label={
                        <LabelWithTooltipIcon
                            label={formatMessage({
                                id: 'material_certificate_drawer.form.item.input_label.univers',
                                defaultMessage: 'Univers',
                                description: 'input label',
                            })}
                            tooltip={
                                <FormattedMessage
                                    id="material_certificate_drawer.form.item.tooltip.univers"
                                    defaultMessage="Indique pour quelles marques de Christian Dior Couture vous travaillez"
                                    description="input help tooltip"
                                />
                            }
                        />
                    }
                    rules={[requiredRule]}
                >
                    <Select
                        placeholder={formatMessage({
                            id: 'material_certificate_drawer.form.item.select.univers',
                            defaultMessage: 'Sélectionner une ou des univers',
                            description: 'input placeholder',
                        })}
                        size="large"
                        mode="multiple"
                        allowClear
                    >
                        {user?.brandsAndTypes && user?.brandsAndTypes?.length > 0
                            ? user?.brandsAndTypes?.map((brand) => (
                                  <Select.Option value={brand.brand.id} key={brand.brand.id}>
                                      {brand.brand.name}
                                  </Select.Option>
                              ))
                            : user?.organization?.brandsAndTypes.map((brand) => (
                                  <Select.Option value={brand.brand.id} key={brand.brand.id}>
                                      {brand.brand.name}
                                  </Select.Option>
                              ))}
                    </Select>
                </Form.Item>
                <Button
                    loading={createState.loading}
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    size="large"
                    className="mb-4"
                    block
                >
                    <FormattedMessage
                        id="material_certificate_drawer.form.submit_button"
                        defaultMessage="Envoyer la certification"
                        description="form submit button text"
                    />
                </Button>
                <Button
                    onClick={() => {
                        drawer.hide();
                    }}
                    shape="round"
                    size="large"
                    className="mb-8"
                    ghost
                    block
                >
                    {formatMessage(formMessages.cancel)}
                </Button>
                <Typography.Paragraph className="!mb-8">
                    <p className="text-center !font-14">
                        <RequiredFields />
                    </p>
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <p style={{ fontSize: '0.875rem' }}>
                        <Typography.Text type="secondary">
                            <FormattedMessage
                                id="material_certificate_drawer.form.item.tooltip.validation"
                                defaultMessage="Attention, une fois l'envoi validé, vous ne pourrez plus supprimer cette certification. Vous devrez contacter un administrateur."
                                description="form help text"
                            />
                        </Typography.Text>
                    </p>
                </Typography.Paragraph>
            </Form>
        </Drawer>
    );
});

export default MaterialCertificationDrawer;
