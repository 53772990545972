import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Skeleton, Typography } from 'antd';
import dayjs from 'dayjs';
import { ConformityDocument } from '../../store/api/apiTypes';
import { RequestState } from '../../store/reducers';

interface SignatureDrawerTitleProps {
    documentDetailsState?: RequestState<ConformityDocument>;
}

const SignatureDrawerTitle: FC<SignatureDrawerTitleProps> = ({ documentDetailsState }) => {
    const isLate = dayjs() > dayjs(documentDetailsState?.data?.deadlineSigningDate);
    const isWarning =
        dayjs() >=
            dayjs(documentDetailsState?.data?.deadlineSigningDate).add(
                -(documentDetailsState?.data?.deadlineNotification ?? 1),
                'days'
            ) && dayjs() <= dayjs(documentDetailsState?.data?.deadlineSigningDate);
    return (
        <div id="title-skeleton">
            <Skeleton loading={documentDetailsState?.loading} paragraph={{ rows: 0 }} active title={{ width: '50%' }}>
                <Typography.Paragraph>
                    <p style={{ fontSize: '1.125rem', textTransform: 'uppercase' }}>
                        {documentDetailsState?.data && isLate && (
                            <FormattedMessage
                                id="signature_drawer_title.paragraph.intro_late"
                                defaultMessage="Ce document <lateBold>devait être signé avant le {deadlineSigningDate, date}</lateBold>. Merci de le lire et le signer au plus vite."
                                description="document to sign intro"
                                values={{
                                    deadlineSigningDate: new Date(documentDetailsState?.data.deadlineSigningDate),
                                    lateBold: (chunk: ReactNode) => (
                                        <Typography.Text type="danger" strong>
                                            {chunk}
                                        </Typography.Text>
                                    ),
                                }}
                            />
                        )}
                        {documentDetailsState?.data && isWarning && (
                            <FormattedMessage
                                id="signature_drawer_title.paragraph.intro_warning"
                                defaultMessage="Ce document <warningBold>doit être signé avant {isTomorrow, select, true {demain} false {le {deadlineSigningDate, date}}}</warningBold>. Merci de le lire et le signer au plus vite."
                                description="document to sign intro"
                                values={{
                                    deadlineSigningDate: new Date(documentDetailsState?.data.deadlineSigningDate),
                                    isTomorrow:
                                        Math.abs(
                                            dayjs(documentDetailsState?.data.deadlineSigningDate).diff(dayjs(), 'hour')
                                        ) < 24,
                                    warningBold: (chunk: ReactNode) => (
                                        <Typography.Text type="warning" strong>
                                            {chunk}
                                        </Typography.Text>
                                    ),
                                }}
                            />
                        )}
                        {documentDetailsState?.data && !isWarning && !isLate && (
                            <FormattedMessage
                                id="signature_drawer_title.paragraph.intro_in_time"
                                defaultMessage="Veuillez lire le document ci-dessous et le signer avant le <bold>{deadlineSigningDate, date}</bold>."
                                description="document to sign intro"
                                values={{
                                    deadlineSigningDate: new Date(documentDetailsState?.data.deadlineSigningDate),
                                    bold: (chunk: ReactNode) => <Typography.Text strong>{chunk}</Typography.Text>,
                                }}
                            />
                        )}
                    </p>
                </Typography.Paragraph>
            </Skeleton>
        </div>
    );
};

export default SignatureDrawerTitle;
