import { Button, Divider, Form, FormProps, Typography } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Seo from '../../components/Seo';
import UserAccountFields from '../../components/UserAccountFields';
import CompanyFields from '../../components/CompanyFields';
import YourRightsFields from '../../components/YourRightsFields';
import formMessages from '../../i18n/formMessages';
import { useSelector } from 'react-redux';
import { getUser, update as updateUser, getUpdateUser } from '../../store/actions/auth';
import { useActions, usePrevious } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import { RoleName, SupportedLanguage } from '../../store/api/apiTypes';
import { errorMessage, successMessage } from '../../helpers/message';
import genericMessages from '../../i18n/genericMessages';
import { hasRole } from '../../helpers/security';
import { LayoutContext } from '../../context/LayoutContext';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { cookiesNotice } from '../../helpers/helpFiles';

const ValidateAccount: FC = () => {
    const history = useHistory();
    const { formatMessage } = useIntl();
    const user = useSelector(getUser);
    const userUpdateState = useSelector(getUpdateUser);
    const [form] = Form.useForm();
    const [checkCookies, setCheckCookies] = useState(false);
    // form.setFieldsValue(user);
    const { setIsTutorialModalOpen, setIsWelcomeModalOpen, setIsAfterValidate } = useContext(LayoutContext);
    const updateMe = useActions(updateUser.trigger);
    const previous = usePrevious({ userUpdateState, user });
    const pageTitle = formatMessage({
        id: 'validate_account.title',
        defaultMessage: 'Validez les informations du compte',
        description: 'validate account page title',
    });
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        const validateUser = {
            ...values.user,
            organization: hasRole(user, [RoleName.adminProvider])
                ? {
                      ...values.user.organization,
                      id: user?.organization?.id,
                      verificationData: true,
                  }
                : undefined,
            verificationData: true,
        };
        updateMe(validateUser);
    };
    const oncheckCookies = () => {
        setCheckCookies(!checkCookies);
    };
    useEffect(() => {
        if (previous?.userUpdateState.loading && !userUpdateState.loading) {
            if (userUpdateState.error) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'validate_account.success_message.update',
                        defaultMessage: 'Votre compte a été validé avec succès',
                        description: 'succes message',
                    }),
                });
                setIsTutorialModalOpen(true);
                setIsAfterValidate(true);
                history.push(getRoute(RoutePathName.home));
            }
        }
    }, [
        previous?.userUpdateState.loading,
        userUpdateState.loading,
        userUpdateState.error,
        history,
        formatMessage,
        setIsTutorialModalOpen,
        setIsWelcomeModalOpen,
        setIsAfterValidate,
    ]);

    return (
        <>
            <Seo title={pageTitle} />
            <div style={{ textAlign: 'center' }}>
                <Typography.Title style={{ fontSize: '2.5rem', letterSpacing: 0.5, marginBottom: '0.5rem' }}>
                    {pageTitle}
                </Typography.Title>
                <Typography.Paragraph style={{ marginBottom: '0.5rem' }}>
                    <p style={{ fontSize: '1.125rem' }}>
                        <FormattedMessage
                            id="validate_account.paragraph.intro"
                            defaultMessage="Relisez et complétez / modifiez si nécessaire les informations ci-dessous :"
                            description="validate account intro text"
                        />
                    </p>
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <p>
                        <Typography.Text strong>{formatMessage(formMessages.allFieldsRequired)}</Typography.Text>
                    </p>
                </Typography.Paragraph>
            </div>
            <Divider />
            <Form
                form={form}
                layout="vertical"
                requiredMark={false}
                onFinish={onFormValidSubmit}
                scrollToFirstError
                initialValues={{
                    user: {
                        ...user,
                        organization: {
                            ...user?.organization,
                            materials: user?.organization?.materials?.map((material) => material.id),
                        },
                    },
                }}
            >
                <UserAccountFields
                    form={form}
                    titleLevel={4}
                    title={formatMessage({
                        id: 'validate_account.user_account_fields.title',
                        defaultMessage: 'Vos informations',
                        description: 'validate account page - user account form section title',
                    })}
                    twoCols
                    targetRole={user?.role?.name}
                    brandScope={user?.brandsAndTypes}
                />
                <Divider />
                {hasRole(user, [RoleName.userProvider, RoleName.reader]) ? (
                    <YourRightsFields
                        form={form}
                        titleLevel={4}
                        title={formatMessage({
                            id: 'validate_account.your_rights_fields.title',
                            defaultMessage: 'Vos Droits',
                            description: 'validate account page - your rights',
                        })}
                        twoCols
                    />
                ) : (
                    <CompanyFields
                        form={form}
                        titleLevel={4}
                        title={formatMessage({
                            id: 'validate_account.company_fields.title',
                            defaultMessage: 'Informations de la société',
                            description: 'validate account page - company form section title',
                        })}
                        twoCols
                    />
                )}
                <Divider />
                <Checkbox onChange={oncheckCookies} style={{ fontSize: '12px' }}>
                    <FormattedMessage
                        id="validate_account.checkbox.cookies"
                        defaultMessage="J’ai lu"
                        description="message document cookies"
                    />{' '}
                    <a
                        href={cookiesNotice[user?.language ?? SupportedLanguage.en] ?? cookiesNotice.fr}
                        style={{ textDecoration: 'underline' }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FormattedMessage
                            id="validate_account.label.cookies"
                            defaultMessage="le document sur la gestion des cookies"
                            description="message document cookies"
                        />
                    </a>{' '}
                    <FormattedMessage
                        id="validate_account.label.cookies_2"
                        defaultMessage="et j’ai bien pris connaissance des cookies utilisés sur ce site"
                        description="message document cookies"
                    />
                </Checkbox>
                <div className="flex-center">
                    <Button type="primary" shape="round" htmlType="submit" disabled={!checkCookies}>
                        <FormattedMessage
                            id="validate_account.button.cookies"
                            defaultMessage="Valider les informations ci-dessus"
                            description="validate account submit button"
                        />
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default ValidateAccount;
