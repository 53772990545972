import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { TypeOfAudit, ListResponse } from '../api/apiTypes';
import {
    TypeOfAuditIdPayload,
    TypeOfAuditListPayload,
    list as listApiCall,
    details as detailsApiCall,
} from '../api/typeOfAudits';

// States
export interface TypeOfAuditsState {
    details: RequestState<TypeOfAudit>;
    list: RequestState<ListResponse<TypeOfAudit>>;
}

const initialState: TypeOfAuditsState = {
    details: {
        loading: false,
    },
    list: {
        loading: false,
    },
};

export const details = new EzeeAsyncAction<TypeOfAuditsState['details'], TypeOfAuditIdPayload, TypeOfAudit>(
    'TypeOfAudits/details',
    initialState.details,
    requestReducer<TypeOfAuditsState['details'], TypeOfAudit>(initialState.details)
);

export const list = new EzeeAsyncAction<TypeOfAuditsState['list'], TypeOfAuditListPayload, ListResponse<TypeOfAudit>>(
    'TypeOfAudits/list',
    initialState.list,
    requestReducer<TypeOfAuditsState['list'], ListResponse<TypeOfAudit>>(initialState.list)
);

// Reducer
export const typeOfAuditsReducer = combineReducers<TypeOfAuditsState>({
    list: list.reducer,
    details: details.reducer,
});

// Saga
export function* typeOfAuditsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getTypeOfAuditsListState = (state: MainReducerState) => state.typeOfAudits.list;
export const getTypeOfAuditsDetailsState = (state: MainReducerState) => state.typeOfAudits.details;
