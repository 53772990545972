import { FC } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { Button, Col, Collapse, Divider, Row, Space, Tooltip, Typography } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';

import '../../../assets/styles/OrderDocumentCard.less';

import { DocTypes, DocumentAction, OrderStatus, RemoteFile } from '../../../store/api/apiTypes';
import {
    consultation as consultationAction,
    download as downloadAction,
    getOrdersDetailsState,
} from '../../../store/actions/orders';

import genericMessages from '../../../i18n/genericMessages';
import OrderStatusTag from '../../../components/OrderStatusTag';
import IconDocProcessing from '../../../components/icons/IconDocProcessing';
import { useActions } from '../../../hooks';
import OrderDocumentDrawer from './OrderDocumentDrawer';
import { useSelector } from 'react-redux';
import CollapseHeader, { CollapseHeaderProps } from '../../../components/CollapseHeader';
import { IconHourglass } from '../../../components/icons';

interface OrderDocumentCardProps {
    documentAction: DocumentAction;
    formerVersion?: boolean;
}
const OrderDocumentCard: FC<OrderDocumentCardProps> = ({ documentAction, formerVersion = false }) => {
    const { formatMessage } = useIntl();
    const orderDocumentDrawer = useModal(OrderDocumentDrawer);
    const order = useSelector(getOrdersDetailsState)?.data;
    const [consultation, download] = useActions([consultationAction.trigger, downloadAction.trigger]);

    const onViewDocument = (documentAction: DocumentAction) => {
        if (![OrderStatus.contested, OrderStatus.approved].includes(documentAction?.status)) {
            consultation({ orderId: order?.id, documentId: documentAction.document.id });
        }
        orderDocumentDrawer.show({ documentAction, title: documentAction.document?.originalName, orderId: order?.id });
    };
    const onDownloadDocument = (document: RemoteFile) => {
        download({ id: order?.id, filename: document.originalName || document.filename, fileId: document.id });
    };

    const columns: CollapseHeaderProps['columns'] = [
        {
            content: <OrderStatusTag status={documentAction.status} />,
        },
        {
            title: formatMessage({
                id: 'orders.orderSendDrawer.document',
                defaultMessage: `Document`,
            }),
            content: (
                <Typography.Text
                    ellipsis={{
                        tooltip: documentAction.document.originalName || documentAction.document.filename,
                    }}
                >
                    {documentAction.document.originalName || documentAction.document.filename}
                </Typography.Text>
            ),
            span: 7,
        },
        {
            title: formatMessage({
                id: 'orders.orderSendDrawer.type',
                defaultMessage: `Type`,
            }),
            content: documentAction.docType,
            span: 3,
        },
        {
            title: formatMessage(genericMessages.orderlist_createdAt),
            content: (
                <>
                    <FormattedDate value={documentAction.send?.sendDate} />{' '}
                    <FormattedTime value={documentAction.send?.sendDate} />
                </>
            ),
            span: 4,
        },
        {
            content: (
                <Space>
                    <Button
                        icon={<EyeOutlined />}
                        shape="circle"
                        type="ghost"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            onViewDocument(documentAction);
                        }}
                    />
                    <Button
                        icon={<DownloadOutlined />}
                        shape="circle"
                        type="ghost"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDownloadDocument(documentAction.document);
                        }}
                    />
                </Space>
            ),
        },
    ];

    const collapseHeaderPrefix =
        !formerVersion &&
        ([OrderStatus.pending, OrderStatus.sent].includes(documentAction.status) ||
            ([OrderStatus.consulted].includes(documentAction.status) &&
                documentAction.docType !== DocTypes.COMMESSA)) ? (
            <Tooltip
                className="iconHourglass"
                title={formatMessage({
                    id: 'orders.orderSendDrawer.documentToProcess',
                    defaultMessage: 'Document à traiter',
                    description: 'Document à traiter',
                })}
            >
                <IconHourglass />
            </Tooltip>
        ) : undefined;

    return (
        <Collapse expandIconPosition="right" className="order-document-card">
            <Collapse.Panel key="1" header={<CollapseHeader columns={columns} prefix={collapseHeaderPrefix} />}>
                {documentAction?.status === OrderStatus.consulted && (
                    <div className="order-document-card__row consulted-row">
                        <div className="order-document-card__icon">
                            <EyeOutlined style={{ fontSize: '20px' }} />
                        </div>
                        <div className="w-100 order-document-card__content">
                            {documentAction.docType !== DocTypes.COMMESSA
                                ? formatMessage(genericMessages.OrdersProcess_orderConsultedAt)
                                : formatMessage(genericMessages.OrdersProcess_commessaConsultedAt)}{' '}
                            <FormattedDate value={documentAction.consultation?.consultedAt} />
                        </div>
                    </div>
                )}
                {!(
                    [OrderStatus.consulted].includes(documentAction?.status) &&
                    documentAction.docType === DocTypes.COMMESSA
                ) && (
                    <div className="order-document-card__row">
                        <div className="order-document-card__icon">
                            <IconDocProcessing style={{ fontSize: '20px' }} />
                        </div>
                        <div className="w-100 order-document-card__content">
                            {/* CASE : pending doc ( sent status ) or consulted */}
                            {![OrderStatus.approved, OrderStatus.contested].includes(documentAction?.status) && (
                                <>
                                    <div className="note-information mb-4">
                                        {documentAction.docType !== DocTypes.COMMESSA
                                            ? formatMessage(genericMessages.OrdersProcess_noteForUntreatedOA)
                                            : formatMessage(genericMessages.OrdersProcess_noteForConsultDocCommessa)}
                                    </div>
                                    <Button
                                        className="btn-consult-doc"
                                        shape="round"
                                        onClick={() => onViewDocument(documentAction)}
                                    >
                                        {formatMessage(genericMessages.OrdersProcess_consultDoc)}
                                    </Button>
                                </>
                            )}
                            {/* CASE : approved doc */}
                            {documentAction?.status === OrderStatus.approved && (
                                <div>
                                    <p className="mb-0">
                                        <FormattedMessage
                                            {...genericMessages.OrdersProcess_youApprovedAt}
                                            values={{
                                                approvedAt: new Date(documentAction.approbation?.approvedAt ?? ''),
                                            }}
                                        />
                                    </p>
                                    {(documentAction?.approbation?.provisionalDates?.length ?? 0) > 0 && (
                                        <>
                                            <Divider />
                                            <Typography.Title level={4}>
                                                {formatMessage(genericMessages.OrdersProcess_estimatedDeliveryDates)}
                                            </Typography.Title>
                                            <section className="order-document-card__list-delivry-dates">
                                                {documentAction?.approbation?.provisionalDates?.map(
                                                    (dateDelivryItem, index) => (
                                                        <Row key={index}>
                                                            <Col span={4}>
                                                                <Typography.Text strong className="block">
                                                                    {formatMessage(genericMessages.labelDate)}
                                                                </Typography.Text>
                                                                <Typography.Text className="value">
                                                                    <FormattedDate value={dateDelivryItem.date} />
                                                                </Typography.Text>
                                                            </Col>
                                                            <Col span={16}>
                                                                <Typography.Text strong className="block">
                                                                    {formatMessage(
                                                                        genericMessages.OrdersProcess_labelRefAndSerieProduct
                                                                    )}
                                                                </Typography.Text>
                                                                <Typography.Text className="value">
                                                                    {dateDelivryItem.reference}
                                                                </Typography.Text>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Typography.Text strong className="block">
                                                                    {formatMessage(genericMessages.labelQuantity)}
                                                                </Typography.Text>
                                                                <Typography.Text className="value">
                                                                    {dateDelivryItem.quantity}
                                                                </Typography.Text>
                                                            </Col>
                                                        </Row>
                                                    )
                                                )}
                                            </section>
                                        </>
                                    )}

                                    {documentAction?.approbation?.orderConfirmationDocument && (
                                        <>
                                            <Divider />
                                            <Typography.Title level={4}>
                                                {formatMessage(genericMessages.OrdersProcess_orderConfirmation)}
                                            </Typography.Title>
                                            <section className="order-document-card__list-delivry-dates">
                                                <Row className="bg-lighter-grey" align="middle">
                                                    <Col flex={1}>
                                                        {documentAction?.approbation?.orderConfirmationDocument
                                                            ?.originalName ||
                                                            documentAction?.approbation?.orderConfirmationDocument
                                                                ?.filename}
                                                    </Col>

                                                    <Col>
                                                        <Button
                                                            icon={<EyeOutlined />}
                                                            shape="circle"
                                                            type="ghost"
                                                            size="small"
                                                            onClick={() => onViewDocument(documentAction)}
                                                        />
                                                        <Button
                                                            icon={<DownloadOutlined />}
                                                            shape="circle"
                                                            type="ghost"
                                                            size="small"
                                                            disabled={
                                                                !documentAction.approbation?.orderConfirmationDocument
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    documentAction.approbation
                                                                        ?.orderConfirmationDocument
                                                                ) {
                                                                    onDownloadDocument(
                                                                        documentAction.approbation
                                                                            .orderConfirmationDocument
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </section>
                                        </>
                                    )}
                                </div>
                            )}
                            {/* CASE : contested doc */}
                            {documentAction?.status === OrderStatus.contested && (
                                <div>
                                    {formatMessage(genericMessages.OrdersProcess_youContestedAt)}{' '}
                                    <FormattedDate value={documentAction.contestation?.contestedAt} />
                                    <Divider />
                                    <Typography.Title level={4}>
                                        {formatMessage(genericMessages.commentLabel)}
                                    </Typography.Title>
                                    <div style={{ marginTop: '20px' }}>{documentAction.contestation?.comment}</div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Collapse.Panel>
        </Collapse>
    );
};

export default OrderDocumentCard;
