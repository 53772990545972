import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider, Space, Tag, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { ProviderType, RoleName, User } from '../store/api/apiTypes';

import { getFullName } from '../helpers';
import ButtonIcon from './ButtonIcon';
import DeletePopConfirm from './DeletePopConfirm';
import BasicList from './BasicList';
import { rolesMessages } from '../i18n/roleMessages';
import EditOutlinedWithToolTip from './EditOutlinedWithToolTip';
import Translate from './Translate';

interface UserInfosProps {
    edit?: boolean;
    user: User;
    onEdit?: (user: User) => void;
    onDelete?: (user: User) => void;
}

const UserInfos: FC<UserInfosProps> = ({ edit, user, onEdit, onDelete }) => {
    const providerTypes: ProviderType[] = [];
    if (user?.brandsAndTypes)
        for (const brandScopeData of user?.brandsAndTypes) {
            if (brandScopeData.providerTypes)
                for (const providerType of brandScopeData.providerTypes) {
                    if (!providerTypes.find((element) => element.id === providerType.id))
                        providerTypes.push(providerType);
                }
        }
    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ display: 'flex', width: '100%' }}>
                {!user.activated && (
                    <Divider
                        type="vertical"
                        style={{ backgroundColor: 'red', opacity: 0.5, height: '100%', width: '4px' }}
                    ></Divider>
                )}
                <div style={{ width: '100%' }}>
                    {!user.activated && (
                        <Typography.Text className="user-disable">
                            <FormattedMessage
                                id="user_infos.text.user_disable"
                                defaultMessage="Compte désactivé"
                                description="user disable"
                            />
                        </Typography.Text>
                    )}
                    <div className="flex flex-between" style={{ marginBottom: edit ? '0.25rem' : '1rem' }}>
                        <Typography.Text style={{ textTransform: 'uppercase' }}>{user.job}</Typography.Text>
                        {edit && onDelete && onEdit && (
                            <Space size="middle">
                                <ButtonIcon icon={<EditOutlinedWithToolTip />} onClick={onEdit.bind(null, user)} />
                                <DeletePopConfirm placement="topRight" onConfirm={onDelete.bind(null, user)}>
                                    <ButtonIcon icon={<DeleteOutlined />} />
                                </DeletePopConfirm>
                            </Space>
                        )}
                    </div>
                    <div style={{ lineHeight: '32px' }}>
                        {user?.brandsAndTypes &&
                            user?.role?.name === RoleName.userProvider &&
                            user?.brandsAndTypes.map((brandScope) => (
                                <Tag
                                    key={brandScope.brand.id}
                                    style={{ whiteSpace: 'normal' }}
                                    className="ant-tag-details"
                                >
                                    {brandScope.brand.name}
                                    {' : '}
                                    {brandScope.providerTypes?.map((providerType, index) => {
                                        if (index > 0) {
                                            return (
                                                <>
                                                    {' / '}
                                                    <Translate key={index} text={providerType?.name} />
                                                </>
                                            );
                                        } else {
                                            return <Translate key={index} text={providerType?.name} />;
                                        }
                                    })}
                                </Tag>
                            ))}
                    </div>
                    <Typography.Paragraph
                        style={{
                            marginBottom: '0.5rem',
                            marginTop: '0.5rem',
                            lineHeight: '1rem',
                            fontSize: '1rem',
                            letterSpacing: 1,
                        }}
                    >
                        <Typography.Text strong>{getFullName(user)}</Typography.Text>
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ marginBottom: '0.5rem', lineHeight: '1rem', letterSpacing: 1.07 }}>
                        <Typography.Text>{user.email}</Typography.Text>
                    </Typography.Paragraph>
                    {(user.phone ?? user.mobile) && (
                        <Typography.Paragraph
                            style={{ marginBottom: '0.5rem', lineHeight: '1rem', letterSpacing: 1.07 }}
                        >
                            <Typography.Text>
                                {user.phone && (
                                    <>
                                        <FormattedMessage
                                            id="user_infos.text.phone"
                                            defaultMessage="F : {phone}"
                                            description="Short for landline"
                                            values={{ phone: user.phone }}
                                        />
                                        &nbsp;
                                    </>
                                )}
                                {user.mobile && (
                                    <>
                                        {user.phone && <>&nbsp;/&nbsp;</>}
                                        <FormattedMessage
                                            id="user_infos.text.mobile"
                                            defaultMessage="P : {mobile}"
                                            description="Short for mobile phone"
                                            values={{ mobile: user.mobile }}
                                        />
                                    </>
                                )}
                            </Typography.Text>
                        </Typography.Paragraph>
                    )}
                    {user?.role?.name && (
                        <BasicList inline>
                            <li key={user?.role?.name}>
                                <Tag className="custom-tag-black">
                                    <FormattedMessage {...rolesMessages.get(user?.role?.name)} />
                                </Tag>
                            </li>
                            {user?.role?.name === RoleName.userProvider && (
                                <>
                                    {user.permissions?.conformityDocument === 'w' && (
                                        <li key={'conformityDocument'}>
                                            <Tag className="custom-tag-white">
                                                <FormattedMessage
                                                    id="user_infos.tag.permission_signature"
                                                    defaultMessage="Signature"
                                                />
                                            </Tag>
                                        </li>
                                    )}
                                    {user.permissions?.documentExchange === 'w' && (
                                        <li key={'documentExchange'}>
                                            <Tag className="custom-tag-white">
                                                <FormattedMessage
                                                    id="user_infos.tag.permission_document_exchange"
                                                    defaultMessage="Échanges de documents"
                                                />
                                            </Tag>
                                        </li>
                                    )}
                                    {user.permissions?.majInfoProducts === 'w' && (
                                        <li key={'majInfoProduct'}>
                                            <Tag className="custom-tag-white">
                                                <FormattedMessage
                                                    id="user_infos.tag.permission_maj_info"
                                                    defaultMessage="Informations de production"
                                                />
                                            </Tag>
                                        </li>
                                    )}
                                </>
                            )}
                        </BasicList>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserInfos;
