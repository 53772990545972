import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Input, FormProps, Spin, Typography } from 'antd';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';

import { contest as contestAction, getContestState } from '../../../store/actions/orders';
import { RemoteFile, Order } from '../../../store/api/apiTypes';

import { useActions, usePrevious } from '../../../hooks';
import Modal from '../../../components/Modal';
import genericMessages from '../../../i18n/genericMessages';
import IconExclamationPoint from '../../../components/icons/IconExclamationPoint';
import { errorMessage, successMessage } from '../../../helpers/message';
import formMessages from '../../../i18n/formMessages';

export interface OrderContestFormModalProps {
    documentId?: RemoteFile['id'];
    orderId?: Order['id'];
}

const OrderContestFormModal = NiceModal.create<OrderContestFormModalProps>(({ documentId, orderId }) => {
    const modal = useModal();
    const [form] = Form.useForm();
    const [contest] = useActions([contestAction.trigger]);
    const [isBtnContestDisabled, setIsBtnContestDisabled] = useState(true);
    const { formatMessage } = useIntl();
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const contestState = useSelector(getContestState);
    const previous = usePrevious({
        contestState,
    });

    const onFormFinish: FormProps['onFinish'] = (values) => {
        contest({
            orderId,
            documentId,
            body: {
                comment: values.comment,
            },
        });
    };
    const onFormValuesChange: FormProps['onValuesChange'] = (change, values) => {
        setIsBtnContestDisabled(values.comment.trim() === '');
    };

    useEffect(() => {
        if (previous?.contestState.loading && !contestState.loading) {
            if (contestState.error) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            }
            if (contestState.success) {
                successMessage({
                    content: formatMessage(genericMessages.OrdersProcess_successfulContestationMessage),
                });
                modal.resolve();
                modal.hide();
            }
        }
    }, [previous?.contestState, contestState, formatMessage, modal]);

    return (
        <Modal
            title={
                <span className="flex">
                    <IconExclamationPoint style={{ fontSize: 25, marginRight: 15 }} />{' '}
                    {formatMessage(genericMessages.OrdersProcess_contestTheOrder)}
                </span>
            }
            cancelText={formatMessage(genericMessages.cancel)}
            okText={formatMessage(genericMessages.OrdersProcess_contest)}
            {...antdModal(modal)}
            onOk={() => form.submit()}
            onCancel={() => {
                modal.reject();
                modal.hide();
            }}
            className="popup-doc-processing popup-doc-processing--contestation"
            okButtonProps={{ disabled: isBtnContestDisabled, className: '' }}
        >
            <Typography.Paragraph>
                {formatMessage(genericMessages.OrdersProcess_areYouSureToContest)}
            </Typography.Paragraph>
            <Spin spinning={contestState.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    onValuesChange={onFormValuesChange}
                    scrollToFirstError
                >
                    <Form.Item
                        label={formatMessage(genericMessages.OrdersProcess_mandatoryCommentLabel)}
                        name="comment"
                        rules={[requiredRule]}
                    >
                        <Input placeholder={formatMessage(formMessages.putCommentPlaceholder)} size="large" />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
});

export default OrderContestFormModal;
