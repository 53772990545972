import * as auth from './auth';
import api from './_client';

export type ApiResponse = Record<string, any>;

export interface RequestError {
    data?: any;
    headers?: any;
    message: string;
    status: number;
}

export interface PaginationQuery {
    page?: number;
    pageSize?: number;
    sort?: string;
    sortOrder?: string;
}

export interface SearchQuery {
    search?: string;
    throttling?: number;
}

export interface ApiInfos {
    name: string;
    version: string;
    env: 'development' | 'production' | 'preprod';
    instance: string;
    request: string;
}

export type SearchPaginationQuery = PaginationQuery & SearchQuery;

export const apiInfos = async () => await api.get<ApiInfos>('/');

export default {
    auth,
};
