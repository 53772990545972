import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import '../assets/styles/TagStatus.less';
import genericMessages from '../i18n/genericMessages';
import { OrderStatus } from '../store/api/apiTypes';

import IconStatusApproved from './icons/IconStatusApproved';
import IconStatusContested from './icons/IconStatusContested';
import IconStatusOnHold from './icons/IconStatusOnHold';
import IconStatusConsulted from './icons/IconStatusConsulted';
import { classNames } from '../helpers';

interface OrderStatusTagProps {
    status?: string;
    className?: string;
    iconFontSize?: string;
}

const OrderStatusTag: FC<OrderStatusTagProps> = ({ status = OrderStatus.pending, className }) => {
    const { formatMessage } = useIntl();
    const { icon, text, statusClassName } = useMemo(() => {
        let icon, text, statusClassName;

        if ([OrderStatus.pending, OrderStatus.sent, ''].includes(status)) {
            text = formatMessage(genericMessages.statusCofou_onHold);
            icon = <IconStatusOnHold />;
        } else if (status === OrderStatus.approved) {
            text = formatMessage(genericMessages.statusCofou_approved);
            icon = <IconStatusApproved />;
            statusClassName = 'tag-status--approved';
        } else if (status === OrderStatus.contested) {
            text = formatMessage(genericMessages.statusCofou_contested);
            icon = <IconStatusContested />;
            statusClassName = 'tag-status--contested';
        } else if (status === OrderStatus.consulted) {
            text = formatMessage(genericMessages.statusCofou_consulted);
            icon = <IconStatusConsulted />;
            statusClassName = 'tag-status--consulted';
        }
        return {
            icon,
            text,
            statusClassName,
        };
    }, [formatMessage, status]);

    return (
        <div className={classNames('tag-status', statusClassName, className)}>
            <span className="tag-status__icon">{icon}</span>
            <span className="tag-status__text">{text}</span>
        </div>
    );
};

export default OrderStatusTag;
