import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Form, FormInstance, Tag } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';

import LabelWithTooltipIcon from './LabelWithTooltipIcon';
import BasicList from './BasicList';
import { useSelector } from 'react-redux';
import { getUser } from '../store/actions/auth';
import { hasRole } from '../helpers/security';
import { RoleName } from '../store/api/apiTypes';
import TitleAlt from './TitleAlt';

interface YourRightsFieldsProps {
    title?: string;
    twoCols?: boolean;
    titleLevel?: TitleProps['level'];
    form?: FormInstance;
}

const YourRightsFields: FC<YourRightsFieldsProps> = ({ title, titleLevel = 2 }) => {
    const { formatMessage } = useIntl();
    const user = useSelector(getUser);

    return (
        <>
            {title && (
                <TitleAlt level={titleLevel}>
                    <LabelWithTooltipIcon
                        label={formatMessage({
                            id: 'yours_rights_fields.title.label',
                            defaultMessage: 'Vos Droits',
                            description: 'input label',
                        })}
                        tooltip={formatMessage({
                            id: 'yours_rights_fields.title.tooltip',
                            defaultMessage:
                                'Selon vos droits, vous aurez accès à un certains nombre de fonctionnalité de la plateforme. Si vous souhaitez modifier vos droits sur la plateforme,  veuillez contacter votre administrateur. ',
                            description: 'input help tooltip',
                        })}
                    />
                </TitleAlt>
            )}

            <Form.Item>
                <BasicList>
                    {user?.permissions?.conformityDocument ? (
                        <li>
                            <Tag>
                                {formatMessage({
                                    id: 'yours_rights_fields.tag.sign',
                                    defaultMessage: 'Signature de document',
                                    description: 'sign document',
                                })}
                            </Tag>
                        </li>
                    ) : null}
                    {user?.permissions?.documentExchange ? (
                        <li>
                            <Tag>
                                {formatMessage({
                                    id: 'yours_rights_fields.tag.document_exchange',
                                    defaultMessage: 'Echange de document',
                                    description: 'exchange document',
                                })}
                            </Tag>
                        </li>
                    ) : null}
                    {user?.permissions?.majInfoProducts ? (
                        <li>
                            <Tag>
                                {formatMessage({
                                    id: 'yours_rights_fields.tag.maj_info',
                                    defaultMessage: 'Mise à jour des informations',
                                    description: 'update informations',
                                })}
                            </Tag>
                        </li>
                    ) : hasRole(user, [RoleName.readerProvider]) ? (
                        <li>
                            <Tag>
                                {formatMessage({
                                    id: 'yours_rights_fields.tag.read_only',
                                    defaultMessage: 'Lecture seule',
                                    description: 'read only',
                                })}
                            </Tag>
                        </li>
                    ) : null}
                </BasicList>
            </Form.Item>
        </>
    );
};

export default YourRightsFields;
