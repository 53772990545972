import { FC, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Descriptions, Divider, message, Space, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import ButtonIcon from '../../components/ButtonIcon';
import DeletePopConfirm from '../../components/DeletePopConfirm';
import { getFullName } from '../../helpers';
import FactoryFormDrawer from './FactoryFormDrawer';
import FactoryAudit from './FactoryAudit';
import { useSelector } from 'react-redux';
import { useActions, usePrevious } from '../../hooks';
import {
    list as factoriesList,
    getFactoriesListState,
    remove as removeFactory,
    getFactoriesRemoveState,
} from '../../store/actions/factories';
import { Factory, SupportedLanguage } from '../../store/api/apiTypes';

import genericMessages from '../../i18n/genericMessages';
import EditOutlinedWithToolTip from '../../components/EditOutlinedWithToolTip';

type LangImport = { [key in SupportedLanguage]: any };

const availableLangs = ['es', 'de', 'ru', 'fr', 'jp', 'cn', 'pt', 'it', 'ir', 'ar', 'tr', 'id', 'en'];
const intlData = availableLangs.reduce<LangImport | Record<string, unknown>>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => await import(`react-phone-input-2/lang/${lang}.json`),
    }),
    {}
);
const FactoryList: FC = () => {
    const { formatMessage, formatNumber, locale } = useIntl();
    const [localization, setLocalization] = useState<{ [key: string]: string } | undefined>();
    const [isFormDrawerVisible, setIsFormDrawerVisible] = useState(false);
    const [loadFactories, remove] = useActions([factoriesList.trigger, removeFactory.trigger]);
    const factoryListState = useSelector(getFactoriesListState);
    const factoryRemoveState = useSelector(getFactoriesRemoveState);
    const [selectedFactory, setSelectedFactory] = useState<Factory['id'] | undefined>();
    const previous = usePrevious({ factoryRemoveState });
    const onClickAdd = () => {
        setIsFormDrawerVisible(true);
    };
    const onClickEdit = (factory: Factory) => {
        setSelectedFactory(factory.id);
        setIsFormDrawerVisible(true);
    };
    const onClickDelete = (factory: Factory) => {
        remove({ id: factory.id });
    };
    const onCloseFormDrawer = () => {
        setIsFormDrawerVisible(false);
        setSelectedFactory(undefined);
        loadFactories();
    };
    useEffect(() => {
        loadFactories();
    }, [loadFactories]);

    useEffect(() => {
        if (availableLangs.includes(locale)) {
            if (locale === SupportedLanguage.en) {
                (async () => {
                    const localeData = (await import('../../i18n/countries/en.json')).default;
                    setLocalization(localeData);
                })();
            } else {
                (async () => {
                    const localeData = (await intlData[locale as SupportedLanguage]?.())?.default;
                    setLocalization(localeData);
                })();
            }
        }
    }, [locale]);

    useEffect(() => {
        const afterRemove = previous?.factoryRemoveState.loading && !factoryRemoveState.loading;

        if (afterRemove) {
            if (factoryRemoveState.success) {
                loadFactories();
            } else {
                message.error(formatMessage(genericMessages.defaultError));
            }
        }
    }, [formatMessage, previous, factoryRemoveState, loadFactories]);

    return (
        <>
            {factoryListState.data?.items.map((factory, index) => (
                <div style={index > 0 ? { marginTop: '2rem' } : undefined} key={factory.id}>
                    <div className="flex flex-between" style={{ marginBottom: '0.25rem' }}>
                        <Typography.Text
                            style={{
                                textTransform: 'uppercase',
                                fontSize: '1.25rem',
                                letterSpacing: '1.25px',
                                lineHeight: '1rem',
                            }}
                        >
                            {factory.name}
                        </Typography.Text>

                        <Space size="middle">
                            <ButtonIcon icon={<EditOutlinedWithToolTip />} onClick={onClickEdit.bind(null, factory)} />
                            <DeletePopConfirm placement="topRight" onConfirm={onClickDelete.bind(null, factory)}>
                                <ButtonIcon icon={<DeleteOutlined />} />
                            </DeletePopConfirm>
                        </Space>
                    </div>
                    <Descriptions column={1} style={{ marginBottom: '1.5rem' }}>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'factoty_list.descriptions.item.label.address',
                                defaultMessage: 'Adresse de fabrication',
                                description: 'label',
                            })}
                        >{`${factory.address.street ?? ''}, ${factory.address.zipCode ?? ''} ${
                            factory.address.city ?? ''
                        }`}</Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'factoty_list.descriptions.item.label.country',
                                defaultMessage: 'Pays',
                                description: 'label',
                            })}
                        >
                            {localization?.[factory.address.country ?? SupportedLanguage.en] ?? ''}
                        </Descriptions.Item>
                    </Descriptions>
                    {factory.contacts.map((contact, index) => (
                        <Descriptions
                            title={
                                index === 0
                                    ? formatMessage({
                                          id: 'factoty_list.descriptions.contact.title',
                                          defaultMessage: "Contact(s) au sein de l'usine",
                                          description: 'label',
                                      })
                                    : undefined
                            }
                            column={1}
                            key={contact.email}
                            style={index > 0 ? { marginTop: '1rem' } : undefined}
                        >
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'factoty_list.descriptions.item.contact.name',
                                    defaultMessage: 'Prénom & Nom',
                                    description: 'label',
                                })}
                            >
                                {getFullName(contact)}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'factoty_list.descriptions.item.contact.email',
                                    defaultMessage: 'Adresse e-mail',
                                    description: 'label',
                                })}
                            >
                                {contact.email}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'factoty_list.descriptions.item.contact.phone',
                                    defaultMessage: 'Téléphone',
                                    description: 'label',
                                })}
                            >
                                {contact.phone}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'factoty_list.descriptions.item.contact.job',
                                    defaultMessage: 'Poste / Titre',
                                    description: 'label',
                                })}
                            >
                                {contact.job}
                            </Descriptions.Item>
                        </Descriptions>
                    ))}
                    <Divider style={{ minWidth: 32, width: 32 }} />
                    <Descriptions column={2} style={{ marginBottom: '1.5rem' }}>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'factoty_list.descriptions.item.nbWorkers',
                                defaultMessage: 'Total travailleurs',
                                description: 'label',
                            })}
                        >
                            {formatNumber(factory.nbWorkers)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'factoty_list.descriptions.item.nbPermanentWorkers',
                                defaultMessage: 'Travailleurs permanents',
                                description: 'label',
                            })}
                        >
                            {formatNumber(factory.nbPermanentWorkers)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'factoty_list.descriptions.item.nbTemporaryWorkers',
                                defaultMessage: 'Travailleurs temporaires',
                                description: 'label',
                            })}
                        >
                            {formatNumber(factory.nbTemporaryWorkers)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'factoty_list.descriptions.item.nbHomeWorkers',
                                defaultMessage: 'Travailleurs à domicile',
                                description: 'label',
                            })}
                        >
                            {formatNumber(factory.nbHomeWorkers)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'factoty_list.descriptions.item.nbInternationalWorkers',
                                defaultMessage: 'Travailleurs immigrés',
                                description: 'label',
                            })}
                        >
                            {formatNumber(factory.nbInternationalWorkers)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'factoty_list.descriptions.item.nbYoungWorkers',
                                defaultMessage: 'Travailleurs jeunes (16-18ans)',
                                description: 'label',
                            })}
                        >
                            {formatNumber(factory.nbYoungWorkers)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'factoty_list.descriptions.item.nbNightWorkers',
                                defaultMessage: 'Travailleurs de nuit',
                                description: 'label',
                            })}
                        >
                            {formatNumber(factory.nbNightWorkers)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage(
                                {
                                    id: 'factoty_list.descriptions.item.nbPermanentWorkersLeaving',
                                    defaultMessage: 'Départ de travailleurs permanents en {year}',
                                    description: 'label',
                                },
                                { year: dayjs().subtract(1, 'year').get('year') }
                            )}
                        >
                            {formatNumber(factory.nbPermanentWorkersLeaving)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage(
                                {
                                    id: 'factoty_list.descriptions.item.nbNewPermanantsWorkers',
                                    defaultMessage: 'Arrivée de travailleurs permanents en {year}',
                                    description: 'label',
                                },
                                { year: dayjs().subtract(1, 'year').get('year') }
                            )}
                        >
                            {formatNumber(factory.nbNewPermanantsWorkers)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage(
                                {
                                    id: 'factoty_list.descriptions.item.nbWorksBeginYear',
                                    defaultMessage: 'Travailleurs présents au 1er Janvier {year}',
                                    description: 'label',
                                },
                                { year: dayjs().subtract(1, 'year').get('year') }
                            )}
                        >
                            {formatNumber(factory.nbWorksBeginYear)}
                        </Descriptions.Item>
                    </Descriptions>
                    <FactoryAudit factoryId={factory.id} />
                </div>
            ))}
            <div style={{ textAlign: 'center' }}>
                {!factoryListState.data?.totalCount && (
                    <Typography.Paragraph>
                        <p>
                            <FormattedMessage
                                id="factoty_list.form.item.paragraph.help"
                                defaultMessage="Vous devez rattacher au moins 1 usine à votre compte"
                                description="help text"
                            />
                        </p>
                    </Typography.Paragraph>
                )}

                <Button onClick={onClickAdd} size="small" shape="round">
                    <FormattedMessage
                        id="factoty_list.form.item.button.add_factory"
                        defaultMessage="Ajouter une usine"
                        description="call to action"
                    />
                </Button>
            </div>
            <FactoryFormDrawer visible={isFormDrawerVisible} onClose={onCloseFormDrawer} factoryId={selectedFactory} />
        </>
    );
};

export default FactoryList;
