import { FC } from 'react';
import { Footer } from 'antd/lib/layout/layout';
import '../assets/styles/Footer.less';
import { FormattedMessage, useIntl } from 'react-intl';
import { SupportedLanguage } from '../store/api/apiTypes';
import { termsOfUse, cookiesNotice, legalMentions } from '../helpers/helpFiles';

const FooterFront: FC = () => {
    const { locale } = useIntl();
    return (
        <Footer id="main-footer" className="space-x-6">
            <a href={cookiesNotice[locale as SupportedLanguage]} target="_blank" rel="noreferrer">
                <FormattedMessage
                    id="footer.cookiesInformations"
                    defaultMessage="Informations cookies"
                    description="footer cookiesInformations"
                />
            </a>
            <a href={termsOfUse[locale as SupportedLanguage]} target="_blank" rel="noreferrer">
                <FormattedMessage
                    id="footer.generalConditions"
                    defaultMessage="Conditions générales d'utilisation"
                    description="footer generalConditions"
                />
            </a>
            <a href={legalMentions[locale as SupportedLanguage]} target="_blank" rel="noreferrer">
                <FormattedMessage
                    id="footer.legalMentions"
                    defaultMessage="Mentions légales"
                    description="footer legalMentions"
                />
            </a>
            <a href="mailto:cofou@christiandior.com">
                <FormattedMessage
                    id="footer.supportContact"
                    defaultMessage="Contacter le support"
                    description="footer supportContact"
                />
            </a>
        </Footer>
    );
};

export default FooterFront;
