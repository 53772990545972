import { Drawer, DrawerProps } from 'antd';
import NiceModal, { antdDrawer, useModal } from '@ebay/nice-modal-react';

import { RemoteFile } from '../../store/api/apiTypes';
import PDFViewer from '../../components/pdf/PDFViewer';

interface DocumentDrawerProps extends DrawerProps {
    documentUrl?: RemoteFile['url'];
    documentFilename?: RemoteFile['filename'];
}

const DocumentDrawer = NiceModal.create<DocumentDrawerProps>(
    ({ documentUrl, documentFilename, children, ...props }) => {
        const drawer = useModal();

        return (
            <Drawer
                width={1140}
                {...props}
                {...antdDrawer(drawer)}
                onClose={() => {
                    drawer.resolve();
                    drawer.hide();
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {children}
                    <div style={{ flex: 1, minHeight: '25rem' }}>
                        <PDFViewer file={documentUrl} filename={documentFilename} />
                    </div>
                </div>
            </Drawer>
        );
    }
);

export default DocumentDrawer;
