import { Spin } from 'antd';
import { FC, lazy, Suspense, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import type { PhoneInputProps } from 'react-phone-input-2';

import '../assets/styles/ReactPhoneInput2.less';
import { SupportedLanguage } from '../store/api/apiTypes';

const ReactPhoneInput = lazy(
    async () => await import(/* webpackChunkName: "react-phone-input-2" */ 'react-phone-input-2')
);

type LangImport = { [key in SupportedLanguage]: any };

const availableLangs = ['es', 'de', 'ru', 'fr', 'jp', 'cn', 'pt', 'it', 'ir', 'ar', 'tr', 'id'];

const intlData = availableLangs.reduce<LangImport | Record<string, unknown>>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => await import(`react-phone-input-2/lang/${lang}.json`),
    }),
    {}
);

const PhoneInput: FC<PhoneInputProps> = (props) => {
    const { locale } = useIntl();
    const [localization, setLocalization] = useState<Record<string, unknown> | undefined>();

    useEffect(() => {
        if (availableLangs.includes(locale)) {
            if (locale === SupportedLanguage.en) {
                setLocalization({});
            } else {
                (async () => {
                    const localeData = await intlData[locale as SupportedLanguage]?.();
                    setLocalization(localeData);
                })();
            }
        }
    }, [locale]);

    return localization ?? locale === SupportedLanguage.en ? (
        <Suspense fallback={<Spin />}>
            <ReactPhoneInput
                {...props}
                inputClass="ant-input"
                localization={localization}
                countryCodeEditable={false}
                preferredCountries={['fr', 'gb', 'it', 'pt']}
                country={locale === SupportedLanguage.en ? 'gb' : locale}
                specialLabel=""
            />
        </Suspense>
    ) : null;
};

export default PhoneInput;
