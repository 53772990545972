import { createContext, useState, FC, useMemo } from 'react';

interface LayoutContextTypes {
    isWelcomeModalOpen: boolean;
    setIsWelcomeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isTutorialModalOpen: boolean;
    setIsTutorialModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isAfterValidate: boolean;
    setIsAfterValidate: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LayoutContext = createContext<LayoutContextTypes>({
    isWelcomeModalOpen: false,
    setIsWelcomeModalOpen: () => undefined,
    isTutorialModalOpen: false,
    setIsTutorialModalOpen: () => undefined,
    isAfterValidate: false,
    setIsAfterValidate: () => undefined,
});

export const LayoutContextProvider: FC = ({ children }) => {
    const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
    const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
    const [isAfterValidate, setIsAfterValidate] = useState(false);
    const layoutContext = useMemo(
        () => ({
            isWelcomeModalOpen,
            setIsWelcomeModalOpen,
            isTutorialModalOpen,
            setIsTutorialModalOpen,
            isAfterValidate,
            setIsAfterValidate,
        }),
        [
            isWelcomeModalOpen,
            setIsWelcomeModalOpen,
            setIsTutorialModalOpen,
            isTutorialModalOpen,
            isAfterValidate,
            setIsAfterValidate,
        ]
    );

    return <LayoutContext.Provider value={layoutContext}>{children}</LayoutContext.Provider>;
};
