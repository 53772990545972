import { useIntl } from 'react-intl';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';

import Modal from '../../../components/Modal';
import genericMessages from '../../../i18n/genericMessages';
import IconExclamationPoint from '../../../components/icons/IconExclamationPoint';
import { Typography } from 'antd';

const OrderActionNeededModal = NiceModal.create(() => {
    const modal = useModal();
    const { formatMessage } = useIntl();

    return (
        <Modal
            title={
                <span className="flex">
                    <IconExclamationPoint style={{ fontSize: 25, marginRight: 15 }} />{' '}
                    {formatMessage(genericMessages.OrdersProcess_requiredActionTitle)}
                </span>
            }
            cancelText={formatMessage({
                id: 'PopconfirmValidationCertification.cancelText',
                defaultMessage: 'Fermer',
            })}
            okText={formatMessage(genericMessages.OrdersProcess_processNow)}
            className="popup-doc-processing"
            {...antdModal(modal)}
            onCancel={() => {
                modal.reject();
                modal.hide();
            }}
            onOk={() => {
                modal.resolve();
                modal.hide();
            }}
        >
            <Typography.Paragraph className="mb-0">
                {formatMessage(genericMessages.OrdersProcess_promptToSelectAction)}
            </Typography.Paragraph>
        </Modal>
    );
});

export default OrderActionNeededModal;
