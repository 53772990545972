import { Select as AntdSelect, Spin } from 'antd';
import { RefSelectProps, SelectProps, SelectValue } from 'antd/lib/select';

import { classNames } from '../helpers';

declare type InternalSelectType = <VT extends SelectValue = SelectValue>(
    props: SelectProps<VT> & {
        ref?: ((instance: RefSelectProps | null) => void) | React.RefObject<RefSelectProps> | null | undefined;
    }
) => React.ReactElement;
interface SelectInterface extends InternalSelectType {
    SECRET_COMBOBOX_MODE_DO_NOT_USE: string;
    Option: typeof AntdSelect.Option;
    OptGroup: typeof AntdSelect.OptGroup;
}

const Select: SelectInterface = (props) => (
    <AntdSelect
        {...props}
        className={classNames('custom-select', props.className)}
        suffixIcon={
            props.loading ? (
                <Spin />
            ) : (
                <svg width="1em" height="1em" viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.832 0h-1.579a.336.336 0 00-.271.142L8 8.555 2.02.142A.336.336 0 001.747 0H.168C.033 0-.047.159.033.273l7.423 10.443a.664.664 0 001.089 0L15.967.273c.082-.114.002-.273-.135-.273z"
                        fill="currentColor"
                        fillRule="nonzero"
                    />
                </svg>
            )
        }
    >
        {props.children}
    </AntdSelect>
);

Select.SECRET_COMBOBOX_MODE_DO_NOT_USE = AntdSelect.SECRET_COMBOBOX_MODE_DO_NOT_USE;
Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;

export default Select;
