import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Col, Form, FormInstance, Input, Row, Tag } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';

import formMessages from '../i18n/formMessages';
import LabelWithTooltipIcon from './LabelWithTooltipIcon';
import CountrySelect from './CountrySelect';
import { useSelector } from 'react-redux';
import { getUser } from '../store/actions/auth';
import MaterialsSelect from './MaterialsSelect';
import { hasRole } from '../helpers/security';
import { RoleName } from '../store/api/apiTypes';
import TitleAlt from './TitleAlt';
import { getRawRoute, RoutePathName } from '../routes';
import { useRouteMatch } from 'react-router-dom';
import Translate from './Translate';

interface CompanyFieldsProps {
    title?: string;
    twoCols?: boolean;
    titleLevel?: TitleProps['level'];
    form?: FormInstance;
}

const CompanyFields: FC<CompanyFieldsProps> = ({ title, titleLevel = 2, twoCols }) => {
    const { formatMessage } = useIntl();
    const user = useSelector(getUser);
    const [isManufacturer, setManufacturer] = useState(false);
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const isValidateAccountPage = useRouteMatch(getRawRoute(RoutePathName.validateAccount));
    const manufacturer = useCallback(() => {
        const value = user?.organization?.brandsAndTypes.every((brandScope) =>
            brandScope.providerTypes?.every((providerType) => providerType.type === 'façonnier')
        );
        setManufacturer(value ?? false);
    }, [user?.organization?.brandsAndTypes]);

    useEffect(() => {
        manufacturer();
    }, [manufacturer]);
    return (
        <>
            {title && <TitleAlt level={titleLevel}>{title}</TitleAlt>}

            <Form.Item
                name={['user', 'organization', 'name']}
                label={formatMessage({
                    id: 'company_fields.form.item.name',
                    defaultMessage: 'Nom de la société',
                    description: 'input label',
                })}
                rules={[requiredRule]}
            >
                <Input
                    placeholder={formatMessage({
                        id: 'company_fields.form.item.input.name',
                        defaultMessage: 'Saisir le nom de la société',
                        description: 'input placeholder',
                    })}
                    size="large"
                    disabled={
                        !hasRole(user, [RoleName.adminProvider]) ||
                        (!!isValidateAccountPage && user?.organization?.verificationData)
                    }
                />
            </Form.Item>

            <Form.Item
                name={['user', 'organization', 'address', 'street']}
                label={
                    <LabelWithTooltipIcon
                        label={formatMessage({
                            id: 'company_fields.form.item.label.address.street',
                            defaultMessage: 'Adresse de facturation',
                            description: 'input label',
                        })}
                        tooltip={formatMessage({
                            id: 'company_fields.form.item.tooltip.address.street',
                            defaultMessage: 'Adresse du siège',
                            description: 'input help tooltip',
                        })}
                    />
                }
                rules={[requiredRule]}
            >
                <Input
                    placeholder={formatMessage({
                        id: 'company_fields.form.item.input.address.street',
                        defaultMessage: 'Saisir une adresse de facturation',
                        description: 'input placeholder',
                    })}
                    size="large"
                    disabled={
                        !hasRole(user, [RoleName.adminProvider]) ||
                        (!!isValidateAccountPage && user?.organization?.verificationData)
                    }
                />
            </Form.Item>

            <Row gutter={24}>
                <Col xs={twoCols ? 12 : 24}>
                    <Form.Item
                        name={['user', 'organization', 'address', 'zipCode']}
                        label={formatMessage({
                            id: 'company_fields.form.item.label.address.zipCode',
                            defaultMessage: 'Code postal',
                            description: 'input label',
                        })}
                        rules={[requiredRule]}
                    >
                        <Input
                            placeholder={formatMessage({
                                id: 'company_fields.form.item.input.address.zipCode',
                                defaultMessage: 'Saisir un code postal',
                                description: 'input placeholder',
                            })}
                            size="large"
                            disabled={
                                !hasRole(user, [RoleName.adminProvider]) ||
                                (!!isValidateAccountPage && user?.organization?.verificationData)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={twoCols ? 12 : 24}>
                    <Form.Item
                        name={['user', 'organization', 'address', 'city']}
                        label={formatMessage({
                            id: 'company_fields.form.item.label.address.city',
                            defaultMessage: 'Ville',
                            description: 'input label',
                        })}
                        rules={[requiredRule]}
                    >
                        <Input
                            placeholder={formatMessage({
                                id: 'company_fields.form.item.input.address.city',
                                defaultMessage: 'Saisir une ville',
                                description: 'input placeholder',
                            })}
                            size="large"
                            disabled={
                                !hasRole(user, [RoleName.adminProvider]) ||
                                (!!isValidateAccountPage && user?.organization?.verificationData)
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                name={['user', 'organization', 'address', 'country']}
                label={formatMessage({
                    id: 'company_fields.form.item.label.address.country',
                    defaultMessage: 'Pays',
                    description: 'input label',
                })}
                rules={[requiredRule]}
            >
                <CountrySelect
                    placeholder={formatMessage({
                        id: 'company_fields.form.item.select.address.country',
                        defaultMessage: 'Sélectionner un pays',
                        description: 'input placeholder',
                    })}
                    disabled={
                        !hasRole(user, [RoleName.adminProvider]) ||
                        (!!isValidateAccountPage && user?.organization?.verificationData)
                    }
                />
            </Form.Item>

            <Form.Item
                label={
                    <LabelWithTooltipIcon
                        label={formatMessage({
                            id: 'company_fields.form.item.label.univers',
                            defaultMessage: 'Univers & type(s)',
                            description: 'input label',
                        })}
                        tooltip={formatMessage({
                            id: 'company_fields.form.item.tooltip.univers',
                            defaultMessage:
                                ' Univers est le champ qui indique pour quel univers de Christian Dior Couture vous travaillez. \nType permet de connaître votre activité, et ainsi de vous mettre en relation avec les bons interlocuteurs via la plateforme.',
                            description: 'input help tooltip',
                        })}
                    />
                }
                style={{ lineHeight: '32px' }}
            >
                {user?.organization?.brandsAndTypes.map((brandScope) => (
                    <Tag key={brandScope.brand.id} style={{ whiteSpace: 'normal' }} className="ant-tag-details">
                        {brandScope.brand.name}
                        {' : '}
                        {brandScope.providerTypes?.map((providerType, index) => {
                            if (index > 0) {
                                return (
                                    <>
                                        {' / '}
                                        <Translate key={index} text={providerType?.name} />
                                    </>
                                );
                            } else {
                                return <Translate key={index} text={providerType?.name} />;
                            }
                        })}
                    </Tag>
                ))}
            </Form.Item>

            {!isManufacturer && (
                <Form.Item
                    name={['user', 'organization', 'materials']}
                    label={
                        <LabelWithTooltipIcon
                            label={formatMessage({
                                id: 'company_fields.form.item.label.materials',
                                defaultMessage: 'Matière(s)',
                                description: 'input label',
                            })}
                            tooltip={formatMessage({
                                id: 'company_fields.form.item.tooltip.materials',
                                defaultMessage:
                                    'Matières qui sont produites à date pour le compte de Christian Dior Couture',
                                description: 'input help tooltip',
                            })}
                        />
                    }
                    rules={[requiredRule]}
                >
                    <MaterialsSelect
                        placeholder={formatMessage({
                            id: 'company_fields.form.item.select.materials',
                            defaultMessage: 'Sélectionner une ou des matières',
                            description: 'placeholder material',
                        })}
                        disabled={
                            !hasRole(user, [RoleName.adminProvider]) ||
                            (!!isValidateAccountPage && user?.organization?.verificationData)
                        }
                    />
                </Form.Item>
            )}
        </>
    );
};

export default CompanyFields;
