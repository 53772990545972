import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import '../assets/styles/ProductionInfosBanner.less';

import { getUser } from '../store/actions/auth';

import { classNames } from '../helpers';
import { Typography } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { getRawRoute, getRoute, RoutePathName } from '../routes';
import { details as majInfoDetails, getMajInfoProductsDetailsState } from '../store/actions/majInfoProduct';
import { useActions, usePrevious } from '../hooks';
import { MajInfoProduct, User } from '../store/api/apiTypes';
import { getFactoriesListState } from '../store/actions/factories';
import { getSubContractorsListState } from '../store/actions/SubContractors';
import { getRevenuesListState } from '../store/actions/revenue';
interface ProductionInfosBannerProps {
    inHeader?: boolean;
}

const ProductionInfosBanner: FC<ProductionInfosBannerProps> = ({ inHeader = false }) => {
    const user = useSelector(getUser);
    const loadMajInfoDetails = useActions(majInfoDetails.trigger);
    const majInfoProductDetailsState = useSelector(getMajInfoProductsDetailsState);
    const factoryListState = useSelector(getFactoriesListState);
    const subContractorListState = useSelector(getSubContractorsListState);
    const revenueListState = useSelector(getRevenuesListState);
    const isProductionInfosPage = useRouteMatch(getRawRoute(RoutePathName.productionInfos));
    const previous = usePrevious({ majInfoProductDetailsState });
    const [startMaj, setStartMaj] = useState<boolean>(false);

    const showBanner =
        canShowBanner(user, majInfoProductDetailsState.data) &&
        ((inHeader && !isProductionInfosPage) || (!inHeader && isProductionInfosPage));
    const isWarning =
        dayjs().diff(
            dayjs(majInfoProductDetailsState.data?.majDueDate).subtract(
                majInfoProductDetailsState.data?.deadlineNotifications ?? 0,
                'day'
            )
        ) >= 0 && dayjs().diff(dayjs(majInfoProductDetailsState.data?.majDueDate)) <= 0;
    const isLate = dayjs() > dayjs(majInfoProductDetailsState.data?.majDueDate);

    useEffect(() => {
        loadMajInfoDetails();
    }, [loadMajInfoDetails]);

    useEffect(() => {
        if (previous?.majInfoProductDetailsState.loading && !majInfoProductDetailsState.loading) {
            if (majInfoProductDetailsState.data) {
                if (
                    dayjs(majInfoProductDetailsState.data?.majDueDate).subtract(
                        majInfoProductDetailsState.data.majNotification ?? 0,
                        'days'
                    ) <= dayjs()
                ) {
                    setStartMaj(true);
                } else {
                    setStartMaj(false);
                }
            }
        }
    }, [previous?.majInfoProductDetailsState.loading, majInfoProductDetailsState]);

    const banner = (
        <div
            className={classNames(
                'production-infos-banner',
                isWarning && 'warning',
                isLate && 'error',
                inHeader && 'in-header'
            )}
        >
            <Typography.Paragraph strong>
                <p>
                    <ExclamationCircleOutlined style={{ fontSize: '1.5rem', verticalAlign: 'top' }} />
                    {!inHeader &&
                    (factoryListState.data?.items.length ||
                        subContractorListState.data?.items.length ||
                        revenueListState.data?.items.length) ? (
                        <FormattedMessage
                            id="production_infos_banner.banner_read_and_confirm"
                            defaultMessage="Veuillez relire et confirmer vos informations de production pour l’année en cours en cliquant sur « Enregistrer les informations » en bas de page."
                            description="banner"
                        />
                    ) : (
                        <FormattedMessage
                            id="production_infos_banner.banner_isLate"
                            defaultMessage="{isLate, select, true {La saisie des informations de production était requise avant le {productionInfosDate, date}} false {La saisie des informations de production est requise avant le {productionInfosDate, date}}}."
                            description="banner"
                            values={{
                                productionInfosDate: dayjs(majInfoProductDetailsState.data?.majDueDate),
                                isLate,
                            }}
                        />
                    )}
                    {inHeader && (
                        <FormattedMessage
                            id="production_infos_banner.banner_click"
                            defaultMessage="Cliquez ici pour commencer."
                            description="banner"
                        />
                    )}
                </p>
            </Typography.Paragraph>
        </div>
    );

    const bannerNewProvider = (
        <div className={classNames('production-infos-banner', 'error', inHeader && 'in-header')}>
            <Typography.Paragraph strong>
                <p>
                    <ExclamationCircleOutlined style={{ fontSize: '1.5rem', verticalAlign: 'top' }} />
                    {inHeader ? (
                        <FormattedMessage
                            id="production_infos_banner.banner_new_provider_header"
                            defaultMessage="Merci de remplir vos informations de production sur la page dédiée."
                            description="banner"
                        />
                    ) : (
                        <FormattedMessage
                            id="production_infos_banner.banner_new_provider"
                            defaultMessage="Merci de remplir vos informations de production pour confirmer votre compte Fournisseur."
                            description="banner"
                        />
                    )}
                </p>
            </Typography.Paragraph>
        </div>
    );

    return showBanner && startMaj && majInfoProductDetailsState.data?.providersReminder ? (
        <>{inHeader ? <Link to={getRoute(RoutePathName.productionInfos)}>{banner}</Link> : banner}</>
    ) : !user?.organization?.alreadySubmit &&
      ((inHeader && !isProductionInfosPage) || (!inHeader && isProductionInfosPage)) ? (
        <>
            {inHeader ? (
                <Link to={getRoute(RoutePathName.productionInfos)}>{bannerNewProvider}</Link>
            ) : (
                bannerNewProvider
            )}
        </>
    ) : null;
};

const canShowBanner = (user?: User, majInfo?: MajInfoProduct) => {
    const oldProviderOrNewVerifiedProvider =
        user?.organization?.alreadySubmit ||
        (!user?.organization?.alreadySubmit &&
            user?.organization?.verificationData &&
            (dayjs(user?.organization?.verificationDataAt).diff(
                dayjs(majInfo?.majDueDate).subtract(majInfo?.deadlineNotifications ?? 0, 'day'),
                'hours'
            ) < 0 ||
                dayjs(user?.organization?.verificationDataAt).diff(
                    dayjs(majInfo?.majDueDate).subtract(majInfo?.majNotification ?? 0, 'day'),
                    'hours'
                ) <= 0));
    return (
        dayjs(majInfo?.majDueDate).subtract(majInfo?.majNotification ?? 0, 'days') <= dayjs() &&
        dayjs(majInfo?.majDueDate) >= dayjs(user?.organization?.createdAt) &&
        !user?.organization?.reminderExcluded &&
        !user?.organization?.submitData &&
        oldProviderOrNewVerifiedProvider
    );
};

export default ProductionInfosBanner;
