import { FC } from 'react';
import { Button, Collapse, Descriptions, Space, Typography } from 'antd';
import CollapseHeader from '../../components/CollapseHeader';
import { DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import genericMessages from '../../i18n/genericMessages';

import '../../assets/styles/FiberCards.less';
import { useActions } from '../../hooks';
import {
    download as downloadAction,
    getProviderCertificationsDownloadState,
} from '../../store/actions/providerCertifications';
import { Fiber, ProviderCertification } from '../../store/api/apiTypes';
import LocalizedCountryName from '../../components/LocalizedCountryName';
import ProviderCertificationDocumentDrawer from './ProviderCertificationDocumentDrawer';
import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/actions/auth';
import { getFiberFilename } from '../../helpers/filename';

interface FiberCardsProps {
    fibers: Fiber[];
    providerCertification?: ProviderCertification;
    onDeleteFiber?: (index: number) => void;
    onModifyFiber?: (index: number, fiberToModify: Fiber) => void;
}

const FiberCards: FC<FiberCardsProps> = ({ fibers, providerCertification, onDeleteFiber, onModifyFiber }) => {
    const { formatMessage } = useIntl();
    const user = useSelector(getUser);

    const downloadFiberDocument = useActions(downloadAction.trigger);
    const providerCertificationDownloadState = useSelector(getProviderCertificationsDownloadState);

    const onShowFiber = (fiberToShow: Fiber) => {
        NiceModal.show('fiberInfoDrawer', { fiber: fiberToShow });
    };

    const onDownloadDocument = (fiber: Fiber) => {
        if (providerCertification) {
            const filename = getFiberFilename([fiber], providerCertification, user?.language);
            downloadFiberDocument({
                id: providerCertification?.id,
                filename: filename,
                fileId: fiber.citesFile?.id,
            });
        }
    };

    return (
        <>
            <Collapse expandIconPosition="right" className="fiberCards" defaultActiveKey={'0'}>
                {fibers?.map((fiber, index) => (
                    <Collapse.Panel
                        className="fiberCards"
                        key={index}
                        header={
                            <CollapseHeader
                                columns={[
                                    {
                                        content: <Typography.Text>{fiber.name.toUpperCase()}</Typography.Text>,
                                    },
                                    {
                                        content: onDeleteFiber && onModifyFiber && (
                                            <Space>
                                                <Button
                                                    icon={<EditOutlined />}
                                                    shape="circle"
                                                    type="ghost"
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onModifyFiber(index, fiber);
                                                    }}
                                                    className="btn-fiber-card"
                                                />
                                                <Button
                                                    icon={<DeleteOutlined />}
                                                    shape="circle"
                                                    type="ghost"
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onDeleteFiber(index);
                                                    }}
                                                    className="btn-fiber-card"
                                                />
                                            </Space>
                                        ),
                                    },
                                ]}
                            />
                        }
                    >
                        <Descriptions className="mb-3">
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'fiber_card.description.item.input.fiber',
                                    defaultMessage: 'Fibre',
                                    description: 'input label',
                                })}
                            >
                                {fiber.name}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions className="mb-3">
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'fiber_card.description.item.input.scientificName',
                                    defaultMessage: 'Nom scientifique',
                                    description: 'input label',
                                })}
                            >
                                {fiber.scientificName}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions className="mb-3">
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'fiber_card.description.item.input.originCountry',
                                    defaultMessage: "Pays d'origine",
                                    description: 'input label',
                                })}
                            >
                                <LocalizedCountryName countryCode={fiber.originCountry} />
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions className="mb-3">
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'fiber_card.description.item.input.source',
                                    defaultMessage: 'Source',
                                    description: 'input label',
                                })}
                            >
                                {fiber?.source?.fields?.name}
                            </Descriptions.Item>
                        </Descriptions>

                        {!onDeleteFiber && !onModifyFiber && (
                            <Descriptions className="mb-3">
                                <Descriptions.Item
                                    label={formatMessage({
                                        id: 'fiber_card.description.item.input.commonName',
                                        defaultMessage: 'Nom commun',
                                        description: 'input label',
                                    })}
                                >
                                    {fiber.commonName}
                                </Descriptions.Item>
                            </Descriptions>
                        )}

                        <Descriptions>
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'fiber_card.description.item.input.cites',
                                    defaultMessage: 'CITES',
                                    description: 'input label',
                                })}
                                labelStyle={{ alignSelf: 'center' }}
                            >
                                {fiber.cites ? (
                                    <>
                                        <FormattedMessage {...genericMessages.yes} />

                                        {!onDeleteFiber && !onModifyFiber && (
                                            <Space className="ml-3">
                                                <Button
                                                    icon={<EyeOutlined />}
                                                    shape="circle"
                                                    type="ghost"
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onShowFiber(fiber);
                                                    }}
                                                    className="btn-fiber-card"
                                                />
                                                <Button
                                                    icon={<DownloadOutlined />}
                                                    shape="circle"
                                                    type="ghost"
                                                    size="small"
                                                    loading={providerCertificationDownloadState.loading}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (fiber.citesFile && providerCertification) {
                                                            onDownloadDocument(fiber);
                                                        }
                                                    }}
                                                    className="btn-fiber-card"
                                                />
                                            </Space>
                                        )}
                                    </>
                                ) : (
                                    <FormattedMessage {...genericMessages.no} />
                                )}
                            </Descriptions.Item>
                        </Descriptions>
                    </Collapse.Panel>
                ))}
            </Collapse>
            <ProviderCertificationDocumentDrawer id="fiberInfoDrawer" />
        </>
    );
};

export default FiberCards;
