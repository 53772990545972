import { Layout } from 'antd';
import { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { LayoutContextProvider } from '../context/LayoutContext';
import { getRawRoute, RoutePathName } from '../routes';
import FooterFront from './Footer';
import Header from './Header';

const MainLayout: FC = ({ children }) => {
    const isValidateAccountPage = useRouteMatch(getRawRoute(RoutePathName.validateAccount));
    const isProductionInfosPage = useRouteMatch(getRawRoute(RoutePathName.productionInfos));

    return (
        <LayoutContextProvider>
            <Layout id="main-layout">
                <Header />
                <Layout.Content id="main-content">
                    <div
                        className={`container${
                            isValidateAccountPage ? '-small' : isProductionInfosPage ? '-medium' : ''
                        }`}
                    >
                        {children}
                    </div>
                </Layout.Content>
                <FooterFront />
            </Layout>
        </LayoutContextProvider>
    );
};

export default MainLayout;
