import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import constants from '../config/constants';
import { SupportedLanguage } from '../store/api/apiTypes';

interface SeoProps {
    description?: string;
    title?: string;
}

const Seo: FC<SeoProps> = ({ description, title }) => {
    const { pathname } = useLocation();
    const locale = SupportedLanguage.en;
    const baseUrl = constants.SEO.BASE_URL;
    const fullUrl = `${baseUrl}${pathname}`;
    const image = `${process.env.PUBLIC_URL}/images/icon-192x192.png`;
    const finalDescription = description ?? constants.SEO.DEFAULT_DESCRIPTION;
    const finalTitle = title ?? constants.SEO.DEFAULT_TITLE;
    const schemaOrgJSONLD: any = {
        '@context': 'http://schema.org',
        '@graph': [
            {
                '@type': 'WebSite',
                url: baseUrl,
                name: constants.SEO.SITE_NAME,
            },
        ],
    };

    return (
        <Helmet
            htmlAttributes={{
                lang: locale,
                prefix: 'og: http://ogp.me/ns#',
            }}
        >
            {/* General tags */}
            <meta name="locale" content={locale} />
            <title>{finalTitle}</title>
            <meta name="description" content={finalDescription} />
            <link rel="canonical" href={fullUrl} />
            {/* Schema.org tags */}
            <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
            {/* OpenGraph tags */}
            <meta property="og:site_name" content={constants.SEO.SITE_NAME} />
            <meta property="og:locale" content={locale} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={fullUrl} />
            <meta property="og:title" content={finalTitle} />
            <meta property="og:description" content={finalDescription} />
            <meta property="og:image" content={image} />
            {/* Twitter Card tags (inherits og tags) */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={constants.SEO.DEFAULT_TITLE} />
        </Helmet>
    );
};

export default Seo;
