import { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path
            d="M16.6332 11.2261C17.0607 11.5758 17.1237 12.2058 16.774 12.6333L12.274 18.1333C12.105 18.3397 11.8606 18.4702 11.595 18.4955C11.3294 18.5209 11.0648 18.439 10.8598 18.2683L7.85981 15.7683C7.43554 15.4147 7.37821 14.7841 7.73178 14.3598C8.08534 13.9356 8.71591 13.8782 9.14018 14.2318L11.365 16.0858L15.226 11.3668C15.5758 10.9393 16.2058 10.8763 16.6332 11.2261Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3.5C3 2.39543 3.89543 1.5 5 1.5H15.5C15.7835 1.5 16.0537 1.62032 16.2433 1.83104L20.7407 6.82817C20.9018 7.0057 21 7.24138 21 7.5V21.5C21 22.6046 20.1046 23.5 19 23.5H5C3.89544 23.5 3 22.6046 3 21.5V3.5ZM17.7546 6.5L16 4.5504V6.5H17.7546ZM14 3.5V7.5C14 8.05228 14.4477 8.5 15 8.5H19V21.5H5V3.5H14Z"
            fill="currentColor"
        />
    </svg>
);

const IconStatusApproved: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon
        component={svg}
        {...props}
        className={`icon-status-approved${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconStatusApproved;
