import { FC, Fragment, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Divider,
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    Radio,
    Card,
    Typography,
    Upload,
    Modal,
    InputNumber,
    Spin,
    Space,
} from 'antd';
import { CheckCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';

import {
    Audit,
    AuditCategory,
    Factory,
    Issue,
    TypeOfAudit,
    ScoreType,
    DigitalScore,
    AlphabeticalScore,
} from '../../store/api/apiTypes';

import formMessages from '../../i18n/formMessages';
import genericMessages from '../../i18n/genericMessages';
import { auditQuestions } from '../../i18n/auditQuestionsMessages';
import LabelWithTooltipIcon from '../../components/LabelWithTooltipIcon';
import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';
import RequiredFields from '../../components/RequiredFields';
import {
    update as updateAudit,
    create as createAudit,
    getAuditsCreateState,
    getAuditsUpdateState,
} from '../../store/actions/audits';

import { useActions, usePrevious } from '../../hooks';
import { RcFile } from 'antd/lib/upload';
import { errorMessage, successMessage } from '../../helpers/message';
import { useSelector } from 'react-redux';
import { UploadFile } from 'antd/lib/upload/interface';

type FactoryAuditFormSchema = {
    [key in AuditCategory]?: {
        questionnaire?: Issue[];
        answer?: boolean | null;
        score?: number | string;
        auditType?: TypeOfAudit['id'];
        period?: [Dayjs, Dayjs];
    };
};

function isDigitalScore(score: DigitalScore | AlphabeticalScore[] | undefined): score is DigitalScore {
    return score !== undefined && (score as DigitalScore)?.min !== undefined;
}
interface FactoryAuditDrawerProps extends DrawerProps {
    audits?: Audit[];
    typeOfAudits?: TypeOfAudit[];
    factoryId: Factory['id'];
    category: AuditCategory;
    onSuccess: () => void;
}

type FileByCategory = {
    [key in AuditCategory]?: RcFile[];
};
const FactoryAuditDrawer: FC<FactoryAuditDrawerProps> = ({
    audits,
    typeOfAudits,
    factoryId,
    category,
    onClose,
    onSuccess,
    ...props
}) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const [update, create] = useActions([updateAudit.trigger, createAudit.trigger]);
    const [fileToUpload, setFileToUpload] = useState<FileByCategory>();
    const auditUpdateState = useSelector(getAuditsUpdateState);
    const auditCreateState = useSelector(getAuditsCreateState);
    const [typeOfAuditSelected, setTypeOfAuditSelected] = useState<{ [key: string]: TypeOfAudit | undefined }>();
    const previous = usePrevious({ auditUpdateState, auditCreateState });
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        const formattedValues = { ...values };
        const auditByCategory = audits?.find((item) => item.name === category);
        const payload = {
            score: formattedValues[category].score,
            typeOfAudit: formattedValues[category].auditType,
            factory: factoryId,
            beginValidityDate: formattedValues[category]?.period?.[0].toISOString(),
            endValidityDate: formattedValues[category]?.period?.[1].toISOString(),
            answer: formattedValues[category]?.answer,
            questionnaire: formattedValues[category]?.questionnaire,
            name: category,
            reports: fileToUpload?.[category],
        };
        if (auditByCategory) {
            update({ ...payload, id: auditByCategory.id });
        } else {
            create(payload);
        }
    };

    const getInitialValues = (audits?: Audit[]) => {
        const audit: FactoryAuditFormSchema = {};
        Object.values(AuditCategory).forEach((category) => {
            const auditByCategory = audits?.find((item) => item.name === category);
            if (auditByCategory) {
                if (auditByCategory?.answer) {
                    audit[category] = {
                        answer: auditByCategory?.answer,
                        score: auditByCategory?.score,
                        auditType: auditByCategory?.typeOfAudit?.id,
                        period: [dayjs(auditByCategory?.beginValidityDate), dayjs(auditByCategory?.endValidityDate)],
                    };
                    setTypeOfAuditSelected((data) => ({
                        ...data,
                        [category]: auditByCategory.typeOfAudit,
                    }));
                    setFileToUpload((data) => ({
                        ...data,
                        [category]: auditByCategory.reports?.map((report) => {
                            return {
                                uid: report.id,
                                name: report.originalName,
                                fileName: report.originalName,
                                remoteFile: true,
                            };
                        }),
                    }));
                } else {
                    audit[category] = {
                        answer: auditByCategory?.answer,
                        questionnaire:
                            category === AuditCategory.Environnement ? auditByCategory?.questionnaire : undefined,
                    };
                }
            }
        });

        return audit;
    };

    const onAfterVisibleChange: DrawerProps['afterVisibleChange'] = (visible) => {
        if (!visible) {
            form.resetFields();
        }
    };
    const onChangetypeOfAudit = (category: string, value: string) => {
        form.setFieldsValue({ [category]: { score: undefined } });
        setTypeOfAuditSelected({
            ...typeOfAuditSelected,
            [category]: typeOfAudits?.find((typeOfAudit) => typeOfAudit.id === value),
        });
    };

    const title = (category: AuditCategory) => {
        if (category === AuditCategory.Environnement) {
            return (
                <FormattedMessage
                    id="factory_audi_drawer.title.environnement"
                    defaultMessage="Audit Environnement"
                    description="input label"
                />
            );
        } else if (category === AuditCategory.Social) {
            return (
                <FormattedMessage
                    id="factory_audi_drawer.title.social"
                    defaultMessage="Audit Social"
                    description="input label"
                />
            );
        } else if (category === AuditCategory['H&S']) {
            return (
                <FormattedMessage
                    id="factory_audi_drawer.title.HandS"
                    defaultMessage="Audit Hygiène et Sécurité"
                    description="input label"
                />
            );
        } else {
            return (
                <FormattedMessage
                    id="factory_audi_drawer.title.quality"
                    defaultMessage="Audit Qualité"
                    description="input label"
                />
            );
        }
    };

    const onBeforeSubmit: FormProps['onFinish'] = (values) => {
        let hadError = false;
        Object.values(AuditCategory).forEach((category) => {
            if (values[category]?.answer && !fileToUpload?.[category]?.length) {
                hadError = true;
                switch (category) {
                    case AuditCategory.Environnement:
                        errorMessage({
                            content: formatMessage({
                                id: 'factory_audi_drawer.before_submit.error.environnement',
                                defaultMessage: "un rapport d'audit pour la catégorie environnement est manquant",
                                description: 'upload error',
                            }),
                        });
                        break;
                    case AuditCategory.Quality:
                        errorMessage({
                            content: formatMessage({
                                id: 'factory_audi_drawer.before_submit.error.quality',
                                defaultMessage: "un rapport d'audit pour la catégorie qualité est manquant",
                                description: 'upload error',
                            }),
                        });
                        break;
                    case AuditCategory.Social:
                        errorMessage({
                            content: formatMessage({
                                id: 'factory_audi_drawer.before_submit.error.social',
                                defaultMessage: "un rapport d'audit pour la catégorie sociale est manquant",
                                description: 'upload error',
                            }),
                        });
                        break;
                    case AuditCategory['H&S']:
                        errorMessage({
                            content: formatMessage({
                                id: 'factory_audi_drawer.before_submit.error.HandS',
                                defaultMessage: "un rapport d'audit pour la catégorie hygiène et sécurité est manquant",
                                description: 'upload error',
                            }),
                        });
                        break;
                }
            }
        });
        if (!hadError) {
            onFormValidSubmit(values);
        }
    };
    const onRemove = (category: string, file: UploadFile) => {
        setFileToUpload({
            ...fileToUpload,
            [category]: fileToUpload?.[category as AuditCategory]?.filter((file2) => file2 !== file),
        });
    };
    const onBeforeClose = () => {
        if (form.isFieldsTouched()) {
            Modal.confirm({
                title: formatMessage({
                    id: 'factory_audi_drawer.modal.title',
                    defaultMessage: "Êtes-vous sûr de vouloir quitter l'ajout / édition des audits ?",
                    description: 'close drawer factory',
                }),
                icon: <ExclamationCircleOutlined />,
                content: formatMessage({
                    id: 'factory_audi_drawer.modal.content',
                    defaultMessage: 'Les informations saisies seront perdues',
                    description: 'close drawer factory Audit contente',
                }),
                onOk() {
                    form.resetFields();
                    setFileToUpload(undefined);
                    setTypeOfAuditSelected(undefined);
                    onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
                },
                okText: formatMessage({
                    id: 'factory_audi_drawer.modal.ok',
                    defaultMessage: 'oui',
                    description: 'ok',
                }),
                cancelText: formatMessage({
                    id: 'factory_audi_drawer.modal.cancel',
                    defaultMessage: 'annuler',
                    description: 'cancel',
                }),
            });
        } else {
            form.resetFields();
            setFileToUpload(undefined);
            setTypeOfAuditSelected(undefined);
            onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
        }
    };
    const beforeUpload = (category: AuditCategory, file: RcFile, FileList: RcFile[]) => {
        let result = true;
        for (const fileUploded of FileList) {
            if (fileUploded.type !== 'application/pdf') {
                errorMessage({
                    content: formatMessage({
                        id: 'factory_audi_drawer.upload.error_message.type',
                        defaultMessage: 'Seuls les fichier PDF sont autorisés',
                        description: 'upload error',
                    }),
                });
                result = false;
            }

            if (fileUploded.size / 1024 > 5000) {
                errorMessage({
                    content: formatMessage({
                        id: 'factory_audi_drawer.upload.error_message.5Mo',
                        defaultMessage: 'Votre fichier dépasse la limite de taille maximum de 5Mo',
                        description: 'upload error',
                    }),
                });
                result = false;
            }
        }
        if (result && FileList) {
            const files = fileToUpload?.[category];
            if (!files?.length) {
                setFileToUpload({ ...fileToUpload, [category]: FileList });
            } else {
                setFileToUpload({ ...fileToUpload, [category]: files?.concat(FileList) });
            }
        }
        return false;
    };
    useEffect(() => {
        if (audits) {
            form.setFieldsValue(getInitialValues(audits));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audits, form]);
    useEffect(() => {
        // si loading ÉTAIT true, et que MAINTENANT il est false, cela veut dire que la requête est finie
        if (props.visible && previous?.auditCreateState.loading && !auditCreateState.loading) {
            if (auditCreateState.error || !auditCreateState.data) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'factory_audi_drawer.succes_message.add',
                        defaultMessage: 'Audit créé avec succès',
                        description: 'Audit Success Message',
                    }),
                });
                onSuccess();
                form.resetFields();
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
        }
    }, [previous?.auditCreateState.loading, auditCreateState, formatMessage, onClose, onSuccess, form, props.visible]);
    useEffect(() => {
        // si loading ÉTAIT true, et que MAINTENANT il est false, cela veut dire que la requête est finie
        if (props.visible && previous?.auditUpdateState.loading && !auditUpdateState.loading) {
            if (auditUpdateState.error || !auditUpdateState.data) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'factory_audi_drawer.succes_message.edit',
                        defaultMessage: 'Audit édité avec succès',
                        description: 'Audits Success Message',
                    }),
                });
                onSuccess();
                form.resetFields();
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
        }
    }, [previous?.auditUpdateState.loading, auditUpdateState, formatMessage, onClose, onSuccess, form, props.visible]);
    return (
        <Drawer
            width={500}
            title={title(category)}
            onClose={onBeforeClose}
            afterVisibleChange={onAfterVisibleChange}
            {...props}
        >
            <Spin spinning={auditCreateState.loading || auditUpdateState.loading}>
                <Form form={form} onFinish={onBeforeSubmit} layout="vertical" requiredMark={false} scrollToFirstError>
                    {
                        <Fragment key={category}>
                            <Form.Item
                                name={[category, 'answer']}
                                rules={[requiredRule]}
                                style={{ flexDirection: 'row' }}
                                className="required-mark-on-child form-item-inline"
                                labelCol={{ span: 14 }}
                                label={
                                    <FormattedMessage
                                        id="factory_audi_drawer.form.item.answer"
                                        defaultMessage="Existe-t-il un audit pour cette catégorie ?"
                                        description="input label"
                                        tagName="span"
                                    />
                                }
                            >
                                <Radio.Group size="small">
                                    <Radio.Button value={true}>
                                        <FormattedMessage {...genericMessages.yes} />
                                    </Radio.Button>
                                    <Radio.Button value={false}>
                                        <FormattedMessage {...genericMessages.no} />
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item shouldUpdate noStyle>
                                {({ getFieldValue, setFieldsValue, getFieldsValue }) => (
                                    <>
                                        {getFieldValue([category, 'answer']) === true && (
                                            <>
                                                <Divider />
                                                <Typography.Paragraph>
                                                    <FormattedMessage
                                                        id="factory_audi_drawer.form.item.paragrah.intro"
                                                        defaultMessage="Veuillez compléter ci-dessous les informations concernant cet audit:"
                                                        description="form intro"
                                                        tagName="p"
                                                    />
                                                </Typography.Paragraph>
                                                <Form.Item
                                                    name={[category, 'auditType']}
                                                    label={
                                                        <LabelWithTooltipIcon
                                                            label={formatMessage({
                                                                id: 'factory_audi_drawer.form.item.label.type',
                                                                defaultMessage: "Type d'audit",
                                                                description: 'input label',
                                                            })}
                                                            tooltip={formatMessage({
                                                                id: 'factory_audi_drawer.form.item.tooltip.type',
                                                                defaultMessage:
                                                                    "Sélectionnez le type d'audit réalisé dans votre usine. Attention, vous ne pouvez sélectionner qu'un seul des audits présents dans la liste déroulante",
                                                                description: 'input help tooltip',
                                                            })}
                                                        />
                                                    }
                                                    rules={[requiredRule]}
                                                >
                                                    <Select
                                                        placeholder={formatMessage({
                                                            id: 'factory_audi_drawer.form.item.select.type',
                                                            defaultMessage: "Sélectionner un type d'audit",
                                                            description: 'input placeholder',
                                                        })}
                                                        onChange={onChangetypeOfAudit.bind(null, category)}
                                                    >
                                                        {typeOfAudits
                                                            ?.filter((typeOfAudit) => typeOfAudit.category === category)
                                                            .map((typeOf) => (
                                                                <Select.Option value={typeOf.id} key={typeOf.id}>
                                                                    {typeOf.name}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    name={[category, 'period']}
                                                    label={formatMessage({
                                                        id: 'factory_audi_drawer.form.item.input.period',
                                                        defaultMessage: 'Période de validité',
                                                        description: 'input label',
                                                    })}
                                                    rules={[
                                                        requiredRule,
                                                        {
                                                            validator: async (_, value) => {
                                                                if (value && value[1] > dayjs().endOf('days')) {
                                                                    return await Promise.resolve();
                                                                } else if (value?.[1]) {
                                                                    return await Promise.reject(
                                                                        formatMessage(formMessages.errorEndDate)
                                                                    );
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker.RangePicker
                                                        placeholder={[
                                                            formatMessage({
                                                                id: 'factory_audi_drawer.form.item.date_picker.start',
                                                                defaultMessage: 'Date de début',
                                                                description: 'input placeholder',
                                                            }),
                                                            formatMessage({
                                                                id: 'factory_audi_drawer.form.item.date_picker.end',
                                                                defaultMessage: 'Date de fin',
                                                                description: 'input placeholder',
                                                            }),
                                                        ]}
                                                        style={{ width: '100%' }}
                                                        format="DD/MM/YYYY"
                                                    />
                                                </Form.Item>
                                                {typeOfAuditSelected?.[category]?.scoreType === ScoreType.digital &&
                                                isDigitalScore(typeOfAuditSelected?.[category]?.score) ? (
                                                    <Form.Item
                                                        name={[category, 'score']}
                                                        label={
                                                            <FormattedMessage
                                                                id="factory_audi_drawer.form.item.input.score_numeric"
                                                                defaultMessage="Score de l'audit entre {min} et {max}"
                                                                description="input label"
                                                                values={{
                                                                    min: (typeOfAuditSelected?.[category]
                                                                        ?.score as DigitalScore).min.toString(),
                                                                    max: (typeOfAuditSelected?.[category]
                                                                        ?.score as DigitalScore).max.toString(),
                                                                }}
                                                            />
                                                        }
                                                        rules={[
                                                            requiredRule,
                                                            {
                                                                validator: async (_, value) => {
                                                                    const min = (typeOfAuditSelected?.[category]
                                                                        ?.score as DigitalScore).min;
                                                                    const max = (typeOfAuditSelected?.[category]
                                                                        ?.score as DigitalScore).max;
                                                                    if (
                                                                        parseInt(value) >= min &&
                                                                        parseInt(value) <= max
                                                                    ) {
                                                                        return await Promise.resolve();
                                                                    } else if (parseInt(value) < min) {
                                                                        return await Promise.reject(
                                                                            formatMessage(formMessages.errorMinValue)
                                                                        );
                                                                    } else if (parseInt(value) > max) {
                                                                        return await Promise.reject(
                                                                            formatMessage(formMessages.errorMaxValue)
                                                                        );
                                                                    }
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            placeholder={formatMessage({
                                                                id: 'factory_audi_drawer.form.item.input_number.score',
                                                                defaultMessage: 'Saisir le score',
                                                                description: 'input placeholder',
                                                            })}
                                                            style={{ width: '100%' }}
                                                            size="large"
                                                        />
                                                    </Form.Item>
                                                ) : (
                                                    typeOfAuditSelected?.[category]?.scoreType ===
                                                        ScoreType.alphabetic &&
                                                    Array.isArray(typeOfAuditSelected?.[category]?.score) && (
                                                        <Form.Item
                                                            name={[category, 'score']}
                                                            label={
                                                                <LabelWithTooltipIcon
                                                                    label={formatMessage({
                                                                        id: 'factory_audi_drawer.form.item.label.score',
                                                                        defaultMessage: "Score de l'audit",
                                                                        description: 'input label',
                                                                    })}
                                                                    tooltip={formatMessage({
                                                                        id:
                                                                            'factory_audi_drawer.form.item.tooltip.score',
                                                                        defaultMessage:
                                                                            'Sélectionnez le score dans la liste déroulante',
                                                                        description: 'input help tooltip',
                                                                    })}
                                                                />
                                                            }
                                                            rules={[requiredRule]}
                                                        >
                                                            {/* Input ou Select selon le type d'audit */}
                                                            <Select
                                                                placeholder={formatMessage({
                                                                    id: 'factory_audi_drawer.form.item.select.score',
                                                                    defaultMessage: 'Saisir le score',
                                                                    description: 'input placeholder',
                                                                })}
                                                            >
                                                                {(typeOfAuditSelected?.[category]
                                                                    ?.score as AlphabeticalScore[])?.map((score) => (
                                                                    <Select.Option
                                                                        value={score.scoreString}
                                                                        key={score.scoreString}
                                                                    >
                                                                        {score.scoreString}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )
                                                )}
                                                <Card
                                                    style={{ backgroundColor: '#F6F6F6', marginBottom: '2.5rem' }}
                                                    bordered={false}
                                                >
                                                    <Upload.Dragger
                                                        fileList={fileToUpload?.[category]}
                                                        showUploadList={!!fileToUpload?.[category]}
                                                        beforeUpload={beforeUpload.bind(null, category)}
                                                        multiple={true}
                                                        onRemove={onRemove.bind(null, category)}
                                                    >
                                                        <p className="ant-upload-drag-icon">
                                                            {fileToUpload?.[category]?.length ? (
                                                                <CheckCircleOutlined />
                                                            ) : (
                                                                <InboxOutlined />
                                                            )}
                                                        </p>
                                                        <p className="ant-upload-text">
                                                            {!fileToUpload?.[category] && (
                                                                <FormattedMessage
                                                                    {...formMessages.uploadInstructions}
                                                                />
                                                            )}
                                                        </p>
                                                        <p className="ant-upload-hint">
                                                            <FormattedMessage
                                                                {...formMessages.uploadFileFormatAndSize}
                                                            />
                                                        </p>
                                                    </Upload.Dragger>
                                                    <p
                                                        style={{
                                                            textAlign: 'center',
                                                            margin: '1rem 0 1.5rem',
                                                            lineHeight: '1.0625rem',
                                                        }}
                                                    >
                                                        <Typography.Text type="secondary">
                                                            <FormattedMessage
                                                                id="factory_audi_drawer.form.item.upload.text.instructions"
                                                                defaultMessage="Les rapports ne sont conservées sur la plateforme que pour une durée correspondante à leur période de validité."
                                                                description="upload instructions"
                                                            />
                                                        </Typography.Text>
                                                    </p>
                                                </Card>
                                            </>
                                        )}
                                        {category === AuditCategory.Environnement &&
                                            getFieldValue([category, 'answer']) === false && (
                                                <>
                                                    <Divider />
                                                    <Typography.Paragraph>
                                                        <FormattedMessage
                                                            id="factory_audi_drawer.form.item.environnement.paragraph.intro"
                                                            defaultMessage="Complétez le questionnaire suivant :"
                                                            description="form intro"
                                                            tagName="p"
                                                        />
                                                    </Typography.Paragraph>
                                                    {auditQuestions.map((item, index) => (
                                                        <Fragment key={index}>
                                                            <Form.Item
                                                                name={[category, 'questionnaire', index, 'answer']}
                                                                rules={[requiredRule]}
                                                                className="required-mark-on-child"
                                                                label={
                                                                    <strong>
                                                                        {item.help ? (
                                                                            <LabelWithTooltipIcon
                                                                                label={formatMessage(item.question)}
                                                                                tooltip={formatMessage(item.help)}
                                                                            />
                                                                        ) : (
                                                                            formatMessage(item.question)
                                                                        )}
                                                                    </strong>
                                                                }
                                                            >
                                                                <Radio.Group size="small">
                                                                    <Radio.Button value="yes">
                                                                        <FormattedMessage {...genericMessages.yes} />
                                                                    </Radio.Button>
                                                                    <Radio.Button value="no">
                                                                        <FormattedMessage {...genericMessages.no} />
                                                                    </Radio.Button>
                                                                    <Radio.Button value="notConcerned">
                                                                        <FormattedMessage
                                                                            {...genericMessages.notConcerned}
                                                                        />
                                                                    </Radio.Button>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                            {getFieldValue([
                                                                category,
                                                                'questionnaire',
                                                                index,
                                                                'comment',
                                                            ]) !== undefined && (
                                                                <Form.Item
                                                                    label={formatMessage({
                                                                        id:
                                                                            'factory_audi_drawer.form.item.label.comment',
                                                                        defaultMessage: 'Commentaire',
                                                                        description: 'input label',
                                                                    })}
                                                                    className="form-list-item"
                                                                >
                                                                    <Form.Item
                                                                        name={[
                                                                            category,
                                                                            'questionnaire',
                                                                            index,
                                                                            'comment',
                                                                        ]}
                                                                        rules={[requiredRule]}
                                                                        noStyle
                                                                    >
                                                                        <Input
                                                                            placeholder={formatMessage({
                                                                                id:
                                                                                    'factory_audi_drawer.form.item.input.comment',
                                                                                defaultMessage: 'Saisir le commentaire',
                                                                                description: 'input placeholder',
                                                                            })}
                                                                            size="large"
                                                                        />
                                                                    </Form.Item>
                                                                    <Button
                                                                        type="text"
                                                                        icon={<DeleteOutlined />}
                                                                        onClick={() => {
                                                                            const values = getFieldsValue();
                                                                            const categoryAnswers =
                                                                                values[category]?.questionnaire;
                                                                            delete categoryAnswers[index].comment;
                                                                            const newValues = {
                                                                                ...values,
                                                                                [category]: {
                                                                                    ...values[category],
                                                                                    questionnaire: categoryAnswers,
                                                                                },
                                                                            };
                                                                            setFieldsValue(newValues);
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            )}
                                                            {getFieldValue([
                                                                category,
                                                                'questionnaire',
                                                                index,
                                                                'comment',
                                                            ]) === undefined && (
                                                                <Form.Item className="form-list-add-more">
                                                                    <Button
                                                                        type="link"
                                                                        onClick={() => {
                                                                            const values = getFieldsValue();
                                                                            const categoryAnswers =
                                                                                values[category]?.questionnaire;
                                                                            categoryAnswers[index].comment = '';
                                                                            const newValues = {
                                                                                ...values,
                                                                                [category]: {
                                                                                    ...values[category],
                                                                                    questionnaire: categoryAnswers,
                                                                                },
                                                                            };
                                                                            setFieldsValue(newValues);
                                                                        }}
                                                                        style={{ margin: 0 }}
                                                                    >
                                                                        <FormattedMessage
                                                                            id="factory_audi_drawer.form.item.button.add_comment"
                                                                            defaultMessage="Ajouter un commentaire"
                                                                            description="dynamic form item add-more button"
                                                                        />
                                                                    </Button>
                                                                </Form.Item>
                                                            )}
                                                        </Fragment>
                                                    ))}
                                                </>
                                            )}
                                    </>
                                )}
                            </Form.Item>
                            <Divider />
                        </Fragment>
                    }

                    <Button
                        loading={auditUpdateState.loading || auditCreateState.loading}
                        type="primary"
                        htmlType="submit"
                        shape="round"
                        size="large"
                        block
                    >
                        {formatMessage({
                            id: 'factory_audi_drawer.button.submit',
                            defaultMessage: 'Enregistrer les informations',
                            description: 'form submit button',
                        })}
                    </Button>
                    <Space />
                    <Button
                        onClick={onBeforeClose}
                        style={{ margin: '1rem 0 2rem' }}
                        shape="round"
                        size="large"
                        ghost
                        block
                    >
                        {formatMessage(formMessages.cancel)}
                    </Button>
                    <p style={{ textAlign: 'center' }}>
                        <RequiredFields />
                    </p>
                </Form>
            </Spin>
        </Drawer>
    );
};

export default FactoryAuditDrawer;
