import { FC } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import '../assets/styles/ButtonIcon.less';

import { classNames } from '../helpers';

const ButtonIcon: FC<ButtonProps> = ({ children, className, ...rest }) => (
    <Button className={classNames(className, 'btn-icon')} type="text" size="large" {...rest}>
        {children}
    </Button>
);

export default ButtonIcon;
