import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Drawer, DrawerProps, Form, FormProps, Input, Radio, Typography } from 'antd';
import Upload, { RcFile, UploadProps } from 'antd/lib/upload';
import { CheckCircleOutlined, InboxOutlined } from '@ant-design/icons';
import NiceModal, { antdDrawer, useModal } from '@ebay/nice-modal-react';
import formMessages from '../../../i18n/formMessages';
import CountrySelect from '../../../components/CountrySelect';
import SelectFiberSource from '../../../components/form/SelectFiberSource';
import genericMessages from '../../../i18n/genericMessages';
import { errorMessage, successMessage } from '../../../helpers/message';
import RequiredFields from '../../../components/RequiredFields';
import { FiberElement } from '../../../store/api/providerCertifications';

interface ProviderCertificationFiberDrawerProps extends DrawerProps {
    fiberToModify?: FiberElement;
}

const ProviderCertificationFiberDrawer = NiceModal.create<ProviderCertificationFiberDrawerProps>(
    ({ fiberToModify, ...props }) => {
        const drawer = useModal();
        const { formatMessage } = useIntl();
        const [form] = Form.useForm();
        const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
        const [fileToUpload, setFileToUpload] = useState<RcFile | undefined>();

        const onSubmit: FormProps['onFinish'] = (values) => {
            successMessage({
                content: formatMessage({
                    id: 'supplier_certificate_fiber_drawer.success_message',
                    defaultMessage: 'Fibre ajoutée avec succès',
                    description: 'informations success Message',
                }),
            });
            drawer.resolve({ ...values, citesFile: fileToUpload });
            drawer.hide();
        };

        const beforeUpload: UploadProps['beforeUpload'] = (file) => {
            if (file.type !== 'application/pdf') {
                errorMessage({
                    content: formatMessage({
                        id: 'supplier_certificate_list.upload.error_message.type',
                        defaultMessage: 'Seuls les fichier PDF sont autorisés',
                        description: 'upload error',
                    }),
                });
                return false;
            }

            if (file.size / 1024 > 5000) {
                errorMessage({
                    content: formatMessage({
                        id: 'supplier_certificate_list.upload.error_message.5Mo',
                        defaultMessage: 'Votre fichier dépasse la limite de taille maximum de 5Mo',
                        description: 'upload error',
                    }),
                });
                return false;
            }

            setFileToUpload(file);

            return false;
        };

        useEffect(() => {
            if (fiberToModify) {
                form.setFieldsValue(fiberToModify);
                setFileToUpload(fiberToModify.citesFile);
            }
        }, [fiberToModify, form]);

        return (
            <Drawer
                width={500}
                title={formatMessage({
                    id: 'supplier_certificate_fiber_drawer.title',
                    defaultMessage: 'Ajouter une fibre',
                    description: 'Drawer title',
                })}
                {...props}
                {...antdDrawer(drawer)}
            >
                <Form form={form} layout="vertical" requiredMark={false} onFinish={onSubmit} scrollToFirstError>
                    <Form.Item
                        name="name"
                        label={
                            <FormattedMessage
                                id="supplier_certificate_fiber_drawer.form.label.name"
                                defaultMessage="Fibre"
                                description="input label"
                            />
                        }
                        rules={[requiredRule]}
                    >
                        <Input
                            placeholder={formatMessage({
                                id: 'supplier_certificate_fiber_drawer.form.item.input.name',
                                defaultMessage: 'Saisir le nom de la fibre',
                                description: 'input placeholder',
                            })}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="scientificName"
                        label={
                            <FormattedMessage
                                id="supplier_certificate_fiber_drawer.form.label.scientificName"
                                defaultMessage="Nom scientifique"
                                description="input label"
                            />
                        }
                        rules={[requiredRule]}
                    >
                        <Input
                            placeholder={formatMessage({
                                id: 'supplier_certificate_fiber_drawer.form.item.input.scientificName',
                                defaultMessage: 'Saisir le nom scientifique',
                                description: 'input placeholder',
                            })}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="originCountry"
                        label={formatMessage({
                            id: 'supplier_certificate_fiber_drawer.form.item.label.country',
                            defaultMessage: "Pays d'origine",
                            description: 'input label',
                        })}
                        rules={[requiredRule]}
                    >
                        <CountrySelect
                            placeholder={formatMessage({
                                id: 'supplier_certificate_fiber_drawer.form.item.select.country',
                                defaultMessage: 'Sélectionner le pays',
                                description: 'input placeholder',
                            })}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="source"
                        label={formatMessage({
                            id: 'supplier_certificate_fiber_drawer.form.item.label.fiberSource',
                            defaultMessage: 'Source',
                            description: 'input label',
                        })}
                        rules={[requiredRule]}
                    >
                        <SelectFiberSource
                            placeholder={formatMessage({
                                id: 'supplier_certificate_fiber_drawer.form.item.select.fiberSource',
                                defaultMessage: 'Sélectionner la source',
                                description: 'input placeholder',
                            })}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="commonName"
                        label={
                            <FormattedMessage
                                id="supplier_certificate_fiber_drawer.form.label.commonName"
                                defaultMessage="Nom commun"
                                description="Saisir le nom commun"
                            />
                        }
                    >
                        <Input
                            placeholder={formatMessage({
                                id: 'supplier_certificate_fiber_drawer.form.item.input.commonName',
                                defaultMessage: 'Saisir le nom commun',
                                description: 'input placeholder',
                            })}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="cites"
                        rules={[requiredRule]}
                        label={
                            <FormattedMessage
                                id="supplier_certificate_fiber_drawer.form.item.input.cites"
                                defaultMessage="CITES"
                                description="input label"
                            />
                        }
                    >
                        <Radio.Group size="middle" className="fiber-drawer-cites-radio-group">
                            <Radio.Button value={true}>
                                <FormattedMessage {...genericMessages.yes} />
                            </Radio.Button>
                            <Radio.Button value={false}>
                                <FormattedMessage {...genericMessages.no} />
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item noStyle dependencies={['cites']}>
                        {({ getFieldValue }) =>
                            getFieldValue('cites') === true ? (
                                <Form.Item
                                    name="citesFile"
                                    label={formatMessage({
                                        id: 'supplier_certificate_fiber_drawer.form.item.label.cites_file',
                                        defaultMessage: 'Fichier CITES',
                                    })}
                                    rules={[requiredRule]}
                                    required
                                >
                                    <Upload.Dragger showUploadList={false} beforeUpload={beforeUpload}>
                                        <p className="ant-upload-drag-icon">
                                            {fileToUpload ? <CheckCircleOutlined /> : <InboxOutlined />}
                                        </p>
                                        <p className="ant-upload-text">
                                            {fileToUpload ? (
                                                fileToUpload.name
                                            ) : (
                                                <FormattedMessage {...formMessages.uploadInstructions} />
                                            )}
                                        </p>
                                        <p className="ant-upload-hint">
                                            <FormattedMessage {...formMessages.uploadFileFormatAndSize} />
                                        </p>
                                    </Upload.Dragger>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>

                    <Button type="primary" htmlType="submit" shape="round" size="large" className="mb-4" block>
                        <FormattedMessage
                            id="supplier_certificate_fiber_drawer.form.submit_button"
                            defaultMessage="Ajouter la fibre"
                            description="form submit button text"
                        />
                    </Button>
                    <Button
                        onClick={() => {
                            drawer.hide();
                        }}
                        shape="round"
                        size="large"
                        className="mb-8"
                        ghost
                        block
                    >
                        {formatMessage(formMessages.cancel)}
                    </Button>
                    <Typography.Paragraph className="!mb-8">
                        <p className="text-center !font-14">
                            <RequiredFields />
                        </p>
                    </Typography.Paragraph>
                </Form>
            </Drawer>
        );
    }
);

export default ProviderCertificationFiberDrawer;
