import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { Button, Input, Table, Tag, Typography } from 'antd';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { DownloadOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { InputProps } from 'antd/lib/input';
import { useModal } from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import { LabeledValue, SelectProps } from 'antd/lib/select';

import {
    list as ordersList,
    getOrdersListState,
    downloadAll as downloadAllAction,
    download as downloadAction,
    getOrdersDownloadAllState,
    getConsultationState,
    getApproveState,
    getContestState,
} from '../../../store/actions/orders';
import { Order, OrderStatus, DocumentAction, DocTypes } from '../../../store/api/apiTypes';
import { getUser } from '../../../store/actions/auth';

import Seo from '../../../components/Seo';
import useQueryParams from '../../../hooks/queryParams';
import TableHeader from '../../../components/TableHeader';
import TitleAlt from '../../../components/TitleAlt';
import OrderStatusTag from '../../../components/OrderStatusTag';
import { downloadBlobFile, sortOrderConverter } from '../../../helpers';
import genericMessages from '../../../i18n/genericMessages';
import { useActions, usePrevious } from '../../../hooks';
import Select from '../../../components/Select';
import BrandsAndTypesTagsPopover from '../../../components/BrandsAndTypesTagsPopover';
import { errorMessage } from '../../../helpers/message';
import OrderDetailsDrawer from './OrderDetailsDrawer';

let searchTimeout: number;

const OrdersList: FC = () => {
    const [loadOrders, downloadAll, download] = useActions([
        ordersList.trigger,
        downloadAllAction.trigger,
        downloadAction.trigger,
    ]);
    const { formatMessage, formatDate } = useIntl();
    const user = useSelector(getUser);
    const consultationState = useSelector(getConsultationState);
    const approvalState = useSelector(getApproveState);
    const contestState = useSelector(getContestState);
    const orderDetailsDrawer = useModal(OrderDetailsDrawer);
    const [queryParams, setQueryParams] = useQueryParams('signature');
    const [search, setSearch] = useState<string | undefined>(queryParams.get('search') ?? undefined);
    const [currentOrderDownloadAll, setCurrentOrderDownloadAll] = useState<Order>();
    const pageTitle = formatMessage({
        id: 'order__list.title',
        defaultMessage: "Ordres d'achat / commessa",
        description: 'documents page menu item',
    });

    const orderListState = useSelector(getOrdersListState);
    const orderDownloadAllState = useSelector(getOrdersDownloadAllState);

    const previous = usePrevious({
        orderDownloadAllState,
        consultationState,
        approvalState,
        contestState,
    });
    const onSearch: InputProps['onPressEnter'] = (event) => {
        setSearch((event.target as HTMLInputElement).value || undefined);
        setQueryParams({ search: (event.target as HTMLInputElement).value || undefined });
    };
    const onChangeSearch: InputProps['onChange'] = (event) => {
        clearTimeout(searchTimeout);

        searchTimeout = window.setTimeout(() => {
            setQueryParams({ search: (event.target as HTMLInputElement).value || undefined });
        }, 350);
    };
    const startDownloadAll = (orderToDownload: Order) => {
        setCurrentOrderDownloadAll(orderToDownload);
        downloadAll({ id: orderToDownload.id });
    };
    const startDownload = (order: Order) => {
        download({
            id: order.id,
            fileId: order.documentActions?.[0]?.document?.id,
            filename: order.documentActions?.[0]?.document?.originalName,
        });
    };

    const onTableChange: TableProps<Order>['onChange'] = (pagination, filters, sorter: any) => {
        setQueryParams({
            sort: sorter.column?.dataIndex ? sorter.column?.dataIndex : undefined,
            sortOrder: sorter.order ? sortOrderConverter(sorter.order) : undefined,
            page: pagination.current ? pagination.current - 1 : 0,
        });
    };

    const onChangeSelecComplementaryInfo: SelectProps<LabeledValue[] | undefined>['onChange'] = (labeledValue) => {
        let payloadTmp = {};

        complementaryInfoOptions.forEach((item) => {
            payloadTmp = { ...payloadTmp, [item.key]: undefined };
        });

        labeledValue?.forEach((item) => {
            payloadTmp = { ...payloadTmp, [item.key as string]: true };
        });

        setQueryParams(payloadTmp);
    };

    const formatDocType = (documentActions?: DocumentAction[]) => {
        if (!documentActions) {
            return '';
        }
        return [
            ...new Set(
                documentActions
                    .filter(
                        (documentAction) =>
                            documentAction.docType !== DocTypes.COMMESSA ||
                            [
                                OrderStatus.approved,
                                OrderStatus.contested,
                                OrderStatus.consulted,
                                OrderStatus.sent,
                            ].includes(documentAction?.status)
                    )
                    .map((docAction) => docAction.docType)
            ),
        ]
            .sort((prev, next) => next.localeCompare(prev))
            .join(' / ');
    };

    const columns: Array<ColumnProps<Order>> = [
        {
            dataIndex: 'status',
            title: formatMessage(genericMessages.statusCofouLabel),
            render: (_, record) => <OrderStatusTag status={record.providerStatus ?? OrderStatus.pending} />,
            width: 150,
            fixed: 'left',
        },
        {
            title: formatMessage(genericMessages.orderlist_OANumber),
            render: (_, record) => (record.docNumber ? record.docNumber : record.name),
            fixed: 'left',
            sorter: true,
            width: 150,
        },
        {
            title: formatMessage(genericMessages.orderlist_nbrDocuments),
            render: (_, record) => (
                <FormattedNumber
                    value={
                        record?.documentActions?.filter((docItem) =>
                            [
                                OrderStatus.approved,
                                OrderStatus.consulted,
                                OrderStatus.contested,
                                OrderStatus.pending,
                                OrderStatus.sent,
                            ].includes(docItem.status)
                        ).length ?? 0
                    }
                />
            ),
            width: 140,
        },
        {
            dataIndex: 'saison',
            title: formatMessage(genericMessages.orderlist_saison),
            width: 120,
        },
        {
            dataIndex: 'brandsAndTypes',
            title: formatMessage({
                id: 'orders.list.tableHeader.universeAndProviderTypes',
                defaultMessage: 'Univers & Type(s)',
                description: 'label table provider list',
            }),
            width: 200,
            onCell: () => ({ style: { lineHeight: '32px' } }),
            render: (_, record) => (
                <BrandsAndTypesTagsPopover brands={record.brands} providerTypes={record.providerTypes} />
            ),
        },
        {
            dataIndex: 'typeDOC',
            title: formatMessage(genericMessages.orderlist_typeDoc),
            width: 160,
            render: (_, record) => formatDocType(record.documentActions),
        },
        {
            dataIndex: 'associatedDocNumber',
            title: formatMessage(genericMessages.orderlist_commessaNumber),
            width: 140,
            render: (_, record) => {
                const orderHasACommessaSent = record?.documentActions?.some(
                    (documentAction) =>
                        documentAction.docType === DocTypes.COMMESSA &&
                        [OrderStatus.approved, OrderStatus.contested, OrderStatus.consulted, OrderStatus.sent].includes(
                            documentAction?.status
                        )
                );
                return orderHasACommessaSent && record.associatedDocNumber ? record.associatedDocNumber : '';
            },
        },
        { dataIndex: 'styleNumber', title: formatMessage(genericMessages.orderlist_StyleNumber), width: 110 },
        {
            title: formatMessage(genericMessages.orderlist_complementaryInfo),
            width: 225,
            render: (_, record) => {
                return (
                    <>
                        {record.isVIP && <Tag>{formatMessage(genericMessages.vipMessage)}</Tag>}
                        {record.presse && <Tag>{formatMessage(genericMessages.presseMessage)}</Tag>}
                        {record.isPATOrPAI && <Tag>{formatMessage(genericMessages.patPaiMessage)}</Tag>}
                        {record.reassort && <Tag> {record.reassort}</Tag>}
                        {record.exportChina && <Tag>{formatMessage(genericMessages.exportChineMessage)}</Tag>}
                        {record.oneToOne && <Tag>{formatMessage(genericMessages.oneToOneMessage)}</Tag>}
                        {record.cancelled && <Tag>{formatMessage(genericMessages.cancelledMessage)}</Tag>}
                        {record.mto && <Tag>{formatMessage(genericMessages.MTOMessage)}</Tag>}
                        {record.permanent && (
                            <Tag
                                style={{
                                    textTransform: 'uppercase',
                                }}
                            >
                                {formatMessage(genericMessages.permanent)}
                            </Tag>
                        )}
                        {record.uniform && <Tag>{formatMessage(genericMessages.uniform)}</Tag>}
                    </>
                );
            },
        },
        {
            dataIndex: 'createdAt',
            title: formatMessage(genericMessages.orderlist_createdAt),
            render: (_, record) => <FormattedDate value={record.createdAt} />,
            sorter: true,
            width: 140,
        },
        {
            title: formatMessage(genericMessages.orderlist_lastActionProvider),
            render: (_, record) => formatProviderLastAction(record?.providerStatus, record?.providerLastActionDate),
            width: 240,
        },
        {
            title: formatMessage(genericMessages.actions),
            key: 'actions',
            width: 116,
            fixed: 'right',
            render: (_, record) => (
                <div className="space-x-3">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EyeOutlined />}
                        onClick={() => openDetailOrder(record.id)}
                    />
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<DownloadOutlined />}
                        onClick={() =>
                            (record.documentActions ?? []).length === 1
                                ? startDownload(record)
                                : startDownloadAll(record)
                        }
                    />
                </div>
            ),
        },
    ];

    const updateList = useCallback(() => {
        const params: any = {
            provider: user?.organization?.id,
            sort: 'createdAt',
            sortOrder: 'desc',
        };

        queryParams.forEach((value, key) => {
            params[key] = value;
        });

        loadOrders(params);
    }, [loadOrders, queryParams, user?.organization?.id]);

    useEffect(() => {
        updateList();
    }, [queryParams, updateList]);

    useEffect(() => {
        if (previous?.orderDownloadAllState.loading && !orderDownloadAllState.loading) {
            if (orderDownloadAllState.success && orderDownloadAllState.data) {
                const fileName = `allDoc-${currentOrderDownloadAll?.associatedDocNumber ?? ''}.zip`;
                downloadBlobFile(fileName, orderDownloadAllState.data, 'application/zip');
            } else {
                errorMessage({ content: formatMessage(genericMessages.defaultError) });
            }
        }
    }, [previous?.orderDownloadAllState, orderDownloadAllState, formatMessage, currentOrderDownloadAll]);

    useEffect(() => {
        if (previous?.consultationState.loading && !consultationState.loading) {
            if (consultationState.success) {
                updateList();
            }
        }

        if (previous?.approvalState.loading && !approvalState.loading) {
            if (approvalState.success) {
                updateList();
            }
        }

        if (previous?.contestState.loading && !contestState.loading) {
            if (contestState.success) {
                updateList();
            }
        }
    }, [consultationState, previous, approvalState, contestState, updateList]);

    const openDetailOrder = (orderId: Order['id']) => {
        orderDetailsDrawer.show({ orderId }).finally(() => {
            updateList();
        });
    };

    const orderStatusOptions = [
        { id: OrderStatus.pending, value: formatMessage(genericMessages.statusCofou_onHold) },
        { id: OrderStatus.approved, value: formatMessage(genericMessages.statusCofou_approved) },
        { id: OrderStatus.contested, value: formatMessage(genericMessages.statusCofou_contested) },
        { id: OrderStatus.consulted, value: formatMessage(genericMessages.statusCofou_consulted) },
    ];

    const complementaryInfoOptions = [
        { key: 'isVIP', label: formatMessage(genericMessages.vipMessage), value: 'isVIP' },
        { key: 'presse', label: formatMessage(genericMessages.presseMessage), value: 'presse' },
        { key: 'isPATOrPAI', label: formatMessage(genericMessages.patPaiMessage), value: 'isPATOrPAI' },
        { key: 'exportChina', label: formatMessage(genericMessages.exportChineMessage), value: 'exportChina' },
        { key: 'cancelled', label: formatMessage(genericMessages.cancelledMessage), value: 'cancelled' },
        { key: 'oneToOne', label: formatMessage(genericMessages.oneToOneMessage), value: 'oneToOne' },
        { key: 'mto', label: formatMessage(genericMessages.MTOMessage), value: 'mto' },
        { key: 'permanent', label: formatMessage(genericMessages.permanent), value: 'permanent' },
        { key: 'uniform', label: formatMessage(genericMessages.uniform), value: 'uniform' },
    ];

    const formatProviderLastAction = (providerStatusParam?: OrderStatus, providerLastActionDateParam?: string) => {
        if (providerStatusParam === OrderStatus.consulted) {
            return `${formatMessage(genericMessages.consultedAt)} ${formatDate(providerLastActionDateParam)}`;
        }
        if (providerStatusParam === OrderStatus.approved) {
            return `${formatMessage(genericMessages.approvedAt)}  ${formatDate(providerLastActionDateParam)}`;
        }
        if (providerStatusParam === OrderStatus.contested) {
            return `${formatMessage(genericMessages.contestedAt)}  ${formatDate(providerLastActionDateParam)}`;
        }
        // sent
        return '-';
    };

    return (
        <>
            <Seo title={pageTitle} />
            <TitleAlt className="!mb-6" style={{ fontWeight: 'normal' }}>
                <FormattedMessage
                    id="order__list.title.informations"
                    defaultMessage={`{count, plural, =0 {<bold>Aucun ordre d'achat / commessa</bold> à traiter.} one {<bold>{count, number} ordre d'achat / commessa</bold> à traiter.} other {<bold>{count, number} ordres d'achat / commessa</bold> à traiter.}}`}
                    description="purchase orders list title"
                    values={{
                        count: orderListState.data?.providerPendingDocumentCount ?? 0,
                        bold: (chunk: ReactNode) => <Typography.Text strong>{chunk}</Typography.Text>,
                    }}
                />
            </TitleAlt>

            <TableHeader>
                <Input
                    placeholder={formatMessage({
                        id: 'order__list.table_header.input.search',
                        defaultMessage: 'Rechercher un OA / Commessa',
                        description: 'Search input placeholder',
                    })}
                    onPressEnter={onSearch}
                    onChange={onChangeSearch}
                    defaultValue={search}
                    suffix={<SearchOutlined className="font-16" />}
                    allowClear
                />
                <Select<LabeledValue>
                    onChange={(labeledValue) => {
                        setQueryParams({ providerStatus: labeledValue ? labeledValue.value : undefined });
                    }}
                    placeholder={formatMessage(genericMessages.statusCofouLabel)}
                    style={{ width: '20.875rem' }}
                    labelInValue
                    allowClear
                >
                    {orderStatusOptions.map((statusOption, index) => (
                        <Select.Option value={statusOption.id} key={index}>
                            {statusOption.value}
                        </Select.Option>
                    ))}
                </Select>
                <Select<LabeledValue>
                    onChange={(labeledValue) => {
                        setQueryParams({ docType: labeledValue ? labeledValue.value : undefined });
                    }}
                    placeholder={formatMessage(genericMessages.typeDocumentLabel)}
                    style={{ width: '20.875rem' }}
                    labelInValue
                    allowClear
                >
                    {Object.values(DocTypes).map((docTypeItem, index) => (
                        <Select.Option value={docTypeItem} key={index}>
                            {docTypeItem}
                        </Select.Option>
                    ))}
                </Select>
                <Select<LabeledValue[]>
                    onChange={onChangeSelecComplementaryInfo}
                    onClear={() => onChangeSelecComplementaryInfo(undefined, [])}
                    placeholder={formatMessage(genericMessages.complementaryInfoLabel)}
                    style={{ width: '20.875rem' }}
                    mode="multiple"
                    maxTagCount={'responsive'}
                    labelInValue
                    allowClear
                >
                    {complementaryInfoOptions.map((infoItem) => (
                        <Select.Option value={infoItem.value} key={infoItem.key}>
                            {infoItem.label}
                        </Select.Option>
                    ))}
                </Select>
            </TableHeader>
            <Table<Order>
                style={{ width: '100%' }}
                columns={columns}
                loading={orderListState.loading}
                dataSource={orderListState.data?.items}
                pagination={{
                    total: orderListState.data?.totalCount,
                    pageSize: orderListState.data?.pageSize,
                    hideOnSinglePage: true,
                    size: 'small',
                    style: { marginBottom: 0 },
                    showSizeChanger: false,
                }}
                rowKey={(record) => record.id}
                rowClassName={(record) =>
                    [OrderStatus.pending, 'sent', ''].includes(record.providerStatus ?? '')
                        ? 'signature-row-unopened'
                        : ''
                }
                onChange={onTableChange}
                scroll={{ x: 2300, y: 'calc(100vh - 442px)' }}
            />
        </>
    );
};

export default OrdersList;
