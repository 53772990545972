import api from './_client';
import { SearchPaginationQuery } from './';

// Types
import { Collection } from './apiTypes';

export interface CollectionIdPayload {
    id: Collection['id'];
}

export type CollectionListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: CollectionListPayload = {}) => {
    return await api.get(`/collections`, { params: payload });
};

export const details = async (payload: CollectionIdPayload) => {
    return await api.get(`/collections/${payload.id}`);
};
