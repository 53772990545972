import { ReactNode } from 'react';
import { Drawer, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import NiceModal, { antdDrawer, useModal } from '@ebay/nice-modal-react';

import { ProviderCertification, Fiber } from '../../store/api/apiTypes';

import BasicList from '../../components/BasicList';
import Translate from '../../components/Translate';
import PDFViewer from '../../components/pdf/PDFViewer';

interface ProviderCertificationDocumentDrawerProps {
    document?: ProviderCertification;
    fiber?: Fiber;
}

const ProviderCertificationDocumentDrawer = NiceModal.create<ProviderCertificationDocumentDrawerProps>(
    ({ document, fiber }) => {
        const { formatMessage } = useIntl();
        const drawer = useModal();

        return (
            <Drawer
                width={1140}
                {...antdDrawer(drawer)}
                onClose={() => {
                    drawer.resolve();
                    drawer.hide();
                }}
                title={
                    !fiber
                        ? document?.name || undefined
                        : formatMessage({
                              id: 'provider_certification.drawer.title',
                              defaultMessage: 'Visualiser le document CITES',
                              description: 'provider certification drawer title',
                          })
                }
            >
                {!fiber && document?.validationStatus === false && (
                    <div>
                        <Typography.Paragraph className="mb-8">
                            <p className="uppercase">
                                <FormattedMessage
                                    id="providerCertificationsListState.list.documentRefusedInfo"
                                    defaultMessage="L'attestation a été <late> refusée le {refusedDate, date} par {user} {lastName}</late> pour les raisons suivantes:"
                                    description="documents to sign list intro"
                                    values={{
                                        refusedDate: new Date(document?.refusedDate ?? 0),
                                        user: document?.refusedBy?.firstName,
                                        lastName: document?.refusedBy?.lastName,
                                        late: (chunk: ReactNode) => (
                                            <Typography.Text type="danger" strong>
                                                {chunk}
                                            </Typography.Text>
                                        ),
                                    }}
                                />
                            </p>
                        </Typography.Paragraph>
                        <p className="uppercase">
                            <BasicList style={{ marginBottom: '15px' }}>
                                {document?.reasons?.map((reason) => (
                                    <li
                                        key={reason.id}
                                        style={{ listStyleType: 'disc', marginTop: '0', marginLeft: '18px' }}
                                    >
                                        {reason.type ? (
                                            <div>
                                                <Translate text={reason?.name} />
                                                {' :'}
                                            </div>
                                        ) : (
                                            <Translate text={reason?.name} />
                                        )}
                                    </li>
                                ))}
                            </BasicList>
                        </p>
                        {document?.commentary && (
                            <div
                                style={{
                                    marginBottom: '34px',
                                    backgroundColor: '#F0F2F5',
                                    padding: '16px',
                                    borderRadius: '8px',
                                }}
                            >
                                <Typography.Paragraph
                                    className="text-darker-grey font-bold font-14"
                                    style={{
                                        lineHeight: '22px',
                                    }}
                                >
                                    <FormattedMessage
                                        id="supplierCertificateListState.list.commentary"
                                        defaultMessage="Commentaire"
                                        description="commentary"
                                    />
                                </Typography.Paragraph>
                                {document?.commentary}
                            </div>
                        )}
                    </div>
                )}

                <div style={{ flex: 1, minHeight: '25rem' }}>
                    <PDFViewer
                        file={!fiber ? document?.file?.url : fiber?.citesFile?.url}
                        filename={!fiber ? document?.file?.filename : fiber?.citesFile?.filename}
                        scaleProp={1}
                    />
                </div>
            </Drawer>
        );
    }
);

export default ProviderCertificationDocumentDrawer;
