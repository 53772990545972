import { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path
            d="M13.657 12.317l-10-10c-.356-.355-.945-.343-1.314.026l-.062.068c-.307.37-.298.912.038 1.248l10 10a.947.947 0 0 0 1.34-1.34zm-9.974 1.34l10-10c.355-.356.343-.945-.026-1.314l-.068-.062c-.37-.307-.912-.298-1.248.038l-10 10a.947.947 0 0 0 1.34 1.34z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

const IconClose: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon
        component={svg}
        {...props}
        style={{
            width: '32px',
            height: '32px',
            backgroundColor: '#FFFFFF',
            borderRadius: '50%',
            padding: '8px',
        }}
        className={`icon-close${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconClose;
