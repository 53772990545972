import { DetailedHTMLProps, HTMLAttributes, forwardRef, ForwardRefRenderFunction } from 'react';

import '../assets/styles/BasicList.less';

import { classNames } from '../helpers';

interface BasicListProps extends DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement> {
    inline?: boolean;
    grid?: boolean;
}

const BasicList: ForwardRefRenderFunction<HTMLUListElement, BasicListProps> = (
    { children, className, grid, inline, ...props },
    ref
) => (
    <ul
        {...props}
        className={classNames('basic-list', inline && 'basic-list-inline', grid && 'basic-list-grid', className)}
        ref={ref}
    >
        {children}
    </ul>
);

export default forwardRef(BasicList);
