import { defineMessages } from 'react-intl';

const messages = defineMessages({
    question1: {
        id: 'audit_questions_messages.question1',
        defaultMessage: "Le site est-il équipé d'un système de management ?",
        description: 'audit question',
    },
    question1Help: {
        id: 'audit_questions_messages.question1_help',
        defaultMessage:
            'i.e. definition des rôles et responsabilités, engagements et procédures écrites, communication interne et pilotage',
        description: 'audit question help',
    },
    question2: {
        id: 'audit_questions_messages.question2',
        defaultMessage: "Une étude d'impact environnemental a-t-elle été conduite ?",
        description: 'audit question',
    },
    question3: {
        id: 'audit_questions_messages.question3',
        defaultMessage:
            "Le site détient-il une licence/autorisation/permis d'activités local délivré par les autorités locales ?",
        description: 'audit question',
    },
    question4: {
        id: 'audit_questions_messages.question4',
        defaultMessage:
            "Le site a-t'il reçu des plaintes de résidents locaux ou des demandes spécifiques de la part des autorités locales au cours des 3 dernières années ?",
        description: 'audit question',
    },
    question5: {
        id: 'audit_questions_messages.question5',
        defaultMessage:
            'Les produits dangereux (y compris hydrocarbures) et les déchets dangereux sont-ils stockés de manière appropriée ?',
        description: 'audit question',
    },
    question5Help: {
        id: 'audit_questions_messages.question5_help',
        defaultMessage:
            'Conservation dans de bonnes conditions, conformité avec la compatibilité de stockage ainsi que sa localisation',
        description: 'audit question help',
    },
    question6: {
        id: 'audit_questions_messages.question6',
        defaultMessage:
            "Si le procédé industriel utilise de l'eau qui est ensuite rejetée dans l'environnement, ces rejets aqueux sont-ils analysés avant d'être rejetés dans l'environnement (qu'il soient rejetés de l'usine ou de l'usine de traitement) ?",
        description: 'audit question',
    },
    question7: {
        id: 'audit_questions_messages.question7',
        defaultMessage: "Les émissions dans l'air ont-elles été analysées (poussière, C02, NOx, SOx, COV) ?",
        description: 'audit question',
    },
    question8: {
        id: 'audit_questions_messages.question8',
        defaultMessage:
            'Les déchets dangereux produits sur le site sont-ils séparés et traités par des filières appropriées ?',
        description: 'audit question',
    },
});

export default messages;

export const auditQuestions = [
    { question: messages.question1, help: messages.question1Help },
    { question: messages.question2 },
    { question: messages.question3 },
    { question: messages.question4 },
    { question: messages.question5, help: messages.question5Help },
    { question: messages.question6 },
    { question: messages.question7 },
    { question: messages.question8 },
];
