import api from './_client';
import { SearchPaginationQuery } from '.';

// Types
import { MaterialCertification } from './apiTypes';
import { RcFile } from 'antd/lib/upload';
import { serialize } from 'object-to-formdata';

// Controller Interface
export interface MaterialCertificationCreatePayload {
    body: {
        expirationDate: MaterialCertification['expirationDate'];
        validityStartDate: MaterialCertification['validityStartDate'];
        certifications: MaterialCertification['certifications'];
        references: MaterialCertification['references'];
        brands: MaterialCertification['brands'];
        season: MaterialCertification['season'];
        certificateType: MaterialCertification['certificateType'];
    };
    file: RcFile;
}

export interface MaterialCertificationIdPayload {
    id: MaterialCertification['id'];
}

export type MaterialCertificationListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: MaterialCertificationListPayload = {}) => {
    return await api.get(`/materialCertifications`, { params: payload });
};

export const create = async (payload: MaterialCertificationCreatePayload) => {
    const formData = serialize(payload);
    return await api.post(`/materialCertifications`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const details = async (payload: MaterialCertificationIdPayload) => {
    return await api.get(`/materialCertifications/${payload.id}`);
};

export const remove = async (payload: MaterialCertificationIdPayload) => {
    return await api.delete(`/materialCertifications/${payload.id}`);
};

export const downloadFromApi = async (payload: MaterialCertificationIdPayload) => {
    return await api.get(`/materialCertifications/download/${payload.id}`, {
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
    });
};
