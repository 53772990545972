import { message, MessageArgsProps } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

export const successMessage = (args: Partial<MessageArgsProps>) => {
    message.success({
        icon: <CheckCircleOutlined />,
        ...args,
    });
};

export const errorMessage = (args: Partial<MessageArgsProps>) => {
    message.error({
        icon: <CloseCircleOutlined />,
        ...args,
    });
};

export const warningMessage = (args: Partial<MessageArgsProps>) => {
    message.warning({
        icon: <ExclamationCircleOutlined />,
        ...args,
    });
};
