import { Permission, RoleName, User, PermissionRight } from '../store/api/apiTypes';

export const hasPermission = (user?: User, permission?: Permission, right: PermissionRight = PermissionRight.read) => {
    if (!permission) {
        return true;
    }
    switch (right) {
        case PermissionRight.read:
            return Boolean(user?.mergedPermissions?.[permission]);
        case PermissionRight.write:
            return user?.mergedPermissions?.[permission] === PermissionRight.write;
    }
    return false;
};

export const hasRole = (user?: User, roles?: RoleName[]) => {
    return (user?.role?.name && roles?.includes(user.role.name)) ?? false;
};
