import { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <g fillRule="evenodd" clipRule="evenodd" fill="currentColor" clipPath="url(#clip0_729_3663)">
            <path d="M16.027 9.09V7.38h1.093c.215 0 .43-.167.43-.42v-.84a.425.425 0 00-.43-.42H6.88a.425.425 0 00-.43.42v.84c0 .253.215.42.43.42h1.093v1.71c0 1.18.584 2.226 1.486 2.91-.902.684-1.486 1.73-1.486 2.91v1.71H6.88a.425.425 0 00-.43.42v.84c0 .253.215.42.43.42h10.24c.215 0 .43-.167.43-.42v-.84a.425.425 0 00-.43-.42h-1.093v-1.71c0-1.18-.584-2.226-1.486-2.91.902-.684 1.486-1.73 1.486-2.91zm-1.767 5.82v1.71H9.74v-1.71c0-.546.229-1.063.654-1.456A2.345 2.345 0 0112 12.84c.612 0 1.182.22 1.607.615.425.393.653.91.653 1.455zm0-5.82c0 .546-.228 1.063-.653 1.455A2.347 2.347 0 0112 11.16c-.612 0-1.181-.22-1.606-.614l-.001-.001a2.048 2.048 0 01-.486-.67 1.904 1.904 0 01-.167-.785V7.38h4.52v1.71z" />
            <path d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zm-2.25 0c0 5.385-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25s9.75 4.365 9.75 9.75z" />
        </g>
        <defs>
            <clipPath id="clip0_729_3663">
                <path fill="currentColor" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

const IconHourglass: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-hourglass${props.className ? ` ${props.className}` : ''}`} />
);

export default IconHourglass;
