import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setDefaultBreakpoints, BreakpointProvider } from 'react-socks';
import { CookiesProvider } from 'react-cookie';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Provider as NiceModalProvider } from '@ebay/nice-modal-react';
import './assets/styles/AntOverride.less';

import reportWebVitals from './reportWebVitals';
import App from './App';

import { UniversalCookie } from './store/actions/cookies';
import configureStore from './store/configureStore';

import { LocaleContextProvider } from './context/LocaleContext';
import IntlProvider from './components/IntlProvider';

Spin.setDefaultIndicator(<LoadingOutlined spin />);

setDefaultBreakpoints([{ xs: 0 }, { sm: 480 }, { md: 576 }, { lg: 768 }, { xl: 992 }, { xxl: 1200 }, { xxxl: 1600 }]);

const store = configureStore();

const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <CookiesProvider cookies={UniversalCookie}>
                <BrowserRouter>
                    <BreakpointProvider>
                        <LocaleContextProvider>
                            <IntlProvider>
                                <NiceModalProvider>
                                    <App />
                                </NiceModalProvider>
                            </IntlProvider>
                        </LocaleContextProvider>
                    </BreakpointProvider>
                </BrowserRouter>
            </CookiesProvider>
        </Provider>,
        document.getElementById('root') as HTMLElement
    );
};

declare global {
    interface Window {
        ResizeObserver: any;
    }
}

const init = async () => {
    const checkPolyfills = async () => {
        if (!window.IntersectionObserver) {
            await import('intersection-observer');
        }
        if (!window.ResizeObserver) {
            await (await import('resize-observer')).install();
        }
        if (!window.URLSearchParams) {
            await import('url-search-params-polyfill');
        }
    };

    await checkPolyfills();

    render();
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
