import { SearchPaginationQuery } from './';
import api from './_client';
import { User } from './apiTypes';

export interface UserDetailsPayload {
    id: User['id'];
}
export interface UserCreatePayload extends Omit<User, 'id'> {}
export interface UserUpdatePayload extends User {}
export type UserListPayload = SearchPaginationQuery;

export const details = async (payload: UserDetailsPayload) => await api.get(`/users/${payload.id}`);

export const list = async (payload: UserListPayload = {}) => {
    return await api.get(`/users`, { params: payload });
};

export const create = async (payload: UserCreatePayload) => {
    return await api.post(`/users`, payload);
};
export const update = async (payload: UserUpdatePayload) => {
    return await api.put(`/users/${payload.id}`, payload);
};
export const remove = async (payload: UserDetailsPayload) => {
    return await api.delete(`/users/${payload.id}`);
};
