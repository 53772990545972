import { FC } from 'react';
import { Translation } from '../store/api/apiTypes';
import { translation } from '../helpers';
import { useSelector } from 'react-redux';
import { getUser } from '../store/actions/auth';

interface TranslateProps {
    text?: Translation | string | undefined;
}

const Translate: FC<TranslateProps> = ({ text }) => {
    const user = useSelector(getUser);
    return <>{translation(text, user)}</>;
};

export default Translate;
