import api from './_client';
import { SearchPaginationQuery } from '.';
import { ValueListItem, ValueListSlug } from './apiTypes';

export interface ValueListItemListPayload extends SearchPaginationQuery {
    valueListSlug: ValueListSlug;
}

export interface ValueListItemDetailPayload {
    valueListSlug: ValueListSlug;
    valueItemId: ValueListItem['id'];
}

export const list = async (payload: ValueListItemListPayload) => {
    return await api.get(`/valueLists/${payload.valueListSlug}/items`, { params: payload });
};

export const detail = async (payload: ValueListItemDetailPayload) => {
    return await api.get(`/valueLists/${payload.valueListSlug}/items/${payload.valueItemId}`, { params: payload });
};
