import { SelectProps } from 'antd';

export const getSelectValue = (value: any) => {
    if (Array.isArray(value)) {
        return value.map((value: any) => {
            return typeof value === 'object' ? value.id : value;
        });
    } else {
        if (typeof value === 'object') {
            return value.id;
        } else {
            return value;
        }
    }
};

export const selectValueOnChange = (
    value: any,
    mode: SelectProps<any>['mode'],
    valueType: string | undefined,
    items: any[] | undefined
) => {
    if (valueType === 'id' || !items) {
        return value;
    } else {
        if (mode === 'multiple') {
            return items.filter((item) => value.indexOf(item.id) !== -1);
        } else {
            return items.find((provider) => provider.id === value);
        }
    }
};
