import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';

import '../../assets/styles/SignatureListTitle.less';

interface SignatureListTitleProps {
    countLate: number;
    countToSignNotLate: number;
    countNewNotLateNotOpened: number;
}
const SignatureListTitle: FC<SignatureListTitleProps> = ({
    countLate,
    countToSignNotLate,
    countNewNotLateNotOpened,
}) => {
    const total = countToSignNotLate + countLate;
    return (
        <div className="title-skeleton">
            <Typography.Paragraph>
                <p style={{ fontSize: '1.125rem', textTransform: 'uppercase' }}>
                    {!!countLate && !!countNewNotLateNotOpened && (
                        <FormattedMessage
                            id="signature_list_title.paragraph.intro_count_new_and_late"
                            defaultMessage="Il y a <bold>{documentCount} documents en attente</bold> de signature dont <new>{newCount} {newCount, plural, one {nouveau} other {nouveaux}} </new> et <late>{lateCount} en retard</late>."
                            description="documents to sign list intro"
                            values={{
                                documentCount: total,
                                lateCount: countLate,
                                newCount: countNewNotLateNotOpened,
                                bold: (chunk: ReactNode) => <strong>{chunk}</strong>,
                                new: (chunk: ReactNode) => (
                                    <Typography.Text type="success" strong>
                                        {chunk}
                                    </Typography.Text>
                                ),
                                late: (chunk: ReactNode) => (
                                    <Typography.Text type="danger" strong>
                                        {chunk}
                                    </Typography.Text>
                                ),
                            }}
                        />
                    )}
                    {!(!!countLate && !!countNewNotLateNotOpened) && (!!countNewNotLateNotOpened || !!countLate) && (
                        <FormattedMessage
                            id="signature_list_title.paragraph.intro_count_late_or_new"
                            // eslint-disable-next-line formatjs/no-multiple-whitespaces
                            defaultMessage={`{documentCount, plural,
                                            one {Il y a <bold>{documentCount} document en attente</bold>}
                                            other {Il y a <bold>{documentCount} documents en attente</bold>}
                                        } de signature dont <highlight>{itemCount} {itemType, select, late {en retard} new {
                                            {itemCount, plural, one {nouveau} other {nouveaux}}
                                        }}</highlight>.`}
                            description="documents to sign list intro"
                            values={{
                                documentCount: total,
                                itemType: countNewNotLateNotOpened ? 'new' : 'late',
                                itemCount: countNewNotLateNotOpened || countLate,
                                bold: (chunk: ReactNode) => <strong>{chunk}</strong>,
                                highlight: (chunk: ReactNode) => (
                                    <Typography.Text type={countNewNotLateNotOpened ? 'success' : 'danger'} strong>
                                        {chunk}
                                    </Typography.Text>
                                ),
                            }}
                        />
                    )}
                    {!countNewNotLateNotOpened && !countLate && (
                        <FormattedMessage
                            id="signature_list_title.paragraph.intro_count"
                            // eslint-disable-next-line formatjs/no-multiple-whitespaces
                            defaultMessage={`{documentCount, plural,
                                    =0 {Il n'y a <bold>aucun document en attente</bold>}
                                    one {Il y a <bold>{documentCount} document en attente</bold>}
                                    other {Il y a <bold>{documentCount} documents en attente</bold>}
                                } de signature.`}
                            description="documents to sign list intro"
                            values={{
                                documentCount: countToSignNotLate,
                                bold: (chunk: ReactNode) => <strong>{chunk}</strong>,
                            }}
                        />
                    )}
                </p>
            </Typography.Paragraph>
        </div>
    );
};

export default SignatureListTitle;
