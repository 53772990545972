import { Divider, SelectProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { classNames } from '../helpers';
import { SupportedLanguage } from '../store/api/apiTypes';

import Select from './Select';

type LangImport = { [key in SupportedLanguage]: any };

const availableLangs = ['es', 'de', 'ru', 'fr', 'jp', 'cn', 'pt', 'it', 'ir', 'ar', 'tr', 'id', 'en'];

const intlData = availableLangs.reduce<LangImport | Record<string, unknown>>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => await import(`react-phone-input-2/lang/${lang}.json`),
    }),
    {}
);

const CountrySelect: FC<SelectProps<SupportedLanguage>> = (props) => {
    const { locale } = useIntl();
    const [localization, setLocalization] = useState<{ [key: string]: string } | undefined>();
    useEffect(() => {
        if (availableLangs.includes(locale)) {
            if (locale === SupportedLanguage.en) {
                (async () => {
                    const localeData = (await import('../i18n/countries/en.json')).default;
                    setLocalization(localeData);
                })();
            } else {
                (async () => {
                    const localeData = (await intlData[locale as SupportedLanguage]?.())?.default;
                    setLocalization(localeData);
                })();
            }
        }
    }, [locale]);

    return localization ? (
        <Select
            {...props}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                option?.text?.toString().toLowerCase().startsWith(input.toLowerCase()) ?? false
            }
            dropdownClassName="lang-select-dropdown"
        >
            <Select.Option value="fr" key="fr" text={localization.fr}>
                <span className={classNames('flag', 'fr')} /> {localization.fr}
            </Select.Option>
            <Select.Option value="it" key="it" text={localization.it}>
                <span className={classNames('flag', 'it')} /> {localization.it}
            </Select.Option>
            <Select.Option value="pt" key="pt" text={localization.pt}>
                <span className={classNames('flag', 'pt')} /> {localization.pt}
            </Select.Option>
            <Select.Option value="" style={{ minHeight: 0, padding: 0 }} disabled>
                <Divider style={{ margin: '0.25rem 0' }} />
            </Select.Option>
            {Object.keys(localization)
                .filter((key) => !['fr', 'it', 'pt'].includes(key))
                .map((lang) => (
                    <Select.Option value={lang} key={lang} text={localization[lang]}>
                        <span className={classNames('flag', lang)} /> {localization[lang]}
                    </Select.Option>
                ))}
        </Select>
    ) : null;
};

export default CountrySelect;
