import { FC, useEffect, useState } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import {
    getCategories as getCategoriesAction,
    getConformityDocumentsGetCategoriesState,
} from '../../store/actions/conformityDocuments';
import { useActions } from '../../hooks';
import { useSelector } from 'react-redux';
import { Translation, ValueListItem, ValueListSlug } from '../../store/api/apiTypes';
import { getSelectValue, selectValueOnChange } from '../../helpers/select';
import Translate from '../Translate';

type SelectCategoryProps = SelectProps<any> & {
    onChange?: (value?: ValueListItem['id']) => void;
    initialValue?: ValueListItem[];
    valueType?: 'id' | 'object';
};

const SelectCategory: FC<SelectCategoryProps> = ({ valueType = 'id', onChange, ...props }) => {
    const [value, setValue] = useState<any>();
    const [search] = useActions([getCategoriesAction.trigger]);
    const searchState = useSelector(getConformityDocumentsGetCategoriesState);
    const [initialValue, setInitialValue] = useState<ValueListItem[]>([]);
    const list = searchState?.data?.items ?? [];

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue);
            setInitialValue(props.initialValue);
        } else {
            setValue([]);
            setInitialValue([]);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setValue(getSelectValue(props.value));
    }, [props.value]);

    const onFocus: SelectProps<ValueListItem['id']>['onFocus'] = () => {
        search({
            valueListSlug: ValueListSlug.conformityDocumentCategory,
            search: null,
        });
    };

    useEffect(() => {
        search({
            valueListSlug: ValueListSlug.conformityDocumentCategory,
            search: null,
        });
    }, [search]);

    const onSearch: SelectProps<ValueListItem['id']>['onSearch'] = (newValue) => {
        if (newValue) {
            search({
                valueListSlug: ValueListSlug.conformityDocumentCategory,
                search: newValue,
            });
        } else {
            search({
                valueListSlug: ValueListSlug.conformityDocumentCategory,
                search: null,
            });
        }
    };

    const onChangeSelect: SelectProps<ValueListItem['id']>['onChange'] = (newValue) => {
        setValue(newValue);
        if (onChange) {
            onChange(selectValueOnChange(newValue, props.mode, valueType, searchState?.data?.items));
        }
    };

    return (
        <Select
            {...props}
            showSearch
            value={searchState.loading ? undefined : value}
            notFoundContent={searchState.loading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSearch}
            onChange={onChangeSelect}
            onFocus={onFocus}
            allowClear
        >
            {[
                ...initialValue, // Display initial value
                ...list.filter((a) => !initialValue.map((b) => b.id).includes(a.id)), // Search items, excluding initial value
            ].map((category) => (
                <Select.Option value={category.id} key={category.id}>
                    {<Translate text={category?.fields?.name as Translation} />}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SelectCategory;
