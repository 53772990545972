import { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M12 15a1 1 0 100-2 1 1 0 000 2z" fill="currentColor" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 10c-1.628 0-2.991.965-3.86 1.764a10.82 10.82 0 00-1.436 1.63l-.023.032-.007.01-.003.004v.002a1 1 0 00-.001 1.115L7.5 14c-.83.557-.83.559-.83.559h.001l.003.004.007.01.023.034.08.112a10.82 10.82 0 001.357 1.517C9.009 17.036 10.372 18 12 18c1.628 0 2.991-.965 3.86-1.764a10.823 10.823 0 001.436-1.63l.023-.032.007-.01.002-.004.002-.002a1 1 0 000-1.115L16.5 14c.83-.557.83-.559.83-.559h-.002l-.002-.004-.007-.01-.023-.034a9.707 9.707 0 00-.373-.491c-.249-.308-.612-.721-1.064-1.138C14.991 10.964 13.628 10 12 10zm4.5 4l.83.558L16.5 14zm-9 0l-.83-.558.83.558zm1.995-.764c-.29.267-.538.537-.731.764.193.227.441.497.731.764.761.7 1.648 1.236 2.505 1.236s1.744-.535 2.505-1.236c.29-.267.538-.537.731-.764a8.705 8.705 0 00-.732-.764C13.745 12.536 12.857 12 12 12s-1.744.535-2.505 1.236z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 1a2 2 0 00-2 2v18a2 2 0 002 2h14a2 2 0 002-2V7a.996.996 0 00-.26-.672l-4.497-4.997A1 1 0 0015.5 1H5zm9 2H5v18h14V8h-4a1 1 0 01-1-1V3zm3.755 3H16V4.05L17.755 6z"
            fill="currentColor"
        />
    </svg>
);

const IconStatusConsulted: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon
        component={svg}
        {...props}
        className={`icon-status-consulted${props.className ? ` ${props.className}` : ''}`}
    />
);
export default IconStatusConsulted;
