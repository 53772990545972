/* eslint-disable react/style-prop-object */
import { FC, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Button, Descriptions, message, Space, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Revenue, RevenueType } from '../../store/api/apiTypes';

import ButtonIcon from '../../components/ButtonIcon';
import DeletePopConfirm from '../../components/DeletePopConfirm';
import RevenueFormDrawer from './RevenueFormDrawer';
import {
    list as revenuesList,
    getRevenuesListState,
    remove as removeRevenu,
    getRevenuesRemoveState,
} from '../../store/actions/revenue';
import { details as majInfoDetails } from '../../store/actions/majInfoProduct';
import { useSelector } from 'react-redux';
import { useActions, usePrevious } from '../../hooks';
import genericMessages from '../../i18n/genericMessages';
import EditOutlinedWithToolTip from '../../components/EditOutlinedWithToolTip';

const revenueYearsTable = [dayjs().year() - 1, dayjs().year() - 2, dayjs().year() - 3];

const RevenueInfos: FC = () => {
    const { formatMessage, formatDate } = useIntl();
    const [isFormDrawerVisible, setIsFormDrawerVisible] = useState(false);
    const [loadRevenues, loadMajInfo] = useActions([revenuesList.trigger, majInfoDetails.trigger]);
    const remove = useActions(removeRevenu.trigger);
    const revenueListState = useSelector(getRevenuesListState);
    const revenueRemoveState = useSelector(getRevenuesRemoveState);
    const previous = usePrevious({ revenueRemoveState });

    const canEdit = revenueListState?.data?.items && revenueListState?.data?.items.length > 2;
    const onClickAdd = () => {
        setIsFormDrawerVisible(true);
    };
    const onClickEdit = () => {
        setIsFormDrawerVisible(true);
    };
    const onClickDelete = () => {
        if (revenueListState.data?.items) {
            for (const revenue of revenueListState.data?.items) {
                remove({ id: revenue.id });
            }
        }
    };
    const onCloseFormDrawer = () => {
        setIsFormDrawerVisible(false);
        loadRevenues();
    };

    useEffect(() => {
        loadRevenues();
    }, [loadRevenues]);

    useEffect(() => {
        loadMajInfo();
    }, [loadMajInfo]);

    useEffect(() => {
        const afterRemove = previous?.revenueRemoveState.loading && !revenueRemoveState.loading;
        if (afterRemove) {
            if (revenueRemoveState.success) {
                loadRevenues();
            } else {
                message.error(formatMessage(genericMessages.defaultError));
            }
        }
    }, [formatMessage, previous, revenueRemoveState, loadRevenues]);

    const renderItems = (revenue: Revenue) => (
        <Descriptions.Item
            key={revenue.id}
            label={
                dayjs(revenue.date).year() === dayjs().year() ? (
                    formatMessage(
                        {
                            id: 'revenue.render_items.current_year',
                            defaultMessage: 'À date ({date})',
                            description: 'revenue current year',
                        },
                        { date: formatDate(dayjs().toDate(), { year: 'numeric', month: 'short' }) }
                    )
                ) : (
                    <FormattedDate value={revenue.date} year="numeric" />
                )
            }
        >
            <FormattedNumber
                value={revenue.revenue}
                currency={revenue.currency}
                style="currency"
                maximumFractionDigits={0}
            />
        </Descriptions.Item>
    );

    return (
        <>
            <div className="flex flex-between" style={{ marginBottom: '1.5rem' }}>
                <div>
                    <Typography.Paragraph
                        style={{
                            textTransform: 'uppercase',
                            letterSpacing: '0.5px',
                            lineHeight: '1rem',
                            marginBottom: '0.75rem',
                        }}
                    >
                        {!!revenueListState.data?.items.length && (
                            <p style={{ fontSize: '0.875rem' }}>
                                <FormattedMessage
                                    id="revenue.paragraph.title.globale"
                                    defaultMessage="Au global"
                                    description="section title"
                                />
                            </p>
                        )}
                    </Typography.Paragraph>

                    <Descriptions column={1}>
                        {revenueYearsTable.map((year) => {
                            return revenueListState.data?.items
                                .filter(
                                    (revenue) =>
                                        dayjs(revenue.date).year() === year &&
                                        revenue.type === RevenueType.global &&
                                        revenue.revenue
                                )
                                .map(renderItems);
                        })}
                    </Descriptions>
                </div>
                <div>
                    <Typography.Paragraph
                        style={{
                            textTransform: 'uppercase',
                            letterSpacing: '0.5px',
                            lineHeight: '1rem',
                            marginBottom: '0.75rem',
                        }}
                    >
                        {!!revenueListState.data?.items.length && (
                            <p style={{ fontSize: '0.875rem' }}>
                                <FormattedMessage
                                    id="revenue.paragraph.title.dior"
                                    defaultMessage="Avec Christian Dior Couture"
                                    description="section title"
                                />
                            </p>
                        )}
                    </Typography.Paragraph>
                    <Descriptions column={1}>
                        {revenueYearsTable.map((year) => {
                            return revenueListState.data?.items
                                .filter(
                                    (revenue) =>
                                        dayjs(revenue.date).year() === year &&
                                        revenue.type === RevenueType.dior &&
                                        revenue.revenue
                                )
                                .map(renderItems);
                        })}
                    </Descriptions>
                </div>
                {canEdit && (
                    <Space size="middle">
                        <ButtonIcon icon={<EditOutlinedWithToolTip />} onClick={onClickEdit} />
                        <DeletePopConfirm placement="topRight" onConfirm={onClickDelete}>
                            <ButtonIcon icon={<DeleteOutlined />} />
                        </DeletePopConfirm>
                    </Space>
                )}
            </div>
            {revenueListState.data?.items && revenueListState.data?.items.length <= 2 && (
                <div style={{ textAlign: 'center' }}>
                    <Button onClick={onClickAdd} size="small" shape="round">
                        <FormattedMessage
                            id="revenue.button.add_revenues"
                            defaultMessage="Saisir les chiffres d'affaires"
                            description="call to action"
                        />
                    </Button>
                </div>
            )}
            <RevenueFormDrawer
                visible={isFormDrawerVisible}
                onClose={onCloseFormDrawer}
                revenues={revenueListState.data?.items}
            />
        </>
    );
};

export default RevenueInfos;
