import { Button, Drawer, DrawerProps, Row, Typography, Col } from 'antd';
import { useCallback, useMemo } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import NiceModal, { antdDrawer, useModal } from '@ebay/nice-modal-react';

import { Order, OrderStatus, DocumentAction, DocTypes } from '../../../store/api/apiTypes';
import { details as detailOrderAction, getOrdersDetailsState } from '../../../store/actions/orders';

import PDFViewer from '../../../components/pdf/PDFViewer';
import genericMessages from '../../../i18n/genericMessages';
import IconExclamationPoint from '../../../components/icons/IconExclamationPoint';
import { useActions } from '../../../hooks';
import OrderActionNeededModal from './OrderActionNeededModal';
import OrderApprovalFormDrawer from './OrderApprovalFormDrawer';
import OrderContestFormModal from './OrderContestFormModal';

interface OrderDocumentDrawerProps extends DrawerProps {
    documentAction?: DocumentAction;
    orderId?: Order['id'];
}

const OrderDocumentDrawer = NiceModal.create<OrderDocumentDrawerProps>(
    ({ documentAction, orderId, children, ...props }) => {
        const drawer = useModal();
        const { formatMessage } = useIntl();
        const orderDetailsState = useSelector(getOrdersDetailsState);
        const [orderDetails] = useActions([detailOrderAction.trigger]);

        const actionNeededModal = useModal(OrderActionNeededModal);
        const orderApprovalFormDrawer = useModal(OrderApprovalFormDrawer);
        const orderContestFormModal = useModal(OrderContestFormModal);

        const document = documentAction?.document;

        const onApproval = useCallback(() => {
            orderApprovalFormDrawer
                .show({
                    documentId: document?.id,
                    orderId,
                })
                .then(() => {
                    orderDetails({ id: orderId });
                    drawer.hide();
                });
        }, [document?.id, drawer, orderApprovalFormDrawer, orderDetails, orderId]);

        const onContestation = useCallback(() => {
            orderContestFormModal
                .show({
                    documentId: document?.id,
                    orderId,
                })
                .then(() => {
                    orderDetails({ id: orderId });
                    drawer.hide();
                });
        }, [document?.id, drawer, orderContestFormModal, orderDetails, orderId]);

        const onCloseConfirmationDrawer = () => {
            if (
                documentAction?.docType === DocTypes.OA &&
                [OrderStatus.sent, OrderStatus.consulted].includes(documentAction.status)
            ) {
                actionNeededModal.show().catch(() => {
                    drawer.hide();
                });
            } else {
                drawer.hide();
            }
        };

        const getDocStatus = useCallback(
            (docStatus: OrderStatus | undefined) => {
                switch (docStatus) {
                    case OrderStatus.approved:
                        return formatMessage(genericMessages.statusCofou_approved);
                    case OrderStatus.contested:
                        return formatMessage(genericMessages.statusCofou_contested);
                    case OrderStatus.consulted:
                        return formatMessage(genericMessages.statusCofou_consulted);

                    default:
                        return formatMessage(genericMessages.statusCofou_onHold);
                }
            },
            [formatMessage]
        );

        const drawerFooter = useMemo(() => {
            if (
                documentAction?.docType === DocTypes.OA &&
                [OrderStatus.sent, OrderStatus.consulted].includes(documentAction.status)
            ) {
                return (
                    <div className="flex flex-between btns-doc-processing">
                        <Button
                            onClick={() => onContestation()}
                            className="btn-contest"
                            type="primary"
                            size="large"
                            shape="round"
                        >
                            {formatMessage(genericMessages.OrdersProcess_disputeTheOrder)}
                        </Button>
                        <Button
                            onClick={() => onApproval()}
                            className="btn-approve"
                            type="primary"
                            size="large"
                            shape="round"
                        >
                            {formatMessage(genericMessages.OrdersProcess_approveTheOrder)}
                        </Button>
                    </div>
                );
            }

            return undefined;
        }, [documentAction?.docType, documentAction?.status, formatMessage, onApproval, onContestation]);

        return (
            <Drawer
                width={1140}
                {...props}
                {...antdDrawer(drawer)}
                onClose={onCloseConfirmationDrawer}
                footer={drawerFooter}
            >
                <div className="dflex flex-col h-full">
                    {children}
                    <div style={{ flex: 1, minHeight: '25rem', marginBottom: '34px' }}>
                        <section className="doc-processing-info-header">
                            <Row>
                                <Col flex={3} span={4}>
                                    <Typography.Text className="label">
                                        {formatMessage({
                                            id: 'orders.numOA',
                                            defaultMessage: `N°OA`,
                                        })}
                                    </Typography.Text>
                                    <span>{orderDetailsState.data?.docNumber}</span>
                                </Col>
                                <Col flex={3} span={4}>
                                    <Typography.Text className="label">
                                        {formatMessage({
                                            id: 'orders.docStatus',
                                            defaultMessage: `Statut`,
                                        })}
                                    </Typography.Text>
                                    <span>{getDocStatus(documentAction?.status)}</span>
                                </Col>
                                <Col flex={3} span={4}>
                                    <Typography.Text className="label">
                                        {formatMessage({
                                            id: 'orders.docType',
                                            defaultMessage: `Type`,
                                        })}
                                    </Typography.Text>
                                    <span>{documentAction?.docType ?? '-'}</span>
                                </Col>
                                <Col flex={3} span={4}>
                                    <Typography.Text className="label">
                                        {formatMessage({
                                            id: 'orders.docCreatedAt',
                                            defaultMessage: `Mise en ligne`,
                                        })}
                                    </Typography.Text>
                                    <span>
                                        <FormattedDate value={documentAction?.document?.createdAt} />
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <div className="note-information flex">
                                    <IconExclamationPoint style={{ fontSize: '30px', marginRight: '8px' }} />
                                    {documentAction?.docType === 'OA'
                                        ? formatMessage(genericMessages.OrdersProcess_messageDoAction)
                                        : formatMessage(genericMessages.OrdersProcess_messageConsultDoc)}
                                </div>
                            </Row>
                        </section>

                        <PDFViewer file={document?.url} filename={document?.filename} />
                    </div>
                </div>
            </Drawer>
        );
    }
);

export default OrderDocumentDrawer;
