import { FC, useState, useEffect, CSSProperties } from 'react';
import useIsInViewport from 'use-is-in-viewport';
import { ApiOutlined } from '@ant-design/icons';

import '../assets/styles/Img.less';

import { classNames } from '../helpers';

interface ImgProps {
    alt?: string;
    src?: string;
    onClick?: () => void;
    onKeyPress?: () => void;
    role?: string;
    width?: number;
    height?: number;
    fit?: CSSProperties['objectFit'];
}

const Img: FC<ImgProps> = ({ alt, height, src, width, fit = 'cover', ...props }) => {
    const [isInViewport, targetRef] = useIsInViewport();
    const [image, setImage] = useState<string | undefined>();
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const hasSize = height !== undefined && width !== undefined;

    useEffect(() => {
        if (src && isInViewport && !isLoaded) {
            const loadingTimeout = window.setTimeout(() => {
                setIsLoading(true);
            }, 100);
            const img = new Image();
            const onLoad = async () => {
                if (img.decode !== undefined) {
                    try {
                        await img.decode();
                    } catch (e) {
                        setHasError(true);
                    }
                }
                setImage(img.src);
                setIsLoading(false);
                setIsLoaded(true);
                window.clearTimeout(loadingTimeout);
            };

            img.onload = onLoad;
            img.onerror = () => {
                setHasError(true);
                setIsLoading(false);
                window.clearTimeout(loadingTimeout);
            };
            img.src = src;

            return () => {
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                img.removeEventListener('load', onLoad);
                window.clearTimeout(loadingTimeout);
            };
        }

        return () => undefined;
    }, [src, isInViewport, isLoaded, setIsLoaded]);

    return (
        <div
            {...props}
            className={classNames(
                'img-wrapper',
                image && 'img-loaded',
                isLoading && 'img-loading',
                hasError && 'img-error',
                !hasSize && 'img-fill'
            )}
            ref={targetRef}
            style={
                hasSize
                    ? {
                          height: height ?? 'auto',
                          width: width ?? 'auto',
                      }
                    : undefined
            }
        >
            {hasError ? !isLoading && <ApiOutlined /> : <img src={image} alt={alt} style={{ objectFit: fit }} />}
        </div>
    );
};

export default Img;
