import { FC, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import { Spin } from 'antd';

import { checkLoginStatus as checkLoginStatusAction, getAuthState } from '../store/actions/auth';
import { getRawRoute, getRoute, RoutePathName } from '../routes';
import { useActions } from '../hooks';
import { defaultLanguage, LocaleContext } from '../context/LocaleContext';

const ProtectedRoute: FC = ({ children }) => {
    const { setLocale } = useContext(LocaleContext);
    const [checkLoginStatus, logout] = useActions([checkLoginStatusAction.trigger, checkLoginStatusAction.failure]);
    const authState = useSelector(getAuthState);
    const isValidateAccountPage = useRouteMatch(getRawRoute(RoutePathName.validateAccount));
    useEffect(() => {
        // dispatched from axios interceptor in api/_client.ts
        window.addEventListener('unauthorized.error', logout);

        if (!authState.hasCheckedLoginStatus) {
            checkLoginStatus();
        }

        return () => window.removeEventListener('unauthorized.error', logout);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLocale(authState.user?.language ?? defaultLanguage);
    }, [authState.user?.language, setLocale]);

    if (authState.hasCheckedLoginStatus && !authState.isConnected) {
        return <Redirect to={getRawRoute(RoutePathName.login)} />;
    } else if (authState.hasCheckedLoginStatus && authState.isConnected) {
        if (!authState.user?.verificationData && !isValidateAccountPage) {
            return <Redirect to={getRoute(RoutePathName.validateAccount)} />;
        }
        return <>{children}</>;
    } else {
        return (
            <Route>
                <div id="initial-loader">
                    <Spin size="large" />
                </div>
            </Route>
        );
    }
};

export default ProtectedRoute;
