import { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Layout, SelectProps } from 'antd';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import '../assets/styles/Login.less';
import '../assets/styles/ReactPhoneInput2.less';

import { LocaleContext } from '../context/LocaleContext';
import { LanguageName } from '../i18n/lang';
import { classNames } from '../helpers';
import { IconLogo } from './icons';
import Select from './Select';
import { SupportedLanguage } from '../store/api/apiTypes';
import { cookiesNotice } from '../helpers/helpFiles';
import { useSelector } from 'react-redux';
import { getApiInfosInfosState } from '../store/actions/apiInfos';

require('swiper/swiper.less');
require('swiper/components/pagination/pagination.less');
require('swiper/components/effect-fade/effect-fade.less');

SwiperCore.use([EffectFade, Autoplay]);

const LoginLayout: FC = ({ children }) => {
    const { setLocale } = useContext(LocaleContext);
    const { locale } = useIntl();
    const onChangeLocale: SelectProps<SupportedLanguage>['onChange'] = (value) => {
        setLocale(value);
    };
    const apiInfosInfosState = useSelector(getApiInfosInfosState);

    const privacyFiles = {
        fr: '/pdf/Statement_Fournisseurs_COFOU_FR.pdf',
        en: '/pdf/Statement_Fournisseurs_COFOU_EN.pdf',
        it: '/pdf/Statement_Fournisseurs_COFOU_EN.pdf',
        pt: '/pdf/Statement_Fournisseurs_COFOU_EN.pdf',
    };

    return (
        <Layout
            id="login-layout"
            className={
                apiInfosInfosState?.data?.env === 'development' || apiInfosInfosState?.data?.env === 'preprod'
                    ? 'test-environment'
                    : undefined
            }
        >
            <div id="login-section">
                <div id="login-section-inner">
                    <IconLogo />
                    {apiInfosInfosState?.data?.env === 'preprod' && (
                        <div id="test-env-badge" className="font-24 font-bold uppercase">
                            UAT
                        </div>
                    )}
                    {apiInfosInfosState?.data?.env === 'development' && (
                        <div id="test-env-badge" className="font-24 font-bold uppercase">
                            DEV
                        </div>
                    )}
                    {children}
                    <Select
                        className="login-lang-select"
                        defaultValue={locale as SupportedLanguage}
                        dropdownClassName="lang-select-dropdown"
                        onChange={onChangeLocale}
                        size="small"
                    >
                        {Object.values(SupportedLanguage).map((lang) => (
                            <Select.Option value={lang} key={lang}>
                                <span className={classNames('flag', lang)} /> {LanguageName.get(lang)}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div id="login-section-footer">
                    <a href={cookiesNotice[locale as SupportedLanguage]} target="_blank" rel="noreferrer">
                        <FormattedMessage
                            id="login_layout.link.cookies"
                            defaultMessage="Gestion des cookies"
                            description="gestion cookies link"
                        />
                    </a>
                    <a href={privacyFiles[locale as SupportedLanguage]} target="_blank" rel="noreferrer">
                        <FormattedMessage
                            id="login_layout.link.privacy_politic"
                            defaultMessage="Politique de vie privée fournisseur"
                            description="privacy politic link"
                        />
                    </a>
                </div>
            </div>
            <div id="login-carousel">
                <Swiper
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    speed={800}
                    slidesPerView={1}
                    effect="fade"
                    fadeEffect={{
                        crossFade: true,
                    }}
                    loop
                >
                    <SwiperSlide>
                        <picture>
                            <img
                                sizes="(max-width: 962px) 100vw, 962px"
                                srcSet={`
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_1_nlszpf_c_scale,w_512.jpg 512w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_1_nlszpf_c_scale,w_677.jpg 677w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_1_nlszpf_c_scale,w_823.jpg 823w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_1_nlszpf_c_scale,w_951.jpg 951w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_1_nlszpf_c_scale,w_962.jpg 962w`}
                                src={`${process.env.PUBLIC_URL}/images/visuel_dior_login_1_nlszpf_c_scale,w_962.jpg`}
                                alt=""
                            />
                        </picture>
                    </SwiperSlide>
                    <SwiperSlide>
                        <picture>
                            <img
                                sizes="(max-width: 962px) 100vw, 962px"
                                srcSet={`
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_2_ssyjvc_c_scale,w_512.jpg 512w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_2_ssyjvc_c_scale,w_684.jpg 684w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_2_ssyjvc_c_scale,w_820.jpg 820w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_2_ssyjvc_c_scale,w_957.jpg 957w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_2_ssyjvc_c_scale,w_962.jpg 962w`}
                                src={`${process.env.PUBLIC_URL}/images/visuel_dior_login_2_ssyjvc_c_scale,w_962.jpg`}
                                alt=""
                            />
                        </picture>
                    </SwiperSlide>
                    <SwiperSlide>
                        <picture>
                            <img
                                sizes="(max-width: 962px) 100vw, 962px"
                                srcSet={`
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_3_gptjkh_c_scale,w_512.jpg 512w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_3_gptjkh_c_scale,w_649.jpg 649w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_3_gptjkh_c_scale,w_768.jpg 768w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_3_gptjkh_c_scale,w_880.jpg 880w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_3_gptjkh_c_scale,w_928.jpg 928w,
                                    ${process.env.PUBLIC_URL}/images/visuel_dior_login_3_gptjkh_c_scale,w_962.jpg 962w`}
                                src={`${process.env.PUBLIC_URL}/images/visuel_dior_login_3_gptjkh_c_scale,w_962.jpg`}
                                alt=""
                            />
                        </picture>
                    </SwiperSlide>
                </Swiper>
            </div>
        </Layout>
    );
};

export default LoginLayout;
