import api from './_client';
import { SearchPaginationQuery } from './';

// Types
import { Factory } from './apiTypes';

// Controller Interface
export interface FactoryCreatePayload extends Omit<Factory, 'id'> {}
export interface FactoryUpdatePayload extends Factory {}

export interface FactoryIdPayload {
    id: Factory['id'];
}

export type FactoryListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: FactoryListPayload = {}) => {
    return await api.get(`/factories`, { params: payload });
};

export const create = async (payload: FactoryCreatePayload) => {
    return await api.post(`/factories`, payload);
};

export const update = async (payload: FactoryUpdatePayload) => {
    return await api.put(`/factories/${payload.id}`, payload);
};

export const details = async (payload: FactoryIdPayload) => {
    return await api.get(`/factories/${payload.id}`);
};

export const remove = async (payload: FactoryIdPayload) => {
    return await api.delete(`/factories/${payload.id}`);
};
