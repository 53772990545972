import { FC, ReactNode, useCallback, useEffect } from 'react';
import { Button, Form, FormProps, Input, Table, Tooltip, Typography } from 'antd';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { CloseCircleOutlined, DownloadOutlined, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { useModal } from '@ebay/nice-modal-react';

import '../../assets/styles/Document.less';

import {
    Permission,
    PermissionRight,
    RoleName,
    ProviderCertification,
    SupportedLanguage,
} from '../../store/api/apiTypes';
import {
    details as getProviderCertificationsDetails,
    list as providerCertificationsList,
    getProviderCertificationsListState,
} from '../../store/actions/providerCertifications';
import { downloadFromApi } from '../../store/api/providerCertifications';
import { getUser } from '../../store/actions/auth';

import Seo from '../../components/Seo';
import useQueryParams from '../../hooks/queryParams';
import TableHeader from '../../components/TableHeader';
import TitleAlt from '../../components/TitleAlt';
import genericMessages from '../../i18n/genericMessages';
import ProviderCertificationDrawer from './ProviderCertificationDrawer';
import { useActions, useDebounce } from '../../hooks';
import { hasPermission, hasRole } from '../../helpers/security';
import { downloadBlobFile, sortOrderConverter } from '../../helpers';
import ProviderCertificationFiberInfoDrawer from './ProviderCertificationFiberInfoDrawer';
import SelectDocumentYear from '../../components/form/SelectDocumentYear';
import SelectProviderCollection from '../../components/form/SelectProviderCollection';
import LabelWithTooltipIcon from '../../components/LabelWithTooltipIcon';
import SelectDocumentCites from '../../components/form/SelectDocumentCites';
import { getFiberFilename } from '../../helpers/filename';
import ProviderCertificationDocumentDrawer from './ProviderCertificationDocumentDrawer';

const ProviderCertificationsList: FC = () => {
    const { formatMessage } = useIntl();
    const itemsPerPage = 20;
    const [loadProviderCertifications, getDetails] = useActions([
        providerCertificationsList.trigger,
        getProviderCertificationsDetails.trigger,
    ]);
    const providerCertificationsListState = useSelector(getProviderCertificationsListState);
    const user = useSelector(getUser);
    const [filtersForm] = Form.useForm();
    const providerCertificationFiberInfoDrawer = useModal(ProviderCertificationFiberInfoDrawer);
    const providerCertificationDocumentDrawer = useModal(ProviderCertificationDocumentDrawer);
    const providerCertificationDrawer = useModal(ProviderCertificationDrawer);
    const [queryParams, setQueryParams] = useQueryParams('supplierCertificate');
    const searchParam = queryParams.get('search') ?? undefined;
    const year = queryParams.get('year') ?? undefined;
    const cites = queryParams.get('cites') ?? undefined;
    const providerCollection = queryParams.get('providerCollection') ?? undefined;
    const page = queryParams.get('page') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const search = useDebounce(searchParam, 350);
    const pageTitle = formatMessage({
        id: 'supplier_certificate_list.title',
        defaultMessage: 'Attestations fournisseurs',
        description: 'documents page menu item',
    });

    const canEdit =
        hasRole(user, [RoleName.adminProvider]) ||
        (hasRole(user, [RoleName.userProvider]) &&
            hasPermission(user, Permission.documentExchange, PermissionRight.write));

    const startDownload = async (orderToDownload: ProviderCertification) => {
        const downloadedFile = await downloadFromApi({ id: orderToDownload.id });

        if (downloadedFile && orderToDownload.fibers) {
            const fileName = getFiberFilename(orderToDownload.fibers, orderToDownload, user?.language);
            if ((orderToDownload.fibers?.filter((fiber) => fiber.citesFile) || []).length > 1) {
                downloadBlobFile(fileName, downloadedFile, 'application/zip');
            } else {
                downloadBlobFile(fileName, downloadedFile, 'application/pdf');
            }
        }
    };

    const onProviderCertificationDetailsClick = (document: ProviderCertification) => {
        if (document.fibers && document.fibers.length > 0) {
            providerCertificationFiberInfoDrawer.show({
                providerCertificationId: document.id,
            });
        } else {
            getDetails({ id: document.id });
            providerCertificationDocumentDrawer.show({
                document: document,
            });
        }
    };

    const onTableChange: TableProps<ProviderCertification>['onChange'] = (pagination, filters, sorter: any) => {
        setQueryParams({
            sort: sorter.column?.dataIndex ? sorter.column?.dataIndex : undefined,
            sortOrder: sorter.order ? sortOrderConverter(sorter.order) : undefined,
            page: pagination.current ? pagination.current - 1 : 0,
        });
    };

    const columns: Array<ColumnProps<ProviderCertification>> = [
        {
            key: 'name',
            title: formatMessage(genericMessages.documentName),
            render: (_, record) => (
                <>
                    <div className="flex">
                        {record.validationStatus !== undefined && !record.validationStatus && (
                            <Tooltip
                                title={formatMessage({
                                    id: 'CloseCircleOutlined.tooltip',
                                    defaultMessage: 'Document refusé',
                                    description: 'tooltip label',
                                })}
                            >
                                <CloseCircleOutlined
                                    style={{ color: 'red', fontSize: 24, alignSelf: 'center', marginRight: 5 }}
                                />
                            </Tooltip>
                        )}
                        <Button
                            className="uppercase no-underline w-full "
                            type="link"
                            onClick={() => onProviderCertificationDetailsClick(record)}
                        >
                            <Typography.Text
                                ellipsis={{
                                    tooltip: record.name,
                                }}
                            >
                                {record.name}
                            </Typography.Text>
                        </Button>
                    </div>
                </>
            ),
            sorter: true,
            ellipsis: true,
            width: 160,
        },
        {
            key: 'brands',
            title: formatMessage({
                id: 'supplier_certificate_list.column.title.univers',
                defaultMessage: 'Univers',
                description: 'table header',
            }),
            render: (_, record) => record.brands?.map((brand) => brand.name).join(', '),
        },
        {
            key: 'supplierTypes',
            title: formatMessage({
                id: 'supplier_certificate_list.column.title.supplier_types',
                defaultMessage: 'Type(s) fournisseur',
                description: 'table header',
            }),
            render: (_, record) =>
                record.providerTypes
                    .map((providerType) => providerType.name[user?.language ?? SupportedLanguage.en])
                    .join(', '),
        },
        {
            dataIndex: 'providerCollection',
            title: formatMessage({
                id: 'supplier_certificate_list.column.title.providerCollection',
                defaultMessage: 'Saison',
                description: 'table header',
            }),
            render: (provider) => {
                return provider?.name[user?.language ?? SupportedLanguage.en];
            },
        },
        {
            dataIndex: 'year',
            title: formatMessage({
                id: 'supplier_certificate_list.column.title.year',
                defaultMessage: 'Année',
                description: 'table header',
            }),
            render: (year) => year || '-',
        },
        {
            dataIndex: 'reference',
            title: formatMessage({
                id: 'supplier_certificate_list.column.title.materialReference',
                defaultMessage: 'Réf. matière ou PF',
                description: 'table header',
            }),
        },
        {
            dataIndex: 'cites',
            title: formatMessage({
                id: 'supplier_certificate_list.column.title.cites',
                defaultMessage: 'CITES',
                description: 'table header',
            }),
            render: (cites) => {
                return cites ? formatMessage(genericMessages.yes) : formatMessage(genericMessages.no);
            },
            sorter: true,
        },
        {
            dataIndex: 'uploadDate',
            title: formatMessage(genericMessages.postedAtTableHeader),
            render: (_, record) => <FormattedDate value={record.uploadDate} />,
            sorter: true,
        },
        {
            key: 'actions',
            title: formatMessage(genericMessages.actions),
            width: 140,
            render: (_, record) => (
                <div className="space-x-3">
                    {canEdit && record.validationStatus === false ? (
                        <Button
                            type="primary"
                            icon={<EditOutlined />}
                            shape="circle"
                            onClick={() => onProviderCertificationDetailsClick(record)}
                        />
                    ) : (
                        <Button
                            type="primary"
                            icon={<EyeOutlined />}
                            shape="circle"
                            onClick={() => onProviderCertificationDetailsClick(record)}
                        />
                    )}
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<DownloadOutlined />}
                        onClick={startDownload.bind(null, record)}
                        disabled={!record.cites}
                    />
                </div>
            ),
        },
    ];
    const updateList = useCallback(() => {
        const params: any = {
            provider: user?.organization?.id,
            sort: sort ?? 'uploadDate',
            sortOrder: sortOrder ?? 'desc',
            pageSize: itemsPerPage,
            page,
            year,
            cites,
            providerCollection,
            search,
        };

        loadProviderCertifications(params);
        filtersForm.setFieldsValue(params);
    }, [
        filtersForm,
        loadProviderCertifications,
        user?.organization?.id,
        page,
        sort,
        sortOrder,
        year,
        cites,
        providerCollection,
        search,
    ]);

    const onFiltersChange: FormProps['onValuesChange'] = (change, values) => {
        setQueryParams({
            ...values,
            page: 0,
        });
    };

    useEffect(() => {
        updateList();
    }, [updateList]);

    return (
        <>
            <Seo title={pageTitle} />
            <div className="dflex justify-between items-center mb-6">
                <TitleAlt style={{ fontWeight: 'normal' }} className="mb-0">
                    <LabelWithTooltipIcon
                        label={
                            <FormattedMessage
                                id="supplier_certificate_list.title.informations"
                                defaultMessage={`{count, plural, =0 {<bold>Aucune attestation fournisseur</bold> envoyée.} one {<bold>{count, number} attestation fournisseur</bold> envoyée.} other {<bold>{count, number} attestations fournisseurs</bold> envoyées.}}`}
                                description="supplier certificates list title"
                                values={{
                                    count: providerCertificationsListState.data?.totalCount ?? 0,
                                    bold: (chunk: ReactNode) => <Typography.Text strong>{chunk}</Typography.Text>,
                                }}
                            />
                        }
                        tooltip={
                            <FormattedMessage
                                id="supplier_certificate_list.text.intro"
                                defaultMessage="L'attestation fournisseur est émise par vos soins et vise à donner l'origine, le nom scientifique et la source de la matière première par référence tissu ou matière Christian Dior Couture. Vous pouvez également télécharger les liasses CITES ainsi que les certificats d'origine (document douanier)."
                                description="supplier certificates intro text"
                            />
                        }
                    />
                </TitleAlt>
                {canEdit && (
                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        onClick={() => {
                            providerCertificationDrawer.show().then(() => {
                                updateList();
                            });
                        }}
                    >
                        <FormattedMessage
                            id="supplier_certificate_list.cta"
                            defaultMessage="Envoyer nouvelle attestation"
                            description="supplier certificates CTA"
                        />
                    </Button>
                )}
            </div>
            <Form form={filtersForm} onValuesChange={onFiltersChange}>
                <TableHeader>
                    <Form.Item name="search" style={{ width: '100%' }}>
                        <Input
                            placeholder={formatMessage({
                                id: 'supplier_certificate_list.table_header.input.search',
                                defaultMessage: 'Rechercher une attestation fournisseur',
                                description: 'Search input placeholder',
                            })}
                            suffix={<SearchOutlined className="font-16" />}
                            allowClear
                        />
                    </Form.Item>

                    <Form.Item name="providerCollection">
                        <SelectProviderCollection
                            placeholder={formatMessage({
                                id: 'supplier_certificate_list.table_header.input.season',
                                defaultMessage: 'Saison',
                                description: 'Year input placeholder',
                            })}
                            allowClear
                            style={{ width: '160px' }}
                        />
                    </Form.Item>

                    <Form.Item name="year">
                        <SelectDocumentYear
                            placeholder={formatMessage({
                                id: 'supplier_certificate_list.table_header.input.year',
                                defaultMessage: 'Année',
                                description: 'Year input placeholder',
                            })}
                            allowClear
                            style={{ width: '160px' }}
                        />
                    </Form.Item>
                    <Form.Item name="cites">
                        <SelectDocumentCites
                            placeholder={formatMessage({
                                id: 'supplier_certificate_list.table_header.input.cites',
                                defaultMessage: 'CITES',
                                description: 'CITES input placeholder',
                            })}
                            allowClear
                            style={{ width: '160px' }}
                        />
                    </Form.Item>
                </TableHeader>
            </Form>
            <Table<ProviderCertification>
                columns={columns}
                loading={providerCertificationsListState.loading}
                dataSource={providerCertificationsListState.data?.items}
                pagination={{
                    total: providerCertificationsListState.data?.totalCount,
                    pageSize: itemsPerPage,
                    hideOnSinglePage: true,
                }}
                rowKey={(record) => record.id}
                rowClassName={(record) => {
                    if (record.validationStatus !== undefined && !record.validationStatus) {
                        return 'document-row-refused';
                    }
                    return '';
                }}
                onChange={onTableChange}
            />
        </>
    );
};

export default ProviderCertificationsList;
