import { combineReducers } from 'redux';
import { apiInfos as apiInfosApiCall, ApiInfos } from '../api';
import { requestReducer } from '../helpers';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { call, delay, put, race, take, takeLatest } from 'redux-saga/effects';
import { EzeeAction } from '../helpers/EzeeAction';

export interface ApiInfosState {
    infos: RequestState<ApiInfos>;
}

const initialState: ApiInfosState = {
    infos: {
        data: undefined,
        loading: false,
    },
};

export const infos = new EzeeAsyncAction<ApiInfosState['infos'], null, ApiInfos>(
    'api/infos',
    initialState.infos,
    requestReducer<ApiInfosState['infos'], ApiInfos>(initialState.infos)
);

export const polling = new EzeeAction(
    'api_infos',
    {},
    {
        stopPolling: () => ({}),
        startPolling: () => ({}),
    }
);

export const apiInfosReducer = combineReducers<ApiInfosState>({
    infos: infos.reducer,
});

export function* apiInfosSaga() {
    yield takeLatest(infos.type.trigger, infosSaga);
}

function* infosSaga() {
    yield put(polling.actions.startPolling());
    yield race([call(pollTask), take(polling.actions.stopPolling().type)]);
}

function* pollTask() {
    while (true) {
        try {
            const response = yield call(apiInfosApiCall);

            yield put(infos.success(response));
            yield delay(60000);
        } catch (error) {
            yield put(infos.failure(error));
            yield delay(60000);
        }
    }
}

export const getApiInfosState = (state: MainReducerState) => state.apiInfos;
export const getApiInfosInfosState = (state: MainReducerState) => state.apiInfos.infos;
