import { Select, SelectProps } from 'antd';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { CertificateType } from '../../store/api/apiTypes';
import { materialCertificateTypeMessages } from '../../i18n/materialCertificateTypeMessages';

type SelectCertificateTypeProps = SelectProps<any>;

const SelectCertificateType: FC<SelectCertificateTypeProps> = ({ ...props }) => {
    return (
        <Select {...props} allowClear>
            {Object.values(CertificateType).map((type) => (
                <Select.Option value={type} key={type}>
                    <FormattedMessage {...materialCertificateTypeMessages.get(type)} />
                </Select.Option>
            ))}
        </Select>
    );
};

export default SelectCertificateType;
