import { Select, SelectProps } from 'antd';
import { FC } from 'react';
import dayjs from 'dayjs';

type SelectDocumentYearProps = SelectProps<any> & {
    yearsNumber?: number;
};

const SelectDocumentYear: FC<SelectDocumentYearProps> = ({ yearsNumber = 6, ...props }) => {
    // by default 2 years before and 3 years after current year
    const optionYears = Array.from({ length: yearsNumber }, (_, index) =>
        dayjs().subtract(2, 'years').add(index, 'year').get('year')
    );
    return (
        <Select {...props} allowClear>
            {optionYears.map((year) => (
                <Select.Option value={year} key={year}>
                    {year}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SelectDocumentYear;
