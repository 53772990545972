import { FC } from 'react';
import { Button, Form } from 'antd';
import { ButtonProps } from 'antd/lib/button';

const ButtonSubmit: FC<ButtonProps> = ({ children, ...rest }) => (
    <Form.Item className="cta-submit">
        <Button type="primary" size="large" shape="round" block htmlType="submit" {...rest}>
            {children}
        </Button>
    </Form.Item>
);

export default ButtonSubmit;
