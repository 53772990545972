import { FC } from 'react';
import Icon from '@ant-design/icons';
// tslint:disable-next-line: no-submodule-imports
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 18 18">
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M17.67.33c.434.433.44 1.131.019 1.572L10.59 9l7.08 7.08a1.125 1.125 0 01-1.573 1.609L9 10.59l-7.08 7.08a1.125 1.125 0 01-1.609-1.573L7.41 9 .329 1.92A1.125 1.125 0 011.903.311L9 7.41l7.08-7.08c.439-.439 1.151-.439 1.59 0z"
        />
    </svg>
);

const IconCross: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-cross${props.className ? ` ${props.className}` : ''}`} />
);

export default IconCross;
