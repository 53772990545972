import { FC } from 'react';
import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';

import '../assets/styles/TitleAlt.less';
import { classNames } from '../helpers';

const TitleAlt: FC<TitleProps> = ({ children, level = 1, ...props }) => (
    <Typography.Title level={level} {...props} className={classNames('title-alt', props.className)}>
        {children}
    </Typography.Title>
);

export default TitleAlt;
