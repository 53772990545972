import { Select, SelectProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Translation, ValueListItem, ValueListSlug } from '../../store/api/apiTypes';
import { useActions } from '../../hooks';
import { getSelectValue, selectValueOnChange } from '../../helpers/select';
import { getActivities, getSubContractorsActivitiesState } from '../../store/actions/SubContractors';
import Translate from '../Translate';

type SelectSubcontractorActivityProps = SelectProps<any> & {
    onChange?: (value?: ValueListItem['id']) => void;
    valueType?: 'id' | 'object';
};

const SelectSubcontractorActivity: FC<SelectSubcontractorActivityProps> = ({
    onChange,
    valueType = 'id',
    ...props
}) => {
    const [value, setValue] = useState<ValueListItem['id']>();
    const [search] = useActions([getActivities.trigger]);
    const searchState = useSelector(getSubContractorsActivitiesState);
    const list = searchState?.data?.items ?? [];

    const onChangeSelect: SelectProps<ValueListItem['id']>['onChange'] = (newValue) => {
        setValue(newValue);
        if (onChange) {
            onChange(selectValueOnChange(newValue, props.mode, valueType, list));
        }
    };

    const onFocus: SelectProps<ValueListItem['id']>['onFocus'] = () => {
        search({
            valueListSlug: ValueListSlug.subContractorActivities,
            search: null,
        });
    };

    useEffect(() => {
        search({
            valueListSlug: ValueListSlug.subContractorActivities,
            search: null,
        });
    }, [search]);

    useEffect(() => {
        setValue(getSelectValue(props.value));
    }, [props.value]);

    return (
        <Select
            {...props}
            value={searchState.loading ? undefined : value}
            onChange={onChangeSelect}
            onFocus={onFocus}
            allowClear
        >
            {list.map((activity) => (
                <Select.Option value={activity.id} key={activity.id}>
                    {<Translate text={activity?.fields?.name as Translation} />}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SelectSubcontractorActivity;
