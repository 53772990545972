import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Result } from 'antd';
import { ResultProps } from 'antd/lib/result';

import Seo from '../../components/Seo';
import ButtonLink from '../../components/ButtonLink';
import { getRoute, RoutePathName } from '../../routes';
import genericMessages from '../../i18n/genericMessages';

const ErrorPage: FC = () => {
    const { formatMessage } = useIntl();
    const { 0: param } = useParams<{ 0?: string }>();
    let statusCode = parseInt(param?.substring(1) ?? '', 10);
    let status: ResultProps['status'] = 'error';
    let title: ResultProps['title'] = 'Erreur';
    let subTitle: ResultProps['subTitle'] = formatMessage(genericMessages.defaultError);

    if (isNaN(statusCode)) {
        statusCode = 404;
    }

    if (`${statusCode}`.length === 3 && `${statusCode}`.startsWith('5')) {
        status = '500';
        title = `${statusCode}`;
    } else if (statusCode === 403 || statusCode === 401) {
        status = '403';
        title = statusCode;
        subTitle =
            statusCode === 403
                ? formatMessage({
                      id: 'error_page.403.forbidden',
                      defaultMessage: "Nous sommes désolés, vous n'êtes pas autorisé à accéder à cette ressource.",
                      description: 'forbidden',
                  })
                : formatMessage({
                      id: 'error_page.403.authentification_required',
                      defaultMessage:
                          'Nous sommes désolés, vous devez être authentifié pour accéder à cette ressource.',
                      description: 'authentication required',
                  });
    } else if (statusCode === 400) {
        title = statusCode;
        subTitle = formatMessage({
            id: 'error_page.400',
            defaultMessage: 'Nous sommes désolés, les informations envoyées ne sont pas valides.',
            description: 'invalid informations provided',
        });
    } else {
        status = '404';
        title = 404;
        subTitle = formatMessage({
            id: 'error_page.404',
            defaultMessage: "Nous sommes désolés, la page que vous cherchez n'existe pas.",
            description: '404',
        });
    }

    return (
        <>
            <Seo title={`${statusCode}`} />
            <Result
                status={status}
                title={title}
                subTitle={subTitle}
                extra={
                    <ButtonLink to={getRoute(RoutePathName.home)} type="primary">
                        <FormattedMessage
                            id="error_page.button_link.return"
                            defaultMessage="Retourner à l'accueil"
                            description="Back to home page button"
                        />
                    </ButtonLink>
                }
            />
        </>
    );
};

export default ErrorPage;
