import { call, put, takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeSimpleAction } from '../helpers/EzeeSimpleAction';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { User, PreHook } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';
import { update as updateOrganization } from '../api/organizations';
import { update as updateAction } from './organizations';
import {
    LoginPayload,
    login as loginApiCall,
    logout as logoutApiCall,
    checkLoginStatus as checkLoginStatusApiCall,
    forgottenPassword as forgottenPasswordApiCall,
    resetPassword as resetPasswordApiCall,
    preHook as preHookApiCall,
    resendInvitation as resendInvitationApiCall,
    update as updateApiCall,
    ForgottenPasswordPayload,
    ResetPasswordPayload,
    PreHookPayload,
    UpdateMePayload,
    ResendInvitationPayload,
} from '../api/auth';
import { DataAction } from '../helpers/EzeeAction';
import { requestReducer } from '../helpers';
import { combineReducers } from 'redux';
// State
type PreHookResponseType = PreHook | Record<string, any>;
export interface AuthState {
    user?: User;
    forgottenPasswordError?: RequestState['error'];
    forgottenPasswordSuccess: boolean;
    resetPasswordError?: RequestState['error'];
    resetPasswordSuccess: boolean;
    resendInvitationError?: RequestState['error'];
    resendInvitationSuccess: boolean;
    hasCheckedLoginStatus: boolean;
    isConnected: boolean;
    loading: boolean;
    error?: any;
    acceptTermsError?: any;
    updateLoading: boolean;
    updateError?: any;
}

const initialState: AuthState = {
    forgottenPasswordSuccess: false,
    resetPasswordSuccess: false,
    resendInvitationSuccess: false,
    hasCheckedLoginStatus: false,
    isConnected: false,
    loading: false,
    updateLoading: false,
};

export interface PreHookState {
    preHook: RequestState<PreHookResponseType>;
}

const initialPreHookState: PreHookState = {
    preHook: {
        loading: false,
    },
};

// Actions/Reducers

export const login = new EzeeAsyncAction<AuthState, LoginPayload>('auth/login', initialState, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        user: payload,
        hasCheckedLoginStatus: true,
        isConnected: true,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
        error: payload,
    }),
    reset: () => initialState,
});

export const logout = new EzeeAsyncAction<AuthState>('auth/logout', initialState, {
    trigger: (state) => ({
        ...state,
        loading: true,
    }),
    success: (state) => ({
        ...state,
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
    }),
    failure: (state) => ({
        ...state,
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
    }),
    reset: () => ({
        ...initialState,
    }),
});

export const checkLoginStatus = new EzeeAsyncAction<AuthState, any, User>('auth/checkLoginStatus', initialState, {
    trigger: (state) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        ...state,
        user: payload,
        hasCheckedLoginStatus: true,
        isConnected: true,
        loading: false,
    }),
    failure: (state) => ({
        ...state,
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
    }),
    reset: () => ({
        ...initialState,
    }),
});

export const forgottenPassword = new EzeeAsyncAction<AuthState, ForgottenPasswordPayload, User>(
    'auth/forgottenPassword',
    initialState,
    {
        trigger: (state) => ({
            ...state,
            loading: true,
            forgottenPasswordSuccess: false,
            forgottenPasswordError: undefined,
        }),
        success: (state) => ({
            ...state,
            forgottenPasswordSuccess: true,
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            forgottenPasswordSuccess: false,
            forgottenPasswordError: payload,
            loading: false,
        }),
        reset: () => ({
            ...initialState,
        }),
    }
);

export const resetPassword = new EzeeAsyncAction<AuthState, ResetPasswordPayload, User>(
    'auth/resetPassword',
    initialState,
    {
        trigger: (state) => ({
            ...state,
            loading: true,
            resetPasswordSuccess: false,
            resetPasswordError: undefined,
        }),
        success: (state) => ({
            ...state,
            resetPasswordSuccess: true,
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            resetPasswordSuccess: false,
            resetPasswordError: payload,
            loading: false,
        }),
        reset: () => ({
            ...initialState,
        }),
    }
);

export const resendInvitation = new EzeeAsyncAction<AuthState, ResendInvitationPayload, User>(
    'auth/resendInvitation',
    initialState,
    {
        trigger: (state) => ({
            ...state,
            loading: true,
            resendInvitationSuccess: false,
            resendInvitationError: undefined,
        }),
        success: (state) => ({
            ...state,
            resendInvitationSuccess: true,
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            resendInvitationSuccess: false,
            resendInvitationError: payload,
            loading: false,
        }),
        reset: () => ({
            ...initialState,
        }),
    }
);

export const update = new EzeeAsyncAction<AuthState, UpdateMePayload, User>('auth/update', initialState, {
    trigger: (state) => ({
        ...state,
        updateLoading: true,
        updateError: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        user: payload,
        updateLoading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        updateLoading: false,
        updateError: payload,
    }),
    reset: () => ({
        ...initialState,
    }),
});

export const preHook = new EzeeAsyncAction<PreHookState['preHook'], PreHookPayload, PreHookResponseType>(
    'auth/preHook',
    initialPreHookState.preHook,
    requestReducer<PreHookState['preHook'], PreHookResponseType>(initialPreHookState.preHook)
);

// Reducer

export const authReducer = EzeeSimpleAction.mergeActionReducers<AuthState>([
    checkLoginStatus,
    forgottenPassword,
    login,
    logout,
    resendInvitation,
    resetPassword,
    update,
]);

export const preHooksReducer = combineReducers<PreHookState>({
    preHook: preHook.reducer,
});
// Saga

export function* authSaga() {
    yield takeLatest(checkLoginStatus.type.trigger, simpleAsyncSaga(checkLoginStatusApiCall, checkLoginStatus));
    yield takeLatest(forgottenPassword.type.trigger, simpleAsyncSaga(forgottenPasswordApiCall, forgottenPassword));
    yield takeLatest(login.type.trigger, simpleAsyncSaga(loginApiCall, login));
    yield takeLatest(logout.type.trigger, simpleAsyncSaga(logoutApiCall, logout));
    yield takeLatest(resendInvitation.type.trigger, simpleAsyncSaga(resendInvitationApiCall, resendInvitation));
    yield takeLatest(resetPassword.type.trigger, simpleAsyncSaga(resetPasswordApiCall, resetPassword));
    yield takeLatest(preHook.type.trigger, simpleAsyncSaga(preHookApiCall, preHook));
    yield takeLatest(update.type.trigger, updateMeSaga);
}

function* updateMeSaga(actionData: DataAction) {
    try {
        if (actionData.payload.organization) {
            const organizationResponse = yield call(updateOrganization, actionData.payload.organization);
            yield put(updateAction.success(organizationResponse));
        }
        const response = yield call(updateApiCall, actionData.payload);
        return yield put(update.success(response));
    } catch (error) {
        return yield put(update.failure(error));
    }
}

// Store helpers
export const getPreHookState = (state: MainReducerState) => state.preHook.preHook;
export const getAuthState = (state: MainReducerState) => state.auth;
export const getUser = (state: MainReducerState) => state.auth.user;
export const getUpdateUser = (state: MainReducerState) => ({
    loading: state.auth.updateLoading,
    error: state.auth.updateError,
});
