import { FC } from 'react';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

const RequiredFields: FC = () => (
    <Typography.Text>
        <FormattedMessage id="required_fields.text" defaultMessage="* Champs obligatoires" description="form help" />
    </Typography.Text>
);

export default RequiredFields;
