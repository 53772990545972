import { FC, Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, Drawer, DrawerProps, Form, Typography } from 'antd';

import { User } from '../store/api/apiTypes';
import { getUsersListState, getUsersRemoveState, list as userList, remove as userRemove } from '../store/actions/users';
import UserFormDrawer from './UserFormDrawer';
import UserInfos from './UserInfos';
import { useSelector } from 'react-redux';
import { useActions, usePrevious } from '../hooks';

const UserListDrawer: FC<DrawerProps> = ({ onClose, ...props }) => {
    const { formatMessage } = useIntl();
    const [isFormDrawerVisible, setIsFormDrawerVisible] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<User['id'] | undefined>();
    const [loadUsers, remove] = useActions([userList.trigger, userRemove.trigger]);
    const removeUserState = useSelector(getUsersRemoveState);
    const usersListState = useSelector(getUsersListState);
    const previous = usePrevious({ removeUserState });
    const [form] = Form.useForm();
    const onClickEdit = (user: User) => {
        setSelectedUserId(user.id);
        setIsFormDrawerVisible(true);
    };
    const onClickDelete = (user: User) => {
        remove({ id: user.id });
    };
    const onClickAddUser = () => {
        setIsFormDrawerVisible(true);
    };
    const onAfterVisibleChange: DrawerProps['afterVisibleChange'] = (visible) => {
        if (!visible) {
            form.resetFields();
        }
    };
    const onDrawerClose = () => {
        setIsFormDrawerVisible(false);
        setSelectedUserId(undefined);
        loadUsers({ sort: 'createdAt', sortOrder: 'desc' });
    };
    useEffect(() => {
        loadUsers({ sort: 'createdAt', sortOrder: 'desc' });
    }, [loadUsers]);
    useEffect(() => {
        if (previous?.removeUserState.loading && !removeUserState.loading) {
            loadUsers({ sort: 'createdAt', sortOrder: 'desc' });
        }
    }, [previous, loadUsers, removeUserState]);
    return (
        <Drawer
            width={500}
            title={formatMessage({
                id: 'user_infos_drawer.title',
                defaultMessage: 'Utilisateurs',
                description: 'Drawer title',
            })}
            onClose={onClose}
            afterVisibleChange={onAfterVisibleChange}
            {...props}
            footer={
                <Button onClick={onClickAddUser} size="large" shape="round" ghost block>
                    <FormattedMessage
                        id="user_infos_drawer.button_add"
                        defaultMessage="Ajouter un utilisateur"
                        description="Call to action"
                    />
                </Button>
            }
        >
            <Typography.Paragraph>
                <p>
                    <FormattedMessage
                        id="user_infos_drawer.paragraph"
                        defaultMessage="En tant qu'administrateur, vous êtes en charge de désigner qui dans votre entreprise peut avoir accès à la plateforme."
                        description="users list info text"
                    />
                </p>
            </Typography.Paragraph>
            <Divider />
            {usersListState.data?.items.map((user) => (
                <Fragment key={user.id}>
                    <UserInfos user={user} edit onEdit={onClickEdit} onDelete={onClickDelete} />
                    <Divider />
                </Fragment>
            ))}
            <UserFormDrawer visible={isFormDrawerVisible} onClose={onDrawerClose} userId={selectedUserId} />
        </Drawer>
    );
};

export default UserListDrawer;
