import { Select, SelectProps } from 'antd';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import genericMessages from '../../i18n/genericMessages';

type SelectDocumentCitesProps = SelectProps<any>;

const SelectDocumentCites: FC<SelectDocumentCitesProps> = ({ ...props }) => {
    const { formatMessage } = useIntl();
    return (
        <Select {...props} allowClear>
            <Select.Option value={'true'} key={'true'}>
                {formatMessage(genericMessages.yes)}
            </Select.Option>
            <Select.Option value={'false'} key={'false'}>
                {formatMessage(genericMessages.no)}
            </Select.Option>
        </Select>
    );
};

export default SelectDocumentCites;
