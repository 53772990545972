import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import * as React from 'react';
import { useIntl } from 'react-intl';

const EditOutlinedWithToolTip = () => {
    const { formatMessage } = useIntl();
    return (
        <Tooltip
            title={formatMessage({
                id: 'edit_outlined_with_tooltip.tooltip.edit.title',
                defaultMessage: 'Modifier',
                description: 'tooltip text',
            })}
        >
            <EditOutlined />
        </Tooltip>
    );
};

export default EditOutlinedWithToolTip;
