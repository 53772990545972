import { FC, useEffect, KeyboardEvent, MouseEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, Drawer, DrawerProps, Form, FormProps, Input } from 'antd';
import { useSelector } from 'react-redux';

import formMessages from '../i18n/formMessages';
import validatePasswordRules from '../helpers/passwords';
import UserAccountFields from './UserAccountFields';
import PasswordInput from './PasswordInput';
import { update as updateAuth, getUser, getUpdateUser } from '../store/actions/auth';
import TitleAlt from './TitleAlt';
import { useActions, usePrevious } from '../hooks';
import { errorMessage, successMessage } from '../helpers/message';
import genericMessages from '../i18n/genericMessages';

const UserProfileDrawer: FC<DrawerProps> = ({ onClose, ...props }) => {
    const { formatMessage } = useIntl();
    const update = useActions(updateAuth.trigger);
    const [form] = Form.useForm();
    const user = useSelector(getUser);
    const useUpdateState = useSelector(getUpdateUser);
    const [validate, setValidator] = useState<string | undefined>('onSubmit');
    const previous = usePrevious({ useUpdateState });
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        update({ ...values.user, password: values.password, oldPassword: values.oldPassword });
    };
    const onAfterVisibleChange: DrawerProps['afterVisibleChange'] = (visible) => {
        if (!visible) {
            form.resetFields();
        }
    };
    const onSubmit = () => {
        setValidator(undefined);
    };
    useEffect(() => {
        // si loading ÉTAIT true, et que MAINTENANT il est false, cela veut dire que la requête est finie
        if (previous?.useUpdateState.loading && !useUpdateState.loading && props.visible) {
            if (useUpdateState.error) {
                errorMessage({
                    content:
                        useUpdateState.error.status === 409
                            ? formatMessage(genericMessages.newPasswordError)
                            : useUpdateState.error.data.error_code === 'wrong_password'
                            ? formatMessage(genericMessages.oldPasswordError)
                            : useUpdateState.error.data.error_code === 'enter_old_password'
                            ? formatMessage(genericMessages.enterOldPasswordError)
                            : formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'user_profil_drawer.success_message',
                        defaultMessage: 'profil édité avec succès',
                        description: 'profil Success Message',
                    }),
                });
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
        }
    });
    return (
        <Drawer
            width={500}
            title={formatMessage({
                id: 'user_profil_drawer.title',
                defaultMessage: 'Gérer votre profil',
                description: 'Drawer title',
            })}
            onClose={onClose}
            afterVisibleChange={onAfterVisibleChange}
            {...props}
        >
            <Form
                form={form}
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    user,
                }}
                scrollToFirstError
            >
                <UserAccountFields />
                {!user?.ssoEmail && (
                    <>
                        <Divider />
                        <TitleAlt level={4}>
                            <FormattedMessage
                                id="user_profil_drawer.form.title.password"
                                defaultMessage="Modifier le mot de passe associé"
                                description="form section title"
                            />
                        </TitleAlt>
                        <Form.Item
                            label={formatMessage({
                                id: 'user_profil_drawer.form.item.password',
                                defaultMessage: 'Mot de passe actuel',
                                description: 'field label',
                            })}
                            name="oldPassword"
                        >
                            <Input.Password
                                placeholder={formatMessage({
                                    id: 'user_profil_drawer.form.item.placeholder.password',
                                    defaultMessage: 'Saisir votre mot de passe',
                                    description: 'field placeholder',
                                })}
                            />
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({
                                id: 'user_profil_drawer.form.item.newpassword',
                                defaultMessage: 'Nouveau mot de passe',
                                description: 'field label',
                            })}
                            rules={[
                                {
                                    validator: async (_, value) => {
                                        if (!validatePasswordRules(value)) {
                                            return await Promise.resolve();
                                        }
                                        return await Promise.reject(formatMessage(formMessages.invalidPassword));
                                    },
                                },
                            ]}
                            name="password"
                            validateTrigger={validate}
                        >
                            <PasswordInput
                                placeholder={formatMessage({
                                    id: 'user_profil_drawer.form.title.placeholder_newpassword',
                                    defaultMessage: 'Saisir votre nouveau mot de passe',
                                    description: 'field placeholder',
                                })}
                            />
                        </Form.Item>
                    </>
                )}

                <Divider />
                <Button onClick={onSubmit} type="primary" htmlType="submit" shape="round" size="large" block>
                    {formatMessage({
                        id: 'user_profil_drawer.form.button.edit',
                        defaultMessage: 'Enregistrer les modifications',
                        description: 'Edit form submit button',
                    })}
                </Button>
            </Form>
        </Drawer>
    );
};

export default UserProfileDrawer;
