import { Action, ActionCreator, Reducer, AnyAction } from 'redux';

interface GenericState {
    [key: string]: any;
}

export interface DataAction<T = any, A = string> extends Action<A> {
    payload: T;
    meta?: ActionMeta;
}

export interface ActionMeta {
    throttling?: number;
    [key: string]: any;
}

interface CustomActionType {
    name: string;
    actionTypes: { [actionType: string]: string };
    match: (type: string) => boolean;
    typeName: (actionType: string) => string;
}

export class EzeeCustomActionType implements CustomActionType {
    public name: string;
    public actionTypes: { [type: string]: string };

    constructor(name: string, actionTypes: string[]) {
        this.name = name.toUpperCase();
        this.actionTypes = actionTypes.reduce(
            (res, actionType) => ({
                ...res,
                [actionType]: `${this.name}_${actionType.toUpperCase()}`,
            }),
            {}
        );
    }

    public match(type: string) {
        return Object.values(this.actionTypes).includes(type);
    }

    public typeName(actionType: string) {
        return `${this.name}_${actionType.toUpperCase()}`;
    }
}

export type EzeeActionReducerFunc<State extends GenericState> = (state: State, ...args: any) => State;
export type EzeeCustomActionReducerFunc<State extends GenericState, Payload> = (
    state: State,
    payload: Payload
) => State;
export interface EzeeCustomActionReducer<State extends GenericState> {
    [actionType: string]: EzeeActionReducerFunc<State>;
}

export class EzeeAction<
    State extends GenericState,
    Payload = any,
    ActionType extends CustomActionType = CustomActionType,
    ActionReducerType extends EzeeCustomActionReducer<State> = EzeeCustomActionReducer<State>
> {
    public static mergeActionReducers<State>(actions: Array<EzeeAction<State>>): Reducer<State> {
        return (state: State | undefined, actionData: AnyAction) => {
            const matchingAction = actions.find((a) => a.type.match(actionData.type));
            if (matchingAction) {
                return matchingAction.ezeeReduce(state, actionData);
            }
            if (!state) {
                // console.warn(`Unknown action type "${actionData.type}" and empty inital state`);
                return actions.reduce(
                    (res: any, action) => ({
                        ...res,
                        ...action.ezeeReduce(state, actionData),
                    }),
                    {}
                );
            }
            return state;
        };
    }

    public type: ActionType;
    public actions: { [actionType: string]: ActionCreator<DataAction<Payload>> };

    protected actionReducer: ActionReducerType;
    protected initialState: State;
    protected name: string;

    constructor(name: string, initialState: State, actionReducer: ActionReducerType) {
        const actionTypes = Object.keys(actionReducer);
        this.name = name.toUpperCase();
        this.type = new EzeeCustomActionType(this.name, actionTypes) as ActionType;
        this.initialState = initialState;
        this.actionReducer = actionReducer;

        this.actions = actionTypes.reduce(
            (res: any, actionType) => ({
                ...res,
                [actionType]: (payload: Payload, meta?: ActionMeta) => ({
                    type: this.type.actionTypes[actionType],
                    payload,
                    meta,
                }),
            }),
            {}
        );
    }

    public ezeeReduce(state: State | undefined, actionState: AnyAction): State {
        if (!state) {
            state = this.initialState;
        }
        for (const actionType of Object.keys(this.type.actionTypes)) {
            if (actionState.type === this.type.actionTypes[actionType]) {
                if (this.actionReducer[actionType]) {
                    return { ...this.actionReducer[actionType](state, actionState.payload, actionState.meta) };
                }
            }
        }
        return state;
    }

    public get reducer() {
        return this.ezeeReduce.bind(this);
    }
}
