import { Fiber, ProviderCertification, SupportedLanguage } from '../store/api/apiTypes';

export const getFiberFilename = (
    fibers: Fiber[],
    providerCertification: ProviderCertification,
    language?: SupportedLanguage
) => {
    if (!fibers.length) {
        throw new Error('Impossible to generate a filename without fibers.');
    }

    if (fibers.length > 1) {
        const fibersNames = fibers?.map((fiber) => fiber.name).join('-') ?? '';

        return `${providerCertification.cites ? 'CITES-' : ''}${providerCertification.provider.name}-${
            providerCertification.year
        }${`-${providerCertification?.providerCollection.name[language ?? SupportedLanguage.en] ?? ''}`}-${
            providerCertification.reference
        }-${fibersNames}.zip`;
    } else {
        return `${providerCertification.cites ? 'CITES-' : ''}${providerCertification.provider.name}-${
            providerCertification.year
        }${`-${providerCertification?.providerCollection.name[language ?? SupportedLanguage.en] ?? ''}`}-${
            providerCertification.reference
        }-${fibers[0].name}.pdf`;
    }
};
