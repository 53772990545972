import { createContext, useState, FC } from 'react';
import { SupportedLanguage } from '../store/api/apiTypes';

const availableLangs = Object.values(SupportedLanguage);
export const defaultLanguage = SupportedLanguage.fr;

const initialLanguage = availableLangs.includes(localStorage.getItem('locale') as SupportedLanguage)
    ? (localStorage.getItem('locale') as SupportedLanguage)
    : defaultLanguage;

interface LocaleContextTypes {
    locale: SupportedLanguage;
    setLocale: React.Dispatch<React.SetStateAction<SupportedLanguage>>;
}

export const LocaleContext = createContext<LocaleContextTypes>({
    locale: initialLanguage,
    setLocale: () => undefined,
});

export const LocaleContextProvider: FC = ({ children }) => {
    const [locale, setLocale] = useState(initialLanguage);

    const layoutContext = {
        locale,
        setLocale,
    };

    return <LocaleContext.Provider value={layoutContext}>{children}</LocaleContext.Provider>;
};
