import { FC, useEffect, useState } from 'react';
import { TreeSelect, TreeSelectProps } from 'antd';
import { BrandScope, BrandAndTypesIds, ProviderType } from '../../store/api/apiTypes';
import Translate from '../Translate';

type TreeSelectBrandAndTypeProps = TreeSelectProps<any> & {
    onChange?: (value?: BrandAndTypesIds[]) => void;
    value?: BrandScope[] | BrandAndTypesIds[];
    brandsAndTypes?: BrandScope[];
};

const TreeSelectBrandAndType: FC<TreeSelectBrandAndTypeProps> = ({ brandsAndTypes, ...props }) => {
    const [value, setValue] = useState<string[]>();
    const options = brandsAndTypes?.map((brand) => {
        return {
            key: brand.brand.id,
            title: <Translate text={brand.brand.name} />,
            value: brand.brand.id,
            children: brand.providerTypes?.map((providerType: ProviderType) => {
                return {
                    value: brand.brand.id + '-' + providerType.id,
                    title: <Translate text={providerType.name} />,
                };
            }),
        };
    });

    const formatValue = (values: string[]) => {
        const brands = [
            ...new Set(
                values.map((value) => {
                    const ids = value.split('-');
                    return ids[0];
                })
            ),
        ];

        const brandsAndTypes: BrandAndTypesIds[] = [];
        brands.forEach((brandId) => {
            const brandAndTypes: BrandAndTypesIds = {
                brand: brandId,
                providerTypes: [],
            };

            values.forEach((value) => {
                const ids = value.split('-');
                if (ids.length >= 2 && ids[0] === brandId) {
                    brandAndTypes.providerTypes.push(ids[1]);
                }
            });

            brandsAndTypes.push(brandAndTypes);
        });

        return brandsAndTypes;
    };

    const onChange: TreeSelectProps<any>['onChange'] = (value) => {
        setValue(value);

        if (props.onChange) {
            props.onChange(formatValue(value));
        }
    };

    useEffect(() => {
        const values: string[] = [];
        let needsUpdate = false;
        if (props.value) {
            props.value.forEach((brandAndType: any) => {
                brandAndType.providerTypes.forEach((providerType: any) => {
                    if (typeof brandAndType.brand === 'object') {
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        values.push(brandAndType.brand.id + '-' + providerType.id);
                        needsUpdate = true;
                    } else {
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        values.push(brandAndType.brand + '-' + providerType);
                    }
                });
            });

            if (needsUpdate) {
                if (props.onChange) {
                    props.onChange(formatValue(values));
                }
            }
        }
        setValue(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    return (
        <TreeSelect
            {...props}
            onChange={onChange}
            treeData={options}
            value={value}
            showCheckedStrategy="SHOW_ALL"
            treeCheckable
            treeDefaultExpandAll
            maxTagCount={2}
            allowClear
        />
    );
};

export default TreeSelectBrandAndType;
