import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { ProviderCertification, ListResponse, ValueListItem } from '../api/apiTypes';
import {
    ProviderCertificationIdPayload,
    ProviderCertificationListPayload,
    ProviderCertificationCreatePayload,
    ProviderCertificationUpdatePayload,
    list as listApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
    details as detailsApiCall,
    downloadAll as downloadAllApiCall,
    download as downloadApiCall,
    ProviderCertificationDownloadPayload,
} from '../api/providerCertifications';
import {
    ValueListItemDetailPayload,
    ValueListItemListPayload,
    list as getValueListItemApiCall,
    detail as getValueListItemDetailApiCall,
} from '../api/valueListItems';
import { DataAction } from '../helpers/EzeeAction';
import dayjs from 'dayjs';
import { downloadBlobFile } from '../../helpers';

// States
export interface ProviderCertificationsState {
    create: RequestState<ProviderCertification>;
    update: RequestState<ProviderCertification>;
    remove: RequestState<ProviderCertification>;
    details: RequestState<ProviderCertification>;
    list: RequestState<ListResponse<ProviderCertification>>;
    listCount: RequestState<ListResponse<ProviderCertification>>;
    getFiberSources: RequestState<ListResponse<ValueListItem>>;
    getFiberSource: RequestState<ValueListItem>;
    downloadAll: RequestState<string>;
    download: RequestState<string>;
}

const initialState: ProviderCertificationsState = {
    create: {
        loading: false,
    },
    update: {
        loading: false,
    },
    remove: {
        loading: false,
    },
    details: {
        loading: false,
    },
    list: {
        loading: false,
    },
    listCount: {
        loading: false,
    },
    getFiberSources: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 50,
            pageCount: 0,
        },
        loading: false,
    },
    getFiberSource: {
        loading: false,
    },
    downloadAll: {
        data: undefined,
        loading: false,
    },
    download: {
        data: undefined,
        loading: false,
    },
};

export const create = new EzeeAsyncAction<
    ProviderCertificationsState['create'],
    ProviderCertificationCreatePayload,
    ProviderCertification
>(
    'ProviderCertifications/create',
    initialState.create,
    requestReducer<ProviderCertificationsState['create'], ProviderCertification>(initialState.create)
);

export const update = new EzeeAsyncAction<
    ProviderCertificationsState['update'],
    ProviderCertification,
    ProviderCertificationUpdatePayload
>(
    'ProviderCertifications/update',
    initialState.update,
    requestReducer<ProviderCertificationsState['update'], ProviderCertification>(initialState.update)
);

export const remove = new EzeeAsyncAction<
    ProviderCertificationsState['remove'],
    ProviderCertificationIdPayload,
    ProviderCertification
>(
    'ProviderCertifications/remove',
    initialState.remove,
    requestReducer<ProviderCertificationsState['remove'], ProviderCertification>(initialState.remove)
);

export const details = new EzeeAsyncAction<
    ProviderCertificationsState['details'],
    ProviderCertificationIdPayload,
    ProviderCertification
>(
    'ProviderCertifications/details',
    initialState.details,
    requestReducer<ProviderCertificationsState['details'], ProviderCertification>(initialState.details)
);

export const downloadAll = new EzeeAsyncAction<
    ProviderCertificationsState['downloadAll'],
    ProviderCertificationIdPayload,
    ProviderCertification
>(
    'ProviderCertifications/downloadAll',
    initialState.downloadAll,
    requestReducer<ProviderCertificationsState['downloadAll'], ProviderCertification>(initialState.downloadAll)
);

export const download = new EzeeAsyncAction<
    ProviderCertificationsState['download'],
    ProviderCertificationDownloadPayload,
    ProviderCertification
>(
    'ProviderCertifications/download',
    initialState.download,
    requestReducer<ProviderCertificationsState['download'], ProviderCertification>(initialState.download)
);

export const list = new EzeeAsyncAction<
    ProviderCertificationsState['list'],
    ProviderCertificationListPayload,
    ListResponse<ProviderCertification>
>(
    'ProviderCertifications/list',
    initialState.list,
    requestReducer<ProviderCertificationsState['list'], ListResponse<ProviderCertification>>(initialState.list)
);

export const listCount = new EzeeAsyncAction<
    ProviderCertificationsState['listCount'],
    ProviderCertificationListPayload,
    ListResponse<ProviderCertification>
>(
    'ProviderCertifications/listCount',
    initialState.listCount,
    requestReducer<ProviderCertificationsState['listCount'], ListResponse<ProviderCertification>>(
        initialState.listCount
    )
);

export const getFiberSources = new EzeeAsyncAction<
    ProviderCertificationsState['getFiberSources'],
    ValueListItemListPayload,
    ListResponse<ValueListItem>
>(
    'ProviderCertifications/getFiberSources',
    initialState.getFiberSources,
    requestReducer<ProviderCertificationsState['getFiberSources'], ListResponse<ValueListItem>>(
        initialState.getFiberSources
    )
);

export const getFiberSource = new EzeeAsyncAction<
    ProviderCertificationsState['getFiberSource'],
    ValueListItemDetailPayload,
    ValueListItem
>(
    'ProviderCertifications/getFiberSource',
    initialState.getFiberSource,
    requestReducer<ProviderCertificationsState['getFiberSource'], ValueListItem>(initialState.getFiberSource)
);

// Reducer
export const providerCertificationsReducer = combineReducers<ProviderCertificationsState>({
    list: list.reducer,
    create: create.reducer,
    update: update.reducer,
    remove: remove.reducer,
    details: details.reducer,
    downloadAll: downloadAll.reducer,
    download: download.reducer,
    listCount: listCount.reducer,
    getFiberSources: getFiberSources.reducer,
    getFiberSource: getFiberSource.reducer,
});

// Saga
export function* providerCertificationsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(listCount.type.trigger, simpleAsyncSaga(listApiCall, listCount));
    yield takeLatest(getFiberSources.type.trigger, simpleAsyncSaga(getValueListItemApiCall, getFiberSources));
    yield takeLatest(getFiberSource.type.trigger, simpleAsyncSaga(getValueListItemDetailApiCall, getFiberSource));
    yield takeLatest(downloadAll.type.trigger, simpleAsyncSaga(downloadAllApiCall, downloadAll));
    yield takeLatest(download.type.trigger, downloadSaga);
}

function* downloadSaga(action: DataAction<ProviderCertificationDownloadPayload>) {
    try {
        const response = yield call(downloadApiCall, action.payload);

        const fileName = action.payload.filename || `${dayjs().format('YYYY-MM-DD_HH[h]mm')}-document.pdf`;
        downloadBlobFile(fileName, response, 'application/pdf');

        return yield put(download.success(''));
    } catch (error) {
        return yield put(download.failure(error));
    }
}

// Store helpers

export const getProviderCertificationsListState = (state: MainReducerState) => state.providerCertifications.list;
export const getProviderCertificationsCreateState = (state: MainReducerState) => state.providerCertifications.create;
export const getProviderCertificationsUpdateState = (state: MainReducerState) => state.providerCertifications.update;
export const getProviderCertificationsRemoveState = (state: MainReducerState) => state.providerCertifications.remove;
export const getProviderCertificationsDetailsState = (state: MainReducerState) => state.providerCertifications.details;
export const getProviderCertificationsListCountState = (state: MainReducerState) =>
    state.providerCertifications.listCount;
export const getProviderCertificationsGetFiberSourcesState = (state: MainReducerState) =>
    state.providerCertifications.getFiberSources;
export const getProviderCertificationsGetFiberSourceState = (state: MainReducerState) =>
    state.providerCertifications.getFiberSource;
export const getProviderCertificationsDownloadAllState = (state: MainReducerState) =>
    state.providerCertifications.downloadAll;
export const getProviderCertificationsDownloadState = (state: MainReducerState) =>
    state.providerCertifications.download;
