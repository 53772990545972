import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Card, Drawer, Form, Input, Spin, Divider, FormProps, InputNumber } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';

import '../../../assets/styles/ApprovalFormDrawer.less';

import { approve as approveAction, getApproveState } from '../../../store/actions/orders';
import { Order, RemoteFile } from '../../../store/api/apiTypes';

import { useActions, usePrevious } from '../../../hooks';
import DatePicker from '../../../components/DatePicker';
import ButtonSubmit from '../../../components/ButtonSubmit';
import genericMessages from '../../../i18n/genericMessages';
import formMessages from '../../../i18n/formMessages';
import UploadFile from '../../../components/UploadFile';
import TitleAlt from '../../../components/TitleAlt';
import { errorMessage, successMessage } from '../../../helpers/message';
import NiceModal, { antdDrawer, useModal } from '@ebay/nice-modal-react';

interface OrderApprovalFormDrawerProps {
    documentId?: RemoteFile['id'];
    orderId?: Order['id'];
}

const OrderApprovalFormDrawer = NiceModal.create<OrderApprovalFormDrawerProps>(({ documentId, orderId }) => {
    const drawer = useModal();
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [approve] = useActions([approveAction.trigger]);
    const approvalState = useSelector(getApproveState);
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const previousApprovalState = usePrevious(approvalState);

    const onFormFinish: FormProps['onFinish'] = (values) => {
        const payload = {
            ...values,
            provisionalDates: JSON.stringify(
                values.provisionalDates.map((provisionalDate: { date: Dayjs; quantity?: number }) => ({
                    ...provisionalDate,
                    date: provisionalDate?.date?.toISOString(),
                    quantity: !provisionalDate.quantity ? 0 : provisionalDate.quantity,
                }))
            ),
        };

        approve({
            orderId: orderId,
            documentId: documentId,
            body: payload,
        });
    };

    useEffect(() => {
        if (previousApprovalState?.loading && !approvalState.loading) {
            if (approvalState.error) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage(genericMessages.OrdersProcess_approval_success),
                });
                drawer.resolve();
                drawer.hide();
            }
        }
    }, [approvalState.error, approvalState.loading, formatMessage, previousApprovalState?.loading, drawer]);

    return (
        <Drawer
            className="approval-form-drawer"
            title={formatMessage({
                id: 'conformityDocuments.conformityDocumentsDrawer.addDocument',
                defaultMessage: 'Approuver la commande',
            })}
            width={500}
            {...antdDrawer(drawer)}
        >
            <Spin spinning={approvalState.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    scrollToFirstError
                    encType="multipart/form-data"
                    initialValues={{
                        provisionalDates: [],
                        file: undefined,
                    }}
                >
                    <TitleAlt level={5}>{formatMessage(genericMessages.OrdersProcess_estimatedDeliveryDates)}</TitleAlt>
                    <Form.List name="provisionalDates">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Card key={field.key}>
                                        <div className="ant-card__head">
                                            <span> {`Date N°${index + 1}`}</span>
                                            <Button
                                                className="btn-delete"
                                                shape="circle"
                                                type="ghost"
                                                icon={<DeleteOutlined />}
                                                size="small"
                                                onClick={remove.bind(null, index)}
                                            />
                                        </div>
                                        <Form.Item label={`Date`} name={[field.name, 'date']} rules={[requiredRule]}>
                                            <DatePicker
                                                placeholder={formatMessage(genericMessages.OrdersProcess_selectDate)}
                                                style={{ width: '100%' }}
                                                format="DD/MM/YYYY"
                                                showToday={false}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={formatMessage(genericMessages.OrdersProcess_refFabricSerises)}
                                            name={[field.name, 'reference']}
                                        >
                                            <Input
                                                placeholder={formatMessage(
                                                    genericMessages.OrdersProcess_PlaceholderInputRef
                                                )}
                                                size="large"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={formatMessage(genericMessages.OrdersProcess_ConfirmedQuantity)}
                                            name={[field.name, 'quantity']}
                                        >
                                            <InputNumber
                                                placeholder={formatMessage(
                                                    genericMessages.OrdersProcess_PlaceholderInputQuantity
                                                )}
                                                size="large"
                                                min={0}
                                            />
                                        </Form.Item>
                                    </Card>
                                ))}
                                {fields.length < 20 && (
                                    <Button shape="round" size="small" block onClick={() => add({ quantity: 0 })}>
                                        {formatMessage(genericMessages.OrdersProcess_addDate)}
                                    </Button>
                                )}
                            </>
                        )}
                    </Form.List>
                    <Divider />
                    <Form.Item label={'Confirmation de commande'} name={'file'}>
                        <UploadFile
                            setRemoveFile={() => true}
                            fileType="pdf"
                            maxSize={5}
                            fileTypeText={formatMessage(formMessages.uploadFileFormatAndSize)}
                        />
                    </Form.Item>
                    <Divider />
                    <div className="note-information">
                        {formatMessage(genericMessages.OrdersProcess_noteForIrreversibilityApproval)}
                    </div>
                    <ButtonSubmit>
                        {formatMessage({
                            id: 'approval.submitApprove',
                            defaultMessage: 'Approuver',
                        })}
                    </ButtonSubmit>

                    <Button
                        size="large"
                        shape="round"
                        block
                        onClick={() => {
                            drawer.reject();
                            drawer.hide();
                        }}
                    >
                        {formatMessage(genericMessages.cancel)}
                    </Button>
                    <div className="required-fields"> {formatMessage(genericMessages.requiredFields)}</div>
                </Form>
            </Spin>
        </Drawer>
    );
});

export default OrderApprovalFormDrawer;
