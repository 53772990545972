import api from './_client';
import { SearchPaginationQuery } from './';

// Types
import { Brand } from './apiTypes';

export interface BrandIdPayload {
    id: Brand['id'];
}

export type BrandListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: BrandListPayload = {}) => {
    return await api.get(`/brands`, { params: payload });
};

export const details = async (payload: BrandIdPayload) => {
    return await api.get(`/brands/${payload.id}`);
};
