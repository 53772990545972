import { FC } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import '../assets/styles/ProductionYearTagStatus.less';
import { classNames } from '../helpers';
import genericMessages from '../i18n/genericMessages';
import dayjs from 'dayjs';

interface ProductionYearTagStatusProps {
    date: string;
    style?: React.CSSProperties;
}

const ProductionYearTagStatus: FC<ProductionYearTagStatusProps> = ({ date, style }) => {
    const { formatMessage } = useIntl();

    const dateLimit = dayjs().subtract(1, 'year').toISOString();

    const statusClassName = dayjs(date).isAfter(dateLimit)
        ? 'production_year_tag_status--up-to-date'
        : 'production_year_tag_status--outdated';

    return (
        <div className={classNames('production_year_tag_status', statusClassName)} style={style}>
            <span className="production_year_tag_status__text">
                {formatMessage(genericMessages.factoriesProductionLastUpdate)} : <FormattedDate value={date} />
            </span>
        </div>
    );
};

export default ProductionYearTagStatus;
