import api from './_client';
import { User } from './apiTypes';

export interface LoginPayload {
    username: string;
    password: string;
}

export const login = async (payload: LoginPayload) => await api.post('/auth', payload);

export const logout = async () => await api.post('/auth/logout');

export interface ForgottenPasswordPayload {
    username: User['email'];
}

export const forgottenPassword = async (payload: ForgottenPasswordPayload) => await api.post('/auth/reset', payload);

export interface ResetPasswordPayload {
    token: string;
    password: string;
}

export const resetPassword = async (payload: ResetPasswordPayload) => await api.post('/signedAction', payload);

export interface PreHookPayload {
    token: string;
    firstStep: boolean;
}

export const preHook = async (payload: PreHookPayload) => await api.post('/signedAction/pre', payload);

export interface ResendInvitationPayload {
    token: string;
}

export const resendInvitation = async (payload: ResendInvitationPayload) => await api.post('/auth/reset', payload);

export interface UpdateMePayload
    extends Pick<User, 'email' | 'firstName' | 'job' | 'language' | 'lastName' | 'phone' | 'password' | 'phone'> {
    newPassword: User['password'];
}

export const update = async (payload: UpdateMePayload) => {
    return await api.put('/me', payload);
};

export const checkLoginStatus = async () => await api.get('/me');
