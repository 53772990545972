import { defineMessages } from 'react-intl';

export default defineMessages({
    requiredField: {
        id: 'form_message.required_field',
        defaultMessage: 'Champ requis',
        description: 'Form required field',
    },
    requiredCITESFile: {
        id: 'form_message.requiredCITESFile',
        defaultMessage: 'Document CITES obligatoire',
        description: 'Form required CITES field',
    },
    allFieldsRequired: {
        id: 'form_message.all_fields_required',
        defaultMessage: 'Tous les champs sont requis',
        description: 'Form required fields',
    },
    invalidEmail: {
        id: 'form_message.invalid_email',
        defaultMessage: 'Veuillez entrer une adresse email valide',
        description: 'Form invalid email error',
    },
    invalidZipCode: {
        id: 'form_message.invalid_zipCode',
        defaultMessage: 'Veuillez entrer un code postal valide',
        description: 'Form invalid zipCode error',
    },
    invalidPassword: {
        id: 'form_message.invalid_password',
        defaultMessage: 'Veuillez entrer un mot de passe valide',
        description: 'Form invalid password error',
    },
    confirmEmailNotIdentical: {
        id: 'form_message.confirm_email_not_identical',
        defaultMessage: 'Les deux emails doivent être identiques',
        description: 'Form invalid email error',
    },
    passwordStrengthLength: {
        id: 'form_message.password_strength_length',
        defaultMessage: '12 caractères minimum',
        description: 'Form password strength',
    },
    passwordStrengthDigit: {
        id: 'form_message.password_strength_digit',
        defaultMessage: 'Au moins un chiffre',
        description: 'Form password strength',
    },
    passwordStrengthUppercase: {
        id: 'form_message.password_strength_uppercase',
        defaultMessage: 'Au moins une lettre en majuscule',
        description: 'Form password strength',
    },
    passwordStrengthSymbol: {
        id: 'form_message.password_strength_symbol',
        defaultMessage: 'Au moins un caractère spécial',
        description: 'Form password strength',
    },
    addressLabel: {
        id: 'form_message.address_label',
        defaultMessage: 'Adresse',
        description: 'Form address',
    },
    addressPlaceholder: {
        id: 'form_message.address_placeholder',
        defaultMessage: 'Saisir une adresse',
        description: 'Form address',
    },
    selectPlaceholder: {
        id: 'form_message.select_placeholder',
        defaultMessage: 'Choisir une valeur',
        description: 'Form select placeholder',
    },
    submit: {
        id: 'form_message.submit',
        defaultMessage: 'Envoyer',
        description: 'Form submit button text',
    },
    cancel: {
        id: 'form_message.cancel',
        defaultMessage: 'Annuler',
        description: 'Form cancel button text',
    },
    errorRevenuGlobal: {
        id: 'form_message.error_revenu_global',
        defaultMessage: 'Revenu global de l’année doit être superieur au revenu dior',
        description: 'error message',
    },
    errorRevenuDior: {
        id: 'form_message.error_revenu_dior',
        defaultMessage: 'Revenu Dior de l’année doit être inférieur au revenu global',
        description: 'error message',
    },
    errorMinValue: {
        id: 'form_message.error_min_value',
        defaultMessage: 'La valeur que vous avez saisie est inférieure au score minimal autorisé',
        description: 'error message',
    },
    errorMaxValue: {
        id: 'form_message.error_max_value',
        defaultMessage: 'La valeur que vous avez saisie est supérieure au score maximal autorisé',
        description: 'error message',
    },
    errorEndDate: {
        id: 'form_message.error_end_date',
        defaultMessage: 'La date de fin doit être supérieur à la date du jour',
        description: 'error message',
    },
    errorNbWorker: {
        id: 'form_message.error_nb_worker',
        defaultMessage: 'Au vu des informations remplies ci-dessus, le nombre total de travailleurs est erroné',
        description: 'error message',
    },
    errorNbWorkerTotal: {
        id: 'form_message.error_nb_worker_total',
        defaultMessage: 'Attention, ce chiffre est supérieur au nombre total de travailleurs',
        description: 'error message',
    },
    errorRevenue15: {
        id: 'revenue_form_drawer.form.item.input.rules_max_number',
        defaultMessage: "Le chiffre d'affaire ne doit pas dépasser les 15 chiffres",
    },
    material_certificate_drawer_year: {
        id: 'material_certificate_drawer.form.item.input.year',
        defaultMessage: 'Année',
        description: 'input label',
    },
    supplier_certificate_drawer_year: {
        id: 'supplier_certificate_drawer.form.item.input.year',
        defaultMessage: 'Année correspondante',
        description: 'input label',
    },
    supplier_certificate_drawer_collection: {
        id: 'supplier_certificate_drawer.form.item.input.collection',
        defaultMessage: 'Collection associée',
        description: 'input label',
    },
    supplier_certificate_drawer_ref: {
        id: 'supplier_certificate_drawer.form.item.input.label.ref',
        defaultMessage: 'Ref. matière ou PF Dior',
        description: 'input label',
    },
    buttonModify: {
        id: 'Document.refused.button',
        defaultMessage: 'Modifier le Document',
    },
    putCommentPlaceholder: {
        id: 'putCommentPlaceholder',
        defaultMessage: 'Saisir un commentaire',
        description: 'Saisir un commentaire',
    },
    uploadInstructions: {
        id: 'upload.instructions',
        defaultMessage: 'Cliquez ici ou déposez un fichier',
        description: 'Upload help text',
    },
    uploadFileFormatAndSize: {
        id: 'upload.file_format_size',
        defaultMessage: '(Format PDF / Max 5 Mo)',
        description: 'Upload file format & size text',
    },
});
