import api from './_client';
import { SearchPaginationQuery } from './';

// Types
import { Revenue } from './apiTypes';

// Controller Interface
export interface RevenueCreatePayload extends Omit<Revenue, 'now'> {}
export interface RevenueUpdatePayload extends Omit<Revenue, 'now'> {
    id: Revenue['id'];
}
export interface RevenueIdPayload {
    id: Revenue['id'];
}

export type RevenueListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: RevenueListPayload = {}) => {
    return await api.get(`/revenues`, { params: payload });
};

export const create = async (payload: RevenueCreatePayload) => {
    return await api.post(`/revenues`, payload);
};
export const update = async (payload: RevenueCreatePayload) => {
    return await api.put(`/revenues/${payload.id}`, payload);
};

export const details = async (payload: RevenueIdPayload) => {
    return await api.get(`/revenues/${payload.id}`);
};

export const remove = async (payload: RevenueIdPayload) => {
    return await api.delete(`/revenues/${payload.id}`);
};
