import { Select, SelectProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { list as listAction, getCollectionsListState } from '../../store/actions/collections';
import { Collection, SupportedLanguage } from '../../store/api/apiTypes';
import { getUser } from '../../store/actions/auth';
import { useActions } from '../../hooks';
import { getSelectValue, selectValueOnChange } from '../../helpers/select';

type SelectProviderCollectionProps = SelectProps<any> & {
    onChange?: (value?: Collection['id']) => void;
    valueType?: 'id' | 'object';
};

const SelectProviderCollection: FC<SelectProviderCollectionProps> = ({ onChange, valueType = 'id', ...props }) => {
    const [getList] = useActions([listAction.trigger]);
    const collectionListState = useSelector(getCollectionsListState);
    const user = useSelector(getUser);
    const [value, setValue] = useState<Collection['id']>();

    const onChangeSelect: SelectProps<Collection['id']>['onChange'] = (newValue) => {
        setValue(newValue);
        if (onChange) {
            onChange(selectValueOnChange(newValue, props.mode, valueType, collectionListState?.data?.items));
        }
    };

    useEffect(() => {
        getList();
    }, [getList]);

    useEffect(() => {
        setValue(getSelectValue(props.value));
    }, [props.value]);

    return (
        <Select {...props} value={collectionListState.loading ? undefined : value} onChange={onChangeSelect} allowClear>
            {collectionListState.data?.items.map((collection) => (
                <Select.Option value={collection.id} key={collection.id}>
                    {collection.name[user?.language ?? SupportedLanguage.en]}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SelectProviderCollection;
