import { FC, ReactElement, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Carousel, Button, CarouselProps, Typography } from 'antd';

import '../assets/styles/TutorialModal.less';

import { getUser } from '../store/actions/auth';
import { RoleName } from '../store/api/apiTypes';

import Modal from './Modal';
import image1 from '../assets/images/welcome-image.png';
import image2 from '../assets/images/bandeau-gestion-compte.png';
import image4 from '../assets/images/bandeau-signature.png';
import image3 from '../assets/images/bandeau-infos_prod.png';
import image5 from '../assets/images/bandeau-docs_de_prod.png';
import image6 from '../assets/images/bandeau-connectes.png';
import tutorialMessages from '../i18n/tutoMessages';
import { classNames } from '../helpers';
import { LayoutContext } from '../context/LayoutContext';
import { hasRole } from '../helpers/security';
import { LeftSquareOutlined, RightSquareOutlined } from '@ant-design/icons';
import WelcomeModal from './WelcomeModal';

const slides = [
    {
        title: tutorialMessages.welcome,
        message: tutorialMessages.welcomeMessage,
        image: image1,
    },
    {
        title: tutorialMessages.profiletitle,
        message: tutorialMessages.profileMessage,
        image: image2,
    },
    {
        title: tutorialMessages.signatureTitle,
        message: tutorialMessages.signatureMessage,
        image: image4,
    },
    {
        title: tutorialMessages.infoProductTitle,
        message: tutorialMessages.infoProductMessage,
        image: image3,
    },
    {
        title: tutorialMessages.productionDocumentsTitle,
        message: tutorialMessages.productionDocumentsMessage,
        image: image5,
    },
    {
        title: tutorialMessages.stayConnectedTitle,
        message: tutorialMessages.stayConnectedMessage,
        image: image6,
    },
];

const PrevButton: FC<any> = ({ className, onClick }) => (
    <Button
        className={classNames('carousel-control', className)}
        type="link"
        style={{ width: '40px' }}
        onClick={onClick}
    >
        <LeftSquareOutlined style={{ color: '#000000', fontSize: '167%' }} />
    </Button>
);

const NextButton: FC<any> = ({ className, onClick }) => (
    <Button className={classNames('carousel-control', className)} type="link" onClick={onClick}>
        <RightSquareOutlined style={{ color: '#000000', fontSize: '167%' }} />
    </Button>
);

interface TutorialModalProps {
    isVisible?: boolean;
    isAfterValidate: boolean;
}

const TutorialModal: FC<TutorialModalProps> = ({ isVisible, isAfterValidate }) => {
    const { setIsTutorialModalOpen, isWelcomeModalOpen, setIsWelcomeModalOpen, setIsAfterValidate } = useContext(
        LayoutContext
    );
    const user = useSelector(getUser);
    const [imageUrl, setImageUrl] = useState(slides[0].image);
    const canAddUsers = hasRole(user, [RoleName.adminProvider]);
    const firstUser = user?.email === user?.organization?.principalContact?.email;
    const onBeforeChange: CarouselProps['beforeChange'] = (from, to) => {
        setImageUrl(slides[to].image);
    };
    const onClose = () => {
        if (isAfterValidate && canAddUsers && firstUser) {
            setIsWelcomeModalOpen(true);
            setIsAfterValidate(false);
        }
        setIsTutorialModalOpen(false);
    };

    return (
        <>
            <Modal
                footer={null}
                onCancel={onClose}
                visible={isVisible}
                title={<div style={{ backgroundImage: `url(${imageUrl})`, height: '112px' }}></div>}
                className="tutorial-modal"
                zIndex={900}
                width={800}
            >
                <div id="tutorial-carousel-wrapper">
                    <Carousel
                        prevArrow={<PrevButton />}
                        nextArrow={<NextButton />}
                        beforeChange={onBeforeChange}
                        infinite={false}
                        swipe
                        arrows
                    >
                        {slides.map((slide, index) => (
                            <div className="tutorial-slide" key={index}>
                                <Typography style={{ fontSize: '28px' }}>
                                    <FormattedMessage {...slide.title} />
                                </Typography>
                                <Typography style={{ fontSize: '16px' }}>
                                    <FormattedMessage
                                        {...slide.message}
                                        values={{
                                            br: <br style={{ height: '40px' }} />,
                                            strongPrimary: (...chunks: ReactElement[]) => (
                                                <strong className="text-primary">{chunks}</strong>
                                            ),
                                            primary: (...chunks: ReactElement[]) => (
                                                <span className="text-primary">{chunks}</span>
                                            ),
                                        }}
                                        tagName="p"
                                    />
                                </Typography>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </Modal>
            <WelcomeModal isVisible={isWelcomeModalOpen} />
        </>
    );
};

export default TutorialModal;
