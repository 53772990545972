import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SupportedLanguage } from '../store/api/apiTypes';

type LangImport = { [key in SupportedLanguage]: any };

const availableLangs = ['es', 'de', 'ru', 'fr', 'jp', 'cn', 'pt', 'it', 'ir', 'ar', 'tr', 'id', 'en'];

const intlData = availableLangs.reduce<LangImport | Record<string, unknown>>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => await import(`react-phone-input-2/lang/${lang}.json`),
    }),
    {}
);

interface LocalizedCountryNameProps {
    countryCode: string;
}

const LocalizedCountryName: FC<LocalizedCountryNameProps> = ({ countryCode }) => {
    const { locale } = useIntl();
    const [localization, setLocalization] = useState<{ [key: string]: string } | undefined>();
    useEffect(() => {
        if (availableLangs.includes(locale)) {
            if (locale === SupportedLanguage.en) {
                (async () => {
                    const localeData = (await import('../i18n/countries/en.json')).default;
                    setLocalization(localeData);
                })();
            } else {
                (async () => {
                    const localeData = (await intlData[locale as SupportedLanguage]?.())?.default;
                    setLocalization(localeData);
                })();
            }
        }
    }, [locale]);

    return <>{localization?.[countryCode] ?? countryCode}</>;
};

export default LocalizedCountryName;
