import { defineMessages } from 'react-intl';

const tutoMessages = defineMessages({
    welcome: {
        id: 'tuto_message.welcome',
        defaultMessage: 'Bienvenue',
        description: 'welcome',
    },
    welcomeMessage: {
        id: 'tuto_message.welcome_message',
        defaultMessage:
            "Sur le profil de la plateforme fournisseur Christian Dior Couture ! {br} Lancée en 2021, elle permet l'échange des documents et des données de conformité ainsi que des documents relatifs à la production.",
        description: 'welcome message',
    },
    profiletitle: {
        id: 'tuto_message.profile_title',
        defaultMessage: 'Gestion de votre compte',
        description: 'tutorial profil title',
    },
    profileMessage: {
        id: 'tuto_message.profile_message',
        defaultMessage:
            "Mettez à jour les informations générales de votre profil quand vous le souhaitez. {br} Si vous êtes administrateur, c'est à vous de créer et gérer les comptes de vos collaborateurs: rendez-vous dans l'onglet Utilisateurs dans le menu situé en haut à droite de la plateforme.",
        description: 'tutorial profil message',
    },
    signatureTitle: {
        id: 'tuto_message.signature_title',
        defaultMessage: 'Signature électronique',
        description: 'tutorial signature title',
    },
    signatureMessage: {
        id: 'tuto_message.signature_message',
        defaultMessage:
            'Fini les documents légaux en version papier avec la signature électronique des documents ! Retrouvez tous vos documents à signer dans la rubrique Signature. Vous retrouverez les documents signés sous le même onglet. ',
        description: 'tutorial signature message',
    },
    infoProductTitle: {
        id: 'tuto_message.info_product_titles',
        defaultMessage: 'Information pour vos sites production',
        description: 'tutorial info product title',
    },
    infoProductMessage: {
        id: 'tuto_message.info_product_message',
        defaultMessage:
            "Pour répondre aux enjeux actuels liés à la transparence de nos filières, nous souhaitons mieux vous connaître. Communiquez-nous une fois par an en toute sérénité des renseignements sur vos structures. Vos certifications d'usines et la gestion de vos exploitations pourront ainsi être mis en valeur.{br} Vous serez notifiés par mail de l'ouverture du formulaire en fin d'année.{br} A ce moment-là : Rendez-vous dans l'onglet Informations de production. ",
        description: 'tutorial info product message',
    },
    productionDocumentsTitle: {
        id: 'tuto_message.production_documents_title',
        defaultMessage: 'Documents de production',
        description: 'tutorial production document title',
    },
    productionDocumentsMessage: {
        id: 'tuto_message.production_documents_message',
        defaultMessage:
            "Téléchargez vos documents directement sur la plateforme :{br} - Attestations fournisseur (attestations et certificats d'origine, documents CITES) {br} - Certifications matières (certifications GOTS, GRS etc.) ! {br} Les ordres d'achats et/ou commessa seront également mis à disposition pour consultation ou téléchargement sur la plateforme pour une durée d'un an. Rendez vous dans l'onglet Échanges de document !",
        description: 'tutorial production document message',
    },

    stayConnectedTitle: {
        id: 'tuto_message.stay_connected_title',
        defaultMessage: 'Restez Connectés!',
        description: 'tutorial stay connected title',
    },
    stayConnectedMessage: {
        id: 'tuto_message.stay_connected_message',
        defaultMessage:
            "Nous vous conseillons de mettre le lien de la plateforme dans les favoris de votre navigateur et d'enregistrez votre mot de passe afin de garder la plateforme à portée de main!{br} Si vous rencontrez une difficulté sur la plateforme, rapprochez vous de votre interlocuteur chez Christian Dior Couture, nous ferons notre possible pour vous aider.",
        description: 'tutorial stay connected message',
    },
});

export default tutoMessages;
