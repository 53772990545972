import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Col, Divider, Form, FormInstance, Input, Row, Tag, TreeSelect, TreeSelectProps } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';

import { BrandScope, RoleName, SupportedLanguage } from '../store/api/apiTypes';

import formMessages from '../i18n/formMessages';
import { LanguageName } from '../i18n/lang';
import { classNames } from '../helpers';
import Select from './Select';
import PhoneInput from './PhoneInput';
import TitleAlt from './TitleAlt';
import RequiredFields from './RequiredFields';
import LabelWithTooltipIcon from './LabelWithTooltipIcon';
import { useSelector } from 'react-redux';
import { getUser } from '../store/actions/auth';
import BasicList from './BasicList';
import TagRoleProviderUser from './TagRoleProviderUser';
import { hasRole } from '../helpers/security';
import Translate from './Translate';

interface UserAccountFieldsProps {
    title?: string;
    titleLevel?: TitleProps['level'];
    twoCols?: boolean;
    targetRole?: string;
    form?: FormInstance;
    brandScope?: BrandScope[];
    language?: SupportedLanguage;
    edit?: boolean;
    brandScopeSelected?: string[];
}
const UserAccountFields: FC<UserAccountFieldsProps> = ({
    title,
    titleLevel,
    twoCols,
    targetRole,
    brandScope,
    language,
    edit,
    brandScopeSelected,
}) => {
    const { formatMessage } = useIntl();
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const user = useSelector(getUser);
    const [valueBrandsAndTypes, setValue] = useState<string[]>();
    const brandsAndTypes = user?.organization?.brandsAndTypes
        ? [
              ...new Set(
                  user?.organization?.brandsAndTypes
                      ?.map((brandScope) => {
                          return {
                              title: brandScope.brand.name,
                              value: brandScope.brand.id,
                              key: brandScope.brand.id,
                              children: brandScope.providerTypes
                                  ? [
                                        ...new Set(
                                            brandScope.providerTypes
                                                ?.map((providerType) => {
                                                    return {
                                                        title: providerType.name[user.language ?? SupportedLanguage.en],
                                                        value: brandScope.brand.id + ',' + providerType.id,
                                                        key: brandScope.brand.id + ',' + providerType.id,
                                                    };
                                                })
                                                .flat(1)
                                        ),
                                    ]
                                  : [],
                          };
                      })
                      .flat(1)
              ),
          ]
        : undefined;
    const onChange: TreeSelectProps<any>['onChange'] = (value) => {
        setValue(value);
    };

    return (
        <>
            {title && (
                <div className="flex flex-between" style={{ marginBottom: '1.5rem' }}>
                    <TitleAlt level={titleLevel ?? 2} style={{ margin: 0 }}>
                        {title}
                    </TitleAlt>
                    <RequiredFields />
                </div>
            )}
            {user && !targetRole && <TagRoleProviderUser user={user} />}
            {user?.brandsAndTypes && !targetRole && hasRole(user, [RoleName.userProvider]) && (
                <>
                    <div style={{ marginBottom: '8px' }}>
                        <p
                            style={{
                                fontWeight: 700,
                                marginLeft: '4px',
                                marginRight: '8px',
                                fontSize: '16px',
                                textTransform: 'uppercase',
                            }}
                        >
                            {formatMessage({
                                id: 'user_account_fields.p.brandAndTypes',
                                defaultMessage: 'Univers & type(s)',
                                description: 'title section basic list',
                            })}
                        </p>
                    </div>
                    <div style={{ lineHeight: '32px' }}>
                        {user?.brandsAndTypes.map((brandScope) => (
                            <Tag key={brandScope.brand.id} style={{ whiteSpace: 'normal' }} className="ant-tag-details">
                                {brandScope.brand.name}
                                {' : '}
                                {brandScope.providerTypes?.map((providerType, index) => {
                                    if (index > 0) {
                                        return (
                                            <>
                                                {' / '}
                                                <Translate key={index} text={providerType?.name} />
                                            </>
                                        );
                                    } else {
                                        return <Translate key={index} text={providerType?.name} />;
                                    }
                                })}
                            </Tag>
                        ))}
                    </div>
                </>
            )}
            <Divider style={{ margin: '24px 0' }}></Divider>
            <Row gutter={24}>
                <Col xs={twoCols ? 12 : 24}>
                    <Form.Item
                        name={['user', 'lastName']}
                        label={formatMessage({
                            id: 'user_account_fields.form.item.label.lastname',
                            defaultMessage: 'Nom',
                            description: 'input label',
                        })}
                        rules={[requiredRule]}
                    >
                        <Input
                            placeholder={formatMessage({
                                id: 'user_account_fields.form.item.input.lastname',
                                defaultMessage: 'Saisir votre nom',
                                description: 'input placeholder',
                            })}
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col xs={twoCols ? 12 : 24}>
                    <Form.Item
                        name={['user', 'firstName']}
                        label={formatMessage({
                            id: 'user_account_fields.form.item.label.firstname',
                            defaultMessage: 'Prénom',
                            description: 'input label',
                        })}
                        rules={[requiredRule]}
                    >
                        <Input
                            placeholder={formatMessage({
                                id: 'user_account_fields.form.item.input.firstname',
                                defaultMessage: 'Saisir votre prénom',
                                description: 'input placeholder',
                            })}
                            size="large"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name={['user', 'job']}
                label={formatMessage({
                    id: 'user_account_fields.form.item.label.job',
                    defaultMessage: 'Poste / Titre',
                    description: 'input label',
                })}
                rules={[requiredRule]}
            >
                <Input
                    placeholder={formatMessage({
                        id: 'user_account_fields.form.item.input.job',
                        defaultMessage: 'Saisir votre poste / titre',
                        description: 'input placeholder',
                    })}
                    size="large"
                />
            </Form.Item>
            {targetRole === RoleName.userProvider && edit && (
                <Form.Item
                    name={['user', 'brandsAndTypes']}
                    initialValue={brandScopeSelected}
                    label={
                        <LabelWithTooltipIcon
                            label={formatMessage({
                                id: 'user_account_fields.form.item.label.brandsAndTypes',
                                defaultMessage: 'Univers et type(s) fournisseur associé(s)',
                                description: 'input label',
                            })}
                            tooltip={formatMessage({
                                id: 'user_account_fields.form.item.tooltip.brandsAndTypes',
                                defaultMessage:
                                    "Ce champ permet de mettre en relation l'utilisateur avec les bons interlocuteurs côté Christian Dior Couture.",
                                description: 'input help tooltip',
                            })}
                        />
                    }
                    rules={[requiredRule]}
                >
                    {brandsAndTypes && (
                        <TreeSelect
                            treeData={brandsAndTypes}
                            onChange={onChange}
                            value={valueBrandsAndTypes}
                            treeCheckable={true}
                            showCheckedStrategy={'SHOW_PARENT'}
                            placeholder={formatMessage({
                                id: 'user_account_fields.form.item.tree_select.brandsAndTypes',
                                defaultMessage: 'Sélectionner',
                                description: 'Select label',
                            })}
                        />
                    )}
                </Form.Item>
            )}
            {targetRole === RoleName.userProvider && brandScope && !edit && (
                <BasicList>
                    {brandScope.map((brandScopeData) => (
                        <li key={brandScopeData.brand.id}>
                            <BasicList inline>
                                <p style={{ fontWeight: 600, marginRight: '8px' }}>{brandScopeData.brand.name}:</p>
                                <BasicList inline>
                                    {brandScopeData.providerTypes?.map((providerType) => (
                                        <li key={providerType.id} style={{ marginRight: '-18px' }}>
                                            <Tag>{providerType.name[language ?? SupportedLanguage.en]}</Tag>
                                        </li>
                                    ))}
                                </BasicList>
                            </BasicList>
                        </li>
                    ))}
                </BasicList>
            )}

            <Form.Item
                name={['user', 'language']}
                label={formatMessage({
                    id: 'user_account_fields.form.item.label.langue',
                    defaultMessage: "Langue d'affichage et de communication",
                    description: 'input label',
                })}
                rules={[requiredRule]}
            >
                <Select
                    dropdownClassName="lang-select-dropdown"
                    placeholder={formatMessage({
                        id: 'user_account_fields.form.item.selec.langue',
                        defaultMessage: 'Choisir une langue',
                        description: 'input placeholder',
                    })}
                    size="large"
                >
                    {Object.values(SupportedLanguage).map((lang) => (
                        <Select.Option value={lang} key={lang}>
                            <span className={classNames('flag', lang)} /> {LanguageName.get(lang)}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name={['user', 'email']}
                label={formatMessage({
                    id: 'user_account_fields.form.item.label.email',
                    defaultMessage: 'Adresse e-mail',
                    description: 'input label',
                })}
                rules={[requiredRule, { type: 'email', message: formatMessage(formMessages.invalidEmail) }]}
            >
                <Input
                    placeholder={formatMessage({
                        id: 'user_account_fields.form.item.input.email',
                        defaultMessage: 'Saisir votre e-mail',
                        description: 'input placeholder',
                    })}
                    size="large"
                />
            </Form.Item>
            <Row gutter={24}>
                <Col xs={twoCols ? 12 : 24}>
                    <Form.Item
                        name={['user', 'phone']}
                        label={formatMessage({
                            id: 'user_account_fields.form.item.label.phone',
                            defaultMessage: 'Téléphone fixe',
                            description: 'input label',
                        })}
                        rules={[
                            requiredRule,
                            {
                                validator: async (_, value) => {
                                    if (value.length > 7) {
                                        return await Promise.resolve();
                                    }
                                    return await Promise.reject(formatMessage(formMessages.requiredField));
                                },
                            },
                        ]}
                    >
                        <PhoneInput
                            placeholder={formatMessage({
                                id: 'user_account_fields.form.item.input.phone',
                                defaultMessage: 'Saisir votre numéro',
                                description: 'input placeholder',
                            })}
                        />
                    </Form.Item>
                </Col>
                <Col xs={twoCols ? 12 : 24}>
                    <Form.Item
                        name={['user', 'mobile']}
                        label={formatMessage({
                            id: 'user_account_fields.form.item.label.mobile',
                            defaultMessage: 'Téléphone portable',
                            description: 'input label',
                        })}
                    >
                        <PhoneInput
                            placeholder={formatMessage({
                                id: 'user_account_fields.form.item.input.mobile',
                                defaultMessage: 'Saisir votre numéro',
                                description: 'input placeholder',
                            })}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default UserAccountFields;
