import api from './_client';
import { SearchPaginationQuery } from '.';

// Types
import { Audit } from './apiTypes';
import { serialize } from 'object-to-formdata';

// Controller Interface
export interface AuditCreatePayload extends Omit<Audit, 'id'> {}
export interface AuditUpdatePayload extends Audit {}
export interface AuditIdPayload {
    id: Audit['id'];
}

export type AuditListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: AuditListPayload = {}) => {
    return await api.get(`/audits`, { params: payload });
};

export const create = async (payload: AuditCreatePayload) => {
    if (payload.questionnaire?.length) {
        return await api.post(`/audits`, payload);
    }
    const formData = serialize(payload);
    return await api.post(`/audits`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
export const update = async (payload: AuditUpdatePayload) => {
    if (payload.questionnaire?.length) {
        return await api.put(`/audits/${payload.id}`, payload);
    }
    const formData = serialize(payload);
    return await api.put(`/audits/${payload.id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const details = async (payload: AuditIdPayload) => {
    return await api.get(`/audits/${payload.id}`);
};

export const remove = async (payload: AuditIdPayload) => {
    return await api.delete(`/audits/${payload.id}`);
};

export const downloadFromApi = async (payload: AuditIdPayload) => {
    return await api.get(`/audits/download/${payload.id}`, {
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
    });
};
