import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Avatar, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import '../assets/styles/UserMenu.less';

import { getUser, logout as logoutAction } from '../store/actions/auth';
import { update as updateProvider } from '../store/actions/organizations';
import { getRoute, RoutePathName } from '../routes';
import { useActions } from '../hooks';
import UserProfileDrawer from './UserProfileDrawer';
import UserListDrawer from './UserListDrawer';
import OrganizationFormDrawer from './OrganizationFormDrawer';
import ContactsDrawer from './ContactsDrawer';
import { RoleName, SupportedLanguage } from '../store/api/apiTypes';
import { hasRole } from '../helpers/security';
import ResponsivePlayer from './ReactPlayer';
import TutorialModal from './TutorialModal';
import { LayoutContext } from '../context/LayoutContext';
import WelcomeModal from './WelcomeModal';
import { getNameWithFirstLetterOfLastName } from '../helpers';
import ManagementModal from './ManagementModal';

const userGuideFiles = {
    fr: '/pdf/Guide_Utilisateur_COFOU_FR.pdf',
    en: '/pdf/User_Guide_COFOU_EN.pdf',
    it: '/pdf/Guida_Utente_COFOU_IT.pdf',
    pt: '/pdf/Guia_Utilizador_PT.pdf',
};

const urlVideo = {
    fr: 'https://vod.fl.freecaster.net/vod/dior/geo/open/ceu4gBGYLX_1080p.mp4',
    en: 'https://vod.fl.freecaster.net/vod/dior/geo/open/y9YY1E8TF2_1080p.mp4',
    it: 'https://vod.fl.freecaster.net/vod/dior/geo/open/Yy46hKKJrV_1080p.mp4',
    pt: 'https://vod.fl.freecaster.net/vod/dior/Xq17wLPYzD_1080p.mp4',
};

const UserMenu: FC = () => {
    const { formatMessage } = useIntl();
    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
    const [isUserListDrawerOpen, setIsUserListDrawerOpen] = useState(false);
    const [isOrganizationDrawerOpen, setIsOrganizationDrawerOpen] = useState(false);
    const [isContactsDrawerOpen, setIsContactsDrawerOpen] = useState(false);
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [isManagementModalOpen, setIsManagementModalOpen] = useState(false);
    const { isTutorialModalOpen, setIsTutorialModalOpen, isAfterValidate, isWelcomeModalOpen } = useContext(
        LayoutContext
    );
    const [logout, update] = useActions([logoutAction.trigger, updateProvider.trigger]);
    const user = useSelector(getUser);
    const onCloseManagementModal = () => {
        update({ id: user?.organization?.id, managementCheck: true });
        setIsManagementModalOpen(false);
    };

    useEffect(() => {
        if (!user?.managementCheck && hasRole(user, [RoleName.adminProvider])) {
            setIsManagementModalOpen(true);
        }
    }, [user]);

    return (
        <>
            <Dropdown
                overlayClassName="user-menu-dropdown"
                trigger={['click']}
                overlay={
                    <Menu>
                        <Menu.Item style={{ padding: '1rem 1.5rem' }}>
                            <Link to="" onClick={setIsProfileDrawerOpen.bind(null, true)}>
                                <FormattedMessage
                                    id="user_menu.link_management"
                                    defaultMessage="Gérer votre profil"
                                    description="user dropdown menu item"
                                />
                            </Link>
                        </Menu.Item>
                        {hasRole(user, [RoleName.adminProvider]) && <Menu.Divider />}
                        {hasRole(user, [RoleName.adminProvider]) && (
                            <Menu.ItemGroup
                                title={formatMessage({
                                    id: 'user_menu.link_society',
                                    defaultMessage: 'Société',
                                    description: 'user dropdown menu section',
                                })}
                            >
                                <Menu.Item>
                                    <Link to="" onClick={setIsOrganizationDrawerOpen.bind(null, true)}>
                                        <FormattedMessage
                                            id="user_menu.link_informations"
                                            defaultMessage="Informations"
                                            description="user dropdown menu item"
                                        />
                                    </Link>
                                </Menu.Item>

                                <Menu.Item>
                                    <Link to="" onClick={setIsUserListDrawerOpen.bind(null, true)}>
                                        <FormattedMessage
                                            id="user_menu.link_users"
                                            defaultMessage="Utilisateurs"
                                            description="user dropdown menu item"
                                        />
                                    </Link>
                                </Menu.Item>
                            </Menu.ItemGroup>
                        )}
                        <Menu.Divider />
                        <Menu.ItemGroup
                            title={formatMessage({
                                id: 'user_menu.link_help',
                                defaultMessage: 'Aide',
                                description: 'user dropdown menu section',
                            })}
                        >
                            <Menu.Item>
                                <Link to="" onClick={setIsVideoOpen.bind(null, true)}>
                                    <FormattedMessage
                                        id="user_menu.link_service"
                                        defaultMessage="Présentation du service"
                                        description="user dropdown menu item"
                                    />
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to="" onClick={setIsTutorialModalOpen.bind(null, true)}>
                                    <FormattedMessage
                                        id="user_menu.link_tuto"
                                        defaultMessage="Tutoriel"
                                        description="user dropdown menu item"
                                    />
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <a
                                    href={userGuideFiles[user?.language ?? SupportedLanguage.en]}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FormattedMessage
                                        id="user_menu.link_user_guide"
                                        defaultMessage="Guide utilisateur"
                                        description="user dropdown menu item"
                                    />
                                </a>
                            </Menu.Item>
                            {!hasRole(user, [RoleName.adminProvider]) && (
                                <Menu.Item>
                                    <Link to="" onClick={setIsContactsDrawerOpen.bind(null, true)}>
                                        <FormattedMessage
                                            id="user_menu.link_contacts"
                                            defaultMessage="Contact(s) administrateur(s)"
                                            description="user dropdown menu item"
                                        />
                                    </Link>
                                </Menu.Item>
                            )}
                        </Menu.ItemGroup>
                        <Menu.Divider />
                        <Menu.Item style={{ padding: '1rem 1.5rem' }}>
                            <Link to={getRoute(RoutePathName.login)} onClick={logout}>
                                <FormattedMessage
                                    id="user_menu.link_disconnection"
                                    defaultMessage="Se déconnecter"
                                    description="user dropdown menu item"
                                />
                            </Link>
                        </Menu.Item>
                    </Menu>
                }
            >
                <Link to="" className="user-menu">
                    <Avatar size={32}>{user?.firstName ? user?.firstName.substring(0, 1) : ''}</Avatar>
                    {getNameWithFirstLetterOfLastName(user).substr(0, 22)}
                    <DownOutlined />
                </Link>
            </Dropdown>
            <UserProfileDrawer visible={isProfileDrawerOpen} onClose={setIsProfileDrawerOpen.bind(null, false)} />
            <UserListDrawer visible={isUserListDrawerOpen} onClose={setIsUserListDrawerOpen.bind(null, false)} />
            <OrganizationFormDrawer
                visible={isOrganizationDrawerOpen}
                onClose={setIsOrganizationDrawerOpen.bind(null, false)}
            />
            <ContactsDrawer visible={isContactsDrawerOpen} onClose={setIsContactsDrawerOpen.bind(null, false)} />
            <ResponsivePlayer
                isVisible={isVideoOpen}
                url={urlVideo[user?.language ?? 'en']}
                onClose={setIsVideoOpen.bind(null, false)}
            />
            <TutorialModal isVisible={isTutorialModalOpen} isAfterValidate={isAfterValidate} />
            <WelcomeModal isVisible={isWelcomeModalOpen} />
            <ManagementModal isVisible={isManagementModalOpen} onClose={onCloseManagementModal} />
        </>
    );
};

export default UserMenu;
