import { FC, useMemo } from 'react';

import { Divider, Popover, Tag, Typography } from 'antd';
import { Brand, ProviderType } from '../store/api/apiTypes';
import Translate from './Translate';

interface BrandsAndTypesTagsPopoverProps {
    brands?: Brand[];
    providerTypes: ProviderType[];
}
const BrandsAndTypesTagsPopover: FC<BrandsAndTypesTagsPopoverProps> = ({ brands, providerTypes }) => {
    const brandsWithTypes = useMemo(
        () =>
            brands?.map((brand) => ({
                ...brand,
                providerTypes: providerTypes.filter((providerType) => providerType?.brand?.id === brand.id),
            })),
        [brands, providerTypes]
    );

    if (!brandsWithTypes?.length) {
        return <>—</>;
    }

    return (
        <Popover
            content={brandsWithTypes.map((brand, index) => (
                <div key={brand.id} style={{ maxWidth: 400 }}>
                    {index > 0 && <Divider className="mt-8 mb-8" />}
                    <Typography.Title level={4}>
                        <Translate text={brand.name} />
                    </Typography.Title>
                    <div style={{ margin: -4 }}>
                        {brand.providerTypes.map((providerType) => (
                            <Tag key={providerType.id} className="inline-flex" style={{ margin: 4 }}>
                                <Translate text={providerType?.name} />
                            </Tag>
                        ))}
                    </div>
                </div>
            ))}
        >
            <span>
                {brandsWithTypes.slice(0, 1).map((brand) => (
                    <Tag key={brand.id}>
                        <Translate text={brand?.name} />{' '}
                        {/* <Badge count={brand.providerTypes.length} size="small" /> */}
                    </Tag>
                ))}
                {brandsWithTypes.length > 1 && <Tag>+{brandsWithTypes.length - 1}</Tag>}
            </span>
        </Popover>
    );
};

export default BrandsAndTypesTagsPopover;
