import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { Order, ListResponse } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

import {
    OrderDetailsPayload,
    docConsultationPayload,
    docApprovalPayload,
    OrderListPayload,
    details as detailsApiCall,
    list as listApiCall,
    consultation as consultationApiCall,
    approve as approveApiCall,
    downloadAll as downloadAllApiCall,
    OrderdownloadAllPayload,
    download as downloadApiCall,
    OrderdownloadPayload,
    docContestPayload,
    contest as contestApiCall,
} from '../api/orders';
import { DataAction } from '../helpers/EzeeAction';
import dayjs from 'dayjs';
import { downloadBlobFile } from '../../helpers';

// State

export interface OrdersState {
    list: RequestState<ListResponse<Order>>;
    details: RequestState<Order>;
    consultation: RequestState<Order>;
    approve: RequestState<Order>;
    downloadAll: RequestState<string>;
    download: RequestState<string>;
    contest: RequestState<string>;
}

const initialState: OrdersState = {
    details: {
        data: undefined,
        loading: false,
    },
    list: {
        loading: false,
    },
    consultation: {
        data: undefined,
        loading: false,
    },
    approve: {
        data: undefined,
        loading: false,
    },
    downloadAll: {
        data: undefined,
        loading: false,
    },
    download: {
        data: undefined,
        loading: false,
    },
    contest: {
        data: undefined,
        loading: false,
    },
};

// Actions/Reducers
export const details = new EzeeAsyncAction<OrdersState['details'], OrderDetailsPayload, OrdersState>(
    'orders/details',
    initialState.details,
    requestReducer<OrdersState['details'], OrdersState>(initialState.details)
);

export const list = new EzeeAsyncAction<OrdersState['list'], OrderListPayload, ListResponse<Order>>(
    'orders/list',
    initialState.list,
    requestReducer<OrdersState['list'], ListResponse<Order>>(initialState.list)
);

export const consultation = new EzeeAsyncAction<OrdersState['consultation'], docConsultationPayload, OrdersState>(
    'orders/consultation',
    initialState.consultation,
    requestReducer<OrdersState['consultation'], OrdersState>(initialState.consultation)
);

export const approve = new EzeeAsyncAction<OrdersState['approve'], docApprovalPayload, OrdersState>(
    'orders/approve',
    initialState.approve,
    requestReducer<OrdersState['approve'], OrdersState>(initialState.approve)
);

export const contest = new EzeeAsyncAction<OrdersState['contest'], docContestPayload, OrdersState>(
    'orders/contest',
    initialState.contest,
    requestReducer<OrdersState['contest'], OrdersState>(initialState.contest)
);

export const downloadAll = new EzeeAsyncAction<OrdersState['downloadAll'], OrderdownloadAllPayload, Order>(
    'orders/downloadAll',
    initialState.downloadAll,
    requestReducer<OrdersState['downloadAll'], Order>(initialState.downloadAll)
);

export const download = new EzeeAsyncAction<OrdersState['download'], OrderdownloadPayload, Order>(
    'orders/download',
    initialState.download,
    requestReducer<OrdersState['download'], Order>(initialState.download)
);

// Reducer
export const ordersReducer = combineReducers<OrdersState>({
    details: details.reducer,
    list: list.reducer,
    consultation: consultation.reducer,
    approve: approve.reducer,
    downloadAll: downloadAll.reducer,
    download: download.reducer,
    contest: contest.reducer,
});

// Saga

export function* ordersSaga() {
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(consultation.type.trigger, simpleAsyncSaga(consultationApiCall, consultation));
    yield takeLatest(approve.type.trigger, simpleAsyncSaga(approveApiCall, approve));
    yield takeLatest(contest.type.trigger, simpleAsyncSaga(contestApiCall, contest));
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(downloadAll.type.trigger, simpleAsyncSaga(downloadAllApiCall, downloadAll));
    yield takeLatest(download.type.trigger, downloadSaga);
}

function* downloadSaga(action: DataAction<OrderdownloadPayload>) {
    try {
        const response = yield call(downloadApiCall, action.payload);

        const fileName = action.payload.filename || `${dayjs().format('YYYY-MM-DD_HH[h]mm')}-document.pdf`;
        downloadBlobFile(fileName, response, 'application/pdf');

        return yield put(download.success(''));
    } catch (error) {
        return yield put(download.failure(error));
    }
}

// Store helpers
export const getOrdersState = (state: MainReducerState) => state.orders;
export const getOrdersListState = (state: MainReducerState) => state.orders.list;
export const getOrdersDetailsState = (state: MainReducerState) => state.orders.details;
export const getConsultationState = (state: MainReducerState) => state.orders.consultation;
export const getApproveState = (state: MainReducerState) => state.orders.approve;
export const getContestState = (state: MainReducerState) => state.orders.contest;
export const getOrdersDownloadAllState = (state: MainReducerState) => state.orders.downloadAll;
export const getOrdersDownloadState = (state: MainReducerState) => state.orders.download;
