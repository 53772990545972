import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Button, Typography } from 'antd';
import { FormProps } from 'antd/lib/form/Form';

import { login as loginAction, getAuthState } from '../../store/actions/auth';

import Seo from '../../components/Seo';
import LoginLayout from '../../components/LoginLayout';
import { getRoute, RoutePathName } from '../../routes';
import { useActions } from '../../hooks';
import formMessages from '../../i18n/formMessages';
import genericMessages from '../../i18n/genericMessages';

const Login: FC = () => {
    const { formatMessage } = useIntl();
    const login = useActions(loginAction.trigger);
    const authState = useSelector(getAuthState);
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        login(values);
    };
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };

    if (authState.isConnected) {
        return <Redirect to="/" />;
    }

    let error: ReactNode = authState.error ? formatMessage(genericMessages.defaultError) : null;

    if (authState.error?.status) {
        switch (authState.error.status) {
            case 401:
                error =
                    authState.error.data.error_code === 'user Disabled' ? (
                        <FormattedMessage
                            id="login.error.401.user_disabled"
                            defaultMessage="Compte désactivé"
                            description="login user disable"
                        />
                    ) : (
                        <FormattedMessage
                            id="login.error.401.invalid"
                            defaultMessage="Informations de connexion incorrectes"
                            description="login invalid credentials"
                        />
                    );
                break;

            case 403:
                error = (
                    <FormattedMessage
                        id="login.error.403.user_not_allowed"
                        defaultMessage="Vous n'avez pas l'autorisation nécessaire"
                        description="login user not allowed"
                    />
                );
                break;

            case 404:
                error = (
                    <FormattedMessage
                        id="login.error.404.user_not_found"
                        defaultMessage="Nom d'utilisateur introuvable"
                        description="login user not found"
                    />
                );
                break;
            case 400:
                authState.error.data.error_code === 'provider Disabled'
                    ? (error = (
                          <FormattedMessage
                              id="login.error.400"
                              defaultMessage="le compte fournisseur correspondant est désactivé"
                              description="provider disabled"
                          />
                      ))
                    : (error = formatMessage(genericMessages.defaultError));
                break;

            default:
                error = formatMessage(genericMessages.defaultError);
                break;
        }
    }
    const pageTitle = formatMessage({
        id: 'login.page_title',
        defaultMessage: 'Connectez-vous',
        description: 'Login page title',
    });

    return (
        <LoginLayout>
            <Seo title={pageTitle} />
            <Form
                className="login-form"
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
                scrollToFirstError
            >
                <Typography.Title level={1}>{pageTitle}</Typography.Title>

                <Form.Item
                    name="username"
                    label={formatMessage({
                        id: 'login.form.item.email',
                        defaultMessage: 'Adresse e-mail',
                        description: 'login email label',
                    })}
                    rules={[requiredRule, { type: 'email', message: formatMessage(formMessages.invalidEmail) }]}
                >
                    <Input
                        placeholder={formatMessage({
                            id: 'login.form.item.input.email',
                            defaultMessage: 'Saisir votre e-mail',
                            description: 'login email placeholder',
                        })}
                        size="large"
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    label={formatMessage({
                        id: 'login.form.item.password',
                        defaultMessage: 'Mot de passe',
                        description: 'login password label',
                    })}
                    rules={[requiredRule]}
                >
                    <Input.Password
                        placeholder={formatMessage({
                            id: 'login.form.item.input.password',
                            defaultMessage: '••••••••••••',
                            description: 'login email placeholder',
                        })}
                        size="large"
                    />
                </Form.Item>

                <Form.Item style={{ margin: '2rem 0' }}>
                    {error ? (
                        <Typography.Paragraph type="danger">
                            <p>{error}</p>
                        </Typography.Paragraph>
                    ) : null}

                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        htmlType="submit"
                        loading={authState.loading}
                        block
                    >
                        <FormattedMessage
                            id="login.button.connect"
                            defaultMessage="Me connecter"
                            description="Login form submit button"
                        />
                    </Button>
                </Form.Item>

                <Link to={getRoute(RoutePathName.forgottenPassword)}>
                    <FormattedMessage
                        id="login.link.password"
                        defaultMessage="Mot de passe oublié ?"
                        description="Login forgotten password link"
                    />
                </Link>
            </Form>
        </LoginLayout>
    );
};

export default Login;
