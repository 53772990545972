import { Modal as AntModal } from 'antd';
import { FC } from 'react';
import { ModalProps } from 'antd/lib/modal';

import { IconClose } from './icons';

const Modal: FC<ModalProps> = ({ children, ...props }) => (
    <AntModal {...props} closeIcon={<IconClose />}>
        {children}
    </AntModal>
);

export default Modal;
