import { combineReducers } from 'redux';
import { all, call, delay, put, race, take, takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { ConformityDocument, ListResponse, ValueListItem } from '../api/apiTypes';
import {
    ConformityDocumentIdPayload,
    ConformityDocumentListPayload,
    list as listApiCall,
    details as detailsApiCall,
    sign as signApiCall,
    ConformityDocumentSignPayload,
} from '../api/ConformityDocuments';
import { DataAction, EzeeAction } from '../helpers/EzeeAction';
import { ValueListItemListPayload, list as getValueListItemApiCall } from '../api/valueListItems';

// States
export interface ConformityDocumentsState {
    details: RequestState<ConformityDocument>;
    list: RequestState<ListResponse<ConformityDocument>>;
    sign: RequestState<ConformityDocument>;
    listCount: RequestState<{ toSign: number; late: number; warning: number }>;
    getCategories: RequestState<ListResponse<ValueListItem>>;
}

const initialState: ConformityDocumentsState = {
    details: {
        loading: false,
    },
    list: {
        loading: false,
    },
    sign: {
        loading: false,
    },
    listCount: {
        loading: false,
    },
    getCategories: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 50,
            pageCount: 0,
        },
        loading: false,
    },
};

export const details = new EzeeAsyncAction<
    ConformityDocumentsState['details'],
    ConformityDocumentIdPayload,
    ConformityDocument
>(
    'ConformityDocuments/details',
    initialState.details,
    requestReducer<ConformityDocumentsState['details'], ConformityDocument>(initialState.details)
);

export const sign = new EzeeAsyncAction<
    ConformityDocumentsState['sign'],
    ConformityDocumentSignPayload,
    ConformityDocument
>(
    'ConformityDocuments/sign',
    initialState.sign,
    requestReducer<ConformityDocumentsState['sign'], ConformityDocument>(initialState.sign)
);

export const list = new EzeeAsyncAction<
    ConformityDocumentsState['list'],
    ConformityDocumentListPayload,
    ListResponse<ConformityDocument>
>(
    'ConformityDocuments/list',
    initialState.list,
    requestReducer<ConformityDocumentsState['list'], ListResponse<ConformityDocument>>(initialState.list)
);

export const getCategories = new EzeeAsyncAction<
    ConformityDocumentsState['getCategories'],
    ValueListItemListPayload,
    ListResponse<ValueListItem>
>(
    'conformityDocuments/getCategories',
    initialState.getCategories,
    requestReducer<ConformityDocumentsState['getCategories'], ListResponse<ValueListItem>>(initialState.getCategories)
);

export const listCount = new EzeeAsyncAction<
    ConformityDocumentsState['listCount'],
    ConformityDocumentListPayload,
    ListResponse<ConformityDocument>
>(
    'ConformityDocuments/listCount',
    initialState.listCount,
    requestReducer<ConformityDocumentsState['listCount'], ListResponse<ConformityDocument>>(initialState.listCount)
);

export const polling = new EzeeAction(
    'notifications_conformity',
    {},
    {
        stopPolling: () => ({}),
        startPolling: () => ({}),
    }
);

// Reducer
export const conformityDocumentsReducer = combineReducers<ConformityDocumentsState>({
    list: list.reducer,
    details: details.reducer,
    sign: sign.reducer,
    listCount: listCount.reducer,
    getCategories: getCategories.reducer,
});

// Saga
export function* conformityDocumentsSaga() {
    yield takeLatest(list.type.trigger, listSaga);
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(sign.type.trigger, simpleAsyncSaga(signApiCall, sign));
    yield takeLatest(listCount.type.trigger, listCountSaga);
    yield takeLatest(getCategories.type.trigger, simpleAsyncSaga(getValueListItemApiCall, getCategories));
}
function* listCountSaga() {
    try {
        const [toSign, warning, late]: Array<ListResponse<ConformityDocument>> = yield all([
            call(listApiCall, { pageSize: 1, delaySignatureStatus: 'toSign' }),
            call(listApiCall, { pageSize: 1, delaySignatureStatus: 'warning' }),
            call(listApiCall, { pageSize: 1, delaySignatureStatus: 'late' }),
        ]);
        return yield put(
            listCount.success({
                toSign: toSign.totalCount,
                warning: warning.totalCount,
                late: late.totalCount,
            })
        );
    } catch (error) {
        return yield put(listCount.failure(error));
    }
}

function* listSaga(action: DataAction<ConformityDocumentListPayload>) {
    if (action.payload.poll) {
        yield put(polling.actions.startPolling());
        yield race([call(pollTask, action), take(polling.actions.stopPolling().type)]);
    } else {
        try {
            const response = yield call(listApiCall, action.payload);

            return yield put(list.success(response));
        } catch (error) {
            return yield put(list.failure(error));
        }
    }
}

/* Worker Function */
function* pollTask(action: DataAction<ConformityDocumentListPayload>) {
    while (true) {
        try {
            const response = yield call(listApiCall, action.payload);

            yield put(list.success(response));
            yield delay(5000);
        } catch (error) {
            yield put(list.failure(error));
            yield delay(5000);
        }
    }
}

// Store helpers
export const getConformityDocumentsListState = (state: MainReducerState) => state.conformityDocuments.list;
export const getConformityDocumentsDetailsState = (state: MainReducerState) => state.conformityDocuments.details;
export const getConformityDocumentsSignState = (state: MainReducerState) => state.conformityDocuments.sign;
export const getConformityDocumentsListCountState = (state: MainReducerState) => state.conformityDocuments.listCount;
export const getConformityDocumentsGetCategoriesState = (state: MainReducerState) =>
    state.conformityDocuments.getCategories;
