import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Certification, ListResponse } from '../api/apiTypes';
import {
    CertificationIdPayload,
    CertificationListPayload,
    list as listApiCall,
    details as detailsApiCall,
} from '../api/certifications';

// States
export interface CertificationsState {
    details: RequestState<Certification>;
    list: RequestState<ListResponse<Certification>>;
}

const initialState: CertificationsState = {
    details: {
        loading: false,
    },
    list: {
        loading: false,
    },
};

export const details = new EzeeAsyncAction<CertificationsState['details'], CertificationIdPayload, Certification>(
    'Certifications/details',
    initialState.details,
    requestReducer<CertificationsState['details'], Certification>(initialState.details)
);

export const list = new EzeeAsyncAction<
    CertificationsState['list'],
    CertificationListPayload,
    ListResponse<Certification>
>(
    'Certifications/list',
    initialState.list,
    requestReducer<CertificationsState['list'], ListResponse<Certification>>(initialState.list)
);

// Reducer
export const certificationsReducer = combineReducers<CertificationsState>({
    list: list.reducer,
    details: details.reducer,
});

// Saga
export function* certificationsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getCertificationsListState = (state: MainReducerState) => state.certifications.list;
export const getCertificationsDetailsState = (state: MainReducerState) => state.certifications.details;
