import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Modal, ModalProps, Row, Typography } from 'antd';
import IconExclamationPoint from './icons/IconExclamationPoint';

interface ConfirmationModalProps extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'> {
    text?: string;
    onConfirmation?: () => void;
    onClose?: () => void;
    isLoading?: boolean;
    title?: string;
    confirmText?: string;
    cancelText?: string;
}
const ConfirmationModal = NiceModal.create<ConfirmationModalProps>(
    ({ title, text, confirmText, cancelText, onConfirmation, onClose, isLoading, ...props }) => {
        const confirmModal = useModal();

        return (
            <Modal
                {...props}
                {...antdModal(confirmModal)}
                afterClose={confirmModal.remove}
                footer={null}
                bodyStyle={{ padding: 24 }}
                centered
            >
                <Row gutter={8}>
                    <Col span={2}>
                        <IconExclamationPoint className="text-orange font-24" />
                    </Col>
                    <Col span={22}>
                        <div>
                            {title && (
                                <div>
                                    <span className="title uppercase font-bold leading-6 text-2xl">{title}</span>
                                </div>
                            )}
                            <Typography.Paragraph className="pt-8 m-0">{text}</Typography.Paragraph>
                            <div className="justify-end flex pt-24">
                                {cancelText && (
                                    <Button
                                        key="cancelBtn"
                                        className="px-16 py-12 flex "
                                        shape="round"
                                        onClick={onClose ?? confirmModal.hide}
                                    >
                                        {cancelText}
                                    </Button>
                                )}
                                {confirmText && (
                                    <Button
                                        key="yesBtn"
                                        className="px-16 py-12 flex "
                                        shape="round"
                                        onClick={onConfirmation}
                                        type="primary"
                                        loading={isLoading}
                                    >
                                        {confirmText}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
        );
    }
);
export default ConfirmationModal;
