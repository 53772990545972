import { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" fill="none" height="1em" viewBox="0 0 24 25">
        <path
            d="M12 9.5V13.25M21 12.5C21 17.4706 16.9706 21.5 12 21.5C7.02944 21.5 3 17.4706 3 12.5C3 7.52944 7.02944 3.5 12 3.5C16.9706 3.5 21 7.52944 21 12.5ZM12 16.25H12.0075V16.2575H12V16.25Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        ></path>
    </svg>
);

const IconExclamationPoint: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon
        component={svg}
        {...props}
        className={`icon-exclamation-point${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconExclamationPoint;
