import { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 21 21">
        <path
            fill="currentColor"
            d="M1.83347 1.54364C1.90373 1.22695 2.20096 1 2.54545 1H13.4545C13.799 1 14.0963 1.22695 14.1665 1.54364L15.9847 9.73876C15.9949 9.78458 16 9.83125 16 9.87805V14.3171C16 14.6942 15.6744 15 15.2727 15H0.727273C0.325611 15 0 14.6942 0 14.3171V9.87805C0 9.83125 0.00512258 9.78458 0.015287 9.73876L1.83347 1.54364ZM3.13683 2.36585L1.62168 9.19512H4.69422C5.01464 9.19512 5.29731 9.39204 5.39023 9.67999L5.89492 11.2439H10.1051L10.6098 9.67999C10.7027 9.39204 10.9854 9.19512 11.3058 9.19512H14.3783L12.8632 2.36585H3.13683ZM14.5455 10.561H11.8453L11.3406 12.1249C11.2477 12.4128 10.9651 12.6098 10.6446 12.6098H5.35537C5.03495 12.6098 4.75228 12.4128 4.65935 12.1249L4.15467 10.561H1.45455V13.6341H14.5455V10.561ZM8 3.73171C8.40166 3.73171 8.72727 4.03746 8.72727 4.41463V6.86346L9.66756 5.98051C9.95158 5.71381 10.4121 5.71381 10.6961 5.98051C10.9801 6.24721 10.9801 6.67962 10.6961 6.94632L8.51426 8.9951C8.23024 9.2618 7.76976 9.2618 7.48574 8.9951L5.30392 6.94632C5.0199 6.67962 5.0199 6.24721 5.30392 5.98051C5.58794 5.71381 6.04842 5.71381 6.33244 5.98051L7.27273 6.86346V4.41463C7.27273 4.03746 7.59834 3.73171 8 3.73171Z"
        ></path>
    </svg>
);

const IconStatusOnHold: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-status-onhold${props.className ? ` ${props.className}` : ''}`} />
);

export default IconStatusOnHold;
