import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Brand, ListResponse } from '../api/apiTypes';
import { BrandIdPayload, BrandListPayload, list as listApiCall, details as detailsApiCall } from '../api/brands';

// States
export interface BrandsState {
    details: RequestState<Brand>;
    list: RequestState<ListResponse<Brand>>;
}

const initialState: BrandsState = {
    details: {
        loading: false,
    },
    list: {
        loading: false,
    },
};

export const details = new EzeeAsyncAction<BrandsState['details'], BrandIdPayload, Brand>(
    'Brands/details',
    initialState.details,
    requestReducer<BrandsState['details'], Brand>(initialState.details)
);

export const list = new EzeeAsyncAction<BrandsState['list'], BrandListPayload, ListResponse<Brand>>(
    'Brands/list',
    initialState.list,
    requestReducer<BrandsState['list'], ListResponse<Brand>>(initialState.list)
);

// Reducer
export const brandsReducer = combineReducers<BrandsState>({
    list: list.reducer,
    details: details.reducer,
});

// Saga
export function* brandsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getBrandsListState = (state: MainReducerState) => state.brands.list;
export const getBrandsDetailsState = (state: MainReducerState) => state.brands.details;
