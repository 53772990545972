import { FC, useEffect, useState, KeyboardEvent, MouseEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Checkbox,
    Divider,
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    message,
    Switch,
    SwitchProps,
    Typography,
} from 'antd';
import { useSelector } from 'react-redux';

import {
    getUserStateById,
    details as userdetails,
    create as userCreate,
    update as userUpdate,
    getUsersCreateState,
    getUsersUpdateState,
} from '../store/actions/users';
import { Permission, PermissionRight, RoleName, User } from '../store/api/apiTypes';

import { rolesMessages, rolesHelpMessages } from '../i18n/roleMessages';
import formMessages from '../i18n/formMessages';
import UserAccountFields from './UserAccountFields';
import TitleAlt from './TitleAlt';
import Select from './Select';
import LabelWithTooltipIcon from './LabelWithTooltipIcon';
import { useActions, usePrevious } from '../hooks';
import { getUser } from '../store/actions/auth';
import { errorMessage, successMessage } from '../helpers/message';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

interface UserFormDrawerProps extends DrawerProps {
    userId?: User['id'];
}

const UserFormDrawer: FC<UserFormDrawerProps> = ({ onClose, userId, ...props }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [loadUserDetails, create, update] = useActions([userdetails.trigger, userCreate.trigger, userUpdate.trigger]);
    const user = useSelector(getUserStateById(userId ?? '-1'));
    const userCreateState = useSelector(getUsersCreateState);
    const userUpdateState = useSelector(getUsersUpdateState);
    const me = useSelector(getUser);
    const [currentRole, setCurrentRole] = useState<RoleName | undefined>(user?.data?.role?.name);
    const [disable, setDisable] = useState<boolean | undefined>(user?.data?.activated);
    const [selectedBrandScope, setSelectedBrandScope] = useState<string[]>([]);
    const previous = usePrevious({ user, userCreateState, userUpdateState });
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        if (values.user.role.name === RoleName.userProvider) {
            let permissions = {};
            if (values.user.permissions) {
                for (const permission of values.user.permissions) {
                    permissions = { ...permissions, [permission]: PermissionRight.write };
                }
                let brandsAndTypesPayload = values.user.brandsAndTypes.map((brandAndType: string) => {
                    if (!brandAndType.includes(',')) {
                        const variable = me?.organization?.brandsAndTypes.find(
                            (brand) => brand.brand.id === brandAndType
                        );
                        return {
                            brand: variable?.brand.id,
                            providerTypes: variable?.providerTypes?.map((providerType) => providerType.id),
                        };
                    } else {
                        const providerType = brandAndType.split(',')[1];
                        const brand = brandAndType.split(',')[0];
                        return { brand, providerTypes: providerType };
                    }
                });
                const duplicateBrand = brandsAndTypesPayload
                    .map((e: { brand: string; providerTypes: string[] }) => e.brand)
                    .map((e: string, i: number, final: string[]) => final.indexOf(e) !== i && i)
                    .filter((obj: number) => brandsAndTypesPayload[obj])
                    .map((e: number) => brandsAndTypesPayload[e].brand);
                for (const duplicate of duplicateBrand) {
                    const newBrandAndType = brandsAndTypesPayload.filter(
                        (e: { brand: string; providerTypes: string[] }) => e.brand === duplicate
                    );
                    brandsAndTypesPayload = brandsAndTypesPayload.filter(
                        (e: { brand: string; providerTypes: string[] }) => e.brand !== duplicate
                    );
                    brandsAndTypesPayload.push({
                        brand: newBrandAndType[0].brand,
                        providerTypes: newBrandAndType
                            .map((e: { brand: string; providerTypes: string }) => e.providerTypes)
                            .flat(),
                    });
                }
                !userId
                    ? create({
                          ...values.user,
                          brandsAndTypes: brandsAndTypesPayload,
                          permissions,
                          organization: me?.organization?.id,
                          role: values.user.role.name,
                      })
                    : update({
                          ...values.user,
                          id: userId,
                          brandsAndTypes: brandsAndTypesPayload,
                          permissions,
                          organization: me?.organization?.id,
                          role: values.user.role.name,
                          activated: !!disable,
                      });
            } else {
                errorMessage({
                    content: formatMessage({
                        id: 'user_form_drawer.on_form_submit.failed_message',
                        defaultMessage: 'Un utilisateur doit avoir au moins un droit',
                        description: 'user Failed Message',
                    }),
                });
            }
        } else {
            !userId
                ? create({
                      ...values.user,
                      brandsAndTypes: me?.organization?.brandsAndTypes.map((brand) => {
                          return {
                              brand: brand?.brand.id,
                              providerTypes: brand?.providerTypes?.map((providerType) => providerType.id),
                          };
                      }),
                      organization: me?.organization?.id,
                      role: values.user.role.name,
                  })
                : update({
                      ...values.user,
                      id: userId,
                      brandsAndTypes: me?.organization?.brandsAndTypes.map((brand) => {
                          return {
                              brand: brand?.brand.id,
                              providerTypes: brand?.providerTypes?.map((providerType) => providerType.id),
                          };
                      }),
                      organization: me?.organization?.id,
                      role: values.user.role.name,
                      activated: !!disable,
                  });
        }
    };
    const onChangeRole = (value: RoleName) => {
        setCurrentRole(value);
    };
    const onChangeDisable: SwitchProps['onChange'] = (checked: boolean) => {
        setDisable(checked);
    };
    const onAfterVisibleChange: DrawerProps['afterVisibleChange'] = (visible) => {
        if (!visible) {
            form.resetFields();
        }
    };
    useEffect(() => {
        if (userId) {
            loadUserDetails({ id: userId });
        }
    }, [loadUserDetails, userId]);

    useEffect(() => {
        // si loading ÉTAIT true, et que MAINTENANT il est false, cela veut dire que la requête est finie
        if (previous?.userCreateState.loading && !userCreateState.loading) {
            if (userCreateState.error || !userCreateState.data) {
                if (userCreateState.error.status === 409)
                    errorMessage({
                        content: formatMessage({
                            id: 'user_form_drawer.error_message.create.409.email',
                            defaultMessage: 'Adresse email déjà utilisée',
                            description: 'user Failed Message',
                        }),
                    });
                else
                    errorMessage({
                        content: formatMessage({
                            id: 'user_form_drawer.error_message.create',
                            defaultMessage: 'Une erreur est survenue au moment de la création',
                            description: 'user Failed Message',
                        }),
                    });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'user_form_drawer.success_message.create',
                        defaultMessage: 'Utilisateur créé avec succès',
                        description: 'user Success Message',
                    }),
                });
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
        }
    }, [previous?.userCreateState.loading, userCreateState, form, formatMessage, onClose]);

    useEffect(() => {
        // si loading ÉTAIT true, et que MAINTENANT il est false, cela veut dire que la requête est finie
        if (previous?.userUpdateState.loading && !userUpdateState.loading) {
            if (userUpdateState.error || !userUpdateState.data) {
                errorMessage({
                    content: formatMessage({
                        id: 'user_form_drawer.error_message.update',
                        defaultMessage: 'Une erreur est survenue lors de la mise à jour',
                        description: 'user Failed Message',
                    }),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'user_form_drawer.success_message.update',
                        defaultMessage: 'Utilisateur mis à jour avec succès',
                        description: 'user Success Message',
                    }),
                });
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
        }
    }, [previous?.userUpdateState.loading, userUpdateState, form, formatMessage, onClose]);

    useEffect(() => {
        // si loading ÉTAIT true, et que MAINTENANT il est false, cela veut dire que la requête est finie
        if (previous?.user.loading && !user.loading) {
            if (user.error || !user.data) {
                message.error(`Une erreur est survenue pendant la récupération de l'utilisateur`);
            } else {
                let permissionValues: Permission[] = [];
                let brandsAndTypes: string[] = [];
                if (user.data.permissions) {
                    for (const permission of Object.keys(user.data.permissions) as Permission[]) {
                        if (user.data.permissions[permission] === PermissionRight.write)
                            permissionValues = [...permissionValues, permission];
                    }
                }
                if (user.data.brandsAndTypes) {
                    for (const brandScope of user.data.brandsAndTypes)
                        brandsAndTypes = [
                            ...brandsAndTypes,
                            ...(brandScope.providerTypes?.map((providerType) => {
                                return brandScope.brand.id + ',' + providerType.id;
                            }) ?? []),
                        ];
                }
                setCurrentRole(user.data.role?.name);
                setDisable(user.data.activated);
                setSelectedBrandScope(brandsAndTypes);
                form.setFieldsValue({ user: { ...user.data, permissions: permissionValues, brandsAndTypes } });
            }
        }
    }, [previous?.user.loading, user, form]);
    return (
        <Drawer
            width={500}
            title={
                userId
                    ? formatMessage({
                          id: 'user_form_drawer.title.edit',
                          defaultMessage: "Modifier l'utilisateur",
                          description: 'Drawer title',
                      })
                    : formatMessage({
                          id: 'user_form_drawer.title.add',
                          defaultMessage: "Ajouter l'utilisateur",
                          description: 'Drawer title',
                      })
            }
            onClose={onClose}
            afterVisibleChange={onAfterVisibleChange}
            {...props}
        >
            <Form form={form} onFinish={onFormValidSubmit} layout="vertical" scrollToFirstError>
                {userId && (
                    <div className="flex flex-between" style={{ marginBottom: '1.5rem' }}>
                        <div className="flex">
                            <TitleAlt level={4} style={{ margin: 0 }}>
                                <FormattedMessage
                                    id="user_form_drawer.form.title"
                                    defaultMessage="État du compte"
                                    description="form section title"
                                />
                            </TitleAlt>

                            {!disable ? (
                                <Typography.Text className="user-disable-edit">
                                    <FormattedMessage
                                        id="user_form_drawer.form.text.user_disable"
                                        defaultMessage="Désactivé"
                                        description="user disable"
                                    />
                                </Typography.Text>
                            ) : (
                                <Typography.Text className="user-activated-edit">
                                    <FormattedMessage
                                        id="user_form_drawer.form.text.user_activated"
                                        defaultMessage="Activé"
                                        description="user activated"
                                    />
                                </Typography.Text>
                            )}
                        </div>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            className="switch-style"
                            defaultChecked={true}
                            checked={disable}
                            onChange={onChangeDisable}
                        ></Switch>
                    </div>
                )}
                <Divider style={{ margin: '2em 0' }}></Divider>
                <TitleAlt level={4}>
                    <FormattedMessage
                        id="user_form_drawer.form.title.role"
                        defaultMessage="Rôle et droits"
                        description="form section title"
                    />
                </TitleAlt>
                <Form.Item
                    name={['user', 'role', 'name']}
                    rules={[requiredRule]}
                    extra={
                        currentRole ? (
                            <FormattedMessage {...rolesHelpMessages.get(currentRole)} values={{ br: <br /> }} />
                        ) : undefined
                    }
                >
                    <Select
                        placeholder={formatMessage({
                            id: 'user_form_drawer.form.item.select.role',
                            defaultMessage: 'Sélectionner un rôle',
                            description: 'input placeholder',
                        })}
                        size="large"
                        onChange={onChangeRole}
                    >
                        {[RoleName.adminProvider, RoleName.userProvider, RoleName.readerProvider].map((role) => (
                            <Select.Option value={role} key={role}>
                                <FormattedMessage {...rolesMessages.get(role)} />
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {currentRole === RoleName.userProvider && (
                    <Form.Item
                        name={['user', 'permissions']}
                        label={formatMessage({
                            id: 'user_form_drawer.form.item.permission',
                            defaultMessage: 'Sélectionner les droits associés',
                            description: 'field label',
                        })}
                    >
                        <Checkbox.Group>
                            <Checkbox value={'conformityDocument'}>
                                <LabelWithTooltipIcon
                                    label={
                                        <FormattedMessage
                                            id="user_form_drawer.form.item.checkbox.label.signature"
                                            defaultMessage="Signer les documents réglementaires"
                                            description="permission"
                                        />
                                    }
                                    tooltip={formatMessage({
                                        id: 'user_form_drawer.form.item.checkbox.tooltip.signature',
                                        defaultMessage:
                                            "L'utilisateur pourra signer les documents de conformité nécessaires à la collaboration avec Christian Dior Couture. Si cette option n'est pas cochée, l'utilisateur ne pourra que les consulter.",
                                        description: 'permission help',
                                    })}
                                />
                            </Checkbox>
                            <Checkbox value={'documentExchange'}>
                                <LabelWithTooltipIcon
                                    label={
                                        <FormattedMessage
                                            id="user_form_drawer.form.item.checkbox.label.document_exchange"
                                            defaultMessage="Être notifié des nouveaux OA/commessa & charger des attestations d'origine et certifications matière"
                                            description="permission"
                                        />
                                    }
                                    tooltip={formatMessage({
                                        id: 'user_form_drawer.form.item.checkbox.tooltip.document_exchange',
                                        defaultMessage:
                                            "L'utilisateur recevra les notifications par email lorsqu'un nouvel ordre d'achat/ commessa sera déposé par Dior sur la plateforme. De son coté, l'utilisateur pourra y déposer les attestations fournisseurs ainsi que les certifications matières.",
                                        description: 'permission help',
                                    })}
                                />
                            </Checkbox>
                            <Checkbox value={'majInfoProducts'}>
                                <LabelWithTooltipIcon
                                    label={
                                        <FormattedMessage
                                            id="user_form_drawer.form.item.checkbox.label.maj_info"
                                            defaultMessage="Renseigner / mettre à jour les informations de production"
                                            description="permission"
                                        />
                                    }
                                    tooltip={formatMessage({
                                        id: 'user_form_drawer.form.item.checkbox.tooltip.maj_info',
                                        defaultMessage:
                                            "L'utilisateur sera informé de la nécessité de compléter ou de mettre à jour ses informations de production une fois par an. Ces informations portent sur les usines (nombres d'employés, audits menés, sous-traitance, chiffres d'affaires)",
                                        description: 'permission help',
                                    })}
                                />
                            </Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                )}
                <Divider style={{ margin: '2em 0' }}></Divider>
                <UserAccountFields
                    title={formatMessage({
                        id: 'user_account_fileds.title',
                        defaultMessage: 'Informations',
                        description: 'form section title',
                    })}
                    titleLevel={4}
                    targetRole={currentRole}
                    language={user.data?.language}
                    brandScopeSelected={selectedBrandScope}
                    edit
                />
                <Divider />
                <Button type="primary" htmlType="submit" shape="round" size="large" block>
                    {userId
                        ? formatMessage({
                              id: 'user_form_drawer.button_edit',
                              defaultMessage: 'Enregistrer les modifications',
                              description: 'form submit button',
                          })
                        : formatMessage({
                              id: 'user_form_drawer.button_add',
                              defaultMessage: "Ajouter l'utilisateur",
                              description: 'form submit button',
                          })}
                </Button>
            </Form>
        </Drawer>
    );
};

export default UserFormDrawer;
