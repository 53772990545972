export const cookiesNotice = {
    fr: '/pdf/Notice_Cookies_COFOU_FR.pdf',
    en: '/pdf/Notice_Cookies_COFOU_EN.pdf',
    it: '/pdf/Notice_Cookies_COFOU_IT.pdf',
    pt: '/pdf/Notice_Cookies_COFOU_EN.pdf',
};

export const termsOfUse = {
    fr: '/pdf/COFOU_CGU_FR.pdf',
    en: '/pdf/COFOU_Terms_of_Use_EN.pdf',
    it: '/pdf/COFOU_CGU_IT.pdf',
    pt: '/pdf/COFOU_Terms_of_Use_EN.pdf',
};

export const legalMentions = {
    fr: '/pdf/COFOU_Mentions_légales_FR.pdf',
    en: '/pdf/COFOU_Legal_Notice_ENG.pdf',
    it: '/pdf/COFOU_Mentions_légales_IT.pdf',
    pt: '/pdf/COFOU_Legal_Notice_ENG.pdf',
};
