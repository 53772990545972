import { call, delay, put } from 'redux-saga/effects';

import { DataAction } from './EzeeAction';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

export type CallableAsyncFunc<Payload> = (payload: Payload) => Promise<any>;

export function simpleAsyncSaga<Payload>(func: CallableAsyncFunc<Payload>, action: EzeeAsyncAction<any, Payload>) {
    return function* asyncSaga(actionData: DataAction<Payload & { throttling?: number }>) {
        try {
            const { throttling, ...payload } = actionData.payload || {};

            if (throttling) {
                yield delay(throttling);
            }

            const response = yield call(func, payload as Payload);
            return yield put(action.success(response));
        } catch (error) {
            return yield put(action.failure(error));
        }
    };
}
