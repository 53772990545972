import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Material, ListResponse } from '../api/apiTypes';
import {
    MaterialIdPayload,
    MaterialListPayload,
    MaterialCreatePayload,
    list as listApiCall,
    create as createApiCall,
    remove as removeApiCall,
    details as detailsApiCall,
} from '../api/material';

// States
export interface MaterialsState {
    create: RequestState<Material>;
    remove: RequestState<Material>;
    details: RequestState<Material>;
    list: RequestState<ListResponse<Material>>;
}

const initialState: MaterialsState = {
    create: {
        loading: false,
    },
    remove: {
        loading: false,
    },
    details: {
        loading: false,
    },
    list: {
        loading: false,
    },
};

export const create = new EzeeAsyncAction<MaterialsState['create'], MaterialCreatePayload, Material>(
    'Materials/create',
    initialState.create,
    requestReducer<MaterialsState['create'], Material>(initialState.create)
);

export const remove = new EzeeAsyncAction<MaterialsState['remove'], MaterialIdPayload, Material>(
    'Materials/remove',
    initialState.remove,
    requestReducer<MaterialsState['remove'], Material>(initialState.remove)
);

export const details = new EzeeAsyncAction<MaterialsState['details'], MaterialIdPayload, Material>(
    'Materials/details',
    initialState.details,
    requestReducer<MaterialsState['details'], Material>(initialState.details)
);

export const list = new EzeeAsyncAction<MaterialsState['list'], MaterialListPayload, ListResponse<Material>>(
    'Materials/list',
    initialState.list,
    requestReducer<MaterialsState['list'], ListResponse<Material>>(initialState.list)
);

// Reducer
export const materialsReducer = combineReducers<MaterialsState>({
    list: list.reducer,
    create: create.reducer,
    remove: remove.reducer,
    details: details.reducer,
});

// Saga
export function* materialsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getMaterialsListState = (state: MainReducerState) => state.materials.list;
export const getMaterialsCreateState = (state: MainReducerState) => state.materials.create;
export const getMaterialsRemoveState = (state: MainReducerState) => state.materials.remove;
export const getMaterialsDetailsState = (state: MainReducerState) => state.materials.details;
