import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Factory, ListResponse } from '../api/apiTypes';
import {
    FactoryIdPayload,
    FactoryListPayload,
    FactoryCreatePayload,
    FactoryUpdatePayload,
    list as listApiCall,
    create as createApiCall,
    remove as removeApiCall,
    details as detailsApiCall,
    update as updateApiCall,
} from '../api/factories';

// States
export interface FactoriesState {
    create: RequestState<Factory>;
    remove: RequestState<Factory>;
    details: RequestState<Factory>;
    list: RequestState<ListResponse<Factory>>;
    update: RequestState<Factory>;
}

const initialState: FactoriesState = {
    create: {
        loading: false,
    },
    remove: {
        loading: false,
    },
    details: {
        loading: false,
    },
    list: {
        loading: false,
    },
    update: {
        loading: false,
    },
};

export const create = new EzeeAsyncAction<FactoriesState['create'], FactoryCreatePayload, Factory>(
    'Factories/create',
    initialState.create,
    requestReducer<FactoriesState['create'], Factory>(initialState.create)
);

export const update = new EzeeAsyncAction<FactoriesState['update'], FactoryUpdatePayload, Factory>(
    'Factories/update',
    initialState.update,
    requestReducer<FactoriesState['update'], Factory>(initialState.update)
);

export const remove = new EzeeAsyncAction<FactoriesState['remove'], FactoryIdPayload, Factory>(
    'Factories/remove',
    initialState.remove,
    requestReducer<FactoriesState['remove'], Factory>(initialState.remove)
);

export const details = new EzeeAsyncAction<FactoriesState['details'], FactoryIdPayload, Factory>(
    'Factories/details',
    initialState.details,
    requestReducer<FactoriesState['details'], Factory>(initialState.details)
);

export const list = new EzeeAsyncAction<FactoriesState['list'], FactoryListPayload, ListResponse<Factory>>(
    'Factories/list',
    initialState.list,
    requestReducer<FactoriesState['list'], ListResponse<Factory>>(initialState.list)
);

// Reducer
export const factoriesReducer = combineReducers<FactoriesState>({
    list: list.reducer,
    create: create.reducer,
    remove: remove.reducer,
    details: details.reducer,
    update: update.reducer,
});

// Saga
export function* factoriesSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
}

// Store helpers
export const getFactoriesListState = (state: MainReducerState) => state.factories.list;
export const getFactoriesCreateState = (state: MainReducerState) => state.factories.create;
export const getFactoriesRemoveState = (state: MainReducerState) => state.factories.remove;
export const getFactoriesDetailsState = (state: MainReducerState) => state.factories.details;
export const getFactoriesUpdateState = (state: MainReducerState) => state.factories.update;
