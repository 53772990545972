import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Revenue, ListResponse } from '../api/apiTypes';
import {
    RevenueIdPayload,
    RevenueListPayload,
    RevenueCreatePayload,
    RevenueUpdatePayload,
    list as listApiCall,
    create as createApiCall,
    remove as removeApiCall,
    details as detailsApiCall,
    update as updateApiCall,
} from '../api/revenue';

// States
export interface RevenuesState {
    create: RequestState<Revenue>;
    remove: RequestState<Revenue>;
    details: RequestState<Revenue>;
    list: RequestState<ListResponse<Revenue>>;
    update: RequestState<Revenue>;
}

const initialState: RevenuesState = {
    create: {
        loading: false,
    },
    remove: {
        loading: false,
    },
    details: {
        loading: false,
    },
    list: {
        loading: false,
    },
    update: {
        loading: false,
    },
};

export const create = new EzeeAsyncAction<RevenuesState['create'], RevenueCreatePayload, Revenue>(
    'Revenues/create',
    initialState.create,
    requestReducer<RevenuesState['create'], Revenue>(initialState.create)
);

export const update = new EzeeAsyncAction<RevenuesState['update'], RevenueUpdatePayload, Revenue>(
    'Revenues/update',
    initialState.update,
    requestReducer<RevenuesState['update'], Revenue>(initialState.update)
);

export const remove = new EzeeAsyncAction<RevenuesState['remove'], RevenueIdPayload, Revenue>(
    'Revenues/remove',
    initialState.remove,
    requestReducer<RevenuesState['remove'], Revenue>(initialState.remove)
);

export const details = new EzeeAsyncAction<RevenuesState['details'], RevenueIdPayload, Revenue>(
    'Revenues/details',
    initialState.details,
    requestReducer<RevenuesState['details'], Revenue>(initialState.details)
);

export const list = new EzeeAsyncAction<RevenuesState['list'], RevenueListPayload, ListResponse<Revenue>>(
    'Revenues/list',
    initialState.list,
    requestReducer<RevenuesState['list'], ListResponse<Revenue>>(initialState.list)
);

// Reducer
export const revenuesReducer = combineReducers<RevenuesState>({
    list: list.reducer,
    create: create.reducer,
    remove: remove.reducer,
    details: details.reducer,
    update: update.reducer,
});

// Saga
export function* revenuesSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
}

// Store helpers
export const getRevenuesListState = (state: MainReducerState) => state.revenues.list;
export const getRevenuesCreateState = (state: MainReducerState) => state.revenues.create;
export const getRevenuesRemoveState = (state: MainReducerState) => state.revenues.remove;
export const getRevenuesUpdateState = (state: MainReducerState) => state.revenues.update;
export const getRevenuesDetailsState = (state: MainReducerState) => state.revenues.details;
