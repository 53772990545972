import api from './_client';
import { SearchPaginationQuery } from './';

// Types
import { SubContractor } from './apiTypes';

// Controller Interface
export interface SubContractorCreatePayload extends Omit<SubContractor, 'id'> {}
export interface SubContractorUpdatePayload extends SubContractor {}

export interface SubContractorIdPayload {
    id: SubContractor['id'];
}

export type SubContractorListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: SubContractorListPayload = {}) => {
    return await api.get(`/subContractors`, { params: payload });
};

export const create = async (payload: SubContractorCreatePayload) => {
    return await api.post(`/subContractors`, payload);
};

export const update = async (payload: SubContractorUpdatePayload) => {
    return await api.put(`/subContractors/${payload.id}`, payload);
};

export const details = async (payload: SubContractorIdPayload) => {
    return await api.get(`/subContractors/${payload.id}`);
};

export const remove = async (payload: SubContractorIdPayload) => {
    return await api.delete(`/subContractors/${payload.id}`);
};
